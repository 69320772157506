import { FC } from "react";
import { Popover } from "antd";

import CircleBar from "components/PriorityApp/SimpleView/CircleBar";

//@ts-ignore
import { ReactComponent as PriorityIcon } from "assets/images/priorities/priority-icon.svg";

import { Wrapper, Header, HeaderContent, Title, Text, Body, Graph, Content, Formula } from "./styled";

interface IProps {
  value: string;
  children: any;
}

const PriorityScorePopover: FC<IProps> = ({ value, children }) => {
  const numberedValue = parseInt(value);

  const PopoverContent = () => {
    return (
      <Wrapper>
        <Header>
          <PriorityIcon />

          <HeaderContent>
            <Title>Priority Score</Title>
            <Text>Priority score that represents the impact of an action relative to effort.</Text>
          </HeaderContent>
        </Header>
        <Body>
          <Graph>
            <CircleBar value={numberedValue} />
          </Graph>

          <Content>
            <Title>Formula</Title>
            <Formula><span>Mission Impact</span> *30% + <span>Goal Impact</span> *15% + <span>Indirect Impact</span> *25% + <span>Urgency</span> *15% + <span>Duration</span> *15%</Formula>
          </Content>
        </Body>
      </Wrapper>
    );
  }

  return (
    <Popover
      align={{ offset: [15, 0] }}
      content={PopoverContent}
      title={null}
      trigger="hover"
      //@ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      placement="bottomRight"
      overlayClassName="target_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      {children}
    </Popover>
  );
}

export default PriorityScorePopover;
