import Notify from "utils/notify";
import { updatePassword } from "api";
import { ActionTypes } from "./types";

export const UpdatePassword = (Data, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await updatePassword(Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (status === 200) {
      callback();
      Notify.success(data.message);
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
