import { FC, useState } from "react";
import { Popover } from "antd";

import TypeBadge from "../../TypeBadge";
import ActivityPopup from "../../ActivityPopup";

interface IProps {
  type: string;
  isClick?: boolean;
  isArrow?: boolean;
  containerStyle?: any;
  offset?: any;
}

const Type: FC<IProps> = ({ type, offset }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!type) return <div style={{ width: "26px", minWidth: "26px" }} />;

  const PopoverContent = () => {
    return <ActivityPopup onClose={handleClose} />;
  };

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: offset ? offset : [108, 0] }}
      // @ts-ignore
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="custom_popover type_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <span style={{ cursor: 'pointer' }}>
        <TypeBadge type={type} />
      </span>
    </Popover>
  );
};

export default Type;
