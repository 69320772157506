import React, { useState, ReactNode } from "react";
import { TooltipPlacement } from 'antd/lib/tooltip';
import { Tooltip as AntdTooltip } from "antd";
import PropTypes from "prop-types";
import styled from 'styled-components';
// @ts-ignore
import styles from "./styles.module.scss";

interface TooltipProps {
  title: string | boolean | null;
  text?: string | ReactNode;
  type?: 'primary' | 'secondary' | 'tertiary';
  placement?: TooltipPlacement;
  alternative?: boolean;
  customOverlayClassName?: string;
  children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ title, text, type = 'primary', placement, alternative, customOverlayClassName, children, ...rest }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (title === null)
    return children;

  const content: () => JSX.Element = () => (
    <Content  data-testid="antd-hover">
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Content>
  );

  if (alternative) {
    return (
      <div
        data-testid="antd-hover"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <AntdTooltip
          title={type === 'tertiary' ? content() : title}
          overlayClassName={customOverlayClassName ? `${styles[type]} ${customOverlayClassName}` : styles[type]}
          placement={placement}
          open={isOpen}
          {...rest}
        >
          {children}
        </AntdTooltip>
      </div>
    );
  }

  return (
    <AntdTooltip
      data-testid="antd-hover"
      title={type === 'tertiary' ? content() : title}
      overlayClassName={customOverlayClassName ? `${styles[type]} ${customOverlayClassName}` : styles[type]}
      placement={placement}
      {...rest}
    >
      {children}
    </AntdTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // text: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom']),
  alternative: PropTypes.bool,
  children: PropTypes.node
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 4px;
  border-top: 3px solid var(--Jewel-Gradient, #1263DE);
  background: var(--Dark-mode-bg, #FFF);
  padding: 16px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const Text = styled.span`
  color: var(--Neutral-700, var(--Input-heading, #404040));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
}`

export default Tooltip;
