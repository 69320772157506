import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Text = styled.span`
  color: var(--text-body-light-state-gray, #798B99);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 6px 12px;
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  gap: 6px;
  text-decoration: none;

  ${({ $style }) => $style && $style === 'secondary' && css`
    background: var(--primary-50, #ECF3F6);
  `}

  ${({ $style }) => $style && $style === 'thernary' && css`
    border: 1px solid var(--primary-500-main, #1271A6);
    background: transparent;
  `}
`;
