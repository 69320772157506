import { FC, useRef, useEffect } from 'react';
import { steps } from "../../data";

import {
  Wrapper,

  Step,
  CircleWrap,
  Circle,
  Content,
  Title,
  Text
} from "./styled";

interface IProps {
  data: any;
  items: any;
  step: number;
  onChange: (step: number) => void;
}

const Steps: FC<IProps> = ({ data, items, step, onChange }) => {
  const stepRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    if (stepRefs.current[step]) {
      stepRefs.current[step]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [step]);

  const handleChange = (step: number) => () => {
    onChange(step);
  }

  let disableNextSteps = false;

  const parsedSteps = steps.reduce((acc: any, step: { label: string; value: number }) => {
    let label = "";
    let disabled = disableNextSteps;

    const getLabel = (data: any) => {
      return data.reduce((acc: any, item: any, index: number) => {
        return acc + (index > 0 ? ', ' : '') + item.label;
      }, '');
    }

    switch (step.value) {
      case 1:
        label = getLabel(data?.roles);
        break;
      case 2:
        label = getLabel(data?.values);
        break;
      case 3:
        label = data?.principle?.name;
        break;
      case 4:
        label = data?.code_of_conduct?.name;
        break;
      case 5:
        label = data?.purpose?.name
        break;
      case 6:
        label = data?.vision?.name;
        break;
      case 7:
        label = "How will your dream life look?"
      default:
        break;
    }
  
    // Determine if the current step should disable the subsequent steps
    if (!disableNextSteps) {
      switch (step.value) {
        case 1:
          disableNextSteps = !data?.roles?.length || data?.roles?.length > 3;
          break;
        case 2:
          disableNextSteps = !data?.values?.length || data?.values?.length > 3;
          break;
        case 3:
          disableNextSteps = !data?.principle?.name;
          break;
        case 4:
          disableNextSteps = !data?.code_of_conduct?.name;
          break;
        case 5:
          disableNextSteps = !data?.purpose?.name;
          break;
        case 6:
          disableNextSteps = !data?.vision?.name || !data?.conclusion;
          break;
        case 7:
          disableNextSteps = !data?.conclusion;
        default:
          break;
      }
    }
  
    // Add the step to the resulting object with its disabled state
    acc[step.value] = {
      label,
      disabled,
    };

    return acc;
  }, {});

  return (
    <Wrapper>
      {items?.map((item: any, i: number) =>
        <Step
          key={i}
          onClick={handleChange(item.step)}
          ref={(el) => (stepRefs.current[item.step] = el)}
          disabled={parsedSteps[item.step].disabled}
        >
          <CircleWrap
            $first={i === 0}
            $last={i === items.length - 1}
            $active={item.step <= step || (item.step === 7 ? !!data?.conclusion : parsedSteps[item.step].label)}
            $next={item.step === step + 1 && step < 7 || (item.step === 7 ? !!data?.conclusion : parsedSteps[item.step].label)}
          >
            <Circle
              $active={item.step === step}
              $next={!!parsedSteps[item.step-1]?.label}
              $completed={item.step === 7 ? !!data?.conclusion : (!!parsedSteps[item.step].label)}>
            </Circle>
          </CircleWrap>
          <Content>
            <Title>{item.title}</Title>
            <Text>{parsedSteps[item.step].label || item.disc}</Text>
          </Content>
        </Step>
      )}
    </Wrapper>
  );
};

export default Steps;
