import React from "react";

import AreasDescription from "components/Journeys/AreasSection";

import { ReactComponent as CloseIcon } from "assets/images/journeys/close.svg";
import { ReactComponent as FlagIcon } from "assets/images/journeys/flag.svg";

import {
  Background,
  Wrapper,
  Content,
  Header,
  TitleWrap,
  Title,
  Close,
} from "./styled";

const AreasList = ({ isActiveState, data = [], onClose, selectedName }) => {
  return (
    <>
      <Wrapper $active={isActiveState}>
        <Header>
          <TitleWrap>
            <FlagIcon />
            <Title>{selectedName} Areas</Title>
          </TitleWrap>

          <Close type="button" onClick={onClose}>
            <CloseIcon />
          </Close>
        </Header>

        <Content>
          <AreasDescription areas={data} />
        </Content>
      </Wrapper>

      <Background $active={isActiveState} onClick={onClose} />
    </>
  );
};

export default AreasList;
