import { Modal } from "antd";
import React from "react";
import styled from "styled-components";
import StatmentBlueIcon from "assets/svg/StatmentIconBlue.svg";

const DeleteModal = ({
  deleteOpenModal,
  setDeleteOpenModal,
  deleteMissionloading,
  missionStatmentGoal,
  title,
  description,
  onClick = () => {},
}) => {
  return (
    <Container>
      <Modal
        bodyStyle={{
          padding: "0",
        }}
        open={deleteOpenModal}
        width={"500px"}
        centered
        footer={null}
        onCancel={() => setDeleteOpenModal(false)}
      >
        <Content>
          <Image src={StatmentBlueIcon} />
          <Heading>{title}</Heading>
          <Text>
            {description}
            <Bolded>
              {`${missionStatmentGoal?.area} ${missionStatmentGoal?.category}`}
            </Bolded>
            ?
          </Text>
          <Button isBg onClick={onClick}>
            {deleteMissionloading ? "Deleting" : "Delete"}
          </Button>
          <Button onClick={() => setDeleteOpenModal(false)}>Cancel</Button>
        </Content>
      </Modal>
    </Container>
  );
};

export default DeleteModal;
const Container = styled.div`
  width: 100%;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  height: 300px;
`;
const Image = styled.img``;
const Bolded = styled.span`
  font-weight: bold;
  margin-left: 0.3rem;
  text-decoration: underline;
`;

const Heading = styled.h2`
  margin: 0;
  padding: 0;
  color: #06203a;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
const Text = styled.p`
  margin: 0;
  padding: 0;
  color: #06203a;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const Button = styled.button`
  margin: 0;
  padding: 0;
  background-color: ${({ isBg }) => (isBg ? "#FFF4E4" : "transparent")};
  color: ${({ isBg }) => (isBg ? "#F90" : "#737373")};
  outline: none;
  border: none;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 134px;
  padding: 6px 12px;
`;
