import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-top: 32px;

  ::-webkit-scrollbar {
    width: 14px;
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #BAC6D3;
  }
`;

export const ScollableArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 548px;
`;

export const Title = styled.span`
  color: var(--fill-icon-oxford-blue, #06203A);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 14px;
`;

export const Text = styled.span`
  color: var(--Oxford-blue, #06203A);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const List = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
  margin-bottom: 20px;
`;

export const ListInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Footer = styled.div`
  width: 100%;
  background: #fff;
  padding: 8px 0 32px 0;
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;

  &::before {
    content: '';
    width: 100%;
    height: 56px;
    pointer-events: none;
    background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    position: absolute;
    top: 0;
    margin-top: -56px;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 32px;
`;

export const Button = styled.button`
  padding: 10px 16px;
  border-radius: 2px;
  background: var(--primary-500-main, #1271A6);
  color: var(--generic-white, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border: none;
`;
