import styled, { css } from 'styled-components';

import { Avatar } from "antd";
import Button from "components/Button";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const TableHeadCol = styled.div`
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $flex }) => $flex && css`
    flex: ${$flex || '1'};
  `}
`;

export const Heading = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
  `}
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ContainerItem = styled.div`
  width: 100%;
  height: 100%;
`;

export const TableContainer = styled.div`
  padding: 10px 0 10px 10px;
  width: 100%;

  @media screen and (max-width: 1025px) {
    margin-bottom: 100px;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #71aaca;
    border-radius: 10px;
    background: #71aaca;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #116595;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #116595;
  }
`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;

export const Row = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  border-left: ${({ border }) => `5px solid ${border}`};
  margin-bottom: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
  height: 3.5rem;
  position: relative;

  @media screen and (max-width: 743px) {
    width: 100%;
  }

  @media (min-width: 744px) and (max-width: 1256px) {
    width: 98%;
  }
  

  ${({ $locked }) =>
    $locked &&
    `
    background-color: #ECF3F6 !important;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);

    .drag-icon {
      opacity: 0.6;
    }
  `}
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 10vw;
  cursor: default;

  ${({ theme }) => theme.max("sm")`
    min-width: initial;
    padding-right: 6px;
  `}
  @media screen and (max-width: 743px) {
    width: 22%;
  }
  @media (min-width: 744px) and (max-width: 1256px) {
    width: 23%;
  }
  
  
`;

export const AreaSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 16vw;
  padding-right: 24px;

  @media screen and (max-width: 743px) {
    width: 28%;
  }
  @media (min-width: 744px) and (max-width: 1256px) {
    width: 28%;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: "Inter";
  font-size: 14px;
  line-height: 20px;

  ${({ $area }) => $area && `
    font-weight: 400;
    color: var(--neutral-900, var(--Color-2, #171717));
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${({ $category }) => $category && `
    font-weight: 600;
    color: var(--neutral-900, var(--Color-2, #171717));
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  ${({ theme }) => theme.max("sm")`
    font-size: 12px;
    line-height: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${({ $area }) => $area && `
      width: 60px;
    `}
  `}
`;

export const MidSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 35%;
  cursor: default;
  min-width: 170px;

  @media screen and (max-width: 743px) {
    min-width: 50px;
    width: 30%;
  }
`;

export const HeaderText = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  padding-left: ${({ isRight }) => isRight && "5rem"};

  ${({ theme }) => theme.max("sm")`
    padding-left: 0rem;
  `}
`;

export const IdAvatar = styled(Avatar)`
  background-color: #e7f1f6 ;
  /* margin: 0 90px 0 8px; */
  margin: 0 50px 0 8px;

  @media screen and (max-width: 743px) {
    margin: 0 7px 0 3px;
  }

  .ant-avatar-string {
    color: #1193ea !important;
    font-family: "Inter" !important ;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    vertical-align: middle !important;
  }
`;

export const HeaderFirstSection = styled.div`
  /* width: calc(40px + 50px + 74px); */
  width: calc(40px + 15px + 74px);
  padding-left: 40px;

  ${({ theme }) => theme.max("sm")`
   width: calc(40px + 50px + 25px);
  `}
`;

export const HeaderLeftSection = styled.div`
  width: 25.5%;
  display: flex;
  min-width: 170px;

  ${({ theme }) => theme.max("xl")`
    min-width: 170px;
    width: 28%;
  `}
  ${({ theme }) => theme.max("lg")`
    min-width: 170px;
    width: 27%;
  `}
  ${({ theme }) => theme.max("md")`
    min-width: 130px;
    width: 25.5%;
  `}

  ${({ theme }) => theme.max("sm")`
    min-width: 100px;
    width: 17.5%;
    padding-left: 1rem;
  `}
`;

export const HeaderMidSection = styled.div`
  width: 35%;
  display: flex;
  min-width: 170px;
`;

export const HeaderLockSection = styled.div`
  width: 36px;
  position: absolute;
  right: 18px;
  top: 12px;
`;

export const DragIcon = styled.img`
  @media screen and (max-width: 743px) {
    width: 22px;
    height: 22px;
  }
`;

export const DragButton = styled.div`
  display: flex;
  margin-left: 10px;
  cursor: grab;

  @media screen and (max-width: 743px) {
    margin-left: 5px;
  }
`;

export const LockButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 12px;
  bottom: 12px;
  width: 20px;
  height: 20px;
`;

export const BtnWrapper = styled.div`
  margin-left: 16px;
`;
