import { timeToConvert } from "components/PriorityApp/SimpleView/ActionItemForm/RemindersSection";
import {
  CardAction,
  CardTime,
  CardTitle,
  TableCard,
  TableCards,
  TableDate,
  Wrapper,
} from "./styled";
import { Type } from "components/PriorityApp/SimpleView/Elements";

const ScheduleList = ({ date, data }: { date: Date; data: any }) => {
  const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const todayDateMatch = new Date().getDate() === date.getDate();

  const filteredData =
    data?.length > 0
      ? data?.filter((item: any) => {
          return new Date(item.scheduled_date).getDate() === date.getDate();
        })
      : [];

  const formateDataByTime = filteredData?.sort((a: any, b: any) => {
    if (a.start_time < b.start_time) {
      return -1;
    }
    if (a.start_time > b.start_time) {
      return 1;
    }
    return 0;
  });

  return (
    <Wrapper>
      <TableDate today={todayDateMatch}>
        {date.getDate()} <span>{dayNames[date.getDay()]}</span>
      </TableDate>

      <TableCards>
        {formateDataByTime?.map((item: any, index: number) => {
          return (
            <TableCard key={index}>
              <CardTitle>{item.activity_name}</CardTitle>

              <CardAction>
                <Type
                  type={item?.activity_type || "task"}
                  isClick
                  containerStyle={{
                    left: "151px",
                  }}
                />
                <CardTime>
                  {timeToConvert(item.start_time)} -{" "}
                  {timeToConvert(item.end_time)}
                </CardTime>
              </CardAction>
            </TableCard>
          );
        })}
      </TableCards>
    </Wrapper>
  );
};

export default ScheduleList;
