import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  width: 100%;
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.15);
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

export const Title = styled.span`
  color: #0F2338;
  leading-trim: both;
  text-edge: cap;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const DaySwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DaySwitchBtn = styled.button`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: transparent;
`;

export const SelectedDay = styled.span`
  color: var(--Navy, #0D354F);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

export const ImageContainer = styled.div`
  padding: 12px 0;
  border-top: 1px solid #BCCEDD;
  border-bottom: 1px solid #BCCEDD;
`;

export const Image = styled.img`
  border-radius: 2px;
  width: 100%;
  object-fit: cover;
`;
