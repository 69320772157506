import React, { ReactNode } from "react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

// @ts-ignore
import ButtonAtom from "../../atoms/Button.js";

type ButtonProps = {
  styles?: React.CSSProperties;
  children?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  icon?: string;
  svgIcon?: boolean;
  isLeft?: boolean;
};

const Button = ({
  styles = {},
  children,
  loading,
  disabled,
  icon,
  svgIcon,
  isLeft,
  ...rest
}: ButtonProps) => {
  return (
    <ButtonAtom {...rest} disabled={loading || disabled} styles={styles}>
      {isLeft && !svgIcon && <Icon src={icon} alt="Icon" />}
      {icon && svgIcon && isLeft && icon}
      {children}
      {loading && <StyledSpinner animation="border" />}
    </ButtonAtom>
  );
};

export default Button;

const StyledSpinner = styled(Spinner).attrs({ "aria-label": "loading" })`
  width: 25px !important;
  height: 25px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const Icon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;
