import { FC } from 'react';
import { Tooltip } from 'elements';

import { Label } from './styled';

interface IProps {
  indirectImpact: number;
}

const Hla: FC<IProps> = ({ indirectImpact }) => {
  if (indirectImpact < 6)
    return null;

  return (
    <Tooltip title="High Leverage Activity">
      <Label>Hla</Label>
    </Tooltip>
  );
}

export default Hla;
