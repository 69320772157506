import styled from "styled-components";

const TextBoxLabel = styled.div`
  /* color: ${({ theme }) => theme.palette.text.primary}; */
  color: #737373;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

export default TextBoxLabel;
