import React, { FC } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { List } from "antd";
import { DatePicker } from "elements";

//@ts-ignore
import { ReactComponent as CalendarBlueIcon } from "assets/images/priorities/calendar-blue.svg";
//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

import {
  Wrapper,
  Head,
  Body,
  FormGroup,
  Title,
  CloseBtn,
  Text,
  ListTitle,
  DateRow,
} from "./styled";

//@ts-ignore
import useDeviceType from "../../../../Hooks/ResponsiveHook";

import { updateAction } from "Redux/APM/CreateAction/action";
import { updateTask } from "Redux/APM/CreateTask/action";

interface CalendarPopoverProps {
  onClose?: (close: boolean) => void;
  id: any;
  isTask?: boolean;
  startline?: string | undefined;
  deadline?: string | undefined;
  minDate?: string | null;
  maxDate?: string | null;
  close?: boolean;
  daysLeft?: number;
}

const CalendarPopover: FC<CalendarPopoverProps> = ({
  onClose,
  id,
  isTask,
  startline,
  deadline,
  minDate,
  maxDate,
  close,
  daysLeft
}) => {
  const screenSize = useDeviceType();
  const dateFormat = "YYYY/MM/DD";

  const dispatch = useDispatch();

  const handleDateChange = (field: string) => (date: any) => {
    const formData = new FormData();

    if (!isTask)
      formData.append("prioritized_activity_id", id);

    if (field === 'startline' && !isTask && date !== moment(startline))
      formData.append("startline", date ? moment(date).format('YYYY-MM-DD') : '');

    if (field === 'deadline' && date !== moment(deadline))
      formData.append("deadline", date ? moment(date).format('YYYY-MM-DD') : '');

    if (isTask)
      dispatch(updateTask(formData, id, () => {}, { notify: false }));
    else
      dispatch(updateAction(formData, () => {}, { notify: false, loader: false }));
  }

  let actionDeadline = moment(deadline);
  let goalDeadline = moment(maxDate);

  let minStartline = moment(minDate).endOf('day');
  let maxStartline = deadline && maxDate
    ? moment.min(actionDeadline, goalDeadline).endOf('day')
    : (deadline ? moment(deadline).endOf('day') : moment(maxDate).endOf('day'));

  return (
    <Wrapper $mobile={screenSize !== "desktop"}>
      <Head>
        <Title>
          <CalendarBlueIcon width={18.11} height={18.11} />
          <span>DATE</span>
        </Title>
        <CloseBtn onClick={() => onClose && onClose(!close)}>
          <CloseIcon />
        </CloseBtn>
      </Head>

      <Body>
        <FormGroup>
          {/* <Label>Startline - Deadline</Label> */}
          {/* @ts-ignore */}
          {/* <DateRangePicker
            readOnly
            defaultValue={[
              startline ? moment(startline, "YYYY-MM-DD") : undefined,
              deadline ? moment(deadline, "YYYY-MM-DD") : undefined,
            ]}
          /> */}
        </FormGroup>

        {deadline ? <Text>
          {daysLeft !== undefined && daysLeft >= 0 && (
            <div data-testid="days-left">
              {daysLeft} days left
            </div>
          )}
          {daysLeft !== undefined && daysLeft < 0 && (
            <div style={{ color: "red" }}>
              {Math.abs(daysLeft)} days overdue
            </div>
          )}
        </Text> : <Text style={{ color: "red" }}>Deadline is not set</Text>}
      </Body>
      <List style={{ height: 38 }}>
        <DateRow>
          {isTask ? null : <><ListTitle>Startline</ListTitle>
          <DatePicker
            style={{
              height: "30px",
              padding: "1px 10px",
              borderRadius: "4px",
              marginLeft: "4px",
            }}
            format={dateFormat}
            placeholder="Set date"
            disabledDate={
              (current: any) => (
                current && (
                  current > moment(maxStartline).subtract(1, 'day').endOf('day') || 
                  current < moment(minStartline).startOf('day')
                )
              )
            }
            allowClear
            inputReadOnly
            value={startline ? moment(startline) : undefined}
            onChange={handleDateChange('startline')}
          /></>}

          <ListTitle style={{ marginLeft: "10px" }}>Deadline{isTask ? "" : "*"}</ListTitle>
          <DatePicker
            style={{
              height: "30px",
              padding: "1px 10px",
              borderRadius: "4px",
              marginLeft: "4px",
            }}
            format={dateFormat}
            placeholder="Set date"
            allowClear={isTask}
            disabledDate={
              (current: any) => (
                current && !isTask && (
                  (goalDeadline !== undefined && current > moment(goalDeadline).endOf('day')) || 
                  (startline === null ? current < moment().endOf('day') : current < moment.min(moment(startline).endOf('day'), moment().endOf('day')))
                )
              )
            }
            inputReadOnly
            value={deadline ? moment(deadline) : undefined}
            onChange={handleDateChange('deadline')}
          />
        </DateRow>
      </List>
    </Wrapper>
  );
};

CalendarPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CalendarPopover;
