import { FC } from 'react';
import { Button } from "elements";
import { create } from 'react-modal-promise';

// @ts-ignore
import flagIcon from 'assets/images/priorities/flag-gray.svg';

import {
  StyledModal,
  Wrapper,
  Icon,
  Title,
  Text,
  Footer,
} from "./styled";

interface IProps {
  isOpen: boolean;
  onResolve: (value: boolean) => void;
}

const ConfirmModal: FC<IProps> = ({ isOpen, onResolve }) => {
  const handleCancel = () => {
    onResolve(false);
  };

  const handleContinue = () => {
    onResolve(true);
  }

  return (
    <StyledModal
      open={isOpen}
      footer={false}
      centered
      width={568}
      onCancel={handleCancel}
    >
      <Wrapper>
        <Icon src={flagIcon} alt="icon" />

        <Title>You have unsaved data</Title>
        <Text>Are you sure you want to go back to the dashboard without saving your progress?</Text>

        <Footer>
          <Button
            styleType="secondary"
            onClick={handleCancel}
          >
            Continue Without Saving
          </Button>
          <Button
            styleType="primary"
            onClick={handleContinue}
          >
            Save and Continue
          </Button>
        </Footer>
      </Wrapper>
    </StyledModal>
  );
};

const confirmModal = create(ConfirmModal);
export { confirmModal };
