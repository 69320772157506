import React from "react";
import styled from "styled-components";

import SubHeader from "components/Scheduler/SubHeader";
import DailySummary from "components/Dashboard/DailySummary";
import Goals from "components/Dashboard/Goals";
import Overview from "components/Dashboard/Overview";
import PriorityActions from "components/Dashboard/PriorityActions";
import Reflections from "components/Dashboard/Reflections";

const Home = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <SubHeader
          title={"Home"}
        />

        <Dashboard>
          <Column>
            <DailySummary />
          </Column>

          <Row>
            <Column>
              <Goals />
              <PriorityActions />
              <Reflections />
            </Column>
            <Column>
              <Overview />
            </Column>
          </Row>
        </Dashboard>
      </InnerContainer>
    </MainContainer>
  );
};

export default Home;

const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px - 50px);
  background: var(--primary-50, #ECF3F6);
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--primary-50, #ECF3F6);
`;

const Dashboard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  gap: 18px;
`;

const Row = styled.div`
  flex: 2;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 24px;
  gap: 18px;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #acb4bc;
  }
`;

const Column = styled.div`
  flex: 1;
`;
