import { createMissionStatement } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { getCookie } from "utils/StorageVariables";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { SetMissionStatement } from "Redux/GetMissionStatement/action";

export const MissionStatement = (Data, setMissionStatementGoal, missionStatmentGoal, setCurrentStep) => async (dispatch) => {
  try {

    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await createMissionStatement(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 201) {
      setCurrentStep(7);
      dispatch(SetMissionStatement(data))

      setMissionStatementGoal({ ...missionStatmentGoal, hasMissionStatement: true });
      let MasterPlanId = getCookie("MasterPlanId");
      dispatch(RetrieveMasterPlanDetails(MasterPlanId, false));

    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const SetCreateMissionStatement = (payload) => async (dispatch) => {

  dispatch({
    type: ActionTypes.SUCCESS,
    payload,
  });

};
