import React from "react";
import styled from "styled-components";

import SubHeader from "components/Scheduler/SubHeader";
import GoalAreas from "components/Hierarchy/GoalAreas";
import List from "components/Hierarchy/List";

const Hierarchy = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <SubHeader
          title={"Your goals"}
        />

        <Dashboard>
          <List />
          <GoalAreas />
        </Dashboard>
      </InnerContainer>
    </MainContainer>
  );
};

export default Hierarchy;

const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px - 50px);
  background: #fff;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
`;

const Dashboard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  gap: 18px;
`;
