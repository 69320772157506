import Notify from "utils/notify";

// @ts-ignore
import { getFocusedExtremes } from "../apm-apis";

import { ActionTypes, IFocusedPopupItemData } from "./type";

export const listFocusedExtremes = (callback?: () => any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const startDate = getState().listFocusedPrioritise.week.startDate || null;
    const { client } = getState().prioritizedActions;

    const filtersParams = {
      week_starting: startDate
    };

    if (client) {
      // @ts-ignore
      filtersParams.client = client;
    }

    const { data } = await getFocusedExtremes(filtersParams);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const openPopup = (payload: IFocusedPopupItemData) => async (dispatch: any) => {
  dispatch({
    type: ActionTypes.OPEN,
    payload
  });
};

export const closePopup = () => async (dispatch: any) => {
  dispatch({
    type: ActionTypes.CLOSE
  });
};
