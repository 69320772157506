// @ts-ignore
import { updateActions } from "api";

// @ts-ignore
import { ActionTypes } from "./types";

// @ts-ignore
import { GetProgress } from "Redux/GetProgress/action";

// @ts-ignore
import { getCookie } from "utils/StorageVariables";

// @ts-ignore
import { fetchListPrioritizedActions , fetchListPrioritizedActionsGrouped } from "../APM/PrioritizedActions/action";

// @ts-ignore
import { listFocusedPrioritise } from "../APM/ListFocusedPriorities/action";

// @ts-ignore
import { listCompleteFocusedPrioritise } from "../APM/ListFocusedPriorities/action";


import Notify from "utils/notify";

export const UpdateActions = (id: string, Data: any, isProgress: boolean, {
  callback,
  showLoader = true,
  isApm = false
}: { callback?: (args?: any) => void, showLoader?: boolean, isApm?: boolean; } = {}) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING, payload: showLoader });
    const { data } = await updateActions(id, Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (isApm) {
      dispatch(fetchListPrioritizedActions());
      dispatch(fetchListPrioritizedActionsGrouped());
      dispatch(listFocusedPrioritise());
      dispatch(listCompleteFocusedPrioritise())
    }

    if (callback)
      callback(data);

    if (isProgress)
      return dispatch(GetProgress(getCookie("MasterPlanId")));
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
