import styled from 'styled-components';

export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
`;
