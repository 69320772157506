import { Slider } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  padding: 4px 4px 4px 8px;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  background: var(--Neutral-100, #F5F5F5);

  ${({ $disabled }) => $disabled && `
    opacity: 0.5;
    pointer-events: none;
  `};

  input:disabled {
    background: #fff;
  }
}`;

export const CustomSlider = styled(Slider)`
  margin: 0;
  padding: 0;
  width: 124px;
  height: 20px;
  padding-top: 6px;
  margin-right: 15px;

  .ant-slider-rail {
    background-color: #fff !important;
    height: 8px;
    border-radius: 100px;
  }

  .ant-slider-track {
    height: 8px;
    background-color: #1271a6 !important;
    border-radius: 100px;
  }

  .ant-slider-handle {
    background: #ffffff !important;
    box-shadow: 0px 2px 6px -1px rgba(16, 24, 40, 0.2),
      0px 1px 4px -2px rgba(16, 24, 40, 0.2);
    border: none;
    width: 20px;
    height: 20px;
    margin-top: -7px;
  }
}`;
