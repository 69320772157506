import React from 'react';
import styled, { css } from "styled-components";

import { ReactComponent as ArootahLogo } from "assets/svg/logoArootah.svg";
// import { ReactComponent as CloseIcon } from "assets/images/close-black.svg";

import Content from "./Content";

const LegalModal = ({ isActive, readOnly, loading, onSubmit, onClose }) => {
  return (
    <Wrapper $isActive={isActive}>
      <Modal className="legal-modal">
        <Head>
          <Logo src={ArootahLogo} alt="" />

          {/* {onClose && <CloseBtn onClick={onClose} disabled={loading} title="Close">
            <CloseIcon />
          </CloseBtn>} */}
        </Head>
        <Body>
          <Content loading={loading} readOnly={readOnly} onSubmit={onSubmit} isModal />
        </Body>
      </Modal>
    </Wrapper>
  );
}

export default LegalModal;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all .3s;

  ${({ $isActive }) => $isActive && css`
    opacity: 1;
    visibility: visible;
    pointer-events: initial;

    .legal-modal {
      transform: scale(1);
    }
  `}
`;

const Modal = styled.div`
  width: 100%;
  height: 100%;
  max-width: 950px;
  max-height: 670px;
  background: #fff;
  transform: scale(0.9);
  transition: transform .3s;
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 18px;
  height: 60px;
  border-bottom: 1px solid #75A3B2;
  background: #fff;
  position: relative;
`;

const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
  background: transparent;
`;

const Logo = styled.img`
  width: 104px;
`;

const Body = styled.div`
  // display: flex;
  height: calc(100% - 60px);

  /* ${({ theme }) => theme.max("md")`
    flex-direction: column;
    overflow: hidden;
  `} */
`;
