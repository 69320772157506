import React from "react";
import PropTypes from "prop-types";

import {
  MainContainer,
  InnerContainer,
  Dashboard
} from './styled';

const ScreenLayout = ({ secondary, children }) => {
  return (
    <MainContainer $secondary={secondary}>
      <InnerContainer>
        <Dashboard>
          {children}
        </Dashboard>
      </InnerContainer>
    </MainContainer>
  );
};

ScreenLayout.propTypes = {
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default ScreenLayout;
