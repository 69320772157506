import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import PropTypes from 'prop-types';
//@ts-ignore
import { ReactComponent as ChartIcon } from "assets/images/priorities/chart.svg";
//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";
//@ts-ignore
import { ReactComponent as CompareIcon } from "assets/images/priorities/common/compare.svg";
//@ts-ignore
import { ReactComponent as QuestionMarkIcon } from "assets/images/priorities/question-mark.svg";

import {
  MainWrapper,
  ScrollableContainer,
  ScrollableContent,
  Container,
  Item,
  Header,
  IconWrapper,
  CloseBtn,
  Heading,
  SubHeading,
  Smallheading,
  Priority,
  Span,
  Action,
  Type,
  PriorityContent,
  ChartWrapper,
  DonutChartContainer,
  ColumnChartContainer,
  ChartHeader,
  SmallHeading,
  Box,
  ColumnItem,
  ColumnHeading,
  BarChartWrapper,
  ColumnDisc,
  Disc,
  DisSpan,
  Icon,
  CustomRow,
  CustomBotton
} from './styled';
//@ts-ignore
import RadarChartComponent, { Filters } from "./StackedBarChart/component/RadarChart";
import BubbleChartComponent from "./StackedBarChart/component/BubbleChart";
import ActionPopover from "./ActionPopover";
import PopoverCustom from "../PopoverCustom";

interface ReturnFactorModalProps {
  active: boolean;
  graphData: any[];
  onClose: () => void;
  index: number;
  selectedItem: any;
  transformedData: any[];
  screenSizes:string;
}
type ObjectType = { 
  Habit: string; 
  Decision: string; 
  'Action Item': string; 
  [key: string]: string; 
};

const ReturnFactorModal: React.FC<ReturnFactorModalProps> = ({ active, graphData, onClose, index, selectedItem, transformedData ,screenSizes}) => {
  const [filter, setFilter] = useState<boolean>(true);
  const [filterData, setFilterData] = useState<any>(null);
  const [compare, setCompare] = useState<boolean>(false);
  const selectedItemGraph = graphData.find(item => item.id === filterData);

  const filteredData = selectedItemGraph
    ? [
        selectedItemGraph.activity_weight <= 0 ? 0 : selectedItemGraph.activity_weight,
        selectedItemGraph.direct_impact <= 0 ? 0 : selectedItemGraph.direct_impact,
        selectedItemGraph.urgency_score <= 0 ? 0 : selectedItemGraph.urgency_score,
        selectedItemGraph.time_score <= 0 ? 0 : selectedItemGraph.time_score,
        selectedItemGraph.indirect_impact <= 0 ? 0 : selectedItemGraph.indirect_impact,
      ]
    : null;

  if (!active) {
    return null;
  }

  const selects = transformedData?.filter(record => record.id !== selectedItem?.id)
  const nextCompareData = selects.length > 0 ? selects[0]?.id : null
  
  const { area_name } = selectedItem;

 

  const onFilterChange = (item: any) => {
    setFilterData(item);
  };

  const idxOfSelectedItem = transformedData.findIndex(item => item.id === selectedItem?.id);
  const priority = idxOfSelectedItem + 1;
  const actionTitles:ObjectType = {
    'Habit': "Habit",
    'Decision': "Decision",
    'Action Item': "Action"
  };
  
  const actionDescriptions:ObjectType = {
    'Habit': "An action you do repeatedly.",
    'Decision': "A choice you have to make.",
    'Action Item': "Something you do once."
  };

  const actionTitle = (data: string) => actionTitles[data] || null;
  const actionDescription = (data: string) => actionDescriptions[data] || null;
  return (
    <MainWrapper $mobile={screenSizes !== "desktop"}>
      <Header>
        <IconWrapper>
          <Icon>
            <ChartIcon />
          </Icon>
          <Heading>Return Factors</Heading>
        </IconWrapper>
        <CloseBtn data-testid="close-button" onClick={() => {
          setCompare(false);
          setFilterData(null);
          onClose();
        }}>
          <CloseIcon data-testid="filter-button" width={24} onClick={() => setFilter(true)} />
        </CloseBtn>
      </Header>
      <ScrollableContainer>
        <ScrollableContent>
          <Container>
            <Item>
              <Priority>
                <PriorityContent>
                  <Span>{index + 1}</Span>
                  <div>
                    <Smallheading>{area_name}</Smallheading>
                    <SubHeading>
                     {selectedItem?.action?.context && selectedItem.action.context.length > 20
                      ? selectedItem.action.context.substring(0, 20) + '...' 
                       : selectedItem?.action?.context}
                      </SubHeading>
                  </div>
                  <Action>
                    <Type>{selectedItem?.action?.type}</Type>
                    <PopoverCustom content={<ActionPopover title={actionTitle(selectedItem?.action?.type)} Description={actionDescription(selectedItem?.action?.type)}/>} popoverStyle={{ arrowStyle:{top:'15px', left:'-3px', rotate:'270deg'}, containerStyle: { top: '-42px', left: '192px' } }}  >
                      <QuestionMarkIcon />
                    </PopoverCustom>
                  </Action>
                    {compare === false && (
                      <CustomBotton $mobile={screenSizes !== "desktop"} data-testid="custom-buttom" type="link" onClick={() => {
                        setFilterData(nextCompareData);
                        setCompare(true)
                      }} style={{ alignSelf: 'center' }}>
                        <CompareIcon />
                      </CustomBotton>
                    )}
                </PriorityContent>
              </Priority>
            </Item>
          </Container>
          {filter && (
            <Filters
              screenSize={screenSizes}
              compare={compare}
              setCompare={setCompare}
              setFilter={setFilter}
              transformedData={selects}
              onFilterChange={onFilterChange}
              selectedItem={selectedItem}
              filterDatas={filterData}
              defaultValue={nextCompareData}
            />
          )}
          <ChartWrapper $mobile={screenSizes !== "desktop"}>
            <DonutChartContainer>
              <RadarChartComponent selectedItem={selectedItem} filteredData={filteredData} />
            </DonutChartContainer>
            <ColumnChartContainer $mobile={screenSizes !== "desktop"}>
              <ChartHeader>
                <ColumnItem>
                  <ColumnHeading>Size of influence</ColumnHeading>
                  <BarChartWrapper>
                    <BubbleChartComponent selectedItem={selectedItem} />
                  </BarChartWrapper>
                </ColumnItem>
                <ColumnDisc>
                  <DisSpan bg={`#00DFFF`}></DisSpan>
                  <Disc>
                    <span>Goal</span> (Hierarchy Direct Impact)
                  </Disc>
                </ColumnDisc>
                <ColumnDisc>
                  <DisSpan bg={`#FFB950`}></DisSpan>
                  <Disc>
                    <span>Activity</span> (Direct Impactt)
                  </Disc>
                </ColumnDisc>
                <ColumnDisc>
                  <DisSpan bg={`#FFB950`}></DisSpan>
                  <Disc>
                    <span>Leverage</span> (Indirect Impact)
                  </Disc>
                </ColumnDisc>
                <ColumnDisc>
                  <DisSpan bg={`#E93535`}></DisSpan>
                  <Disc>
                    <span>Urgency</span> (Urgency)
                  </Disc>
                </ColumnDisc>
                <ColumnDisc>
                  <DisSpan bg={`#BC68FD`}></DisSpan>
                  <Disc>
                    <span>Time</span>
                  </Disc>
                </ColumnDisc>
              </ChartHeader>
              <Row style={{ marginLeft: '10px' }}>
                <Col span={7}>
                  <SmallHeading>Priority#</SmallHeading>
                </Col>
                <Col span={7}>
                  <SmallHeading>Return %</SmallHeading>
                </Col>
                <Col span={10}>
                  <SmallHeading>Prioritized Time</SmallHeading>
                </Col>
              </Row>
              <CustomRow style={{ marginLeft: '10px' }}>
                <Col span={7}>
                  <Box style={{ borderRight: "none" }}>{priority}</Box>
                </Col>
                <Col span={7}>
                  <Box style={{ borderRight: "none" }}>{selectedItem.calculated_priority}</Box>
                </Col>
                <Col span={10}>
                  <Box>{selectedItem.estimated_weekly_duration}</Box>
                </Col>
              </CustomRow>
            </ColumnChartContainer>
          </ChartWrapper>
        </ScrollableContent>
      </ScrollableContainer>
    </MainWrapper>
  );
};

export default ReturnFactorModal;
