import { prioritizeAreaAndCategories } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const PrioritizeAreaAndCategories =
  (Data, masterplanId) => async (dispatch) => {
    try {
      let masterplan = "";
      if (masterplanId) {
        masterplan = `?masterplan=${masterplanId}`;
      }
      dispatch({ type: ActionTypes.LOADING });
      const { data } = await prioritizeAreaAndCategories(Data, masterplan);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };
