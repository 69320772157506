import EmptyPlaceholder from "../EmptyPlaceholder";

import { Wrapper, Head, Title } from "./styled";

import { WELCOME_PATH } from "utils/routes";

const Goals = () => {
  return (
    <Wrapper>
      <Head>
        <Title>Your Goals</Title>
      </Head>

      <EmptyPlaceholder
        title="You don’t have any goals yet. Let’s change that."
        linkLabel="Build Goals Hierarchy"
        linkStyle="thernary"
        linkPath={WELCOME_PATH}
      />
    </Wrapper>
  );
}

export default Goals;
