import styled from "styled-components";

interface ProgressProps {
  $color1?: string;
  $color2?: string;
  width?: string;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 6px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 9px;
  border-radius: 100px;
  background-color: #e5e5e5;
  box-shadow: inset 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const Progress = styled.div<ProgressProps>`
  width: ${({ width = "26%" }) => width};
  height: 100%;
  background: ${({
    $color1 = "rgba(71, 185, 31, 1)",
    $color2 = "rgba(80, 211, 93, 1)",
  }) => `linear-gradient(90deg, ${$color1} 0%, ${$color2} 100%)`};
  border-radius: 0px 100px 100px 0px;
`;

export const ProgressContent = styled.div`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`;
