import { FC, ChangeEvent } from 'react';
import { Checkbox } from 'elements';

import { Wrapper, Input, Label } from './styled';

interface IProps {
  data: IArea;
  onCheck: (value: string | null) => void;
  onChange: (value: string | null) => void;
}

export interface IArea {
  id: string;
  color: string;
  label: string;
  value: string | null;
}

const AreaItem:FC<IProps> = ({ data, onCheck, onChange }) => {
  const { label, color, value } = data;

  const isChecked = value !== null;

  const handleCheck = () => {
    onCheck(value ? null : '10');
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim(); // Trim whitespace
    const numberValue = parseInt(value, 10); // Convert value to a number for range checks

    // Allow empty string or enforce number range [0...10]
    if (value === '') {
      onChange(null); // Empty input
    } else if (/^\d+$/.test(value)) {
      if (numberValue > 10) {
        onChange('10'); // Force max value if input exceeds 10
      } else {
        onChange(value); // Valid input within range
      }
    }
  };

  return (
    <Wrapper $color={color}>
      <Checkbox
        id={`label_${label}`}
        checked={isChecked}
        label={<Label>{label}</Label>}
        onChange={handleCheck}
      />
      <Input
        type="text"
        step="any"
        placeholder="-"
        value={value || ''}
        onChange={handleChange}
      />
    </Wrapper>
  );
}

export default AreaItem;
