import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 38px);
  position: relative;
`;

export const ContentList = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 12px 20px;

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #acb4bc;
    }
  `}
`;

export const ContentListBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--Dark-mode-bg, #FFF);
  padding: 24px;
  width: 630px;
  min-height: 360px;
`;

export const BackLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 12px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  color: var(--Neutral-700, var(--Input-heading, #404040)) !important;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const Icon = styled.img`
  width: 40px;
  height: 39px;
  margin-bottom: 9px;
`;

export const Title = styled.span`
  color: var(--Davys-gray, #4B545B);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 9px;
`;

export const Text = styled.span`
  color: var(--Neutral-500, var(--Input-header, #737373));
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 23px;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  > div {
    width: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
