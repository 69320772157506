import React from "react";
import styled from "styled-components";

import SubHeader from "components/Scheduler/SubHeader";
import CompletedGoals from "components/CompletedGoals";
import ScreenLayout from "components/Journeys/SceneLayout";
import GoalsList from "components/CompletedGoals/GoalsList";

const CompletedGoalsScreen = () => {
  return (
    <ScreenLayout secondary>
      <Wrapper>
        <SubHeader title="Completed Goals" />

        <PagesWrapper>
          <GoalsList />

          <PageLayout>
            <CompletedGoals />
          </PageLayout>
        </PagesWrapper>
      </Wrapper>
    </ScreenLayout>
  )
};

export default CompletedGoalsScreen;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 60px);
`;

const PagesWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1439px) {
    height: calc(100vh - 118px);
  }

  ${({ theme }) => theme.max("sm")`
    height: calc(100vh - 60px);
  }`}
`;

const PageLayout = styled.div`
  flex: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 26px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #acb4bc;
  }

  ${({ theme }) => theme.max("sm")`
    height: calc(100% - 56px);
  }`}
`;
