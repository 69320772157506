// @ts-ignore
import { getValues, createCustomValue, deleteCustomValue } from "api";
import Notify from "utils/notify";

import { ActionTypes } from "./types";

export const GetValues = (roles = []) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    // Create an array of promises for each role
    const promises = roles.map((role) => getValues(role));

    // Await all promises to resolve
    const results = await Promise.all(promises);

    // Combine all data from results
    const combinedData = results.flatMap((result: any) => result.data);

    // Map the combined data to the desired format
    const formattedData = combinedData.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    }));

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: formattedData,
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const CreateCustomValue = (formData: FormData, callback?: (element: any) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await createCustomValue(formData);

    const customItem = {
      label: data.name,
      value: data.id,
      custom: data.custom && data.custom_user
    }

    dispatch({
      type: ActionTypes.CREATE_CUSTOM_SUCCESS,
      payload: customItem
    });

    if (callback) {
      callback(customItem);
    }

    Notify.success("Custom Value created successfully.");
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

export const DeleteCustomValue = (id: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    await deleteCustomValue(id);

    dispatch({
      type: ActionTypes.DELETE_CUSTOM_SUCCESS,
      payload: id
    });

    Notify.success("Custom Value deleted successfully.");
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

