import React, { memo, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import * as moment from 'moment';

import { GetQuotes } from 'Redux/GetQuotes/action';

import quoteIcon from 'assets/images/dashboard/quoteIcon.svg';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const DailyQuote = () => {
  const dispatch = useDispatch();

  const { data } = useSelector(
    (state) => state.getQuotesReducer
  );

  useLayoutEffect(() => {
    dispatch(GetQuotes());
  }, []);

  const getSeed = (days, length) => {
    return days - length * (Math.floor(days / length));
  }

  const getDays = () => {
    const beginning = moment().startOf('year');
    const now = moment();

    return Math.abs(beginning.diff(now, 'days')) + 1;
  }

  const days = getDays();
  const seed = getSeed(days, data.length);

  const { text, author } = data[seed] || {};

  return (
    <Wrapper>
      <Head>
        <Title>Quote of the Day</Title>
        <Icon src={quoteIcon} width={20} alt="" />
      </Head>
      <Body>
        {!text && <Skeleton count={2} />}

        {text && <Quotes>
          <QuoteChar>“</QuoteChar>
          {text}
          <QuoteChar isLast>”</QuoteChar>
        </Quotes>}

        <Author>{author ? `- ${author}` : <Skeleton count={0.5} />}</Author>
      </Body>
    </Wrapper>
  );
};

export default memo(DailyQuote);

const Wrapper = styled.div`
  margin-top: 12px
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Title = styled.h4`
  color: var(--fill-frame-oxford-blue, #06203A);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

const Icon = styled.img`

`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
`;

const Quotes = styled.h3`
  color: var(--fill-frame-oxford-blue, #06203A);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 12px;
`;

const QuoteChar = styled.span`
  font-family: ${({ theme }) => theme.fonts.thernary};
  font-size: 30px;
  line-height: 24px;

  ${({ isLast }) => isLast && css`
    font-size: 25px;
    line-height: 13px;
    position: relative;
    top: 5px;
  `}
`;

const Author = styled.h4`
  color: var(--fill-icon-oxford-blue, #06203A);
  font-variant-numeric: lining-nums tabular-nums;
  text-align: right;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  padding: 0 20px;
  margin-bottom: 0;
`;
