import { ActionTypes } from './type';
const initialState = {
    globalId: null,
};
const globalIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_GLOBAL_ID:
            return {
                ...state,
                globalId: action.payload,
            };
        default:
            return state;
    }
};


export default globalIdReducer;