import React from 'react';
import { Wrapper, Head, SubTitle, Title } from './styled';
//@ts-ignore
import useDeviceType from '../../../../../Hooks/ResponsiveHook';


interface ActionPopoverProps {
    title?: string | null;
    Description: string | null;
}

const ActionPopover: React.FC<ActionPopoverProps> = ({ title, Description }) => {
const screenSizes = useDeviceType();
    return (
        <Wrapper $mobile={screenSizes !== "desktop"}>
            <Head>
                <Title>
                    {title}
                </Title>
            </Head>
            <SubTitle>
                {Description}
            </SubTitle>
        </Wrapper>
    );
}

export default ActionPopover;