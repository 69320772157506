import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// import { ReactComponent as DropArrow } from "assets/images/droparrow.svg";
import { ReactComponent as GeneralSetting } from "assets/images/Schedulesettings.svg";
import { DateTime } from "luxon";

const ResizableDiv = ({
    backgroundColor,
    text,
    heightIndex,
    updatedHeight,
    index,
    isheights,
    setIsHeights,
    divHeight,
}) => {
    console.log({ isheights }, "isHeight");

    const [isResizing, setIsResizing] = useState(false);


    const startResize = (e) => {
        setIsResizing(true);
        const startY = e.clientY;
        const initialHeight = heightIndex;
        const minHeight = 151; // Set the minimum height here

        const handleMouseMove = (e) => {
            const deltaY = e.clientY - startY;
            const newHeight = initialHeight + deltaY;


            if (newHeight < minHeight) {
                updatedHeight(minHeight);
            } else {
                updatedHeight(newHeight);
            }

            // Decrease the height of the next div

            if (index < isheights.length - 1) {
                const nextIndex = index + 1;
                const newNextHeight = isheights[nextIndex] - deltaY;

                // console.log(newNextHeight, 'harirm')

                // Check if the new height of the next div is below the minimum height
                if (newNextHeight < minHeight) {

                    setIsHeights((prevHeights) => {
                        const newHeights = [...prevHeights];
                        newHeights[nextIndex] = minHeight;
                        return newHeights;
                    });
                } else {
                    // Update the height of the next div
                    setIsHeights((prevHeights) => {
                        const newHeights = [...prevHeights];
                        newHeights[nextIndex] = newNextHeight;
                        return newHeights;
                    });
                }
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);

            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <MainDiv
            // ref={divRef}
            className={`resizable-div ${isResizing ? "resizing" : ""}`}
            style={{
                height: `${heightIndex}px`,
                backgroundColor,
                cursor: "pointer",
                overflow: "hidden"

            }}
            onMouseDown={startResize}
        >
            <TimelineText>{text}</TimelineText>
            {/* <VectorDiv>
        <DropArrow />
      </VectorDiv> */}
        </MainDiv >
    );
};

const Calendertimeline = ({ isOpenTimeline,
    setIsOpenTimeline, onSelectTime }) => {

    const handleTimeline = () => {
        setIsOpenTimeline(!isOpenTimeline);
    };




    const divs = [
        {
            backgroundColor: "#8AB2D9",
            text: "Morning",
            start_time: "12:00 AM",
            end_time: "03:00 AM",
            minHeight: [151, 302, 453, 604, 755, 906],
        },
        {
            backgroundColor: "#4BC583",
            text: "Deep work",
            start_time: "02:00 AM",
            end_time: "04:00 AM",
            minHeight: [151, 302, 453, 604, 755, 906],
        },
        {
            backgroundColor: "#FFA178",
            text: "Afternoon",
            start_time: "04:00 AM",
            end_time: "07:00 AM",
            minHeight: [151, 302, 453, 604, 755, 906],
        },
        {
            backgroundColor: "#80888D",
            text: "Evening",
            start_time: "07:00 AM",
            end_time: "08:00 AM",
            minHeight: [151, 302, 453, 604, 755, 906],
        },
        {
            backgroundColor: "#603679",
            text: "Night",
            start_time: "09:00 AM",
            end_time: "10:00 AM",
            minHeight: [151, 302, 453, 604, 755, 906],
        },
    ];

    //For Height increase ******************* //

    const initialHeights = divs.map((div) => {
        const startTime = DateTime.fromFormat(div.start_time, "hh:mm a");
        const endTime = DateTime.fromFormat(div.end_time, "hh:mm a");
        const timeDifference = endTime.diff(startTime, "hours").hours;

        // Assuming 1 hour corresponds to 151 pixels
        return timeDifference * 151;
    });

    const [isheights, setIsHeights] = useState(initialHeights);

    // console.log(initialHeights, "initial");

    const handleHeightChange = (index, newHeight) => {
        const newHeights = [...isheights];
        newHeights[index] = newHeight;
        setIsHeights(newHeights);
    };

    // console.log(isheights, "newheight");

    //******************************************** */

    return (
        <Wrapper>
            <TimelineDiv>
                <Extradiv></Extradiv>
                <SettingDiv>
                    <Div>
                        <GeneralSetting style={{ cursor: 'pointer' }} onClick={handleTimeline} />
                    </Div>
                </SettingDiv>

                {divs.map((div, index) => (
                    <ResizableDiv
                        key={index}
                        backgroundColor={div.backgroundColor}
                        text={div.text}
                        heightIndex={isheights[index]}
                        updatedHeight={(newHeight) => handleHeightChange(index, newHeight)}
                        index={index}
                        isheights={isheights}
                        setIsHeights={setIsHeights}
                        divHeight={div.minHeight}

                    />
                ))}
            </TimelineDiv>
        </Wrapper>
    );
};

export default Calendertimeline;

const VectorDiv = styled.div`
  position: absolute;
  bottom: -10px;
  z-index: 5;
`;

const Wrapper = styled.div`
  width: 23px;
  height: 100%;
  /* border: 1px solid green; */
  /* position: relative; */
  /* margin-left: 10px; */
`;

const TimelineDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* min-height: 151px; */
  /* background-color: red; */
  /* margin-top: ${({ ismargin }) => ismargin && "7.5rem"}; */
`;

const TimelineText = styled.h4`
  margin: 0;
  transform: rotate(-90deg);
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.6px;
  white-space: nowrap;
  user-select: none;
  text-transform: uppercase;
  
`;

const Extradiv = styled.div`
  /* border: 1px solid yellow; */
  height: 56px;
  border-top: 1px solid #c1cad1;
  border-bottom: 1px solid #c1cad1;
`;

const SettingDiv = styled.div`
  height: 66px;
  /* border: 1px solid green; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const Div = styled.div`
  background-color: #eeeeee;
  /* border: 1px solid red; */
  height: 54px;
  display: flex;
  justify-content: center;
  padding-top: 6px;
`;
