import React from "react";
import { Menu, Dropdown } from "antd";

// import "antd/dist/antd.css"; // Or 'antd/dist/antd.less'

// @ts-ignore
import { ReactComponent as EditDots } from "assets/images/priorities/EditDots.svg";
// @ts-ignore
import { ReactComponent as Pencil } from "assets/images/priorities/pencil.svg";
// @ts-ignore
import { ReactComponent as TrashIcon } from "assets/images/priorities/TrashIcon.svg";

import DeleteItem from "../DeleteItem";

import { Dots } from "./styled";

interface EditDropdownProps {
  id: string;
  title: string;
  type: string;
  setActions: (id: string) => void;
  setHandleDelete: (id: string) => void;
  handleDelete: string;
}

const EditDropdown: React.FC<EditDropdownProps> = ({
  id,
  title,
  type,
  setActions,
  setHandleDelete,
  handleDelete,
}) => {

  const menu = (
    <Menu style={{width:"150px"}}>
      <Menu.Item
        key="edit"
        style={{ margin: "2px" }}
        onClick={() => setActions(id)}
      >
        Edit 
        {/* {type === 'Action Item' ? 'Action' : type} */}
        <Pencil />
      </Menu.Item>

      <Menu.Item onClick={() => setHandleDelete(id)}>
        <h6 style={{ color: "#FF9900" }}>Delete</h6>
        <TrashIcon />
      </Menu.Item>
    </Menu>
  );
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={["click"]}
        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      >
        <div className="ant-dropdown-link" style={{ cursor: "pointer" }}>
          <Dots>
            <EditDots />
          </Dots>
        </div>
      </Dropdown>

      {handleDelete && (
        <DeleteItem
          id={id}
          title={title}
          isTask={type === 'Task'}
          type={type}
          setHandleDelete={setHandleDelete}
        />
      )}
    </div>
  );
};

export default EditDropdown;
