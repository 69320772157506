import React, { useEffect, useState } from "react";

import PlanShortcut from "components/PlanShortcut";

import DraggerIcon from "assets/images/dragicon.svg";
import LockIcon from "assets/images/journeys/lock-open.svg";
import UnlockIcon from "assets/images/unlockicon.svg";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrioritizeAreaAndCategories } from "Redux/PrioritizeAreaAndCategories/action";
import { getCookie } from "utils/StorageVariables";
import {
  RetrieveMasterPlanDetails,
  RetrieveMasterPlanDetailsNull,
} from "Redux/RetrieveMasterPlan/action";
import { UpdateLastStep } from "Redux/UpdateLastStep/action";
import { UpdateUserArea } from "Redux/UpdateUserArea/action";

import * as Styles from './styled';
import StepLayout from "../StepLayout/index";
import { MEASUREMENT_PATH, MISSION_PATH } from "utils/routes";
import useDeviceType from "../../../Hooks/ResponsiveHook";

const Prioritize = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prioritizeOrder, setPrioritizeOrder] = useState();
  const [payload, setPayload] = useState([]);
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";
  let selectedClient = getCookie("selectedClient");

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));

    return () => {
      dispatch(RetrieveMasterPlanDetailsNull(null));
      setPrioritizeOrder([]);
      setPayload([]);
    };
  }, []);

  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );

  useEffect(() => {
    if (!masterPlanDetails?.areas) return;
    let response = masterPlanDetails?.areas
      ?.filter((item) => item.goal !== null)
      ?.map((item, i) => {
        let itemCopy = { ...item };
        if (itemCopy?.goal?.priority && !itemCopy?.goal?.priority) {
          itemCopy.goal.priority = i + 1;
        }
        return itemCopy;
      });

    setPrioritizeOrder(handleFormatArrayByKey(response));
  }, [masterPlanDetails?.areas]);

  const handleFormatArrayByKey = (orderedArr) =>
    orderedArr?.sort(function (a, b) {
      return a?.goal?.priority - b?.goal?.priority;
    });
  const handlePriority = (startOrder, priorityOrder) => {
    let copyArr = [...priorityOrder];
    if (copyArr[startOrder?.source?.index]?.goal) {
      copyArr[startOrder?.source?.index].goal.priority =
        startOrder?.destination?.index;
    }
    if (copyArr[startOrder?.destination?.index]?.goal) {
      copyArr[startOrder?.destination?.index].goal.priority =
        startOrder?.source?.index;
    }

    return copyArr;
  };

  const handleOrderAgain = (arr, start, end) => {
    let copyArr = [...arr];
    let element = copyArr.splice(start, 1)[0];
    copyArr.splice(end, 0, element);
    return copyArr;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let settedPiority = handlePriority(result, prioritizeOrder);
    let orderedArr = handleOrderAgain(
      settedPiority,
      result?.source?.index,
      result?.destination?.index
    );
    let obj = {
      goal: orderedArr[result?.destination?.index]?.goal?.id,
      priority: orderedArr[result?.destination?.index]?.goal?.priority,
    };
    setPayload([...payload, obj]);

    setPrioritizeOrder(orderedArr);

    let masterplanId = getCookie("MasterPlanId");

    let payloads = [...orderedArr].map((item, i) => {
      return {
        goal: item?.goal?.id,
        priority: i + 1,
      };
    });
    dispatch(PrioritizeAreaAndCategories(payloads, masterplanId));
  };

  const getItemStyle = (isDragging, draggableStyle) => {
    let copyDraggableStyle = draggableStyle;
    copyDraggableStyle.position = isDragging && "fixed";
    return {
      userSelect: "none",
      background: "white",
      // height: "50px",
      minHeight: '50px',
      height: 'initial',
      ...copyDraggableStyle,
    };
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver && "transparent",
  });

  const handlePrev = () => {
    navigate(MISSION_PATH);
  }

  const handleNext = () => {
    const formData = new FormData();
    formData.append("step", 4); // 3
    dispatch(UpdateLastStep(formData));
    navigate(MEASUREMENT_PATH);
    // navigate(GOALS_PATH);
  };

  const onLockClickHandle =
    ({ id, is_locked }) =>
    () => {
      dispatch(UpdateUserArea(id, { is_locked: !is_locked }));
    };

  const HeaderContent = () => {
    return (
      <>
        <Styles.TableHeadCol $width={isMobile ? "22%" : isTablet ? "24%" : "10vw"}>Priority</Styles.TableHeadCol>
        <Styles.TableHeadCol $width={isMobile ? "32%" : isTablet ? "28%" : "16vw"}>Areas</Styles.TableHeadCol>
        <Styles.TableHeadCol $flex="1">Categories</Styles.TableHeadCol>
        <Styles.TableHeadCol>Lock</Styles.TableHeadCol>
      </>
    );
  }

  return (
    <StepLayout title="2. Prioritize Your Areas & Categories" header={<HeaderContent />} onPrev={handlePrev} onNext={handleNext}>
      <Styles.Container>
        <Styles.ContainerItem>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="characters">
              {(provided, snapshot) => (
                <Styles.TableContainer
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {prioritizeOrder &&
                    prioritizeOrder?.map((item, i) => (
                      <Draggable key={i} draggableId={`${i}`} index={i}>
                        {(swap, snapshot) => (
                          <Styles.Row
                            border={item?.color}
                            $locked={item?.is_locked}
                            ref={swap.innerRef}
                            {...swap.draggableProps}
                            {...swap.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              swap?.draggableProps?.style
                            )}
                          >
                            <Styles.LeftSection>
                              <Styles.DragButton>
                                <Styles.DragIcon
                                  className="drag-icon"
                                  src={DraggerIcon}
                                  title="Click and hold to drag"
                                  alt=""
                                />
                              </Styles.DragButton>

                              <Styles.IdAvatar size={isMobile ? 22 : 26}>{i + 1}</Styles.IdAvatar>
                            </Styles.LeftSection>

                            <Styles.AreaSection>
                              <Styles.Text $area title={item?.name}>{item?.name}</Styles.Text>
                            </Styles.AreaSection>

                            <Styles.MidSection>
                              <Styles.Text $category title={item?.goal?.category_name}>{item?.goal?.category_name}</Styles.Text>
                            </Styles.MidSection>

                            {(item?.is_private && selectedClient) ? null : <Styles.LockButton
                              type="button"
                              onClick={onLockClickHandle(item)}
                            >
                              <Styles.DragIcon
                                src={item?.is_locked ? LockIcon : UnlockIcon}
                                title={item?.is_locked ? "Locked" : "Unlocked"}
                                alt="icon"
                              />
                            </Styles.LockButton>}
                          </Styles.Row>
                        )}
                      </Draggable>
                    ))}

                  <PlanShortcut
                    isVisible={masterPlanDetails?.viewed_masterplan_screen}
                  />
                </Styles.TableContainer>
              )}
            </Droppable>
          </DragDropContext>
        </Styles.ContainerItem>
      </Styles.Container>
    </StepLayout>
  );
};

export default Prioritize;
