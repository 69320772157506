import Notify from "utils/notify";

// @ts-ignore
import { createTask as createTaskRequest, updateTask as updateTaskRequest, deleteTask as deleteTaskRequest } from "../apm-apis";
// @ts-ignore
import {listFocusedPrioritise} from './../../APM/ListFocusedPriorities/action'
import { ActionTypes } from "./type";
import { fetchTasks } from "../ListTasks/action";

export const createTask = (payload: any, callback: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await createTaskRequest(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });    

    Notify.success("Task Successfully Created");

    dispatch(fetchTasks());

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const updateTask = (payload: any, id: any, callback: () => any, options = { notify: true }) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });
    const { data } = await updateTaskRequest(payload, id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (options?.notify !== false)
      Notify.success("Task Successfully Updated");

    dispatch(fetchTasks());
    dispatch(listFocusedPrioritise());

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const deleteTask = (id: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await deleteTaskRequest(id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    Notify.success("Task Successfully Deleted");

    dispatch(fetchTasks());
    dispatch(listFocusedPrioritise());
    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    console.log(error)

    Notify.error(error, true);
  }
};

