import PropTypes from 'prop-types';
import React from "react";

import { Button } from "elements/index.tsx";

import { ReactComponent as MetricIcon } from "assets/images/journeys/metric.svg";
import { ReactComponent as CloseIcon } from "assets/images/journeys/close.svg";

import * as Styled from './styled.js';

const Popup = ({ active, title, footer: Footer, onClose, children }) => {
  const handleCancel = () => {
    onClose();
  };

  return (
    <Styled.CustomModal
      open={active}
      footer={false}
      centered
      width={450}
      closable={false}
    >
      <Styled.Wrapper>
        <Styled.Head>
          <Styled.HeadWrap>
            <MetricIcon />
            <Styled.Title>{title}</Styled.Title>
          </Styled.HeadWrap>

          <Styled.Close type="button" onClick={handleCancel}>
            <CloseIcon />
          </Styled.Close>
        </Styled.Head>

        <Styled.Body $footer={!!Footer}>
          {children}
        </Styled.Body>

        {Footer && <Styled.Footer>
          <Footer />
        </Styled.Footer>}
      </Styled.Wrapper>
    </Styled.CustomModal>
  );
};

Popup.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  footer: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default Popup;
