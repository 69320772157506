import styled, { css } from 'styled-components';

// @ts-ignore
import { fadeInUpMixin } from '../animated';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FormText = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  margin-bottom: 22px;
  text-align: center;
`;

interface IAnimate {
  $animate?: boolean;
  $animateDelay?: number;
}

export const Title = styled.span<IAnimate>`
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 65.5%;
  margin-top: 50px;
  margin-bottom: 12px;
  ${fadeInUpMixin}
`;

export const Text = styled.span<IAnimate>`
  color: var(--Davys-gray, #4B545B);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  margin-bottom:10px;
  max-width: 412px;
  ${fadeInUpMixin}
`;

export const Form = styled.div<IAnimate>`
  ${fadeInUpMixin}
`;

export const Helper = styled.span<IAnimate>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--Davys-gray, #4B545B);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  margin-top: 4px;
  ${fadeInUpMixin}
`;
