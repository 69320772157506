import React, { useEffect, useRef, useState } from "react";
import { Menu, Dropdown } from "antd";
import 'antd/dist/antd.min.css'
import {
  CustomMenu,
  CustomInprogress,
  CustomPencil,
  CustomNav,
  Time,
  Background,
  CustomMenuItem,
  CustomDropdowm
} from "./styled";
//@ts-ignore
import { ReactComponent as Calendar } from "assets/images/priorities/calendar.svg";
//@ts-ignore
import { ReactComponent as Lightning } from "assets/images/priorities/lightning.svg";
//@ts-ignore
import { ReactComponent as ChartIcon } from "assets/images/priorities/common/chartIcon.svg";
//@ts-ignore
import { ReactComponent as Clock } from "assets/images/priorities/hourglass.svg";
//@ts-ignore
import { ReactComponent as RedCal } from "assets/images/priorities/common/redcal.svg";
//@ts-ignore
import { ReactComponent as TargetActive } from "assets/images/priorities/target-active.svg";
//@ts-ignore
import { ReactComponent as Target } from "assets/images/priorities/target.svg";
//@ts-ignore
import { ReactComponent as ButtonList } from "assets/images/priorities/ButtonList.svg";
//@ts-ignore
import { ReactComponent as DeleteIconn } from "assets/images/priorities/DeleteIconn.svg";
import PopoverCustom from "../PopoverCustom";
import TimePopover from "../TimePopover";
import CalendarPopover from "../CalendarPopover";
import LeveragePopover from "../LeveragePopover";
//@ts-ignore
import DeleteGaol from "../DeleteItem"
import { useSelector } from "react-redux";



interface MiniBarProps {
  onEdit: (id?: string) => void;
  id?: string;
  items?: any;
  onClose?: (close: boolean) => void;
  setRowVisible?: (e: string | undefined) => void;
  close?: boolean;
  daysLeft?: number;
  difference?: number;
  handleLeverage?: () => void;
  onLeverageClose?: () => void;
  loading?: boolean;
  selectedItem?: any;
  setAreaData?: () => void;
  isFocused?: boolean;
  onFocusClickHandle?: () => void;
  onClickHandle?: () => void;
  simpleViewmissingData: boolean;
  handleDelete?:string;
   setHandleDelete?:(d:any) => void

}
const MiniBar: React.FC<MiniBarProps> = ({
  onEdit,
  id,
  items,
  onClose,
  close,
  daysLeft,
  difference,
  handleLeverage,
  onLeverageClose,
  loading,
  selectedItem,
  setAreaData,
  isFocused,
  onFocusClickHandle,
  onClickHandle,
  simpleViewmissingData,
  setRowVisible,
  handleDelete,
  setHandleDelete
  
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [background, setBackground] = useState<string | null | boolean>(null);
  const [popover, setPopover] = useState<boolean>(false);

  const { color, deadline, startline } = items || {};
  const time = items?.estimated_weekly_duration;
  const convertMinutesToTimeFormat = (time: any) => {
    const hours = Math.floor(time / 60);
    const mins = time % 60;
    return `${hours}h ${mins}m`;
  };
  //@ts-ignore
  const prioritizedActions = useSelector((state) => state.prioritizedActions);
  const data = prioritizedActions?.prioritizedData?.filter(
    (item:any) => item.missing_data === false && item.action.context !== ""
  );
   //@ts-ignore
   const GoalTitle = data?.find((ele,indx) => ele.id == handleDelete)
  const handleVisibleChange = (flag: boolean) => {
    if(flag){

      setBackground(flag);
    }
    setVisible(flag);
  };
  const handleClick = (e: any) => {
    setBackground(e.key);
  };

  const menuMissingItems = (
    <CustomMenu $simpleViewmissingData={simpleViewmissingData}>
      {/* <CustomMenuItem key="0" disabled>
        <div>
          <CustomInprogress style={{ width: "30px" }} />
        </div>
        <div style={{ marginRight: "8px" }}>-</div>
      </CustomMenuItem>

      <Menu.Item key="1" disabled>
        <Calendar style={{ width: "30px" }} />
      </Menu.Item>

      <Menu.Item key="2" disabled>
        <Lightning style={{ width: "30px" }} />
      </Menu.Item> */}
      
      <Menu.Item key="" disabled>
        <ButtonList style={{ width: "30px" }} />
      </Menu.Item>

   

      <Menu.Item key="3" disabled>
        <ChartIcon style={{ width: "30px" }} />
      </Menu.Item>

      <Menu.Item key="4" onClick={() => onEdit()}>
        <CustomPencil style={{ width: "30px" }} />
      </Menu.Item>

      <Menu.Item key="5" disabled>
        <DeleteIconn style={{ width: "30px" }} />
      </Menu.Item>
    </CustomMenu>
  );
  const menuItems = (
    <CustomMenu  $simpleViewmissingData={simpleViewmissingData}>
      {/* <Menu.Item key="0" onClick={handleClick}>
        <PopoverCustom
          close={close}
          content={
            <TimePopover
              border={"none"}
              time={items?.time_score}
              percent={items?.time_percent}
              duration={items?.duration_score}
              onClose={onClose}
              close={close}
            />
          }
          popoverStyle={{
            arrowStyle: { display: "none" },
            containerStyle: { top: "-74px", left: "-93px" },
          }}
          height={"28px"}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Clock style={{ width: "30px" }} />
            <Time>{convertMinutesToTimeFormat(time)}</Time>
          </div>
        </PopoverCustom>
      </Menu.Item> */}

      {/* <Menu.Item key="1" onClick={handleClick}>
        <PopoverCustom
          close={close}
          content={
            <CalendarPopover
              readOnly={true}
              daysLeft={daysLeft}
              deadline={deadline}
              startline={startline}
              onClose={onClose}
              close={close}
            />
          }
          popoverStyle={{
            arrowStyle: { display: "none" },
            containerStyle: { top: "-74px", left: "-93px" },  
          }}
          height={"24px"}
        >
          {daysLeft === 1 ? (
            <RedCal style={{ width: "30px" }} />
          ) : (
            <Calendar style={{ width: "30px" }} />
          )}
        </PopoverCustom>
      </Menu.Item> */}

      {/* <Menu.Item key="2" onClick={handleClick}>
        <Lightning
          style={{ width: "30px" }}
          onClick={() => {
            setPopover(true);
            handleLeverage && handleLeverage();
          }}
        />
      </Menu.Item> */}
      {/* <Menu.Item key="3">
        {isFocused && (
          <TargetActive
            style={{ width: "30px" }}
            onClick={onFocusClickHandle}
          />
        )}
        {!isFocused && (
          <Target style={{ width: "30px" }} onClick={onFocusClickHandle} />
        )}
      </Menu.Item> */}
      <Menu.Item key="" 
     >
        <ButtonList style={{ width: "30px" }} />
      </Menu.Item>


      <Menu.Item key="4" onClick={() =>{
      setVisible(false)
      setBackground(null)
      if (onClickHandle) {
        onClickHandle(); 
      }
        
        }}>
        <ChartIcon style={{ width: "30px" }} />
      </Menu.Item>

      <Menu.Item key="5" onClick={() => {
        onEdit()
        setTimeout(() => {
          setBackground(null);
        }, 1000);
      }
        }>
        <CustomPencil style={{ width: "30px" }} />
      </Menu.Item>
      
      <Menu.Item >
        <DeleteIconn style={{ width: "30px" }} onClick={() =>{
           setHandleDelete && setHandleDelete(id)
           setBackground(null);
           setVisible(false)
           }}/>
      </Menu.Item>
    </CustomMenu>
  );

  const menu = items ? menuItems : menuMissingItems;

  const title = "";
  const isTask = false;

  return (
    <>
      {background && (
        <Background
          onClick={() => {
            onLeverageClose;
            setBackground(null);
            setPopover(false);
            onLeverageClose && onLeverageClose();
            setRowVisible && setRowVisible(undefined)
          }}
          $active={background}
        />
      )}
      <div>
        <CustomDropdowm
          overlay={menu}
          trigger={["click"]}
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          <CustomNav onClick={() => setRowVisible && setRowVisible(id)}/>
        </CustomDropdowm>

        {popover && (
          <LeveragePopover
            dropDownMobile={true}
            setPopover={setPopover}
            selectedItem={selectedItem}
            id={id}
            setBackground={setBackground}
            onClose={onLeverageClose}
            setOutsideAreaData={setAreaData}
          />
        )}

        {(handleDelete && setHandleDelete) && <DeleteGaol id={items?.action?.id} title={title} isTask={isTask} setHandleDelete={setHandleDelete} />}
      </div>
    </>
  );
};

export default MiniBar;
