
import styled from "styled-components";

export const TypeChar = styled.div<{ $color: string | null, $width: number, $height: number }>`
  text-align: center;
  width: ${({ $width }) => $width ? `${$width}px` : '16px !important'};
  height: ${({ $height }) => $height ? `${$height}px` : '16px !important'};
  background-color: ${({ $color }) => $color ? `${$color}` : '#EBEEF2'};
  color: ${({ $color }) => $color ? `#fff` : '#4F9BE8'};
  border-radius: 2px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0em;
`;
