import { useEffect, useLayoutEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { ReactComponent as CollapseIcon } from "assets/images/priorities/collapse-arrow.svg";

import {
  Wrapper,
  HeadToggle,
  Title,
  Body,
  BodyInner,
  Content
} from "./styled";
import AreasGroup from "../AreasGroup";

const GoalsGroup = ({ activeKey, data, onToggle }) => {
  const { id, title, goals } = data;

  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    if (goals.length)
      setActiveItem(goals[0].id);
  }, [goals, activeKey]);

  const onClickHandle = () => {
    onToggle(id);
  }

  const onToggleHandle = key => {
    setActiveItem(prev => prev === key ? '' : key);
  }

  return (
    <Wrapper $active={activeKey === id}>
      <HeadToggle $active={activeKey === id} onClick={onClickHandle}>
        <CollapseIcon />
        <Title>{title}</Title>
      </HeadToggle>

      <Accordion.Collapse as={Body} eventKey={id}>
        <BodyInner>
          <Accordion as={Content} activeKey={activeItem}>
            {goals.map(item => <AreasGroup activeKey={activeItem} data={item} onToggle={onToggleHandle} key={item.id} />)}
          </Accordion>
        </BodyInner>
      </Accordion.Collapse>
    </Wrapper>
  );
}

export default GoalsGroup;
