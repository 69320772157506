import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Header from "components/Header";
import { Protected, Guest } from "Hooks/ProtectedRoutes";

import FinalStep from "scenes/FinalStep";

import Legal from "scenes/Legal";

// Auth
import Landing from "scenes/AuthScenes/Landing";

import SignIn from 'scenes/AuthScenes/SignIn';
import SignUp from 'scenes/AuthScenes/SignUp';

import TermsOfUse from "./scenes/AuthScenes/TermsOfUse";

import ForgotPassword from "scenes/AuthScenes/ForgotPassword";
import ResetPassword from "scenes/AuthScenes/ResetPassword";
import ForgotPasswordSuccess from "scenes/AuthScenes/ForgotPasswordSuccess";
import Verification from "scenes/AuthScenes/Verification"

// Todo: move to components
import APMLayout from "scenes/ApmLayout";
import Coaching from "scenes/Coaching";

// Scheduler
import Home from "scenes/Home";
import Scheduler from "SchedulerScenes/SchedulerApp/Scheduler";

import Priorities from "scenes/Priorities";
import Hierarchy from "scenes/Hierarchy";

import { CheckAuth } from "Redux/Token/action";

import {
  APM_PATH,
  HOME_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  TERMS_OF_USE_PATH,
  FORGOT_PASSWORD_PATH,
  FORGOT_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD_PATH,
  WSH_PATH,
  WELCOME_PATH,
  HIERARCHY_PATH,
  JOURNEY_PATH,
  PREMISE_PATH,
  MISSION_PATH,
  PRIORITIZE_PATH,
  MEASUREMENT_PATH,
  GOALS_PATH,
  PURPOSE_PATH,
  MASTER_PATH,
  ACTIONS_PATH,
  COMPLETED_GOALS_PATH,
  PROFILE_PATH,
  OTP_PATH,
  COACHING_PATH
} from "utils/routes";

import Journeys from "scenes/Journeys";
import Premise from "scenes/Journeys/Premise";
import MissionScreen from "scenes/Journeys/Mission";
import JourneysLayout from "components/Journeys/Layout";
import PrioritizeScreen from "scenes/Journeys/Prioritize";
import MeasurementScreen from "scenes/Journeys/Measurement";
import PurposeScreen from "scenes/Journeys/Purpose";
import ActionsScreen from "scenes/Journeys/Actions";
import MasterPlanScreen from "scenes/Journeys/MasterPlan";
import CompletedGoalsScreen from "scenes/CompletedGoals";
import Profile from "scenes/Profile";

const Container = styled.div`
  // display: flex;
`;

const AppRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    function handleWindowFocus() {
      dispatch(CheckAuth({ silent: true }));
    }

    if (process.env.NODE_ENV !== 'development')
      window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  const authPaths = [
    WELCOME_PATH,
    SIGN_IN_PATH,
    SIGN_UP_PATH,
    TERMS_OF_USE_PATH,
    FORGOT_PASSWORD_PATH,
    FORGOT_PASSWORD_SUCCESS_PATH,
    RESET_PASSWORD_PATH,
    OTP_PATH
  ];

  const isAuthScreens = authPaths.includes(location.pathname);

  return (
    <>
      {isAuthScreens || <Header />}

      <Container>
        <Routes>
          {/* Auth */}
          <Route index element={<Guest><Navigate to={SIGN_IN_PATH} replace={true} /></Guest>} />

          <Route path={WELCOME_PATH} exact element={
            <Protected>
              <Landing />
            </Protected>
          } />

          <Route path={SIGN_IN_PATH} exact element={
            <Guest>
              <SignIn />
            </Guest>
          } />

          <Route path={SIGN_UP_PATH} exact element={
            <Guest>
              <SignUp />
            </Guest>
          } />

          <Route path={TERMS_OF_USE_PATH} exact element={
            <Guest>
              <TermsOfUse />
            </Guest>
          } />

          <Route path={FORGOT_PASSWORD_PATH} exact element={
            <Guest>
              <ForgotPassword />
            </Guest>
          } />

          <Route path={FORGOT_PASSWORD_SUCCESS_PATH} exact element={
            <Guest>
              <ForgotPasswordSuccess />
            </Guest>
          } />

          <Route path={RESET_PASSWORD_PATH} exact element={
            <Guest>
              <ResetPassword />
            </Guest>
          } />
          
          <Route path={OTP_PATH} exact element={
            <Guest>
              <Verification />
            </Guest>
          } />

          <Route
            path="legal"
            exact
            element={
              <Protected>
                <Legal />
              </Protected>
            }
          />
          <Route
            path="final-step"
            exact
            element={
              <Protected>
                <FinalStep />
              </Protected>
            }
          />

          <Route path="/scheduler/home" element={<Navigate to="/home" />} />
          <Route
            path="/scheduler/schedule"
            element={<Navigate to="/schedule" />}
          />
          <Route
            path="/scheduler/priorities"
            element={<Navigate to="/priorities" />}
          />

          <Route path="/wsh" element={<Navigate to="/schedule" />} />
          <Route path="/priority-manager" element={<Navigate to={APM_PATH} />} />

          <Route path="/" element={<Protected>{<APMLayout />}</Protected>}>
            <Route path={HOME_PATH} element={<Home />} />
            <Route path={WSH_PATH} element={<Scheduler />} />
            <Route path={HIERARCHY_PATH} element={<Hierarchy />} />

            <Route path={APM_PATH} element={<Priorities />} />
            <Route path={`${APM_PATH}${COACHING_PATH}`} element={<Coaching />} />
            <Route path={`${APM_PATH}${COACHING_PATH}/:id`} element={<Priorities />} />

            <Route path={`${JOURNEY_PATH}${PROFILE_PATH}`} element={<Profile />} />
            <Route path={`${APM_PATH}${PROFILE_PATH}`} element={<Profile />} />

            <Route
              path={JOURNEY_PATH}
              element={<JourneysLayout />}
            >
              <Route path={JOURNEY_PATH} element={<Journeys />} />
              <Route path={PREMISE_PATH} element={<Premise />} />
              <Route path={MISSION_PATH} element={<MissionScreen />} />
              <Route path={PRIORITIZE_PATH} element={<PrioritizeScreen />} />
              <Route path={MEASUREMENT_PATH} element={<MeasurementScreen />} />
              <Route path={GOALS_PATH} /* element={<GoalsScreen />} */ element={<Navigate to={MEASUREMENT_PATH} />} />
              <Route path={PURPOSE_PATH} element={<PurposeScreen />} />
              <Route path={ACTIONS_PATH} element={<ActionsScreen />} />
              <Route path={MASTER_PATH} element={<MasterPlanScreen />} />
            </Route>

            <Route path={COMPLETED_GOALS_PATH} element={<CompletedGoalsScreen />} />
          </Route>
        </Routes>
      </Container>
    </>
  );
};

export default AppRoutes;
