// @ts-ignore
import { getRoles, createCustomRole, deleteCustomRole } from "api";

import Notify from "utils/notify";
import { ActionTypes } from "./types";

export const GetRoles = () => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await getRoles();

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data?.map((item: any) => ({
        label: item?.name,
        value: item?.id,
        custom: item?.custom && item?.custom_user
      })),
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

export const CreateCustomRole = (formData: FormData, callback?: (element: any) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await createCustomRole(formData);

    const customItem = {
      label: data.name,
      value: data.id,
      custom: data.custom && data.custom_user
    }

    dispatch({
      type: ActionTypes.CREATE_CUSTOM_SUCCESS,
      payload: customItem
    });

    if (callback) {
      callback(customItem);
    }

    Notify.success("Custom Role created successfully.");
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

export const DeleteCustomRole = (id: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    await deleteCustomRole(id);

    dispatch({
      type: ActionTypes.DELETE_CUSTOM_SUCCESS,
      payload: id
    });

    Notify.success("Custom Role deleted successfully.");
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
