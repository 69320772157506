import React from "react";
import { Box, Row, Col, Typography } from "antd";
import styled from "styled-components";
import { ReactComponent as Prioritize } from "assets/images/prioritize.svg";

const { Text } = Typography;

const AppointmentEvent = ({ appointment }) => {
  const { title, location, resource, address, id } = appointment;

  return (
    <>
      <SelectWrapper>
        <GoalActionWrapper>
          <GoalAction width="100%">
            <Actions startColor={"red"} endColor={"blue"} width="100%">
              <div className="d-flex justify-content-between w-100">
                <div className="d-flex w-75">
                  <Index bg={"#F90"}>{id}</Index>
                  <Index bordered bg={"#e6e8ea"}>
                    H
                  </Index>
                  <SubHeading width="77%" className="mx-2" sm>
                    {title}
                  </SubHeading>
                </div>
                <div>
                  <Icon
                    style={{
                      transform: "translate(5px)",
                    }}
                  >
                    <Prioritize />
                  </Icon>
                </div>
              </div>
            </Actions>
          </GoalAction>
        </GoalActionWrapper>
      </SelectWrapper>

      {/* <Row align="middle" justify="space-between">
        <Col>
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {title}
          </Text>
        </Col>
        <Col>
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {location}
          </Text>
        </Col>
        <Col>
          <Text type="secondary" style={{ fontSize: "12px" }}>
            {resource}
          </Text>
        </Col>
      </Row>
      <div mt={4}>
        {address.split("\n").map((add, index) => (
          <Text key={index} style={{ fontSize: "12px" }}>
            {add}
          </Text>
        ))}
      </div> */}
    </>
  );
};

export default AppointmentEvent;
const GoalAction = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: ${({ width }) => (width ? width : "218px")};
  /* border-top: 2px solid #e6e8ea !important; */
  /* border: 1px solid green; */

  background-color: #fff;
`;
const Actions = styled.div`
  /* background-color: #1a2c42; */
  width: ${({ width }) => (width ? width : "218px")};
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-left: 4px solid;
  border-image: linear-gradient(
      38.44deg,
      ${(props) => props.startColor} 14.59%,
      ${(props) => props.endColor} 71.58%
    )
    1;
`;
const Index = styled.div`
  width: 20px !important;
  height: 20px !important;
  border-radius: ${({ bordered }) => (bordered ? "3px" : " 50%")};
  background: ${({ bg }) => (bg ? bg : "#2f4358")};
  color: ${({ bordered, theme }) =>
    bordered ? theme?.text?.primary_light : theme?.text?.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: ${({ bordered }) => bordered && "bold"};
  margin-left: ${({ bordered }) => bordered && "0.6rem"};
  padding: 0 0.5rem;
`;
const SubHeading = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme && theme?.text?.black};
  margin: 0;
  padding: 0;
  width: ${({ width }) => (width ? width : "120px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Icon = styled.div`
  margin: 0 4px;
  cursor: pointer;
  svg {
    width: ${({ sm, md }) => (sm && "10px") || (md ? "14px" : "16px")};
    height: ${({ sm, md }) => (sm && "10px") || (md ? "14px" : "16px")};
  }
`;

const SelectWrapper = styled.div`
  background-color: #e6e8ea !important;
`;

const GoalActionWrapper = styled.div`
  padding: 3px 4px;
`;
