import React, { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import {
  Heading,
  MainFilter,
  FilterStyle,
  CustomSelect,
  CustomBotton,
} from "./styled";
import { ReactComponent as InputIcon } from "assets/images/priorities/common/inputIcon.svg";

const { Option } = Select;
Chart.register(...registerables);


const defaultDataBar = {
  labels: ["Activity/20", "Goal/232", "Urgency/20", "Time/20", "Leverage/20"],
  datasets: [
    {
      data: [10, 7, 10, 1, 10],
      backgroundColor: "#418DB8",
      borderColor: "#418DB8",
      borderWidth: 1,
    },
    {
      data: [1, 6, 7, 3, 3],
      backgroundColor: "#737373",
      borderColor: "#737373",
      borderWidth: 1,
    },
  ],
};

const optionsBar = {
  maintainAspectRatio: false,
  scales: {
    y: { beginAtZero: true },
    x: {
      ticks: {
        callback: function (value, index, values) {
          const text = this.getLabelForValue(value);
          let labelParts = text.split("/");
          return labelParts.length === 2
            ? ["\u200b" + labelParts[0], labelParts[1]]
            : text;
        },
        font: function (context) {
          var width = context.chart.width;
          var size = Math.round(width / 32);
          return {
            size: size,
            weight: context.tick && context.tick.major ? "bold" : "normal",
          };
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "#36A2EB",
      labels: {
        title: { font: { weight: "bold" } },
        value: { color: "grey" },
      },
    },
  },
};

const RadarChartComponent = ({
  data = defaultDataBar,
  selectedItem,
  filteredData,
}) => {
  const firstGrap = [
    selectedItem?.activity_weight <= 0 ? 0 : selectedItem?.activity_weight,
    selectedItem?.direct_impact <= 0 ? 0 : selectedItem?.direct_impact,
    selectedItem?.urgency_score <= 0 ? 0 : selectedItem?.urgency_score,
    selectedItem?.time_score <= 0 ? 0 : selectedItem?.time_score,
    selectedItem?.indirect_impact <= 0 ? 0 : selectedItem?.indirect_impact,
  ];

  const secondGraph = [
    filteredData?.[0] ?? "",
    filteredData?.[1] ?? "",
    filteredData?.[2] ?? "",
    filteredData?.[3] ?? "",
    filteredData?.[4] ?? "",
  ];

  const defaultDataBar = {
    labels: ["Activity/20", "Goal/20", "Urgency/20", "Time/20", "Leverage/20"],
    datasets: [
      {
        data: [
          firstGrap[0],
          firstGrap[1],
          firstGrap[2],
          firstGrap[3],
          firstGrap[4],
        ],
        backgroundColor: "#418DB8",
        borderColor: "#418DB8",
        borderWidth: 1,
      },
      {
        data: [
          secondGraph[0],
          secondGraph[1],
          secondGraph[2],
          secondGraph[3],
          secondGraph[4],
        ],
        backgroundColor: "#737373",
        borderColor: "#737373",
        borderWidth: 1,
      },
    ],
  };
  return (
    <div style={{ width: "339px", height: "339px" }}>
      <Bar data={defaultDataBar} options={optionsBar} />
    </div>
  );
};

export const Filters = ({
  onFilterChange,
  screenSize,
  transformedData,
  selectedItem,
  compare,
  setCompare,
  defaultValue,
  filterDatas,
}) => {
  

const [disabled, setDisabled] = useState(false);
const _tooltopdata = transformedData?.filter((item, index) => item.id === filterDatas)
useEffect(() => {
  const selectNodes = document.querySelectorAll('.ant-select-selection-item');
  selectNodes.forEach(node => {
    node.removeAttribute('title');
  });
});
  return (
    <FilterStyle $mobile={screenSize !== "desktop"}>
      {screenSize !== "desktop" && compare && (
        <CustomBotton
          $mobile={screenSize !== "desktop"}
          type="link"
          onClick={() => {
            setCompare(false);
            onFilterChange(null);
          }}
          style={{ alignSelf: "flex-end" }}
        >
          Close
        </CustomBotton>
      )}

      <MainFilter $mobile={screenSize !== "desktop"}>
        <InputIcon />

        <Tooltip title={selectedItem.action.context} >

        <Heading>
        {selectedItem?.action?.context && selectedItem.action.context.length > 20
        ? selectedItem.action.context.substring(0, 18) + '...' 
        : selectedItem?.action?.context}
        </Heading>
        </Tooltip>
      </MainFilter>


      {compare && (
        <>
        <Tooltip visible={disabled} title={_tooltopdata[0]?.name} placement="top">
          <CustomSelect
          onMouseEnter={() => setDisabled(true)}
          onMouseLeave={() => setDisabled(false)}
            $mobile={screenSize !== "desktop"}
            onChange={(value) => {
              onFilterChange(value);
            }}
            defaultValue={defaultValue}
            onClick={() => setDisabled(false)}
          >
            {transformedData?.map((item, index) => {
              return <Option value={item?.id}>{item?.name}</Option>;
            })}
          </CustomSelect>
          </Tooltip>
          {screenSize === "desktop" && (
            <CustomBotton
              type="link"
              onClick={() => {
                setCompare(false);
                onFilterChange(null);
              }}
              style={{ alignSelf: "center" }}
            >
              Close
            </CustomBotton>
          )}

        </>
      )}
    </FilterStyle>
  );
};

export default RadarChartComponent;
