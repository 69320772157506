import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 0px 10px);
  width: 411px;
}`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 4px 4px 4px 7px;
  border-bottom: 1px solid #D9D9D9;
}`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}`;

export const Text = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}`;

export const Body = styled.div`
  display: flex;
  gap: 13px;
  padding: 10px 5px 10px 8px;
}`;

export const Graph = styled.div`
  
}`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
}`;

export const Formula = styled.span`
  opacity: 0.7;
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  span {
    opacity: 1;
    font-weight: 500;
  }
}`;
