import { FC, ReactNode } from "react";
import { Tooltip as AntdTooltip } from "antd";
import { Button } from 'elements';

import {
  Content,
  Title,
  Text,
  Footer,
} from "./styled";

// @ts-ignore
import styles from "./styles.module.scss";

interface IProps {
  open?: boolean;
  color: string
  onClick: (action: boolean) => void
  children: ReactNode;
}

const SuggestingTooltip: FC<IProps> = ({ open, color, onClick, children, ...rest }) => {
  const handleCancel = () => {
    onClick(false);
  };

  const handleApply = () => {
    onClick(true);
  };

  const content: () => JSX.Element = () => (
    <Content $color={color}>
      <Title>Create a mission statement for this area</Title>
      <Text>Your mission is the roadmap to your dream life. We recommend you create one to keep you motivated on your journey. It’ll only take a few minutes.</Text>

      <Footer>
        <Button styleType="gray-outlined" onClick={handleCancel}>No thanks</Button>
        <Button onClick={handleApply}>Let's go</Button>
      </Footer>
    </Content>
  );

  if (!open) {
    return <>{children}</>;
  }

  return (
    <AntdTooltip
      open
      title={content()}
      overlayClassName={styles.primary}
      placement={"leftTop"}
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      {...rest}
    >
      {children}
    </AntdTooltip>
  );
};

export default SuggestingTooltip;
