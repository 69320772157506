import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "utils/StorageVariables";
import useDeviceType from "../../../Hooks/ResponsiveHook";

import { AssistantProvider } from "components/InputAreaAI";
import PlanShortcut from "components/PlanShortcut";

import {
  RetrieveMasterPlanDetails,
  RetrieveMasterPlanDetailsNull,
} from "Redux/RetrieveMasterPlan/action";

import { UpdateLastStep } from "Redux/UpdateLastStep/action";
import { UpdateGoal } from "Redux/UpdateGoal/action";
import _ from "lodash";

import { ACTIONS_PATH, MEASUREMENT_PATH } from "utils/routes";

import * as Styles from "./styled";
import StepLayout from "../StepLayout/index";
import PurposeItem from "./PurposeItem";

const SortArr = (data) =>
  data?.sort(function (a, b) {
    return a?.goal?.priority - b?.goal?.priority;
  });

const Purpose = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [areas, setareas] = useState();

  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));

    return () => {
      dispatch(RetrieveMasterPlanDetailsNull(null));
    };
  }, []);

  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );

  useEffect(() => {
    if (!masterPlanDetails?.areas) return;
    const res = SortArr(masterPlanDetails?.areas);
    setareas(res);
  }, [masterPlanDetails?.areas]);

  const handleChange = (id, field, value) => {
    let areasCopy = [...areas];
    let findedObj = areasCopy?.find((item) => item?.id === id);

    findedObj.goal[field] = value;
    areasCopy[areasCopy.indexOf(findedObj)] = findedObj;

    dispatch(UpdateGoal(findedObj.goal.id, {
      [field]: value
    }));

    setareas(areasCopy);
  };

  const userData = JSON.parse(getCookie("userData"));

  const handlePrev = () => {
    navigate(MEASUREMENT_PATH);
  };

  const handleNext = () => {
    const formData = new FormData();
    formData.append("step", 5);
    dispatch(UpdateLastStep(formData));

    navigate(ACTIONS_PATH);
  };

  const HeaderContent = () => {
    return (
      <>
        <Styles.TableHeadCol $width="17vw" $ml={"12px"}>
          Goal
        </Styles.TableHeadCol>
        <Styles.TableHeadCol $flex="1">Purpose</Styles.TableHeadCol>
      </>
    );
  };

  return (
    <StepLayout
      title="5. Purpose"
      header={(isMobile || isTablet) ? null : <HeaderContent />}
      onPrev={handlePrev}
      onNext={handleNext}
      titlePosition={isMobile ? true : false}
    >
      <AssistantProvider>
        <Styles.Container>
          <Styles.ContainerItem>
            <Styles.TableContainer>
              {areas &&
                areas?.map((item, i) => (
                  <PurposeItem
                    key={item?.id}
                    item={item}
                    userData={userData}
                    index={i}
                    onChange={handleChange}
                  />
                ))}

              <PlanShortcut
                isVisible={masterPlanDetails?.viewed_masterplan_screen}
              />
            </Styles.TableContainer>
          </Styles.ContainerItem>
        </Styles.Container>
      </AssistantProvider>
    </StepLayout>
  );
};

export default Purpose;
