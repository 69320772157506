import styled from "styled-components";

export const Time = styled.span`
  color: var(--generic-black, #000);
  text-align: end;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  //  width: 58px;
`;
