import { FC } from 'react';

// @ts-ignore
import profilePlaceholderIcon from "assets/images/priorities/profile-placeholder.svg";

import { Wrapper, ProfilePicture, Content, Name, Email } from './styled';
import { IClient } from 'scenes/Coaching/UserRow';

interface IProps {
  data: IClient;
}

const OptionLabel: FC<IProps> = ({ data }: { data: any }) => {
  const { photo, email, name } = data;

  return (
    <Wrapper>
      <ProfilePicture src={photo || profilePlaceholderIcon} />

      <Content>
        <Name>{name}</Name>
        <Email>{email}</Email>
      </Content>
    </Wrapper>
  );
}

export default OptionLabel;
