import { /* useState, */ ReactNode } from "react";
import { Tooltip as AntdTooltip } from "antd";
import { Button } from 'elements';

import styled from 'styled-components';

// @ts-ignore
import styles from "./styles.module.scss";

// @ts-ignore
import assistantBeta from "assets/images/assistant-beta.svg";

interface TooltipProps {
  onClose?: () => void;
  children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ onClose, children, ...rest }) => {
  const content: () => JSX.Element = () => (
    <Content data-testid="antd-hover">
      <Head>
        <img
          src={assistantBeta}
          width={144}
          height={24}
          alt="AI Assistant Beta"
        />
      </Head>

      <Body>
        <Title>Suggest a goal</Title>
        <Text>Tap the icon to generate goal suggestions.</Text>
      </Body>

      <Footer>
        <Button onClick={onClose}>Got it</Button>
      </Footer>
    </Content>
  );

  return (
    <div
      data-testid="antd-hover"
    >
      <AntdTooltip
        title={content()}
        overlayClassName={styles.primary}
        placement={window.innerWidth < 768 ? "rightBottom" : "rightTop"}
        open
        align={{
          offset: window.innerWidth < 768 ? [0, 14] : [0, -14],
        }}
        getPopupContainer={(trigger) => trigger.parentElement ?? document.body}
        {...rest}
      >
        {children}
      </AntdTooltip>
    </div>
  );
};

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  background: var(--Dark-mode-bg, #FFF);
  padding: 12px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  min-width: 320px;

  @media screen and (max-width: 743px) {
    min-width: 280px;
  }
`;

export const Head = styled.div`
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
`;

export const Body = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Footer = styled.div`
  padding-top: 12px;
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

export const Text = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
}`

export default Tooltip;
