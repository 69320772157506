import styled from 'styled-components';

import Button from '../../Button';
import { Form, Submit, Linkbox } from '../styled';
import TextBox from '../../../atoms/TextBox';
import TextBoxLabel from '../../../atoms/TextBoxLabel';
import { fadeInUpMixin } from '../animated';

export const FormWrapper = styled(Form)``;

export const FormText = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  margin-bottom: 22px;
  text-align: center;
  ${fadeInUpMixin}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 624px;
`;

export const Heading = styled.span`
  font-weight: 700;
  font-size: 50px;
  line-height: 160%;
  margin-bottom: 12px;
`;

export const Subheading = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 34px;
  max-width: 465px;
`;

export const FormStyled = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div`
  width: 100%;
  max-width: 388px;
  margin: 22px auto 0;
  ${fadeInUpMixin}
`;

export const Label = styled(TextBoxLabel)`
  padding: 20px 0 6px;

  &:first-child {
    padding-top: 0;
  }
`;

export const TextBoxs = styled(TextBox)`
  width: 100%;

  &[type="password"] {
    width: calc(100% - 36px);
    border-right: none !important;
    border-radius: 0.25rem 0 0 0.25rem;
  }
`;

export const Eye = styled(Button)`
  position: absolute;
  background: transparent;
  opacity: 0.8;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  padding: 0;
  border-right: 1px solid #e3e5e9;
  border-top: 1px solid #e3e5e9;
  border-bottom: 1px solid #e3e5e9;
  border-radius: 0 0.25rem 0.25rem 0;
`;

export const FormInfo = styled.div`
  margin-top: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #818EA3;
`;

export const FormError = styled.div`
  margin-top: 4px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
  color: #f00;
`;

export const SubmitWrapper = styled.div`
  ${fadeInUpMixin}
`;

export const SubmitStyled = styled(Submit)`
  margin-top: 12px;
`;

export const LinkboxStyled = styled(Linkbox)`
  margin-top: 8px;
  ${fadeInUpMixin}

  a {
    margin-left: 0;
  }
`;
