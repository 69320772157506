import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { deleteMissionStatement } from "api";
import { getCookie } from "utils/StorageVariables";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { SetMissionStatement } from "Redux/GetMissionStatement/action";

export const DeleteMissionStatement = (id, callback) => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.LOADING });
        const { data, status } = await deleteMissionStatement(id);

        dispatch({
            type: ActionTypes.SUCCESS,
            payload: data,
        });

        if (status === 204) {
            dispatch(SetMissionStatement(null));

            let MasterPlanId = getCookie("MasterPlanId");
            dispatch(RetrieveMasterPlanDetails(MasterPlanId, false));

            if (callback) {
                callback();
            }
        }
    } catch (error) {
        Error(error?.response?.data);
        dispatch({
            type: ActionTypes.ERROR,
            payload: error,
        });
    }
};
