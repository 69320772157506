import { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import SimpleViewGroup, { TasksGroup } from "./SimpleViewGroup";
import SimpleViewItem from "./SimpleViewItem";
import ActionItemForm from "./ActionItemForm";
import FocusedPriorities from "./FocusedPriorities";
import Instructions from "./Instructions";
import ActionDropdown from "./ActionDropdown";

import { PrioritizePopup/* , PendingPopup */ } from "./PrioritizationPopup";

import {
  Wrapper,
  List,
  MainTitle,
  BoxIconBtn,
  CustomAccordion,
  CustomAccordionItem,
  SingleGroupView,
  EmptyListTitle,
  EmptyListSubTitle,
  Main,
  Box,
} from "./styled";

import { globalIdAction } from "../../../Redux/APM/GlobalId/action";

import useDeviceType from "../../../Hooks/ResponsiveHook";

import { ReactComponent as ChevronIcon } from "./chevron.svg";
import { fetchTasks } from "../../../Redux/APM/ListTasks/action";

const SimpleView = ({
  isViewOnly,
  ipadView,
  setIpadView,
  setBackground,
}) => {
  const { id } = useParams();

  const buttonRefForSimpleViewItem = useRef(null);

  const [isEditMode, setIsEditMode] = useState(undefined);
  const [visible, setVisible] = useState(false);
  const [actions, setActions] = useState(undefined);
  const [isViewMode, setIsViewMode] = useState(undefined);
  const [activeKeys, setActiveKeys] = useState({
    activities: true,
    tasks: true,
  });

  const screenSizes = useDeviceType();
  const dispatch = useDispatch();

  const prioritizedActions = useSelector((state) => state.prioritizedActions);
  const { data: tasksData } = useSelector((state) => state.listTasks);

  const { setAction } = useSelector((state) => state.actionReducer);

  const onClickHandle = (id) => {
    setIsViewMode(id);
  };

  const handleToggle = (key) => () => {
    setActiveKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onEditOpenHandle = (id) => {
    setIsViewMode(undefined);
    setIsEditMode(id);
  };

  let outputActionId = actions?.replace(/ /g, "");

  const selectedItem =
    isViewMode || isEditMode || outputActionId
      ? prioritizedActions?.prioritizedData?.find(
          (item) =>
            item?.id === (isViewMode || isEditMode || outputActionId) ||
            item?.action?.id === (isViewMode || isEditMode || outputActionId)
        ) ||
        tasksData.find(
          (item) => item.id === (isViewMode || isEditMode || outputActionId)
        )
      : undefined;

  const isProView = !prioritizedActions.simpleView;

  const groupedData = prioritizedActions?.prioritizedDataGrouped;

  const data = prioritizedActions?.prioritizedData;

  useEffect(() => {
    if (selectedItem?.id) {
      dispatch(globalIdAction(selectedItem.id));
    }
  }, [selectedItem?.id]);

  useEffect(() => {
    dispatch(fetchTasks());
  }, []);

  const trueKeys = Object.keys(activeKeys).filter((key) => activeKeys[key]);

  return (
    <Wrapper $mobile={screenSizes !== "desktop"}>
      {(screenSizes !== "desktop" ? isViewOnly : true) && (
        <List $coachView={!!id}>
          {prioritizedActions.length <= 0 && tasksData <= 0 && (
            <Main $mobile={screenSizes !== "desktop"}>
              <EmptyListTitle>
                What ambitions will you conquer today?
              </EmptyListTitle>
              <EmptyListSubTitle>
                This is where your goals will live when you’ve created them. Why
                not start now?{" "}
              </EmptyListSubTitle>
              <ActionDropdown />
            </Main>
          )}

          <CustomAccordion activeKey={trueKeys}>
            {groupedData.length ? (
              <>
                <Box>
                  <MainTitle>
                    Mission Activities ({prioritizedActions.length})
                  </MainTitle>

                  <BoxIconBtn
                    $active={activeKeys.activities}
                    onClick={handleToggle("activities")}
                  >
                    <ChevronIcon />
                  </BoxIconBtn>
                </Box>

                <CustomAccordionItem
                  eventKey={"activities"}
                >
                  <Accordion.Body>
                    {!isProView ? (
                      <SingleGroupView>
                        {data.map((item, index) => (
                          <SimpleViewItem
                            setActions={setActions}
                            setVisible={setVisible}
                            visible={visible}
                            buttonRefForSimpleViewItem
                            data={item}
                            ref={buttonRefForSimpleViewItem}
                            screenSizes={screenSizes}
                            key={item.id}
                            index={index + 1}
                            isActive={
                              isViewMode === item.id || isEditMode === item.id
                            }
                            onClick={onClickHandle}
                            onEdit={onEditOpenHandle}
                          />
                        ))}
                      </SingleGroupView>
                    ) : (
                      groupedData.map((group, index) => (
                        <SimpleViewGroup
                          key={group.id}
                          setVisible={setVisible}
                          visible={visible}
                          index={index + 1}
                          data={group}
                          onClick={onClickHandle}
                          onEdit={onEditOpenHandle}
                        ></SimpleViewGroup>
                      ))
                    )}
                  </Accordion.Body>
                </CustomAccordionItem>
              </>
            ) : null}

            {tasksData.length > 0 ? (
              <>
                <Box>
                  <MainTitle>Tasks ({tasksData.length})</MainTitle>

                  <BoxIconBtn
                    $active={activeKeys.tasks}
                    onClick={handleToggle("tasks")}
                  >
                    <ChevronIcon />
                  </BoxIconBtn>
                </Box>

                <CustomAccordionItem eventKey={"tasks"}>
                  <Accordion.Body>
                    <TasksGroup
                      data={tasksData}
                      setVisible={setVisible}
                      onClick={onClickHandle}
                      onEdit={onEditOpenHandle}
                    />
                  </Accordion.Body>
                </CustomAccordionItem>
              </>
            ) : null}
          </CustomAccordion>
        </List>
      )}

      <FocusedPriorities
        setIpadView={setIpadView}
        screenSizes={screenSizes}
        setBackground={setBackground}
        ipadView={ipadView}
      />

      <ActionItemForm
        active={setAction || actions}
        setActions={setActions}
        onClose={() => {}}
      />

      <Instructions />
      <PrioritizePopup />

      {/* Hidden because rejected by UAT */}
      {/* <PendingPopup /> */}
    </Wrapper>
  );
};

export default SimpleView;
