import React from 'react';
import { Circle, FlexContainer } from './styled';

interface PercentageItem {
  percent: number;
  color: string;
}

const BubbleChartComponent: React.FC<{ selectedItem: any }> = ({ selectedItem }) => {
  const percentages: PercentageItem[] = [
    { percent: 20, color: 'rgba(0, 223, 255, 0.7)' },
    { percent: 20, color: 'rgba(85, 210, 168, 0.7)' },
    { percent: 20, color: 'rgba(227, 51, 51, 0.7)' },
    { percent: 20, color: 'rgba(245, 170, 66, 0.7)' },
    { percent: 20, color: 'rgba(173, 83, 244, 0.7)' },
    // Add other percentages and colors as needed
  ];

  return (
    <FlexContainer>
      {percentages.map((item, index) => (
        <Circle key={index} size={item.percent * 2.9} color={item.color}>
          {item.percent}%
        </Circle>
      ))}
    </FlexContainer>
  );
};

export default BubbleChartComponent;
