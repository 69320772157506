import styled from 'styled-components';

export const Wrapper = styled.form`
  border-top: 1px solid var(--Input-border, #E5E5E5);
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  padding: 8px 9px;
`;

export const Label = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
