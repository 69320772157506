import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: transparent;
  transition: background .2s;

  ${({ $active }) => $active && `
    background: #F3F4F5;
  `}
`;

export const HeadToggle = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  padding: 8px 4px 8px 0;
  border: none;
  background: transparent;
  text-align: left;

  svg {
    transform: rotate(-90deg);
    transition: transform .2s;
  }

  ${({ $active }) => $active && `
    svg {
      transform: rotate(0deg);
    }
  `}
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, #112538);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;

export const Body = styled.div`
  width: 100%;
`;

export const BodyInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
