import { retrieveUserData } from "api";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";

export const RetrieveUserData = () => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await retrieveUserData();

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      setCookie("credits", data?.tokens)
      setCookie("userData", JSON.stringify(data))

    }
  } catch (error) {
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
