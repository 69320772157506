import { useDispatch } from "react-redux";

import {
  ActivitiesList,
  ListAvatar,
  ListCheck,
  ListDrag,
  ListLeft,
  ListRight,
  ListTime,
  ListTitle,
} from "../../styled";
import { Checkbox, Tooltip } from "elements";

import { Dropdown, Menu, Popover } from "antd";

import { Draggable, DraggableProvided } from "react-beautiful-dnd";
// @ts-ignore
import { ReactComponent as DragIcon } from "assets/images/priorities/drag.svg";
// @ts-ignore
import { ReactComponent as DragingIcon } from "assets/images/priorities/draging.svg";
// @ts-ignore
import { ReactComponent as ScheduleAdd } from "assets/images/priorities/scheduleAdd.svg";
// @ts-ignore
import { removeFocusedPriority } from "Redux/APM/ListFocusedPriorities/action";
// @ts-ignore
import { ReactComponent as DotsVertical } from "assets/images/priorities/dots-vertical.svg";
import { useRef, useState } from "react";
import { UpdateActions } from "Redux/UpdateActions/action";
// @ts-ignore
import { completeTaskAction } from "../../../../../../Redux/APM/CompleteTask/action";

import { FaCheck } from "react-icons/fa6";
import { Type } from "components/PriorityApp/SimpleView/Elements";
import TimePopover from "components/PriorityApp/SimpleView/TimePopover";

interface ListProps {
  data: {
    id: number;
    prioritized_activity?: {
      color: string;
      id?: string;
      estimated_weekly_duration: number;
      delegation_time?: number;
      management_time?: number;
      total_time: number;
      action?: {
        type: string;
        context: string;
        id?: string;
        completed: boolean;
      };
    };
    task?: {
      name: string;
      priority: string;
      id?: string;
      completed: boolean;
      estimated_duration?: number;
    };
  };
  index: number;
  onFocusToggle: () => void;
  dragAbleId: any;
  completed?: boolean;
}

const List: React.FC<ListProps> = ({
  data,
  index,
  onFocusToggle,
  dragAbleId,
  completed = false,
}) => {
  const dispatch = useDispatch();

  const popover = useRef<any>();
  const timePopover = useRef<any>();
  const [checked, setChecked] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const isHabit = data?.prioritized_activity?.action?.type === "Habit";
  const activityType = data?.prioritized_activity
    ? data?.prioritized_activity?.action?.type
    : "task";

  const { prioritized_activity, task } = data;
  const label = prioritized_activity?.action?.context || task?.name || "";

  const estimatedDuration = prioritized_activity?.total_time
    ? prioritized_activity?.estimated_weekly_duration
    : task?.estimated_duration || 0;

  const estimatedDurationHours = Math.floor(estimatedDuration / 60);
  const estimatedDurationMinutes = estimatedDuration % 60;

  const formattedDuration = `${
    estimatedDurationHours > 0
      ? estimatedDurationHours + "h "
      : estimatedDurationMinutes
      ? "00h "
      : ""
  }${
    estimatedDurationMinutes > 0
      ? estimatedDurationMinutes + "m"
      : estimatedDurationHours
      ? "00m"
      : ""
  }`;

  const removeFocused = (id: number) => {
    dispatch(removeFocusedPriority(id));
  };

  const completeTask = async (data: any) => {
    if (data?.prioritized_activity && data?.prioritized_activity?.action?.id) {
      await dispatch(
        UpdateActions(
          data.prioritized_activity.action.id,
          { completed: true },
          false,
          {
            callback: () => {},
            showLoader: true,
            isApm: true,
          }
        )
      );
    } else {
      dispatch(completeTaskAction(data?.task?.id));
    }
  };

  const items: any = [];

  if (!completed && !isHabit) {
    items.push({
      key: "complete",
      label: <Menu.Item key="complete">Complete Task</Menu.Item>,
      className: "apm-drawer-dropdownMenu",
    });
  }

  items.push({
    key: "remove",
    label: <Menu.Item key="remove">Remove</Menu.Item>,
    className: "apm-drawer-dropdownMenu apm-drawer-dropdownMenuRemove",
  });

  const onChange = () => {
    setChecked(!checked);
    if (data?.prioritized_activity && data?.prioritized_activity?.action?.id) {
      dispatch(
        UpdateActions(
          data.prioritized_activity.action.id,
          { completed: true },
          false,
          {
            callback: () => {},
            showLoader: true,
            isApm: true,
          }
        )
      );
    } else {
      dispatch(completeTaskAction(data?.task?.id));
    }
  };

  const handleClose = () => {
    if (timePopover.current) {
      popover?.current?.close();
      setOpen(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Draggable key={data?.id} draggableId={data?.id.toString()} index={index}>
      {(provided: DraggableProvided) => (
        <>
          <ActivitiesList
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <ListLeft>
              <ListDrag {...provided.dragHandleProps}>
                {completed ? <h4>-</h4> : <span>{index + 1}</span>}
                {dragAbleId === data?.id ? (
                  <DragIcon title="Drag" />
                ) : (
                  <DragingIcon title="Dragging" />
                )}
              </ListDrag>
              {activityType && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Type
                    type={activityType}
                    isClick
                    offset={[119, 0]}
                    containerStyle={{
                      left: "157px",
                    }}
                  />
                </div>
              )}
              <ListTitle completed={completed}> {label}</ListTitle>
            </ListLeft>
            <ListRight>
              {completed ? (
                <ListTime>{formattedDuration}</ListTime>
              ) : (
                <Popover
                  // @ts-ignore
                  getPopupContainer={(trigger) => trigger.parentNode}
                  // @ts-ignore
                  getTooltipContainer={(trigger) => trigger.parentNode}
                  align={{ offset: [10, 0] }}
                  content={
                    <TimePopover
                      ref={timePopover}
                      id={data?.prioritized_activity?.id || data?.task?.id}
                      isTask={task ? true : false}
                      delegation_time={data?.prioritized_activity?.management_time || 0}
                      estimation_time={(prioritized_activity?.total_time ? data?.prioritized_activity?.delegation_time : task?.estimated_duration) || 0}
                      onClose={handleClose}
                    />
                  }
                  title={null}
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                  placement="bottomRight"
                  overlayClassName="time_popover_weekly"
                  overlayStyle={{ zIndex: 9999 }}
                >
                  <ListTime className="cursor-pointer">
                    {formattedDuration}
                  </ListTime>
                </Popover>
              )}

              {completed && (
                <FaCheck
                  style={{
                    color: "#59A310",
                    marginLeft: "6px",
                  }}
                />
              )}

              {!completed && (
                <div>
                  <Tooltip title="Schedule">
                    <ScheduleAdd id="react-joyride-step7" />
                  </Tooltip>
                </div>
              )}

              {completed || isHabit ? (
                <div
                  style={{
                    width: "18px",
                    height: "18px",
                    visibility: "hidden",
                    opacity: 0,
                  }}
                ></div>
              ) : (
                <Tooltip title="Complete">
                  <ListCheck id="react-joyride-step8">
                    <Checkbox
                      id={`check_${data.id}`}
                      checked={checked}
                      onChange={onChange}
                    />
                  </ListCheck>
                </Tooltip>
              )}

              <div>
                <Dropdown
                  menu={{
                    items,
                    onClick: (e) => {
                      if (e.key === "remove") {
                        removeFocused(data?.id);
                      } else if (e.key === "complete") {
                        completeTask(data);
                      }
                    },
                  }}
                  placement="bottomRight"
                  trigger={["click"]}
                  overlayClassName="apm-drawer-dropdown"
                  getPopupContainer={(triggerNode) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <DotsVertical
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Dropdown>
              </div>
            </ListRight>
          </ActivitiesList>
        </>
      )}
    </Draggable>
  );
};

export default List;