import { FC } from 'react';
import { Checkbox } from 'elements';

import AreaItem, { IArea } from '../AreaItem';

import {
  Wrapper,
  Head,
  Body,
  Title,
  Label
} from './styled';

interface IProps {
  data: IArea[];
  onChange: (id: string, value: string | null) => void;
  onCheckAll: (isChecked: boolean) => void;
}

const AreasList: FC<IProps> = ({ data, onChange, onCheckAll }) => {
  const handleCheck = (id: string) => (value: string | null) => {
    onChange(id, value);
  }

  const handleChange = (id: string) => (value: string | null) => {
    onChange(id, value);
  }

  const handleAllCheck = (isChecked: boolean) => () => {
    onCheckAll(!isChecked);
  }

  const isChecked = data.every(item => item.value !== null);

  return (
    <Wrapper>
      <Head>
        <Checkbox
          id="select-all"
          label={<Label>Select All</Label>}
          checked={isChecked}
          onChange={handleAllCheck(isChecked)}
        />
        <Title>Score</Title>
      </Head>

      <Body>
        {data.map(item => <AreaItem
          key={item.id}
          data={item}
          onCheck={handleCheck(item.id)}
          onChange={handleChange(item.id)}
        />)}
      </Body>
    </Wrapper>
  );
}

export default AreasList;
