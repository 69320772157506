import styled from "styled-components";

export const Wrapper = styled.div<{$mobile: boolean; $inline: boolean}>`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  width: 338px;

  ${({ $inline }) => $inline && `
    filter: none;
    margin-left: initial;
    width: 100%;
  `}
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
  padding: 4px;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-family: 'Inter';

  svg {
    margin-right: 4px;
    width: 20px;
    transform: rotate(0.1deg);
  }

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #112538;
  }
`;

export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const Text = styled.span`
  color: #1B2A3D
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  padding: 2px 8px;
`;

export const Score = styled.span`
  color: #171717;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 7px 6px;
`;

export const BodyTime = styled.div<{ $inline: boolean }>`
  display: flex;
  gap: 6px;
  padding: 7px 6px;
  flex-direction: column;

  ${({ $inline }) => $inline && `
    padding: 0;
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F5F5F5;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
`;

export const TextWithPopover = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
