const location = window.location.href;

const apiProdURL = 'goals.arootah.com';
const apiStagingURL = 'goal-setting-workbo-37-staging.botics.co';
export const apiDevelopURL = 'dev.gm.arootah.com';

const userProdURL = 'auth.arootah.com';
const userStagingURL = 'compensation-tool-3752-staging.botics.co';
const userDevelopURL = 'dev.auth.arootah.com';

const hatProdURL = 'app.arootah.com';
const hatStagingURL = 'hat-arootah-web-24408.botics.co';
export const hatDevelopURL = 'dev.hat.arootah.com';

let apiURL = apiProdURL;
let userURL = userProdURL;
let hatURL = hatProdURL;

// Array of staging urls
const validStagingURLs = [
  'goal-setting-workbo-37-staging.botics.co',
];

const validDevelopURLs = [
  apiDevelopURL
];

// Checking if current url is a valid url
if (validStagingURLs.some(url => location.includes(url))) {
  apiURL = apiStagingURL;
  userURL = userStagingURL;
  hatURL = hatStagingURL;
}
else if (process.env.NODE_ENV === 'development' || validDevelopURLs.some(url => location.includes(url))) {
  apiURL = apiDevelopURL;
  userURL = userDevelopURL;
  hatURL = hatDevelopURL;
}

console.log(`${apiURL === apiProdURL && process.env.NODE_ENV === 'production' ? '🟢' : '🟡'} API:`, apiURL);

export const ROOT_API_URL = `https://${apiURL}/api/v1/`;
export const WEBSOCKET_URL = `wss://${apiURL}/ws/chat/`
export const AUTH_API_URL = `https://${userURL}/api/v1/`;
export const HAT_API_URL = `https://${hatURL}/api/v1/`;

export const isProduction = apiURL === apiProdURL;
