import styled from "styled-components";

interface BackgroundProps {
  $active: boolean;
}

export const Wrapper = styled.div<BackgroundProps>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  padding-top: 60px;
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;
  overflow-y: auto;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Modal = styled.div<BackgroundProps>`
  padding: 19px 20px;
  display: flex;
  flex-direction: column;
  width: 832px;
  min-height: 180px;
  max-height: calc(100vh - 120px);
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.25);
  margin: auto;
  position: relative;
  z-index: 1;
}`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: calc(24px + 18px);
  position: relative;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--Neutral-400, #A3A3A3);
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const Title = styled.span`
  color: var(--Oxford-blue, #06203A);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Subtitle = styled.span`
  color: var(--Neutral-500, #737373);
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  line-height: 20px;
  max-width: 473px;
`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  position: absolute;
  right: 0;
  top: 0;

  svg .st0 {
    fill: #8E97A3;
  }

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 8px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 22px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #CED0D4;
  }
`;

/* export const Label = styled.label`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
`;
 */
/* export const Form = styled.form`
  display: flex;
  flex-direction: column;
`; */

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 12px;
  gap: 12px;
`;

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(79, 89, 102, 0.50);
`;
