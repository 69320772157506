import styled from "styled-components";

export const IconButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
}`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 16px 16px 16px;
  right: 0;
  background: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  display: none;

  // Debug:
  /* display: block;
  position: fixed;
  width: 877px;
  right: 0;
  z-index: 1001;
  margin-top: 500px; */

  @media print {
    display: block;
  }
}`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 9px;
  margin-bottom: 14px;
  border-bottom: 1px solid #A3A3A3;
`;

export const Heading = styled.span`
  color: var(--Primary-600, #116595);
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const HeaderTitle = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

export const HeaderAuthor = styled.span`
  color: var(--Neutral-600, #525252);
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;

export const Span = styled.span`
  width: 2px;
  height: 26px;
  margin: 0 23px;
  background: #1271a6;
`;

export const Logo = styled.img`
  width: 118px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
}`;

export const Journey = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 14px;
}`;

export const Title = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}`;

export const Text = styled.span`
  color: var(--Neutral-700, #404040);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const Copy = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px;
`;
