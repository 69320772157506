import { listAreas } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const ListAreas = (domainId) => async (dispatch) => {
  try {
    let domain = "";
    if (domainId) {
      domain = `?domain=${domainId}`;
    }
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await listAreas(domain);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};

export const SelectedArea = (area) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SELECTED_AREA,
    payload: area,
  });
};
export const ListAreasNull = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
