import { FC, useState } from 'react';
import { Tooltip } from 'elements';

import Popup from './Popup';

// @ts-ignore
import missionStatementFillIcon from "assets/images/journeys/mission-statement-fill.svg";

import { Button } from './styled';

interface IProps {
  /* tooltip?: boolean;
  color: string;
  goal: any; */
}

const MissionStatementsSidebar: FC<IProps> = ({  }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <Tooltip title="Mission Statements">
        <Button onClick={handleClick}>
          <img src={missionStatementFillIcon} alt="" />
        </Button>
      </Tooltip>

      <Popup
        isActive={isOpen}
        onClose={handleClick}
      />
    </>
  );
}

export default MissionStatementsSidebar;
