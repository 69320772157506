import { updateUserData } from "api";
import { getCookie, setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { IsUser } from "Redux/User/action";
import Notify from 'utils/notify';

export const UpdateUserData = (Data, id, setModalOpen) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await updateUserData(Data, id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      dispatch(IsUser(data));

      const userData = JSON.parse(getCookie("userDetail"));
      const updatedUser = JSON.stringify({ ...userData });

      setCookie("userDetail", updatedUser);
      Notify.success('Saved successfully');

      if (setModalOpen)
        setModalOpen(false);
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
