import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 60px);
  background: #EEEFF5;

  ${({ theme }) => theme.max("lg")`
    height: calc(100vh - 60px);
  }`}
`;

export const PagesWrapper = styled.div`
  flex: 1;
  height: calc(100vh - 60px);
  width: calc(100% - 66px);
  background-color: #EBF3F6;
  margin-left: ${({ $mobile }) => $mobile ? "0px" :  "66px"};
  overflow: hidden;

  ${({ theme }) => theme.max("lg")`
    height: calc(100vh - 118px);
    overflow: initial;
  }`}

  ${({ theme }) => theme.max("sm")`
    height: calc(100vh - 60px);
  }`}
`;

export const PageLayout = styled.div`
  height: calc(100% - 50px - 80px);

  ${({ theme }) => theme.max("sm")`
    height: calc(100% - 56px);
  }`}
`;