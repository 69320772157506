import styled from "styled-components";

export const Background = styled.div<{ $active: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
`;

export const Wrapper = styled.div<{ $active: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 342px;
  height: calc(100vh - 60px);
  margin-top: -87px;
  background: #fff;
  right: 0;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 12px 10px 8px;
  border-bottom: 1px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, var(--Header, #112538));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`;

export const Close = styled.button`
  display: flex;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;

  svg {
    transform: rotate(0deg);
    transition: transform .3s;
  }

  :hover {
    svg {
      transform: rotate(90deg);
    }
  }
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  height: calc(100% - 46px);
  flex-direction: column;
  margin-left: auto;
  padding: 12px 6px 16px 16px;

  ${({ theme }) => theme.max("sm")`
    height: calc(100% - 67px - 40px);
    margin-top: calc(32px + 12px);
  `};

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #CED0D4;
    }
  `}
`;

export const Head3 = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const Paragraph = styled.span`
  color: var(--Oxford-blue, #06203A);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const Leverage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
`;

export const LeverageCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: #000;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const LeverageHla = styled.span`
  margin-left: 9px;
  border-radius: 24px;
  background: var(--warning-500-main, #F90);
  padding: 0 6px;
  color: #fff !important;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  cursor: default;
`;

export const Progress = styled.div`
  margin-bottom: 20px;
`;

export const Quadrants = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
`;

export const Quadrant = styled.span<{ $color: string }>`
  width: 26px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: var(--Neutral-100, #F5F5F5);
  padding: 1px 3px;
  color: ${({ $color }) => $color};
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
`;

export const Prioritized = styled.div`
  // display: flex;
  // align-items: center;
  padding: 8px 12px;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  min-height: 74px;
`;

export const BarTitle = styled.span`
  color: var(--Generic-Black, #000);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 4px;

  span {
    font-weight: 500;
  }
`;

export const Bar = styled.div`
  position: relative;
  border-radius: 100px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10) inset;
  height: 13px;
  padding: 2px;
  margin-bottom: 4px;
`;

export const Green = styled.div`
  position: absolute;
  left: 0;
  top: 2px;
  width: 33%;
  height: calc(100% - 4px);
  background: linear-gradient(63deg, #3C8F1F 19.6%, #50D35D 75.41%);
  border-radius: 20px 0px 0px 20px;
`;

export const Blue = styled.div`
  position: absolute;
  left: 33%;
  top: 2px;
  width: 66%;
  height: calc(100% - 4px);
  background: linear-gradient(243deg, #2293E4 16.47%, #58B9FF 42.18%);
  border-radius: 0px 20px 20px 0px;
  border-left: 1px solid #fff;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
`;

export const LegendItem = styled.span<{ $color: string }>`
  display: flex;
  align-items: center;
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;

  span {
    font-weight: 400;
  }

  ::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 6px;

    ${({ $color }) => $color && `
      background: ${$color};
    `};
  }
`;

export const Calendar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  gap: 20px;
`;
