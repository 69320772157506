import styled from "styled-components";

export const Wrapper = styled.div`
  width: 207px;
  height: 96px;
  border-radius: 4px;
  border-top: 3px solid #1263DE;
  padding: 14px;  
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 20px;
`;

export const Title = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-weight: 400;
  }
`;

export const SubTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 12px; 
  line-height: 20px; 
  color: #404040;
  margin-top: 6px;
`;