import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

// @ts-ignore
import { ReactComponent as InfoIcon } from "assets/images/priorities/help-black.svg";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/journeys/close.svg";

// @ts-ignore
import leverageGreenIcon from "assets/images/priorities/leverage-green.svg";

// @ts-ignore
import leverageGrayIcon from "assets/images/priorities/leverage-gray.svg";

// @ts-ignore
import leverageGoldIcon from "assets/images/priorities/leverage-gold.svg";

// @ts-ignore
import calendarIcon from "assets/images/priorities/calendar.svg";

// @ts-ignore
import calendarSecondaryIcon from "assets/images/priorities/calendar-gray.svg";

// @ts-ignore
import calendarDangerIcon from "assets/images/priorities/calendar-red.svg";

// @ts-ignore
import { ToggleInfoSidebarAction } from "Redux/Global/action";

import RadialBar from "components/PriorityApp/SimpleView/RadialBar";

import {
  Background,

  Wrapper,
  Header,
  HeaderCol,
  Title,
  Close,
  Content,
  Head3,
  Paragraph,

  Leverage,
  LeverageCol,
  LeverageHla,

  Progress,

  Quadrants,
  Quadrant,

  Prioritized,
  BarTitle,
  Bar,
  Green,
  Blue,
  Legend,
  LegendItem,

  Calendar
  } from './styled';

const Instructions = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const { infoSidebar: isActiveState } = useSelector((state) => state.globalReducer);

  const isActiveStateRef = useRef(null);

  useEffect(() => {
    isActiveStateRef.current = isActiveState;
  }, [isActiveState]);

  const handleClose = () => {
    dispatch(ToggleInfoSidebarAction());
  };

  if (!isActiveState)
    return null;

  return (
    <>
      <Wrapper $active={isActiveState}>
        <Header>
          <HeaderCol>
            <InfoIcon />
            <Title>Instructions</Title>
          </HeaderCol>

          <Close type="button" onClick={handleClose}>
            <CloseIcon />
          </Close>
        </Header>

        <Content>
          <Head3>1. Leverage score</Head3>
          <Paragraph>Your leverage score shows how much an activity impacts other areas of your mission. Higher leverage means greater returns with fewer resources.</Paragraph>

          <Leverage>
            <LeverageCol>
              <span>0</span>
              <img src={leverageGrayIcon} width={24} height={24} alt="" />
            </LeverageCol>
            <LeverageCol>
              <span>1-5</span>
              <img src={leverageGreenIcon} width={24} height={24} alt="" />
            </LeverageCol>
            <LeverageCol>
              <span>6 or more</span>
              <img src={leverageGoldIcon} width={24} height={24} alt="" />
            </LeverageCol>
            <LeverageCol>
              <span>+ Badge</span>
              <LeverageHla>Hla</LeverageHla>
            </LeverageCol>
          </Leverage>

          <Head3>2. Goal Progress</Head3>
          <Paragraph>The goal progress gauge indicates your progress toward achieving your goal. </Paragraph>

          <Progress>
            <RadialBar value={50} />
          </Progress>

          <Head3>3. Quadrant Type</Head3>
          <Paragraph>Quadrants reflect a goal’s importance and urgency, with Q1 being most important and urgent and Q4 being least important and urgent.</Paragraph>
          <Quadrants>
            <Quadrant $color="#F00000">Q1</Quadrant>
            <Quadrant $color="#59A310">Q2</Quadrant>
            <Quadrant $color="#B15DF3">Q3</Quadrant>
            <Quadrant $color="#FF9900">Q4</Quadrant>
          </Quadrants>

          <Head3>4. Prioritized Hours Bar</Head3>
          <Paragraph>The prioritized hours bar shows how many hours your prioritized activities will take and how many free hours you have left this week. We each have 168 hours a week—schedule them wisely!</Paragraph>

          <Prioritized>
            <BarTitle>Prioritized <span>142 of 168 Hrs</span></BarTitle>

            <Bar>
              <Green />
              <Blue />
            </Bar>

            <Legend>
              <LegendItem $color="#4AC348">Completed - <span>100 hrs</span></LegendItem>
              <LegendItem $color="#54B1F4">Remaining - <span>42 hrs</span></LegendItem>
            </Legend>
          </Prioritized>

          <Head3>5. Calendar</Head3>
          <Paragraph>After you’ve prioritized an activity, click the calendar icon and schedule a specific time you’ll commit to completing it. Connect your personal or work calendar to sync your activities.</Paragraph>

          <Calendar>
            <img src={calendarIcon} width={24} height={24} alt="" />
            <img src={calendarSecondaryIcon} width={24} height={24} alt="" />
            <img src={calendarDangerIcon} width={24} height={24} alt="" />
          </Calendar>
        </Content>
      </Wrapper>

      <Background onClick={handleClose} $active={isActiveState} />
    </>
  );
};

export default Instructions;
