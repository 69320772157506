import styled from "styled-components";

interface ProgressProps {
  $color1?: string;
  $color2?: string;
  width?: string;
}

export const Wrapper = styled.div`
  background-color: white;
  min-width: 1000px;
  height: 650px;
  padding-top: 55px;
  overflow: hidden;
`;

export const ScheduleHeader = styled.div`
  width: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 20px;
  font-family: "Poppins";

  div {
    display: flex;
    align-items: center;
  }
`;

export const CenterLine = styled.div`
  width: 2px;
  height: 20px;
  background-color: #858e99;
  margin-left: 14px;
  margin-right: 14px;
`;

export const ScheduleTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #112538;
`;

export const ScheduleDate = styled.div`
  color: #262626;
  font-weight: 600;
`;

export const ScheduleBody = styled.div`
  width: 100%;
  max-height: 600px;
  height: 600px;
  overflow-y: auto;
  /* padding-top: 55px; */

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #acb4bc;
    border-radius: 100px;
  }
`;

export const ScheduleTable = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 8px 0px;
  padding-bottom: 12px;
  border-top: 1px solid #ced0d4;
  display: flex;
`;

export const ScheduleFooter = styled.div`
  font-size: 12px;
  color: #737373;
  font-size: 400;
  font-family: "Inter", sans-serif;
  text-align: center;
  padding: 8px 0px;
  border-top: 1px solid #ced0d4;
`;

export const ScheduleLoader = styled.div`
  position: relative;
  width: 100%;
  height: 96%;
  top: 6px;
  overflow: hidden;
`;

export const AnimationContainer = styled.div`
  width: 100%;
  height: 100%;
  animation: schuduleLoader 5s infinite;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  gap: 12px;
  transform: scaleX(1);
  transform-origin: right;
  opacity: 1;
  background-color: #f5f5f5;
  border-radius: 4px;

  @keyframes schuduleLoader {
    0% {
      width: 15%;
      opacity: 0;
      background-color: #f5f5f5;
    }
    10% {
      width: 15%;
      opacity: 1;
      background-color: #f5f5f5;
    }
    20% {
      width: 15%;
      opacity: 1;
      background-color: #f5f5f5;
    }
    40% {
      transform: scaleX(1);
      width: 100%;
      background-color: #f5f5f5;
    }
    41% {
      background-color: transparent;
    }
    69% {
      background-color: transparent;
    }
    70% {
      transform: scaleX(1);
      width: 100%;
      background-color: #f5f5f5;
    }
    85% {
      transform: scaleX(0.15);
      background-color: #f5f5f5;
    }
    100% {
      transform: scaleX(0);
      background-color: #f5f5f5;
    }
  }
`;

export const AnimationCard = styled.div`
  width: 15%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  animation: cardVisibility 5s infinite;
  transition: opacity 0.3s;

  @keyframes cardVisibility {
    0%,
    40%,
    80%,
    100% {
      opacity: 0;
    }
    41%,
    79% {
      opacity: 1;
    }
  }
`;

export const GenerateSchedule = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 96%;
  top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins";

  svg {
    animation: rotating 5s linear infinite;
  }

  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(360deg);
    }
    40% {
      transform: rotate(360deg);
    }
    60% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
