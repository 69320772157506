import React, { useState } from "react";
import PropTypes from "prop-types";
import Accordion from 'react-bootstrap/Accordion';

import { Wrapper, Header, Head, HeadTitle, HeadToggle, Title, Text, Body, SubTile } from './styled';
//@ts-ignore
import { ReactComponent as CollapseArrowIcon } from "assets/images/priorities/collapse-arrow.svg";
import { useAccordionButton } from "react-bootstrap";

interface CustomToggleProps {
  children: React.ReactNode;
  eventKey: string;
}

function CustomToggle({ children, eventKey }: CustomToggleProps) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <HeadToggle onClick={decoratedOnClick}>
      {children}
    </HeadToggle>
  );
}

interface GroupProps {
  title: string;
  text: string;
  icon: React.ElementType;
  separator: boolean;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  dayLeft?: number;
}

const Group: React.FC<GroupProps> = ({ title, text, icon: Icon, separator, style, children, dayLeft }) => {
  const [isIconRotated, setIsIconRotated] = useState(false);

  return (
    <Wrapper style={style} $separator={separator} data-testid="show-class">
      <Header>
        <Head>
          <HeadTitle>
            <Icon />
            <Title>{title}</Title>
          </HeadTitle>

          <CustomToggle eventKey={title} >
            <CollapseArrowIcon onClick={() => setIsIconRotated(!isIconRotated)} style={{ transform: isIconRotated ? 'rotate(270deg)' : '' }} />
          </CustomToggle>
        </Head>
        <SubTile>
          <Text>{text}</Text>
          {dayLeft && <div style={{ width: '100px', display: 'flex', justifyContent: 'end', marginRight: '5px', whiteSpace: 'nowrap', color: dayLeft >= 0 ? "orange" : "red" }}>{`
            ${dayLeft >= 0 ? dayLeft : Math.abs(dayLeft)} ${dayLeft >= 0 ? "days left" : "days overdue"}
          `}</div>}
        </SubTile>
      </Header>

      <Accordion.Collapse as={Body} eventKey={title}>
        <>
          {children}
        </>
      </Accordion.Collapse>
    </Wrapper>
  );
}

Group.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  // @ts-ignore
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
  separator: PropTypes.bool.isRequired,
  children: PropTypes.node,
  dayLeft: PropTypes.number,
};

export default Group;
