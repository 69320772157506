import Notify from "utils/notify";
import { completeTask } from "../apm-apis";
import { ActionTypes } from "./type";

import { fetchTasks } from "../ListTasks/action";
import {
  listFocusedPrioritise,
  listCompleteFocusedPrioritise,
} from "../ListFocusedPriorities/action";
import { fetchListPrioritizedActions } from "Redux/APM/PrioritizedActions/action";

export const completeTaskAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_COMPLETETASKS });
    const { data } = await completeTask(payload);

    dispatch({
      type: ActionTypes.UPDATE_COMPLETETASKS_SUCCESS,
      payload: data,
    });

    // temp refetch the updated prioritized activities
    dispatch(fetchTasks());
    dispatch(listFocusedPrioritise());
    dispatch(listCompleteFocusedPrioritise());
    dispatch(fetchListPrioritizedActions());

    // @todo update locally the prioritized activities
  } catch (error) {
    console.error(error);

    Notify.error(error, true);
    dispatch({
      type: ActionTypes.UPDATE_COMPLETETASKS_FAILURE,
      payload: error,
    });
  }
};
