export const ActionTypes = {
    FETCH_COMPLETE_FOCUSED_PRIORITISE_REQUEST: "FETCH_COMPLETE_FOCUSED_PRIORITISE_REQUEST",
    FETCH_COMPLETE_FOCUSED_PRIORITISE_SUCCESS: "FETCH_COMPLETE_FOCUSED_PRIORITISE_SUCCESS",

    FETCH_FOCUSED_PRIORITISE_REQUEST: "FETCH_FOCUSED_PRIORITISE_REQUEST",
    FETCH_FOCUSED_PRIORITISE_SUCCESS: "FETCH_FOCUSED_PRIORITISE_SUCCESS",
    FETCH_FOCUSED_PRIORITISE_FAILURE: "FETCH_FOCUSED_PRIORITISE_FAILURE",

    REMOVE_FOCUSED_PRIORITISE_REQUEST: "REMOVE_FOCUSED_PRIORITISE_REQUEST",
    REMOVE_FOCUSED_PRIORITISE_SUCCESS: "REMOVE_FOCUSED_PRIORITISE_SUCCESS",
    REMOVE_FOCUSED_PRIORITISE_FAILURE: "REMOVE_FOCUSED_PRIORITISE_FAILURE",

    SET_WEEK_FOCUSED_PRIORITISE: "SET_WEEK_FOCUSED_PRIORITISE",
};