import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'elements';
import { Wrapper, PrevNextPlan, PageCount } from './styled';

import PaginationArrow from "assets/images/navigate_icon.svg";

import { SetSelectedGoalAction } from 'Redux/Global/action';

const Pagination = ({ i, actionPlanData }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper $desktop>
      <Tooltip title="Previous Goal" alternative>
        <PrevNextPlan
          $prev
          $hide={!i > 0}
          onClick={() => {
            dispatch(
              SetSelectedGoalAction({
                ...actionPlanData[i - 1],
                i: i - 1,
              })
            );
          }}
        >
          <img src={PaginationArrow} alt="" />
        </PrevNextPlan>
      </Tooltip>

      <PageCount>
        Goal {i + 1} of {actionPlanData?.length}
      </PageCount>

      <Tooltip title="Next Goal" alternative>
        <PrevNextPlan
          $next
          $hide={i === actionPlanData?.length - 1}
          onClick={() => {
            dispatch(
              SetSelectedGoalAction({
                ...actionPlanData[i + 1],
                i: i + 1,
              })
            );
          }}
        >
          <img src={PaginationArrow} alt="" />
        </PrevNextPlan>
      </Tooltip>
    </Wrapper>
  );
}

export default Pagination;
