import { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap";

import {
  Wrapper,
  Title,
  List,
  Area,
  AreaHead,
  AreaHeadGroup,
  AreaIcon,
  AreaTitle,
  AreaToggle,
  AreaBody,
  AreaBodyInner,
} from "./styled";

import collapseIcon from "assets/images/priorities/collapse-arrow.svg";
import Sidebar from "../Sidebar";

const AreaCard = ({ data, activeKey, onToggle }) => {
  const { id, color, name, description, icon } = data;

  const onClickHandle = () => {
    onToggle(id);
  };

  return (
    <Area $active={activeKey === id} $color={color}>
      <AreaHead $active={activeKey === id} onClick={onClickHandle}>
        <AreaHeadGroup>
          <AreaIcon src={icon} alt="" />
          <AreaTitle>{name}</AreaTitle>
        </AreaHeadGroup>

        <AreaToggle type="button">
          <img src={collapseIcon} alt="" />
        </AreaToggle>
      </AreaHead>

      <Accordion.Collapse as={AreaBody} eventKey={id}>
        <AreaBodyInner>{description}</AreaBodyInner>
      </Accordion.Collapse>
    </Area>
  );
};

AreaCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  activeKey: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const AreasDescription = ({ areas }) => {
  const [activeItem, setActiveItem] = useState("");

  useLayoutEffect(() => {
    if (areas.length) setActiveItem(areas[0].id);
  }, [areas]);

  const onToggleHandle = (key) => {
    setActiveItem((prev) => (prev === key ? "" : key));
  };

  return (
    <Accordion as={List} activeKey={activeItem}>
      {areas.map((area) => (
        <AreaCard
          data={area}
          key={area.id}
          activeKey={activeItem}
          onToggle={onToggleHandle}
        />
      ))}
    </Accordion>
  );
};

AreasDescription.propTypes = {
  areas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      order: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AreasDescription;
