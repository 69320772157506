// @ts-ignore
import leverageScreen from "assets/images/journeys/leverage/leverage-bar-2.png";

// @ts-ignore
import measurementScreen from "assets/images/journeys/leverage/measurement-screen.png";

// @ts-ignore
import unitsScreen from "assets/images/journeys/leverage/units-screen.png";

// @ts-ignore
import gapScreen from "assets/images/journeys/leverage/gap-screen.png";

const step4 = {
  title: "",
  image: "",
  body: (
    <>
      <h3>State your goals</h3>
      <p>Write a goal statement for each area you’ve selected and commit to making it a reality.</p>

      <h3>Add Leverage Score</h3>
      <p>Your leverage score shows how much an activity impacts other areas of your mission. Higher leverage means greater returns with fewer resources.</p>

      <img src={leverageScreen} width={305} style={{ marginBottom: 10 }} alt="" />

      <h3>Measure your goals</h3>
      <p>The best goals are <b>specific, measurable, attainable, relevant,</b> and <b>time-bound.</b></p>

      <img src={measurementScreen} alt="" />

      <p>Select a metric to measure your progress. Try using an quantitative metric if possible.</p>

      <ul>
        <li>Number of hours you spend exercising each week</li>
        <li>Getting a promotion within a certain timeframe</li>
        <li>Yearly or monthly revenue targets</li>
      </ul>

      <p><b>Start</b> is your starting measurement when you create a goal.</p>
      <p><b>Target</b> is the goal you want to reach.</p>
      <p><b>Current</b> is your current progress. Update this regularly to measure your GAP.</p>

      <p style={{ fontWeight: 500 }}>Use ratings for qualitative measurements</p>
      <p>When measuring goals that can’t be easily quantified you can create a custom metric using a ten-point scale (1-10), to score your progress.</p>

      <img src={unitsScreen} width={155} alt="" />

      <h3>Mind your GAP</h3>
      <p>Performing a GAP analysis regularly will help you determine how to close the distance between your current and target progress.</p>

      <p><b>GAP</b> is the difference between your <b>Target</b> and <b>Current</b> progress.</p>

      <img src={gapScreen} width={166} alt="" />

      <p><b>GAP %</b> is the same measurement expressed as a percentage.</p>
      <p>Check in every week, month, or quarter as needed to assess how far you need to go to reach your goals.</p>
    </>
  )
}

export { step4 };
