import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
}`;

export const ProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1.5px solid var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.15), 0px 1px 2px -1px rgba(16, 24, 40, 0.15);
}`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
}`;

export const Name = styled.span`
  color: var(--Neutral-700, #404040);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
}`;

export const Email = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
}`;
