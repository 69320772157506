import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const AppsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(144px * 2);
`;

export const AppItem = styled(RouterLink)<{ $active: boolean }>`
  width: 144px;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  ${({ $active }) => $active && css`
    background: #0E2338;
    pointer-events: none;
  `}

  svg {
    width: 50px;
    height: 50px;
  }

  &:hover {
    background: #0E2338;
  }
`;

export const AppLink = styled.a`
  width: 144px;
  height: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  svg {
    width: 50px;
  }

  &:hover {
    background: #0E2338;
  }
`;

export const AppItemTitle = styled.span`
  margin-top: 12px;
  font-family: Inter;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  text-align: center;
`;

export const Toggle = styled.button<{ $rotate?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  background: transparent;
  border: none;
  padding: 0;

  &:hover,
  &.ant-popover-open {
    background: #0E2338;
  }

  ${({ $rotate }) => $rotate && css`
    transform: rotate(${$rotate});
  `}

  ${({ theme }) => theme.max('md')`
    padding-right: 5px;
  `}

  ${({ theme }) => theme.max('lg')`
    width: 49px;
    height: 49px;

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  `}
`;

export const Box = styled.div`
  background: #04192E;
  border-top: 2px solid #71AACA;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 16px 32px;
  border-top: 1px solid #71AACA;
  text-align: center;
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Link = styled.a`
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  text-decoration-line: underline;
`;

export const IconLink = styled.a`
  text-decoration: none;
  width: 24px;
  height: 24px;
`;
