import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 32px 12px 20px;

  ${({ theme }) => theme.max('lg')`
    padding: 12px 0;
    margin: 0 16px;
  `}
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;

  ${({ theme }) => theme.max('md')`
    // height: calc(100vh - 80px); // 56 + 12 * 2
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  border-bottom: 2px solid #fff;
`;

export const ImagePlaceholder = styled.img`
  background-color: #4C5F7C;
  height: 112px;
  width: 100%;
  object-fit: cover;
`;

export const Title = styled.span`
  color: #fff;
  font-size: 20px;
  top: 13px;
  left: 20px;
  position: absolute;
  font-weight: 700;
  line-height: 20px;
`;

export const Bar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1271A6;
  height: 64px;
  padding: 10px 0 4px 172px;

  ${({ theme }) => theme.max('md')`
    padding: 10px 0 4px 152px;
  `}
`;

export const User = styled.span`
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 3px;
`;

export const Plan = styled.span`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

export const ProfilePlanIcon = styled.img`
  margin-right: 8px;
`;

export const Nav = styled.div`
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid var(--neutral-300, #CDCDCD);

  ${({ theme }) => theme.max('md')`
    overflow-x: auto;
  `}
`;

export const NavItem = styled(Link)`
  display: flex;
  padding: 12px 24px;
  color: var(--neutral-500, #737373);
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 14px;
  line-height: 20px;
  border-bottom: 2px solid transparent;
  white-space: nowrap;

  ${({ $active }) => $active && css`
    border-bottom-color: #1271A6;
    color: var(--primary-500-main, #1271A6);
    font-weight: 500;
  `}

  :hover {
    text-decoration: none;
    color: var(--neutral-500, #737373);
    border-bottom-color: #737373;
  }
`;