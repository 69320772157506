import { FC } from "react";
import { DatePicker as DatePickerAntd } from "antd";
import styled from "styled-components";

//@ts-ignore
import { ReactComponent as CalenderIcon } from "assets/images/priorities/calendar-icon.svg";
import { DatePickerProps as AntdDatePickerProps } from "antd/lib/date-picker";

type IProps = AntdDatePickerProps & {
  id?: string;
  customSize?: "xs" | "sm";
};

const DatePicker: FC<IProps> = ({ customSize = 'sm', ...props }) => {
  return (
    <StyledDatePicker
      style={{
        padding: customSize === 'xs' ? '4px 6px' : "7px 12px",
        borderRadius: "4px",
        border: "1px solid",
        borderColor: props?.status === "error" ? "#f00" : "#E5E5E5",
        marginRight:"-6px"
      }}
      suffixIcon={<CalenderIcon />}
      {...props}
    />
  );
};

export default DatePicker;

export const StyledDatePicker = styled(DatePickerAntd)`
  .ant-picker-clear {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
