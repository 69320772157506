import { Wrapper, Content, Slider, Slide, Img } from './styled';

import habitTracker from "assets/images/auth/habit-manager.png";
import projectPlanner from 'assets/images/auth/project-manager.png';
import decisionManager from 'assets/images/auth/decision-manager.png';
import goalSettingsWorkbook from 'assets/images/auth/goal-manager.png';
import priorityManager from 'assets/images/auth/time-manager.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slides = [
  
  {
    id: '1',
    src: habitTracker
  },
  {
    id: '2',
    src: projectPlanner
  },
  {
    id: '3',
    src: decisionManager
  },
  {
    id: '4',
    src: goalSettingsWorkbook
  },
  {
    id: '5',
    src: priorityManager
  },
];

const areasSlider = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  autoplay: true,
  autoplaySpeed: 3000
};

const Gallery = ({ animate }) => {
  return (
    <Wrapper>
      <Content $animate={animate} $animateDelay={650}>
        <Slider {...areasSlider}>
          {slides.map(slide => (
            <Slide key={slide.id}>
              <Img src={slide.src} alt="" />
            </Slide>
          ))}
        </Slider>
      </Content>
    </Wrapper>
  );
}

export default Gallery;
