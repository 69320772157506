import styled from 'styled-components';

export const Wrapper = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  border-left: 3px solid #ecf3f6;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);

  ${({ $color }) => $color && `
    border-left-color: ${$color};
  `};
`;

export const Head = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10.5px 4px 10.5px 8px;
  cursor: pointer;
`;

export const HeadGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ecf3f6;
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, #112538);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
`;

export const Toggle = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  width: 100%;
`;

export const BodyInner = styled.div`
  gap: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 10px 10px 7px;
  border-top: 1px solid #8E97A37F;
`;

export const Content = styled.div`
  color: var(--fill-button-prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
