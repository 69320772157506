// @ts-ignore
import { listGoals } from "../apm-apis";

import { ActionTypes } from "./type";

export const fetchGoals = (payload: string) => async (dispatch: any, getState: any) => {
  const { client } = getState().prioritizedActions;

  const filtersParams = {};

  if (client) {
    // @ts-ignore
    filtersParams.client = client;
  }

  try {
    if (!payload) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: null
      });

      return;
    }
    
    if (payload) {
      // @ts-ignore
      filtersParams.category_name = payload;
    }

    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await listGoals(filtersParams);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });
  }
};
