import { ActionTypes } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  data: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GET_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };

    case ActionTypes.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.ADD_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: [
          ...state.data,
          action.payload
        ],
      };

    case ActionTypes.ADD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data.map(metric => metric.id === action.payload.id ? { ...action.payload } : metric)
      };

    case ActionTypes.UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.DELETE_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: [...state.data.filter(item => item.id !== action.payload)]
      };

    case ActionTypes.DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.ADD_UNIT_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.ADD_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data.map(metric => metric.id === action.payload.id ? ({ ...metric, units: [ ...action.payload.units ]}) : metric)
      };

    case ActionTypes.ADD_UNIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.UPDATE_UNIT_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.UPDATE_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data.map(metric => metric.id === action.payload.metric ? ({ ...metric, units: metric.units.map(unit => unit.id === action.payload.id ? { ...action.payload } : unit) }) : metric)
      };

    case ActionTypes.UPDATE_UNIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.DELETE_UNIT_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.DELETE_UNIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: state.data.map(metric => ({ ...metric, units: metric.units.filter(unit => unit.id !== action.payload)}))
      };

    case ActionTypes.DELETE_UNIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.UPDATE_ALL_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.UPDATE_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };

    case ActionTypes.UPDATE_ALL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
