import { ActionTypes } from "./types";
import { ActionTypes as UpdateGoalActionTypes } from '../UpdateGoal/types';

let INITIAL_STATE = {
  selectedGoalAction: false,
  toggledSidebar: 'left',
  drawerMenu: false,
  infoSidebar: false,
  isDisabled: false,
  SelectedClient: null,
  isEdit: false,
  isEditId: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SELECTED_GOAL_ACTION:
      return {
        ...state,
        selectedGoalAction: action.payload,
      };

    case ActionTypes.SELECTED_GOAL_ACTION_ADD_ACTION:
      return {
        ...state,
        selectedGoalAction: {
          ...state.selectedGoalAction,
          goal: {
            ...state.selectedGoalAction.goal,
            actions: [
              ...state.selectedGoalAction.goal.actions,
              action.payload
            ]
          }
        }
      };

    case ActionTypes.SELECTED_GOAL_ACTION_REMOVE_ACTION: {
      if (!state.selectedGoalAction.goal)
        return state;

      try {
        return {
          ...state,
          selectedGoalAction: {
            ...state.selectedGoalAction,
            goal: {
              ...state.selectedGoalAction.goal,
              actions: state.selectedGoalAction.goal.actions.filter(item => item.id !== action.payload)
            }
          }
        };
      } catch (error) {
        console.error('Error removing action from goal', error);
        return state;
      }
    }

    case UpdateGoalActionTypes.UPDATE_GOAL_SUCCESS:
      return {
        ...state,
        selectedGoalAction: {
          ...state.selectedGoalAction,
          goal: {
            ...state.selectedGoalAction?.goal,
            ...action.payload
          }
        }
      };    

    case ActionTypes.TOGGLE_SIDEBAR_ACTION:
      return {
        ...state,
        toggledSidebar: action.payload,
      };

    case ActionTypes.DRAWER_MENU_ACTION:
      return {
        ...state,
        drawerMenu: action.payload,
      };

    case ActionTypes.TOGGLE_INFO_SIDEBAR_ACTION:
      return {
        ...state,
        infoSidebar: typeof action.payload === 'boolean' ? action.payload : !state.infoSidebar
      };
    case ActionTypes.IS_DISABLED:
      return {
        ...state,
        isDisabled: action.payload,
      };
    case ActionTypes.SELECTED_CLIENT:
      return {
        ...state,
        SelectedClient: action.payload,
      };
    case ActionTypes.IS_EDITING:
      return {
        ...state,
        isEdit: action.payload
      };
    case ActionTypes.IS_EDITING_ID:
      return {
        ...state,
        isEditId: action.payload
      };
    default:
      return state;
  }
};
