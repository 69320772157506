import React, { useLayoutEffect } from 'react';
import { ThemeProvider } from "styled-components";

import { useDispatch, useSelector } from 'react-redux';

import Background from '../../assets/images/profile/background.jpg';

import * as Styled from './styled';
import theme from '../../theme';
import Loader from "components/Loader";

import AccountInformation from '../../components/UserProfile/AccountInformation';
import ProfilePicture from '../../components/UserProfile/ProfilePicture';
import { RetrieveUserData } from 'Redux/RetrieveUserData/action';

const Profile = () => {
  const dispatch = useDispatch();
  const { userDetail: userData } = useSelector((state) => state.userDetailReducer);

  useLayoutEffect(() => {
    dispatch(RetrieveUserData());
  }, []);

  const { first_name, last_name } = userData;

  return (
    <ThemeProvider theme={theme}>
      {!userData ? (
        <Loader />
      ) : (
        <Styled.Wrapper>
          <Styled.Container>
            <Styled.Header>
              <Styled.ImageWrapper>
                <Styled.ImagePlaceholder src={Background} alt="" />
                <Styled.Title>Profile</Styled.Title>
              </Styled.ImageWrapper>

              <ProfilePicture data={userData} />

              <Styled.Bar>
                <Styled.User>{first_name} {last_name}</Styled.User>
              </Styled.Bar>
            </Styled.Header>

            <AccountInformation userData={userData} />
          </Styled.Container>
        </Styled.Wrapper>
      )}
    </ThemeProvider>
  );
};

export default Profile;
