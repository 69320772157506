import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";

import Popup from "../Popup/index.jsx";
import { Button } from "elements/index.tsx";
import MetricGroup from "../UnitGroup/index.jsx";

import { ReactComponent as PlusIcon } from "assets/images/journeys/plus.svg";

import * as Styled from '../styled.js';
import { FooterContent, Row } from './styled';

import { BulkManageMetrics } from "Redux/CustomMetrics/action.js";

const normalizeMetrics = (metrics) => {
  const normalizedData = {
    metrics: {},
    units: {},
  };

  metrics.forEach(metric => {
    // Extract metric data and unit IDs
    const { id: metricId, label, units, user } = metric;

    const unitIds = units.map(unit => {
      // Populate units object
      normalizedData.units[unit.id] = { ...unit };
      return unit.id;
    });

    // Populate metrics object
    normalizedData.metrics[metricId] = {
      id: metricId,
      units: unitIds,
      label,
      user,
    };
  });

  return normalizedData;
};

const CustomizePopup = ({ active, onClose }) => {
  const dispatch = useDispatch();

  const [activeItem, setActiveItem] = useState(null);
  const [normalizedData, setNormalizedData] = useState({
    metrics: {},
    units: {},
  });

  const { data: customMetrics, loading } = useSelector(
    (state) => state.customMetricsReducer
  );

  useEffect(() => {
    if (active) {
      const normalizedState = normalizeMetrics(customMetrics);
      setNormalizedData(normalizedState);
    }
  }, [active, customMetrics]);

  const onToggleHandle = key => {
    setActiveItem(prev => prev === key ? null : key);
  }

  const handleClose = () => {
    setActiveItem(null);
    setNormalizedData({
      metrics: {},
      units: {},
    });
    onClose(false);
  }

  const handleAddMetric = () => {
    const metricId = Object.keys(normalizedData.metrics).length + 1;

    setNormalizedData(prev => ({
      ...prev,
      metrics: {
        ...prev.metrics,
        [metricId]: {
          id: metricId,
          label: `New metric ${metricId}`,
          units: [],
          isAdded: true
        }
      }
    }));

    setTimeout(() => {
      setActiveItem(metricId);
    }, 150);
  }

  const handleUpdateUnit = (name, id) => {
    setNormalizedData(prev => ({
      ...prev,
      units: {
        ...prev.units,
        [id]: {
          ...prev.units[id],
          name,
          isUpdated: !prev.units[id].isAdded
        }
      }
    }));
  }

  const handleUnitDelete = id => {
    setNormalizedData(prev => ({
      ...prev,
      units: {
        ...prev.units,
        [id]: {
          ...prev.units[id],
          isDeleted: true
        }
      }
    }));
  }

  const handleMetricUpdate = (label, id) => {
    setNormalizedData(prev => ({
      ...prev,
      metrics: {
        ...prev.metrics,
        [id]: {
          ...prev.metrics[id],
          label,
          isUpdated: !prev.metrics[id].isAdded
        }
      }
    }));
  }

  const handleMetricDelete = id => {
    setNormalizedData(prev => {
      const { [id]: metricToDelete, ...remainingMetrics } = prev.metrics;
      
      // If metric has `isAdded` as true, remove it; otherwise, set `isDeleted` to true
      if (metricToDelete.isAdded) {
        return {
          ...prev,
          metrics: remainingMetrics
        };
      } else {
        return {
          ...prev,
          metrics: {
            ...remainingMetrics,
            [id]: {
              ...metricToDelete,
              isDeleted: true
            }
          }
        };
      }
    });
  }

  const handleAddUnit = (name, id) => {
    setNormalizedData(prev => {
      const unitId = Object.keys(prev.units).length + 1;

      return {
        ...prev,
        units: {
          ...prev.units,
          [unitId]: {
            id: unitId,
            name,
            metric: id,
            isAdded: true
          }
        },
        metrics: {
          ...prev.metrics,
          [id]: {
            ...prev.metrics[id],
            units: [...prev.metrics[id].units, unitId]
          }
        }
      }
    });
  }

  const handleCancel = () => {
    handleClose();
  }

  const handleSave = () => {
    const metrics = Object.values(normalizedData.metrics);
    const units = Object.values(normalizedData.units);

    // metrics to add
    const metricsToAdd = metrics.filter(metric => metric.isAdded);

    // metrics to update
    const metricsToUpdate = metrics.filter(metric => metric.isUpdated);

    // metrics to delete
    const metricsToDelete = metrics.filter(metric => metric.isDeleted);

    // units to add
    const unitsToAdd = units.filter(unit => {
      const parentMetric = normalizedData.metrics[unit.metric];
      return unit.isAdded && parentMetric && !parentMetric.isAdded;
    });

    // units to update
    const unitsToUpdate = units.filter(unit => unit.isUpdated);

    // units to delete
    const unitsToDelete = units.filter(unit => unit.isDeleted);

    // construct payload for API
    const payload = {
      create: [
        ...metricsToAdd.map(metric => ({
          obj_type: "CustomMetric",
          label: metric.label,
          units: metric.units.map(unitId => ({
            name: normalizedData.units[unitId].name
          }))
        })),
        ...unitsToAdd.map(unit => ({
          obj_type: "CustomUnit",
          name: unit.name,
          metric_id: unit.metric
        }))
      ],
      update: [
        ...metricsToUpdate.map(metric => ({
          obj_type: "CustomMetric",
          id: metric.id,
          label: metric.label
        })),
        ...unitsToUpdate.map(unit => ({
          obj_type: "CustomUnit",
          id: unit.id,
          name: unit.name
        }))
      ],
      delete: [
        ...metricsToDelete.map(metric => ({
          obj_type: "CustomMetric",
          id: metric.id
        })),
        ...unitsToDelete.map(unit => ({
          obj_type: "CustomUnit",
          id: unit.id
        }))
      ]
    }

    dispatch(BulkManageMetrics(payload, handleClose));
  }

  const metricsData = Object.values(normalizedData.metrics)
    .filter(metric => !metric.isDeleted)
    .sort((a, b) => {
      // Both metrics have `isAdded`
      if (a.isAdded && b.isAdded) {
        return a.id - b.id; // Sort by id
      }

      // Only `a` has `isAdded`, move `a` after `b`
      if (a.isAdded) return 1;

      // Only `b` has `isAdded`, move `b` after `a`
      if (b.isAdded) return -1;

      // Neither metric has `isAdded`, keep original order
      return 0;
    });

  const isEmpty = metricsData.filter(metric => !metric.label).length > 0;

  const contentFooter = () => (
    <FooterContent>
      <Button onClick={handleCancel} type="button" styleType="link-primary">
        Cancel
      </Button>
      <Button onClick={handleSave} type="button" disabled={loading || isEmpty}>
        {loading ? 'Saving...' : 'Save'}
      </Button>
    </FooterContent>
  );

  return (
    <Popup active={active} title="Manage Custom Metric" footer={contentFooter} onClose={handleClose}>
      {<Accordion as={Styled.Content} activeKey={activeItem}>
        {metricsData.map(metric => <MetricGroup
          activeKey={activeItem}
          data={{
            ...metric,
            units: metric.units.map(unitId => normalizedData.units[unitId])
          }}
          onToggle={onToggleHandle}
          onUnitAdd={handleAddUnit}
          onUnitUpdate={handleUpdateUnit}
          onUnitDelete={handleUnitDelete}
          onMetricUpdate={handleMetricUpdate}
          onMetricDelete={handleMetricDelete}
          key={metric.id}
        />)}
      </Accordion>}

      <Row>
        <Button onClick={handleAddMetric} type="button" size="medium" styleType="link-primary">
          <PlusIcon />
          Add metric
        </Button>
      </Row>
    </Popup>
  );
};

export default CustomizePopup;
