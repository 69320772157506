import styled from "styled-components";

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  margin-top: 2px;

  span {
    position: absolute;
    display: block;
    top: 0;
    margin-top: 14px;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    color: #1B2A3D;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }
}`;

interface WrapperProps {
  width: number;
  height: number;
}
