import { Popover } from "antd";
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from "assets/images/info.svg";
import { ReactComponent as InfoGrayIcon } from "assets/images/info-gray.svg";

import styles from './styles.module.scss';

export const Wrapper = styled.div`
  display: flex;
  gap: 13px;
  width: 305px;
  padding: 7px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.25);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

export const Text = styled.span`
  color: var(--fill-frame-prussian-blue, #1B2A3D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  line-height: 18px;
`;

const content = (
  <Wrapper>
    <InfoGrayIcon />

    <Content>
      <Text>Set the type of action:<br /><br /> <b>Action — </b>something you do once.</Text>
      <Text><b>Decision — </b>a choice you have to make.</Text>
      <Text><b>Habit — </b>an action you do repeatedly.</Text>
    </Content>
  </Wrapper>
);

const TypeTooltip = () => {
  return (
    <Popover
      placement="topRight"
      align={{ offset: [20, 0] }}
      overlayClassName={styles.popover}
      content={content}
      /* open={true} */
      overlayInnerStyle={{ boxShadow: 'none', backgroundColor: 'transparent' }}
      /* arrow={mergedArrow} */
    >
      <InfoIcon />
    </Popover>
  );
}

export default TypeTooltip;
