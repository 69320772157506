import React from "react";
import { EmptyBox, HiddenBox, EmptyText } from "../styled";
// @ts-ignore
import { ReactComponent as TargetButton } from "assets/images/priorities/tagetIcon.svg";

interface EmptyCartProps {
  numberOfSkeletons: number;
}

export const EmptyCard: React.FC<EmptyCartProps> = ({ numberOfSkeletons }) => {
  return (
    <>
      <HiddenBox>
        {[...Array(numberOfSkeletons)].map((_, index) => (
          <div data-testid="empty-box" key={index}>
            <React.Fragment key={index}>
              <EmptyBox $emptyBox={index === 3} key={`box_${index}`}>
                {index === 3 && (
                  <EmptyText>
                    <TargetButton />
                    Add your top priorities
                  </EmptyText>
                )}
              </EmptyBox>
            </React.Fragment>
          </div>
        ))}
      </HiddenBox>
    </>
  );
};
