import React from "react";

import MasterPlan from "components/Journeys/MasterPlan";
import ScreenLayout from "components/Journeys/SceneLayout";

const MasterPlanScreen = () => {
  return (
    <ScreenLayout>
      <MasterPlan />
    </ScreenLayout>
  );
};

export default MasterPlanScreen;
