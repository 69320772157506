import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Popover } from 'antd';

import LeveragePopover from "../../LeveragePopover";

//@ts-ignore
import { ReactComponent as Pencil } from "assets/images/priorities/pencil.svg";

import { Wrapper, AreaBox, /* AreaHeader, RightBar, DirectImpactStyle, IndirectImpactStyle,  */LeverageButton/* , List  */} from './styled';

//@ts-ignore  
import { availableAreas } from 'Redux/APM/AvailableAreas/action';

//@ts-ignore  
import ImpactComponent from '../../LeveragePopover/ImpactBar';
import ImpactIndicator from '../../LeveragePopover/ImpactIndicator';

//@ts-ignore  
import { fetchListPrioritizedActions } from 'Redux/APM/PrioritizedActions/action';

//@ts-ignore
import { updateIndirectScore } from 'Redux/APM/UpdateIndirectScores/action';

interface Props {
  id: string;
  selectedItem: any;
}

interface Area {
  name?: string;
  color?: string;
  id?: string;
}

interface ImpactArea {
  user_area?: string;
  score?: string;
}

export interface AreaData {
  area: Area;
  impactArea: ImpactArea;
}

interface updateIndirectScores{
  loading:boolean
}

interface RootupdateIndirectScoresState{
  updateIndirectScores:updateIndirectScores
}

interface AvailableArea {
  areas: Area[];
  indirect_impact_area_scores: ImpactArea[];
}

interface AvailableAreaState {
  availablearea: AvailableArea;
}

interface RootAvailableAreaStateState {
  availableAreas: AvailableAreaState;
}

const Impact: React.FC<Props> = ({ id, selectedItem }) => {
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [areaData, setAreaData] = useState<AreaData[]>([]); 

  const { availablearea } = useSelector((state:RootAvailableAreaStateState) => state.availableAreas);
  // const { loading } = useSelector((state:RootupdateIndirectScoresState) => state.updateIndirectScores);

  const { direct_impact, indirect_impact } = selectedItem || {};
  
  const dispatch = useDispatch();

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);

    if (id) {
      dispatch(availableAreas(id));
    };
  };

  const handleClose = async (values?: AreaData[]) => {
    setOpen(false);

    const ad = Array.isArray(values) && values.length > 0 ? values : areaData;
    const impactAreaArray = ad
      .filter((item: AreaData) => item.impactArea.user_area && (item.impactArea.score !== '0.0' && item.impactArea.score !== '-'))
      .map((item: AreaData) => item.impactArea);

    const payload = {
      "updated_scores": {
        "prioritized_activity": id,
        "scores": impactAreaArray.map((item: ImpactArea) => ({ user_area: item.user_area, score: item.score ? item.score : 0}))
      }
    };

    await dispatch(updateIndirectScore(payload));
    await dispatch(fetchListPrioritizedActions());

    setAreaData([]);
  };

  const directImpactColor: { color: string; }[] = (availablearea?.areas?.map((ele: Area) => {
    return { color: ele?.color ?? 'defaultColor' };
  }) ?? []) as { color: string; }[];

  /* const inDirectImpact = availablearea?.indirect_impact_area_scores?.reduce((sum: number, element: ImpactArea) => {
    const weightAsNumber = element?.score ? parseInt(element.score) : 0; 
    if (!isNaN(weightAsNumber)) {
      sum += weightAsNumber;
    }
    return sum;
  }, 0); */

  /* const handleMouseEnter = (componentName: string) => {
    setHoveredComponent(componentName);
  };

  const handleMouseLeave = () => {
    setHoveredComponent('');
  }; */

  const PopoverContent = () => {
    return (
      <LeveragePopover
        selectedItem={selectedItem}
        id={id}
        setOutsideAreaData={setAreaData}
        // @ts-ignore
        selectedItem={selectedItem}
        id={id}
        onClose={handleClose}
        setOutsideAreaData={setAreaData}
      />
    );
  }

  return (
    <Wrapper>
      <AreaBox>
        <ImpactComponent
          color={directImpactColor}
          direct_impact={5}
          indirect_impact={parseInt(indirect_impact) === 0 ? 5 : indirect_impact}
        />

        <ImpactIndicator
          id={id}
          selectedItem={selectedItem}
          // onLeverageClose={() => onLeverageClose()}
          onLeverageClose={() => {}}
          color={directImpactColor}
          indirect_impacts_value={indirect_impact && parseFloat(indirect_impact)}
          direct_impact_value={direct_impact && parseFloat(direct_impact)}
          indirect_impacts={parseFloat(direct_impact) === 0 ? 5 : direct_impact}
          direct_impact={5}
          indirect_impacts_editable={
            <Popover
              // @ts-ignore
              getPopupContainer={(trigger) => trigger.parentNode}
              align={{ offset: [0, 0] }}
              content={PopoverContent}
              title={null}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              placement="bottom"
              overlayClassName="custom_popover"
              autoAdjustOverflow={false}
              overlayStyle={{ zIndex: 9999 }}
            >
              <LeverageButton>
                <Pencil onClick={() => setRefetch(!refetch)} />
              </LeverageButton>
            </Popover>
          }
        />
      </AreaBox>
    </Wrapper>
  );
}

export default Impact;
