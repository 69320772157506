import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 76px 15px 68px;
`;

export const Image = styled.img`
  margin-bottom: 30px;
`;

export const Heading = styled.h2`
  margin: 0 0 8px 0;
  padding: 0;
  color: #06203a;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const Text = styled.p`
  margin: 0 0 32px 0;
  padding: 0;
  color: #06203a;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  line-height: 24px;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;
