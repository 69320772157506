import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ $secondary }) => $secondary && css`
    flex-direction: column-reverse;
    align-items: initial;
    margin-top: 12px;
  `}
`;

export const Label = styled.div`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 10px;
  line-height: 12px;
  margin-right: 12px;
  margin-top: 6px;

  ${({ $secondary }) => $secondary && css`
    font-size: 11px;
    line-height: 12px;
    color: #737373;

    span {
      font-weight: 600;
      color: ${({ color }) => color};
    }
  `}
`;

export const Progress = styled.div`
  width: 130px;
  height: 6px;
  background: #EDEDED;
  border-radius: 6px;
  position: relative;

  ${({ $secondary }) => $secondary && css`
    width: 100%;
  `}

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: inherit;
    width: ${({ width }) => width};
    background-color: ${({ fill }) => fill};
  }
`;
