import { Wrapper, Head, SubTitle, Title } from "./styled";

const PriortizedPopover = ({ TargetPopover }: { TargetPopover?: boolean }) => {
  return (
    <Wrapper targetPopover={TargetPopover}>
      <Head>
        <Title>Prioritized time</Title>
      </Head>
      <SubTitle>Total time you’ve allocated to focused priorities.</SubTitle>
    </Wrapper>
  );
};

export default PriortizedPopover;
