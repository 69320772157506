import React, { FC } from 'react';
import { Popover } from 'antd';

// @ts-ignore
import { Tooltip } from 'elements';

import CalendarPopover from "../CalendarPopover";

// @ts-ignore
import calendarIcon from "assets/images/priorities/calendar.svg";

// @ts-ignore
import calendarSecondaryIcon from "assets/images/priorities/calendar-gray.svg";

// @ts-ignore
import calendarDangerIcon from "assets/images/priorities/calendar-red.svg";

import {
  Icon
} from "./styled";
import moment from 'moment';

interface IGoal {
  startline: string | null;
  deadline: string | null;
}

interface IProps {
  isTask: boolean;
  daysLeft: number;
  data: any;
  goal: IGoal;
}

const CalendarWidget: FC<IProps> = ({ goal, data, isTask, daysLeft }) => {
  const { id, deadline, startline } = data;

  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const PopoverContent = () => {
    return (
      <CalendarPopover
        id={id}
        isTask={isTask}
        startline={startline}
        deadline={deadline}
        minDate={goal?.startline}
        maxDate={goal?.deadline}
        onClose={handleClose}
        daysLeft={daysLeft}
      />
    );
  }

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [108, 0] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="custom_popover calendar_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Tooltip title="Calendar">
        <Icon
          src={deadline ? (daysLeft <= 1 ? calendarDangerIcon : calendarSecondaryIcon) : calendarIcon}
          alt=""
          width={24}
          height={24}
        />
      </Tooltip>
    </Popover>
  );
};

export default CalendarWidget;
