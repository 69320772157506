import { useState, useEffect } from 'react';
import throttle from 'lodash/fp/throttle';

const SCROLL_THRESHOLD = 5;  // Choose a suitable threshold value

export default ({
  activeSectionDefault = 0,
  offsetPx = 0,
  sectionElementRefs = [],
  scrollingElement = null,
  throttleMs = 100,
} ) => {
  const [activeSection, setActiveSection] = useState(activeSectionDefault);

  const handle = throttle(throttleMs, () => {
    let currentSectionId = activeSection;

    const scrollEl = (scrollingElement && scrollingElement.current) || window;
    const maxScroll = scrollEl.scrollHeight - scrollEl.clientHeight;

    // If the scrolling element is scrolled all the way down, set the active section to the last section
    if (maxScroll - scrollEl.scrollTop <= SCROLL_THRESHOLD) {
      setActiveSection(sectionElementRefs.length - 1);
      return;
    }

    for (let i = 0; i < sectionElementRefs.length; i++) {
      const section = sectionElementRefs[i].current;

      // Needs to be a valid DOM Element
      if (!section || !(section instanceof Element)) continue;

      // GetBoundingClientRect returns values relative to viewport
      if (section.getBoundingClientRect().top - scrollEl.getBoundingClientRect().top + offsetPx < 0) {
        currentSectionId = i;
        continue;
      }

      // No need to continue loop, if last element has been detected
      break;
    }

    setActiveSection(currentSectionId);
  });

  useEffect(() => {
    ((scrollingElement && scrollingElement.current) || window).addEventListener('scroll', handle);

    // Run initially
    handle();

    return () => {
      ((scrollingElement && scrollingElement.current) || window).removeEventListener('scroll', handle);
    };
  }, [sectionElementRefs, offsetPx]);
  return activeSection;
};
