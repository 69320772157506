import { Accordion } from "react-bootstrap";

import { AreaItem } from "../AreaItem";

import {
  Wrapper,
  HeadToggle,
  HeadCollapseIcon,
  Title,
  Body,
  BodyInner,
  Content
} from "./styled";

import { ReactComponent as CollapseIcon } from "assets/images/priorities/chevron-right-alpha.svg";
import { ReactComponent as GoalIcon } from "assets/images/priorities/goal.svg";

const AreasGroup = ({ activeKey, data, onToggle }) => {
  const { id, title, areas } = data;

  const onClickHandle = () => {
    onToggle(id);
  }

  return (
    <Wrapper>
      <HeadToggle $active={activeKey === id} onClick={onClickHandle}>
        <HeadCollapseIcon $active={activeKey === id}>
          <CollapseIcon />
        </HeadCollapseIcon>
        <GoalIcon />
        <Title>{title}</Title>
      </HeadToggle>

      <Accordion.Collapse as={Body} eventKey={id}>
        <BodyInner>
          <Accordion as={Content} defaultActiveKey={areas.length ? [areas[0].id] : []}>
            {areas.map(item => <AreaItem data={item} key={item.id} />)}
          </Accordion>
        </BodyInner>
      </Accordion.Collapse>
    </Wrapper>
  );
}

export default AreasGroup;
