import styled from "styled-components";

interface BackgroundProps {
  $active: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.30);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Wrapper = styled.div<BackgroundProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-right: -6px;
  margin-top: -32px;
  padding: 8px 10px;
  background: #fff;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Head = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 10px 4px;
  border-bottom: 1px solid var(--Neutral-300, #CDCDCD);
  margin-bottom: 9px;
}`;

export const HeadCol = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
}`;

export const Title = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}`;

export const Text = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.7;
}`;

export const Label = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  border-radius: 4px;
  background: var(--Neutral-100, #F5F5F5);
  padding: 4px 4px 4px 8px;
  margin: 14px 0;
}`;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
}`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}`;

export const Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
}`;
