import React from "react";
import { Modal } from "antd";
import ReactImageZoom from 'react-image-zoom';

import ModalCloseIcon from "assets/images/modalClose.svg";
import styled from "styled-components";

import useWindowSize from "utils/useWindowSize";

const SampleViewModal = ({ active, image, onClose }) => {
  const { width } = useWindowSize();

  // const props = {width: width > 945 ? 945 : width - 36, height: 'auto', zoomWidth: width > 945 ? 945 : width - 36, img: image, zoomPosition: 'original' };

  return (
    <>
      <CustomModal
        open={active}
        footer={false}
        centered
        width={width > 1088 ? 1088 : '100%'}
        onCancel={onClose}
      >
        <Wrapper>
          <Header>
            <Close src={ModalCloseIcon} width={32} height={32} onClick={onClose} />
          </Header>

          <ImageWrapper>
            <Image src={image} width="100%" />
            {/* {active && <ReactImageZoom {...props} />} */}
          </ImageWrapper>

          <Footer />
        </Wrapper>
      </CustomModal>
    </>
  );
};

export default SampleViewModal;

const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-close-x {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-left: 4px solid #0B4464;
  border-right: 4px solid #0B4464;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 4px;
  background: #0B4464;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 42px;
  width: 100%;
`;

const Footer = styled.div`
  background: #0B4464;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  height: 60px;
  width: 100%;
`;

const Image = styled.img``;

const ImageWrapper = styled.div`
  padding: 20px 45px;
  flex: 1;
  height: 100%;

  /* img {
    flex-shrink: 0;
    height: auto !important;
  } */

  ${({ theme }) => theme.max("sm")`
    padding: 20px 0;
  `};
`;

const Close = styled.img`
  cursor: pointer;
`;
