import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Close } from "assets/images/close.svg";
import { ReactComponent as Priority } from "assets/images/Priority.svg";
import { Checkbox, Modal } from "antd";

const SettingModal = ({ isSettingModal, setIsSettingModal }) => {
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsSettingModal(false);
  };

  const Goalactionitem = [
    {
      bordercolor: "#425EE3",
      serialNo: "1",
      symbol: "D",
      title: "Director of investments",
    },
    {
      bordercolor: "#549AFF",
      serialNo: "2",
      symbol: "B",
      title: "Buy a House",
    },
    {
      bordercolor: "#549AFF",
      serialNo: "3",
      symbol: "S",
      title: "Sleep 8 hours",
    },
    {
      bordercolor: "#549AFF",
      serialNo: "4",
      symbol: "H",
      title: "Hike with daughter",
    },
    {
      bordercolor: "#BE1515",
      serialNo: "5",
      symbol: "E",
      title: "Engage in Meaningful Conversations",
    },
    {
      bordercolor: "#63D2AA",
      serialNo: "6",
      symbol: "P",
      title: "Personal Higiene Routine",
    },
    {
      bordercolor: "#009C16",
      serialNo: "7",
      symbol: "G",
      title: "My goal is to improve attendance at",
    },
    {
      bordercolor: "#03539D",
      serialNo: "8",
      symbol: "D",
      title: "Digital Marketing Course",
    },
    {
      bordercolor: "#FF8A00",
      serialNo: "9",
      symbol: "B",
      title: "Buy a Car",
    },
    {
      bordercolor: "#4489C9",
      serialNo: "10",
      symbol: "F",
      title: "Freelancers Job Portal",
    },
  ];

  const onChange = () => {
    console.log(`Checkbox clicked: `);
  };
  return (
    <Wrapper>
      {/* <button onClick={showModal}>Open</button> */}
      <Modal
        open={isSettingModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        className="setting_modal"
        footer={false}
        closable={false}
      >
        <Content>
          <Header>
            <SettingHeading>
              <Priority />
              <Heading>Choose Daily Priorities</Heading>
            </SettingHeading>
            <CloseButton onClick={handleCancel}>
              <Close />
            </CloseButton>
          </Header>

          <ClearallContainer>
            <SelectedHeading>1 of 4 Selected</SelectedHeading>
            <ClearBtn>Clear</ClearBtn>
          </ClearallContainer>

          <GoalSettingContent>
            {Goalactionitem?.map((item) => (
              <>
                <GoalAction width="100%">
                  <Actions
                    bordercolor={item?.bordercolor}
                    startColor={"#2F4358"}
                    endColor={"#2F4358"}
                    width="100%"
                  >
                    <div className="d-flex justify-content-between w-100 align-items-center">
                      <div className="d-flex w-75">
                        <Index bg={"#f06517"}>{item?.serialNo}</Index>
                        <Index bordered>{item?.symbol}</Index>
                        <SubHeading width="77%" className="mx-2" sm>
                          {item?.title}
                        </SubHeading>
                      </div>
                      <div>
                        <Icon
                          style={{
                            transform: "translate(5px)",
                          }}
                        >
                          <Checkbox onChange={onChange()}></Checkbox>
                        </Icon>
                      </div>
                    </div>
                  </Actions>
                </GoalAction>
              </>
            ))}
          </GoalSettingContent>

          <SaveButtoncontainer>
            <SaveBtn onClick={handleCancel}>Save</SaveBtn>
          </SaveButtoncontainer>
        </Content>
      </Modal>
    </Wrapper>
  );
};

export default SettingModal;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  /* border: 1px solid red; */
  background-color: #fff;
  width: 540px;
  min-height: 610px;
  max-height: 100%;
  padding: 0px 12px 12px 12px;
`;

const Header = styled.div`
  height: 2.8rem;
  border-bottom: 1px solid #394b59;
  display: flex;
  justify-content: space-between;
`;

const SettingHeading = styled.div`
  display: flex;
  align-items: center;
  /* padding-bottom: 10px; */
`;
const CloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  color: #112538;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 0.5rem;
`;

const ClearallContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectedHeading = styled.h3`
  margin-top: 0.5rem;
  color: #418db8;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ClearBtn = styled.button`
  color: #feab3d;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const GoalSettingContent = styled.div`
  /* border: 1px solid green; */
  padding-bottom: 0.6rem;
  border-bottom: 2px solid #CED0D4;
  /* height: 50vh; */
`;

const GoalAction = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: ${({ width }) => (width ? width : "218px")};
  /* border-top: 2px solid #0f2339 !important; */
`;
const Actions = styled.div`
  background-color: #fff;
  width: ${({ width }) => (width ? width : "218px")};
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-left: ${({ bordercolor }) =>
    bordercolor ? `4px solid ${bordercolor}` : null};
  margin-bottom: 0.4rem;
  /* border-image: linear-gradient(
      38.44deg,
      ${(props) => props.startColor} 14.59%,
      ${(props) => props.endColor} 71.58%
    )
    1; */
`;
const Index = styled.div`
  width: 20px;
  height: 20px;
  border-radius: ${({ bordered }) => (bordered ? "3px" : " 50%")};
  background: ${({ bg }) => (bg ? bg : "#D5E0EC")};
  /* color: ${({ bordered, theme }) =>
    bordered ? theme?.text?.primary_light : theme?.text?.white}; */
   color: ${({ bordered }) => (bordered ? "#4F9BE8" : "#fff")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: ${({ bordered }) => bordered && "bold"};
  margin-left: ${({ bordered }) => bordered && "0.6rem"};
  padding: 0 0.5rem;
`;
const SubHeading = styled.p`
  color: #112538;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
  width: ${({ width }) => (width ? width : "120px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Icon = styled.div`
  margin: 0 4px;
  cursor: pointer;
  svg {
    width: ${({ sm, md }) => (sm && "10px") || (md ? "14px" : "16px")};
    height: ${({ sm, md }) => (sm && "10px") || (md ? "14px" : "16px")};
  }
`;

const SaveButtoncontainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.6rem;
`;

const SaveBtn = styled.button`
  cursor: pointer;
  color: #fff;
  padding: 8px 16px;
  border-radius: 2px;
  background: #1271a6;
  border-radius: 3px;
  border: none;
  outline: none;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
 
`;
