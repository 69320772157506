import moment from "moment";

export const EVENTS = [
  // {
  //   title: "Appointment with harim",
  //   start: new Date(2023, 7, 28, 11, 0), // year, month (0-based), day, hour, minute
  //   end: new Date(2023, 7, 28, 12, 0),
  //   data: {
  //     appointment: {
  //       id: 1,
  //       status: "P",
  //       location: "New York",
  //       resource: "Dr Alex",
  //       address: "Building 5\nStreet 44\nNear Express Highway\nNew York",
  //     },
  //   },
  //   isDraggable: true,
  //   resourceId: 1,
  // },
  // {
  //   title: "Appointment with sharif",
  //   start: new Date(2023, 7, 28, 2, 0), // year, month (0-based), day, hour, minute
  //   end: new Date(2023, 7, 28, 3, 0),
  //   data: {
  //     appointment: {
  //       id: 2,
  //       status: "CI",
  //       location: "Washington",
  //       resource: "Dr David",
  //       address: "Block 1\nSStreet 32\nLong Island\nNew York",
  //     },
  //   },
  //   isDraggable: true,
  //   isResizable: true,
  //   resourceId: 2,
  // },
];

export const EVENT_STATUS_COLORS = {
  P: "#bee2fa",
  CI: "#c7edca",
};
