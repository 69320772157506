import { Arc } from "@visx/shape";
import { Group } from "@visx/group";
import { Chord, Ribbon } from "@visx/chord";
import { scaleOrdinal } from "@visx/scale";
import { LinearGradient } from "@visx/gradient";
import styled from "styled-components";

const Wrapper = styled.div`
  /* position: relative; */
  /* width: 700px; */
  
`;
const ChartContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  user-select: none;
  /* width: 720px; */
  /* width: 600px; */
  position: absolute;
`;

const StyledSVG = styled.svg`
  margin: 1rem 0;
  cursor: pointer;
  background-color: #fff;
`;

const DeetsContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

const DeetsItem = styled.div`
  margin: 0.25rem;
`;
const GradientWithText = styled(LinearGradient)`
  position: relative;
  display: inline-block;
`;

const TextOverlay = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
`;
const dataMatrix = [
  [30000, 1, 8916, 2868, 2868],
  [30000, 1, 8916, 2868, 2868],
  [30000, 1, 8916, 2868, 2868],
  [30000, 1, 8916, 2868, 2868],
];

const yellow = "#f5aa42";
const purple = " #7400CF";
const blue = " #2ea63a";
const green = "#ce2a2a";

const bg = "transparent";
const StyledArc = styled(Arc)``;
const ApexChartgradient = ({
  width,
  height,
  centerSize = 80,
  events = false,
  text,
}) => {
  height -= 130;
  const outerRadius = Math.min(width, height) * 0.63 - (centerSize + 3);
  const innerRadius = outerRadius - centerSize;
  function descending(a, b) {
    return b < a ? -1 : b > a ? 1 : b >= a ? 0 : NaN;
  }
  const color = scaleOrdinal({
    domain: [0, 1, 2, 3],
    range: [
      "url(#gpinkorange)",
      "url(#gpurplered)",
      "url(#gpurplegreen)",
      "url(#gbluelime)",
    ],
  });
  return width < 10 ? null : (
    <Wrapper>
      <ChartContainer>
        <StyledSVG width={width} height={height}>
          <GradientWithText
            id="gpinkorange"
            style={{
              position: "relative",
              border: "20px solid white",
            }}
            from={yellow}
            to={yellow}
            vertical={false}
          ></GradientWithText>
          <GradientWithText
            id="gpurplered"
            from={purple}
            to={purple}
            vertical={false}
          ></GradientWithText>
          <GradientWithText
            id="gpurplegreen"
            from={blue}
            to={blue}
            vertical={false}
          ></GradientWithText>
          <GradientWithText
            id="Lightcolor"
            from={green}
            to={green}
            vertical={false}
          ></GradientWithText>
          <GradientWithText
            id="gbluelime"
            from={green}
            to={green}
            vertical={false}
          ></GradientWithText>
          <rect width={width} height={height} fill={bg} rx={14} />
          <Group top={height / 2} left={width / 2}>
            <Chord
              matrix={dataMatrix}
              padAngle={0.05}
              sortSubgroups={descending}
            >
              {({ chords }) => (
                <g>
                  {chords.groups.map((group, i) => (
                    <>
                      <StyledArc
                        key={`key-${i}`}
                        data={group}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        fill={color(i)}
                        onClick={() => {
                          if (events) alert(`${JSON.stringify(group)}`);
                        }}
                      ></StyledArc>
                    </>
                  ))}
                </g>
              )}
            </Chord>
          </Group>
        </StyledSVG>
      </ChartContainer>
      <Gredient>
        <Rect>
          <Items>
            <MainValueWrapper>
              <ValueWrapper>
                <Value isBlue>Q1</Value>
                <Persentage isBlue>40%</Persentage>
              </ValueWrapper>
              <ValueWrapper>
                <Value isGreen>Q1</Value>
                <Persentage isGreen>40%</Persentage>
              </ValueWrapper>
              <ValueWrapper>
                <Value isRed>Q1</Value>
                <Persentage isRed>40%</Persentage>
              </ValueWrapper>
              <ValueWrapper>
                <Value isYellow>Q1</Value>
                <Persentage isYellow>40%</Persentage>
              </ValueWrapper>
            </MainValueWrapper>
          </Items>
        </Rect>
      </Gredient>
    </Wrapper>
  );
};
export default ApexChartgradient;
const Gredient = styled.div`
  z-index: 9999;
  height: 500px;
  transform: translateY(-5px);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Rect = styled.div`
  width: 300px;
  height: 300px;

  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
stroke-width: 7px;
stroke: #FFF;
filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
`;
const Items = styled.div`
  width: 200px;
  height: 200px;
  /* border: 10px solid #192d42; */
  border-radius: 50%;
  transform: translate(6px,5px);
  display: flex;
  justify-content: center;
  align-items: center;
background-color: #fff;
   margin:2rem ;
   padding: 1rem;
  flex-wrap: wrap;
   
`;
const MainValueWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
width: 95%;
height: 95%;
/* background-color: red; */
border-radius: 50%;
background-color: gray;
padding: 1rem;
 background: radial-gradient(68.61% 68.61% at 50% 50%, #FFF 56.81%, #879DB4 100%);
stroke-width: 7px;
stroke: #FFF;
filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
`
const Value = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
  letter-spacing: 0.01em;
  color: #b964fb;
  font-family: "Inter";
  font-weight: 700;
  color: ${({ isBlue, isRed, isGreen, isYellow }) =>
    (isBlue && "#7206c9") ||
    (isRed && "#d12b2b") ||
    (isGreen && "#32ab3e") ||
    (isYellow && "#f5aa41")};
`;
const Persentage = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
  letter-spacing: 0.015em;
  color: ${({ isBlue, isRed, isGreen, isYellow }) =>
    (isBlue && "#7206c9") ||
    (isRed && "#d12b2b") ||
    (isGreen && "#32ab3e") ||
    (isYellow && "#f5aa41")};
  opacity: 10;
  font-family: "Inter";
  font-weight: 300;
`;
const ValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.2rem;
  height: 100%;

`;
