import { useState, createRef, FC, ChangeEvent, KeyboardEvent, FormEvent } from 'react';
import { InputField, Button } from 'elements';

import {
  Wrapper,
  Label,
  Row
} from './styled';

interface IProps {
  type: string;
  onAdd: (value: string, callback: (success: boolean) => void) => void;
}

const AddCustomItem: FC<IProps> = ({ type, onAdd }) => {
  const [value, setValue] = useState('');
  const $input = createRef<HTMLInputElement>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    const inputLength = e.target.value.length;
  
    if (inputLength > 0 && inputLength < 3) {
      e.target.setCustomValidity('Min. 3 characters required');
    } else if (inputLength > 32) {
      e.target.setCustomValidity('Max. 32 characters allowed');
    } else {
      e.target.setCustomValidity('');
    }
  };  

  const Type = type.charAt(0).toUpperCase() + type.slice(1);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onAdd(value, (success) => {
      if (success) {
        setValue('');
        $input.current?.setCustomValidity('');
      }
      else if ($input.current) {
        $input.current.setCustomValidity(`${Type} already exists`);
        $input.current.reportValidity();
      }
    });
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Label>Add Custom {Type}</Label>
      <Row>
        <InputField
          // @ts-ignore
          value={value}
          containerStyle={{ flex: 1 }}
          placeholder={`Enter ${Type} name`}
          required
          max={32}
          error={!!(value && value.length < 2)}
          ref={$input}
          onChange={handleChange}
          onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
        />
        <Button styleType="secondary">Add</Button>
      </Row>
    </Wrapper>
  );
}

export default AddCustomItem;
