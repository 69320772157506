import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import Linkedin from "assets/images/Linkedin.svg";
import Youtube from "assets/images/Youtube.svg";
import Twitter from "assets/images/Twitter.svg";
import Facebook from "assets/images/facebook.svg";
import Instagram from "assets/images/instagram.svg";
import Tiktok from "assets/images/tiktok.svg";
import Pinerest from "assets/images/pinerest.svg";

import useWindowSize from "utils/useWindowSize";

import { Wrapper, LeftSection, FollowText, Icon, Copyright, IconWrapper, Anchor } from './styled';

const Footer = ({ apm }) => {
  const location = useLocation().pathname;
  const { width } = useWindowSize();

  const SocialLinks = [
    {
      id: '0',
      icon: Instagram,
      link: "https://www.instagram.com/arootahcoach/",
    },
    {
      id: '1',
      icon: Facebook,
      link: "https://www.facebook.com/arootahcoach",
    },
    {
      id: '2',
      icon: Twitter,
      link: "https://twitter.com/arootahcoach",
    },
    {
      id: '3',
      icon: Youtube,
      link: "https://www.youtube.com/channel/UCq5fhJkfKRUGmjzTvMsFU-w",
    },
    {
      id: '4',
      icon: Linkedin,
      link: "https://www.linkedin.com/company/arootahcoach",
    },
    {
      id: '5',
      icon: Tiktok,
      link: "https://www.linkedin.com/company/arootahcoach",
    },
    {
      id: '6',
      icon: Pinerest,
      link: "https://www.linkedin.com/company/arootahcoach",
    },
  ];

  const restrictedRoutes = [
    "/",
    "/sign-up",
    "/forgot-password",
    "/password-email-sent",
    "/reset-password",
    "/welcome-page",
    "/final-step",
  ];

  const isRestricted = () => {
    return restrictedRoutes?.includes(location);
  };

  if (width <= 1439 && !apm)
    return null;

  return (
    <div>
      {!isRestricted() && (
        <Wrapper $apm={apm}>
          <LeftSection>
            <FollowText>Follow us @arootahcoach</FollowText>
            <IconWrapper>
              {SocialLinks?.map((item) => (
                <a href={item?.link} target={"_blank"} rel="noreferrer" key={item.id}>
                  <Icon src={item?.icon} alt="icon" />
                </a>
              ))}
            </IconWrapper>
          </LeftSection>
          <div>
            <Copyright>
              © Arootah &nbsp;&nbsp;Visit
              <Anchor target="_blank" href="https://arootah.com/">
                &nbsp;arootah.com
              </Anchor>
            </Copyright>
          </div>
        </Wrapper>
      )}
    </div>
  );
};

export default Footer;
