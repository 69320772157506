import { ActionTypes } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  masterplanList: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return { ...state, loading: true };
    case ActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        masterplanList: action.payload,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
