import React, { useRef } from "react";
import moment, { Moment } from "moment";
import { DatePicker, Checkbox as AntdCheckbox } from "antd";
import styled from "styled-components";
//@ts-ignore
import CalendarToggleIcon from "assets/images/priorities/calendar-toggle.svg";
//@ts-ignore
import CheckboxEmptyIcon from "assets/images/priorities/checkbox-empty.svg";
//@ts-ignore
import CheckboxCheckedIcon from "assets/images/priorities/checkbox-checked.svg";
//@ts-ignore
import useDeviceType from "../../../../Hooks/ResponsiveHook";

interface InputProps {
  value?: string | number;
  type?: string;
  step?: string;
  border?: string;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  defaultValue?: string;
  width?: string;
  icon?: React.ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Input: React.FC<InputProps> = ({
  value,
  type,
  step,
  border,
  placeholder,
  onChange,
  readOnly,
  defaultValue,
  width,
  icon,
  ...rest
}) => (
  <div>
    {icon && <Icon>{icon}</Icon>}
    <InputStyled
      value={value}
      $style={border}
      placeholder={placeholder}
      step={step}
      type={type}
      onChange={onChange}
      readOnly={readOnly}
      defaultValue={defaultValue}
      $width={width}
      {...rest}
    />
  </div>
);

interface DateRangePickerProps {
  value?: [Moment, Moment];
  onChange?: (dates: [Moment, Moment], dateStrings: [string, string]) => void;
  onBlur?: () => void;
  defaultValue?: [Moment, Moment];
  readOnly?: boolean;
  screenSizes?:string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  onBlur,
  defaultValue,
  readOnly,
  screenSizes
}) => {
  const ref = useRef(null);
  return (
    <DatePickerStyled ref={ref} $mobile={screenSizes === "mobile"}>
      <DatePicker.RangePicker
        popupStyle={{ zIndex: 99999 }}
        data-testid="calendar-toggle"
        className="date-range-picker"
        popupClassName="date-range-picker"
        style={{ pointerEvents: readOnly ? "none" : "auto" }}
        value={value}
        defaultValue={
          defaultValue
            ? [moment(defaultValue[0]), moment(defaultValue[1])]
            : undefined
        }
        onChange={(values, formatString) =>
          onChange?.(
            values as [Moment, Moment],
            formatString as [string, string]
          )
        }
        onBlur={onBlur}
        // @ts-ignore
        readOnly
        getPopupContainer={() => (ref.current ? ref.current : document.body)}
      />
    </DatePickerStyled>
  );
};

interface CheckboxProps {
  checked?: boolean | number;
  label?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  label,
  disabled,
  onChange,
  children,
}) => (
  <StyledCheckbox
    checked={!!checked}
    disabled={disabled}
    onChange={onChange as (e: any) => void}
  >
    {label || children}
  </StyledCheckbox>
);

const InputStyled = styled.input<{ $width?: string; $style?: string }>`
  outline: none;
  padding: 4px 6px;
  border-radius: 3px;
  border: 0.5px solid #e5e5e5;
  background: #fff;
  color: #171717;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  width: 54px;
  text-align: center;

  :focus {
    border-color: #71aaca;
    box-shadow: 0px 0px 0px 2px #d0e3ed;
  }

  ${({ $width }) =>
    $width &&
    `
    width: ${$width};
  `};

  ${({ $style }) =>
    $style &&
    `
    border: ${$style};
    :focus{
      border-color: none;
      box-shadow: none;
    }
  `};

  /* For Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const DatePickerStyled = styled.div<{ $mobile: boolean }>`
  .date-range-picker {
    .ant-picker-panels {
      flex-direction: ${($mobile) => {
        return $mobile.$mobile ? "column" : "row";
      }};
    }
  }
  .ant-picker.ant-picker-range {
    padding: 4px 6px;
    max-width: 188px;
    border-radius: 3px;
    border: 0.5px solid #e5e5e5;
    background: #fff;

    .ant-picker-range-separator {
      display: none;
    }

    .ant-picker-input > input {
      color: #1b2a3d;
      font-family: Inter;
      font-size: 12px;
      line-height: 20px;
    }

    .ant-picker-suffix {
      width: 20px;
      height: 20px;
      background-image: url("${CalendarToggleIcon}");
    }

    .ant-picker-suffix > span {
      display: none;
    }

    .ant-picker-clear {
      display: none;
    }
  }
`;

const Icon = styled.span`
  margin-right: 5px;
`;

const StyledCheckbox = styled(AntdCheckbox)`
  display: flex;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  line-height: 21px;

  .ant-checkbox {
    top: 0;

    &.ant-checkbox-disabled {
      opacity: 0.5;
    }
  }

  .ant-checkbox {
    &::after {
      content: none;
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-image: url(${CheckboxEmptyIcon});
    background-color: transparent !important;
    border: none !important;
    border-radius: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-image: url(${CheckboxCheckedIcon});

    &::after {
      content: none;
    }
  }
};
`;
export { Input, DateRangePicker, Checkbox };
