import { Draggable } from "react-beautiful-dnd";

import {
  Wrapper,
  DraggableWrapper,
  Group,
  NameGroup,
  Head,
  Order,
  Title,
  Subtitle,
  DragButton
} from "./styled";

import { ReactComponent as DragIcon } from "assets/images/journeys/drag-goals.svg";

const Area = ({ data, order, draggable }) => {
  const { title, category } = data;

  return (
    <Group>
      <Head>
        <Order>{order}</Order>
        <NameGroup>
          <Title title={title} $draggable={draggable}>{title}</Title>
          <Subtitle title={category} $draggable={draggable}>{category}</Subtitle>
        </NameGroup>
      </Head>
    </Group>
  );
}

const AreaItem = ({ data, order }) => {
  const { color } = data;

  return (
    <Wrapper $color={color} $draggable={false}>
      <Area data={data} order={order} />
    </Wrapper>
  );
}

const AreaItemDraggable = ({ data, highlighted, index, onClick }) => {
  const { id, color } = data;

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick(index);
    }
  }

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {provided => (
        <DraggableWrapper ref={provided.innerRef} {...provided.draggableProps}>
          <Wrapper $color={color} $draggable $highlighted={highlighted} $clickable={typeof onClick === 'function'} onClick={handleClick}>
            <Area data={data} order={index+1} draggable />
            <DragButton {...provided.dragHandleProps} title="Drag" onMouseDown={e => e.currentTarget.focus()}>
              <DragIcon />
            </DragButton>
          </Wrapper>
        </DraggableWrapper>
      )}
    </Draggable>
  );
}

export { AreaItem, AreaItemDraggable };
