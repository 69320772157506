import { connect } from "formik"
import { Component } from "react"

class ErrorFocusInternal extends Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik
    const keyify = (obj, prefix = "") =>
      Object.keys(obj).reduce((res, el) => {
        if (Array.isArray(obj[el])) {
          return res
        } else if (typeof obj[el] === "object" && obj[el] !== null) {
          return [...res, ...keyify(obj[el], prefix + el + ".")]
        } else {
          return [...res, prefix + el]
        }
      }, [])
    const keys = keyify(errors)

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${keys[0]}"]`
      const errorElement = document.querySelector(selector)
      if (errorElement) {
        errorElement.focus()
      }
    }
  }

  render = () => null
}

export const ErrorFocus = connect(ErrorFocusInternal)
