import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background: #EFF7FC;
  height: 60px;
`;

export const TimeCol = styled.div`
  width: 108px;
`;

export const BodyCol = styled.div`
  border-radius: 0 4px 4px 0;
  background: #E0E8F0;
  flex: 1;
`;
