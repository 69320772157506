import { Button, Radio, Menu, Checkbox } from "antd";
import styled from "styled-components"
import { ReactComponent as CheckIcon } from "assets/images/priorities/common/checkicon.svg";

export const Title = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #1271A6;
  background-color: #ECF3F6;
  border: none;
  border-radius: 24px;
  padding: 2px 6px 2px 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  max-width: auto !important;
  white-space: nowrap !important;
`;

export const MobileTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color:${({ $isClicked }) => $isClicked ? '#1271A6' : '#4C545B'};
  svg{
    path{
      stroke:${({ $isClicked }) => $isClicked ? '#1271A6' : '#4C545B'};
    }
  }
`;

export const Label = styled.div`
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    width: 150px;
    letter-spacing: 0em;
    text-align: left;
  
    span {
        margin-right: 10px;
        

        svg {
            path {
                stroke: #8E97A3;
            }

            ${({ $selected }) => $selected && `
                path {
                    stroke: #1271A6;
                }
            `}
        }
    }
`;


export const Heading = styled.div`
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    color: #6D7F8E;
    padding-left: 7px;
    text-transform: uppercase;
    margin: 6px 6px 0 6px;
    height: 31px;
    border-bottom: 1px solid #CED0D4;

    ${({ $criteria }) => $criteria && `
      border-bottom: none;
    `}
`;

export const CustomButton = styled(Button)`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  height: 32px;
  padding: 6px 10px;
  letter-spacing: 0em;
  text-align: center;
  color: #1271A6;
  width: 100%;
  background: transprent;

  span {
    .ant-checkbox-checked .ant-checkbox-inner {
      border-radius: 10px;
    }
  }

  :hover {
    background: rgb(236, 243, 246);
  }
`;

export const CustomCheckboxFilter = styled(Checkbox)`
    width: 100%;

    span {
      width : 20px !important;
      height : 20px !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background: #1271A6 !important;
      border-color: #1271A6 !important;
    }
`;

export const ResetButton = styled.div`
  width: 95%;
  margin: auto;
  border-top: ${props => props.noBorder ? 'none' : '1px solid #CED0D4'};
`;

export const CustomCheckIcon = styled(CheckIcon)`
   margin-top: 4px;
   width:20px;
   
`;


export const CustomMenu = styled(Menu)`
  
  top:${({ $mobile }) => $mobile ? '-2px' : '2px'};
  width: ${({$mobile}) => $mobile ? '212px !important'  : '212px !important'};
  &.ant-dropdown-menu {
   
    overflow: hidden !important;
    &::-webkit-scrollbar {
      display: none;

    }
    ${({ $criteriaheight }) => $criteriaheight && `
      min-height: 300px;
    `}

    ${({ $Sort }) => $Sort && `
   
      left:-10%;
      min-height: 285px;
    `}


    ${({  $Filter }) =>  $Filter && `
  
    max-height: 500px;
   

    &.ant-dropdown-menu{
      top: 70px;
      left: 70px;
    }
  `}
    .ant-dropdown-menu-item {
      &:hover, &:focus-within {
        background: rgba(236, 243, 246, 1);
      }
      &:focus-within label {
        color: rgba(18, 113, 166, 1);
      }
    }
  }
`;

export const CustomCheckbox = styled(Radio)`

    && .ant-radio-inner {
      width: 20px;
      height: 20px;
      border-color: #418DB8 !important; /* Color of the border */
      

      &:after {
        width: 20px; /* Typically smaller than the outer circle */
        height: 20px;
        background-color: #418DB8  !important;
        top: 7px;
        left: 7px;
        

      }
     
    }
 
    &&.ant-radio-checked .ant-radio-inner {
      border-color: red !important; /* Color of the border when checked */
      width: 20px;
      
      &&::after {
        background-color: red; /* Color of the dot when checked */
      }
    }

`;


export const ScrollBar = styled.div`
  max-height: ${({ $Filter }) => $Filter ? '400px' : '150px'};
  overflow: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .ant-dropdown-menu-item.ant-dropdown-menu-item-active{
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #CED0D4;
    border-radius: 15px;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
 && { margin: 0px 5px;
  padding: 7px 8px;
  background: ${({ $selected }) => $selected ? '#ECF3F6' : 'transparent'};}
`;

export const StyledMenuItemCheck = styled(Menu.Item)`
  & { 
    padding: 6px 10px;
  }

  & .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 12px;
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
  }

  ${({ $checked }) => $checked && `
    & .ant-dropdown-menu-title-content {
      color: var(--primary-500-main, #1271A6);
      font-weight: 500;
    }
  `}
`;

export const StyledRadio = styled(Radio.Group)`

`;

export const CustomLabel = styled.div`
  display: flex;
  gap: 8px;
  color: #1271A6;
  
`
export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.50);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

${({ $active }) => $active && `
  opacity: 1;
  visibility: visible;
`};
`;

export const FilterTrext = styled.div`
font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 20px;
margin-left:10px;
margin-bottom:7px;
margin-top:3px;
color:#112538;

`;

export const FilterIcon = styled.div`
width:12px;
height:14px;
margin-bottom:-4px;
margin-left:10px;

`;

export const CrossIcn = styled.div`
display:flex;
justify-content:end;
margin-right:10px;
margin-top:5px;
cursor:pointer;
height:12px;
width:12px;
cursor:pointer

`;
export const SortTitle = styled.div`
font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 20px;
text-align: left;
color:#112538;
margin-top: 3px;
margin-left:4px;

`
export const Arrows = styled.div`

`
