import { FC } from 'react';
import { Checkbox, RadioButton } from "elements";

import {
  Wrapper,
  Label
} from "./styled";

export interface IOption {
  id: string;
  text: string;
}

interface IProps {
  data: IOption;
  type: 'radio' | 'checkbox';
  isSelected: boolean;
  onChange: (e: any) => void;
}

const Option: FC<IProps> = ({ data, type, isSelected, onChange }) => {
  const { id, text } = data;

  const handleChange = () => {
    onChange(data);
  };

  const renderLabel = () => {
    return (
      <Label>
        <span>{text}</span>
      </Label>
    );
  };

  const renderToggle = () => {
    if (type === 'checkbox') {
      return (
        <Checkbox
          id={id}
          checked={isSelected}
          label={renderLabel()}
          rtl
          width="100%"
          onChange={handleChange}
        />
      );
    }

    return (
      <RadioButton
        id={id}
        checked={isSelected}
        label={renderLabel()}
        name="assistant"
        rtl
        width="100%"
        onChange={handleChange}
        styles={{
          label: {
            alignItems: 'center'
          }
        }}
      />
    );
  };

  return (
    <Wrapper $active={isSelected}>
      {renderToggle()}
    </Wrapper>
  );
}

export default Option;
