import { deleteActions } from "api";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { Error } from "Responses/Response";
import { getCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { SelectedGoalRemoveAction } from "Redux/Global/action";
import Notify from "utils/notify";

export const DeleteActions = (id, callback , type) => async (dispatch) => {
  try {
    const userDetails = JSON.parse(getCookie("userData"));
    const selectedClient = getCookie("selectedClient");
    let query = ``;

    if (userDetails?.type === "Coach" && selectedClient) {
      query = `?coaching_user=${selectedClient}`;
    }

    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await deleteActions(id, query);

    if (status === 204) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });

      if(type === "Habit"){
        Notify.success("Habit Successfully Deleted");
      } else{
        Notify.success("Action successfully deleted");
      }

      dispatch(SelectedGoalRemoveAction(id));

      if (callback) callback();

      let MasterPlanId = getCookie("MasterPlanId");

      if (MasterPlanId)
        return dispatch(RetrieveMasterPlanDetails(MasterPlanId));
    }
  } catch (error) {
    Error(error?.response?.data);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
