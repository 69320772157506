import { requestOtp } from "api";
import { setCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { Error } from "Responses/Response";
import { getAppBaseURL } from "utils/utility";

export const RequestOtp = (Data, Navigate) => async (dispatch) => {
  try {

    dispatch({ type: ActionTypes.LOADING });
    const url = getAppBaseURL();
    const dataForm = {
      ...Data,
      redirect_url : `https://${url}/`
    }
    const { data, status } = await requestOtp(dataForm);
    
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
      setCookie("login_email", Data?.email);
      Navigate("/verify-otp");
    }
  } catch (error) {
    // Error(error?.response?.data?.detail);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
