import { Popover } from "antd";
import { useLocation } from "react-router-dom";

// @ts-ignore
import { ReactComponent as AppsIcon } from "assets/svg/appSwitcher.svg";

// @ts-ignore
import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";

// @ts-ignore
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg?v=1";

// @ts-ignore
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";

// @ts-ignore
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";

// @ts-ignore
import { ReactComponent as LogoAPM } from "assets/images/auth/logoAPM.svg";

// @ts-ignore
import instagramSrc from "assets/images/social/instagram-w.svg";

// @ts-ignore
import facebookSrc from "assets/images/social/facebook-w.svg";

// @ts-ignore
import xSrc from "assets/images/social/x-w.svg";

// @ts-ignore
import youtubeSrc from "assets/images/social/youtube-w.svg";

// @ts-ignore
import linkedinSrc from "assets/images/social/linkedin-w.svg";

// @ts-ignore
import tiktokSrc from "assets/images/social/tiktok-w.svg";

import {
  Box,
  AppsList,
  AppItem,
  AppLink,
  AppItemTitle,
  Footer,
  Social,
  Link,
  IconLink,
  Toggle,
} from './styled';

// @ts-ignore
import customStyles from './global.module.scss';

import {
  APM_PATH,
} from "utils/routes";
import { useState } from "react";

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validURLs = [
    'goal-setting-workbo-37-staging.botics.co',
    'localhost',
    '127.0.0.1'
  ];

  if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
    return {
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      ppURL: 'project-planner-36424-staging.botics.co'
    }
  }

  return {
    hcURL: 'app.arootah.com',
    ppURL: 'apps.arootah.com'
  }
}

const AppSwitcher = () => {
  const { ppURL, hcURL } = getPath();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const isAPM = pathname.includes(APM_PATH);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      overlayClassName={customStyles.popover}
      placement="bottomLeft"
      // padding={0}
      content={
        <Box>
          <AppsList>
            <AppItem to={'/'} onClick={hide} $active={!isAPM}>
              <LogoGPS />
              <AppItemTitle>Goal Manager</AppItemTitle>
            </AppItem>

            <AppItem to={APM_PATH} onClick={hide} $active={isAPM}>
              <LogoAPM />
              <AppItemTitle>Time Manager</AppItemTitle>
            </AppItem>

            <AppLink href={`https://${hcURL}`} /* target="_blank" */>
              <LogoHC />
              <AppItemTitle>Habit Manager</AppItemTitle>
            </AppLink>

            <AppLink href={`https://${ppURL}/decision-manager`} /* target="_blank" */>
              <LogoDM />
              <AppItemTitle>Decision Manager</AppItemTitle>
            </AppLink>

            <AppLink href={`https://${ppURL}/project-planner`} /* target="_blank" */>
              <LogoPP />
              <AppItemTitle>Project Manager</AppItemTitle>
            </AppLink>
          </AppsList>

          <Footer>
            <Social>
              <IconLink href="https://www.instagram.com/arootahcoach/" title="Instagram" target="_blank">
                <img src={instagramSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.facebook.com/arootahcoach" title="Facebook" target="_blank">
                <img src={facebookSrc} alt="" />
              </IconLink>

              <IconLink href="https://x.com/arootahcoach" title="X (Twitter)" target="_blank">
                <img src={xSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.youtube.com/channel/UCq5fhJkfKRUGmjzTvMsFU-w" title="Youtube" target="_blank">
                <img src={youtubeSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.linkedin.com/company/arootahcoaching/?ref=website" title="Linkedin" target="_blank">
                <img src={linkedinSrc} alt="" />
              </IconLink>

              <IconLink href="https://www.tiktok.com/@arootahcoach" title="TikTok" target="_blank">
                <img src={tiktokSrc} alt="" />
              </IconLink>
            </Social>

            <Link href="https://arootah.com" target="_blank">www.arootah.com</Link>
          </Footer>
        </Box>
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <Toggle /* $rotate={'-90deg'} */>
        <AppsIcon />
      </Toggle>
    </Popover>
  );
}

export default AppSwitcher;
