import styled from "styled-components";

export const Pointer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  span {
    color: var(--Neutral-900, var(--Color-2, #171717));
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }
`;
