import PropTypes from 'prop-types';
import { Draggable } from "react-beautiful-dnd";
import { Accordion, useAccordionButton } from "react-bootstrap";

import {
  // Main
  Wrapper,

  // Header
  Head,
  HeadWrap,
  DragButton,
  HeadContent,
  Title,
  StatusWrap,
  StatusIndicator,
  StatusText,
  HeadCol,
  Counter,
  CollapseToggle,

  // Body
  Body,

  // Item
  ItemWrap,
  ItemTitle,
  ItemProgress,
  ItemProgressHead,
  ItemProgressLabel,
  ItemProgressValue,
  ItemProgressBar,

  // Footer
  Footer,
  FooterAction,
  FooterProgress,
  FooterProgressText,

  // Progress Circle
  Circle,
  CircleValue
} from "./styled";

import { ReactComponent as DragIcon } from "assets/images/priorities/drag.svg";
import { ReactComponent as CollapseIcon } from "assets/images/priorities/collapse-arrow.svg";
import { ReactComponent as EnterIcon } from "assets/images/dashboard/enter.svg";

const Item = ({ title }) => {
  return (
    <ItemWrap>
      <ItemTitle>{title}</ItemTitle>
      <ItemProgress>
        <ItemProgressHead>
          <ItemProgressLabel>Progress</ItemProgressLabel>
          <ItemProgressValue>0%</ItemProgressValue>
        </ItemProgressHead>
        <ItemProgressBar />
      </ItemProgress>
    </ItemWrap>
  );
}

const CircleProgress = () => {
  return (
    <Circle>
      <CircleValue>0%</CircleValue>
    </Circle>
  );
}

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <CollapseToggle onClick={decoratedOnClick}>
      {children}
    </CollapseToggle>
  );
}

const CONFIG = {
  decision: {
    title: "My Decisions",
    buttonLabel: "View Decisions",
    buttonLink: "https://project-planner-36424-staging.botics.co/decision-manager",
    color: "#2E81B0"
  },
  habit: {
    title: "My Habits",
    buttonLabel: "View Habits",
    buttonLink: "https://hat-arootah-web-24408-staging.botics.co",
    color: "#6FA8FC"
  },
  project: {
    title: "My Projects",
    buttonLabel: "View Projects",
    buttonLink: "https://project-planner-36424-staging.botics.co/project-planner",
    color: "#59A310"
  },
  journie: {
    title: "My Journies",
    buttonLabel: "View Journeys",
    buttonLink: "",
    color: "#FFC01E"
  }
};

export const Type = {
  Decision: "decision",
  Habit: "habit",
  Project: "project",
  Journie: "journie"
}

const OverviewItem = ({ type, total, status, index }) => {
  return (
    <Draggable key={type} draggableId={type} index={index}>
      {provided => (
        <Wrapper $color={CONFIG[type].color} ref={provided.innerRef} {...provided.draggableProps}>
          <Accordion defaultActiveKey={"0"}>
            <Head>
              <HeadWrap>
                <DragButton {...provided.dragHandleProps} title="Drag">
                  <DragIcon width={22} height={22} />
                </DragButton>

                <HeadContent>
                  <Title>{CONFIG[type].title}</Title>
                  <StatusWrap>
                    <StatusIndicator $color={status.color} />
                    <StatusText>{status.title}</StatusText>
                  </StatusWrap>
                </HeadContent>
              </HeadWrap>

              <HeadCol>
                <Counter>{total}</Counter>
                <CustomToggle eventKey="0">
                  <CollapseIcon />
                </CustomToggle>
              </HeadCol>
            </Head>

            <Accordion.Collapse eventKey="0">
              <>
                <Body>
                  <Item title="Hire investment director" />
                  <Item title="Buy a house" />
                </Body>

                <Footer>
                  <FooterAction href={CONFIG[type].buttonLink} target="_blank">{CONFIG[type].buttonLabel} <EnterIcon /></FooterAction>
                  <FooterProgress>
                    <FooterProgressText>Overall progress</FooterProgressText>
                    <CircleProgress />
                  </FooterProgress>
                </Footer>
              </>
            </Accordion.Collapse>
          </Accordion>
        </Wrapper>
      )}
    </Draggable>
  );
}

OverviewItem.propTypes = {
  type: PropTypes.oneOf([
    "decision",
    "habit",
    "project",
    "journie"
  ]).isRequired,
  total: PropTypes.number.isRequired,
  status: PropTypes.shape({
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default OverviewItem;
