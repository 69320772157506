import { useLayoutEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

import GoalsGroup from "../GoalsGroup";
import {
  Wrapper,
  Content
} from "./styled";

import { data } from './dataMockup';

const List = () => {
  const [activeItem, setActiveItem] = useState('');

  useLayoutEffect(() => {
    if (data.length)
      setActiveItem(data[0].id);
  }, [data]);

  const onToggleHandle = key => {
    setActiveItem(prev => prev === key ? '' : key);
  }

  return (
    <Wrapper>
      <Accordion as={Content} activeKey={activeItem}>
        {data.map(item => <GoalsGroup activeKey={activeItem} data={item} onToggle={onToggleHandle} key={item.id} />)}
      </Accordion>
    </Wrapper>
  );
}

export default List;
