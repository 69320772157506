import Notify from "utils/notify";
import { updateIndirectScores } from "../apm-apis";
import { ActionTypes } from "./type";
import { fetchListPrioritizedActions, fetchListPrioritizedActionsGrouped } from "../PrioritizedActions/action";

export const updateIndirectScore = (payload, callback) => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_INDIRECT_SCORE });

    const { data } = await updateIndirectScores(payload);

    dispatch({
      type: ActionTypes.UPDATE_INDIRECT_SCORE_SUCCESS,
      payload: data,
    });

    const { simpleView } = getState().prioritizedActions;

    if (simpleView)
      dispatch(fetchListPrioritizedActions());
    else
      dispatch(fetchListPrioritizedActionsGrouped());

  } catch (error) {
    Notify.error(error?.message, true);
    dispatch({
      type: ActionTypes.UPDATE_INDIRECT_SCORE_FAILURE,
      payload: error,
    });
  }
};