import { isRouteErrorResponse } from "react-router-dom";
import { listAvailableAreasAndIndirectScores } from "../apm-apis";
import { ActionTypes } from "./type";

export const availableAreas = (id) => async (dispatch, getState) => {
    const { client } = getState().prioritizedActions;

    const filtersParams = {};

    if (id)
        filtersParams.prioritized_activity_id = id;

    if (client)
        filtersParams.client = client;

    try {
        dispatch({ type: ActionTypes.FETCH_AVAILABLE_AREAS_REQUEST });
        const { data } = await listAvailableAreasAndIndirectScores(filtersParams);

        dispatch({  
            type: ActionTypes.FETCH_AVAILABLE_AREAS_SUCCESS,
            payload: data
        });
    } catch (error) {
        isRouteErrorResponse(error?.response?.data?.error);
        dispatch({
            type: ActionTypes.FETCH_AVAILABLE_AREAS_FAILURE,
            payload: error
        });
    }
};