import { useState, createRef, FC, ChangeEvent, KeyboardEvent, FormEvent } from 'react';
import { InputField, Button } from 'elements';

import {
  Wrapper,
  Label,
  Row
} from './styled';

interface IProps {
  onCreate: (value: string, callback: (success: any) => void) => void;
  onSelect: (value: any) => void;
}

const CreateCustomAccountability: FC<IProps> = ({ onCreate, onSelect }) => {
  const [value, setValue] = useState('');
  const $input = createRef<HTMLInputElement>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);
    const inputLength = value.trim().length;
  
    if (inputLength > 0 && inputLength < 3) {
      e.target.setCustomValidity('Min. 3 characters required');
    } else if (inputLength > 32) {
      e.target.setCustomValidity('Max. 32 characters allowed');
    } else {
      e.target.setCustomValidity('');
    }
  };  

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onCreate(value.trim(), (success) => {
      if (success) {
        $input.current?.setCustomValidity('');

        setValue('');
        onSelect({
          value: success.id,
          label: success.name
        });
      }
      else if ($input.current) {
        $input.current.setCustomValidity(`Accountability already exists`);
        $input.current.reportValidity();
      }
    });
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Label>Add Custom Accountability</Label>
      <Row>
        <InputField
          // @ts-ignore
          value={value}
          containerStyle={{ flex: 1 }}
          placeholder={"Enter Accountability"}
          required
          max={32}
          error={!!(value && value.length < 2)}
          ref={$input}
          onChange={handleChange}
          onKeyDown={(e: KeyboardEvent) => e.stopPropagation()}
        />
        <Button styleType="secondary" disabled={!value.trim()}>Save</Button>
      </Row>
    </Wrapper>
  );
}

export default CreateCustomAccountability;
