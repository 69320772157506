import styled from "styled-components";
import { Col, Row } from "antd";
import { Button } from "antd";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${({ $mobile }: { $mobile: boolean }) => ($mobile ? '383px' : '680px')};
  max-height: ${({$mobile}:{$mobile:boolean})=>{
   return $mobile?'93vh':'968px'
  }};
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  margin-top: 60px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  overflow: hidden;
  z-index: 999;
`;

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  height: 100%;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #acb4bc;
  }
`;

export const ScrollableContent = styled.div`
  padding: 0 12px 12px 12px;
`;

export const Container = styled.div`
  width: 100%;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #CED0D4;
`;

export const Item = styled.div`
  color: aliceblue;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #CED0D4;
  padding: 6px 12px 6px 6px;
`;

export const IconWrapper = styled.div<{isGap?:string,border?:string}>`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${({ isGap }) => (isGap ? "1.2rem" : "0.3rem")};
  border-right: ${({ border }) => border && "1px solid red "};
`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform .25s;

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Heading = styled.h2`
  margin: 0;
  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-left: 7px;
  color: #112538;
  text-transform: uppercase;
`;

export const SubHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #112538;
`;

export const Smallheading = styled.p`
  margin: 0;
  padding: 0;
  color: #6d7784;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

export const Priority = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 6px;
`;

export const Area = styled.div<{$color:string}>`
  border-radius: 0 4px 4px 0;
  width: 5px;
  height: 32px;
  background-color: ${({ $color }) => $color};
`;

export const Span = styled.span`
  background-color: #B4C3D2;
  width: 16px;
  height: 16px;
  display: flex;
  font-size: 10px;
  line-height: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const Action = styled.div`
  display: flex;
  align-self: end;
  align-items: baseline;
`;

export const Type = styled.span`
  color: #4F9BE8;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  margin-right: 2px;
`;

export const PriorityContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const SliderButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const ChartWrapper = styled.div`
  display: flex;
  ${({ $mobile }:{$mobile:boolean}) => $mobile && `
  flex-direction: column;
  align-items: center;
`}

`;

export const DonutChartContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColumnChartContainer = styled.div`
${({ $mobile }: { $mobile: boolean }) => !$mobile && `
::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  margin-top: 18px;
  height: calc(100% - 18px - 0px);
  background-color: #CED0D4;
}
`}
${({ $mobile }: { $mobile: boolean }) => $mobile && `
border-top: 1px solid #CED0D4;
`}

  width : ${({$mobile}:{$mobile:boolean})=>($mobile?'351px':'248px')}
  height: 100%;
  padding-top: 18px;
  position: relative;

`;

export const ChartHeader = styled.div`
  padding: 0 12px;
`;

export const SmallHeading = styled.p`
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #112538;
  margin: 2px;
`;

export const Box = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0.4px solid #B2B8BE;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #112538;
`;

export const ColumnItem = styled.div`
  margin-top: 10px;
  padding: 5px 5px 10px 5px;
`;

export const ColumnHeading = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #112538;
  text-transform: uppercase;  

`;

export const BarChartWrapper = styled.div`
  padding: 0 10px;
`;

export const ColumnDisc = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  align-items: baseline;
`;

export const Disc = styled.p`
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
  span {
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const DisSpan = styled.span<{bg:string}>`
  background: ${({ bg }) => bg && bg};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const Icon = styled.div<{rotate?:string}>`
  // transform: rotate(${({ rotate }) => (rotate ? "90deg" : "")});
`;

export const CustomRow = styled(Row)`
  border: 0.4px solid #B2B8BE;
  border-radius: 4px;
`;  

export const CustomBotton = styled(Button)`
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
   
    color: #112538;
    margin-left:${({ $mobile }: { $mobile: boolean }) => ($mobile ? '80px' : '0px')};

`

