import React, { memo, useEffect, useState } from "react";

import { ReactComponent as ArrowDown } from "assets/images/priorities/common/arrowdown.svg";
import { ReactComponent as Ascending } from "assets/images/priorities/common/ascending.svg";
import { ReactComponent as Descending } from "assets/images/priorities/common/descending.svg";
import { ReactComponent as FilterGoal } from "assets/images/priorities/common/filterGoal.svg";
import { ReactComponent as FilterBlack } from "assets/images/priorities/FilterBlack.svg";

import { ReactComponent as CrossIcon } from "assets/images/priorities/CrossIcon.svg";

import { Checkbox, Dropdown, Menu, Radio, Tabs } from "antd";
import { Checkbox as CheckboxElement, RadioButton } from "elements";

import {
  Title,
  Label,
  Heading,
  StyledMenuItemCheck,
  StyledMenuItem,
  StyledRadio,
  CustomAscending,
  CustomDescending,
  CustomCheckbox,
  CustomCheckIcon,
  ScrollBar,
  CustomButton,
  ResetButton,
  CustomMenu,
  MobileTitle,
  CustomLabel,
  CustomNumber,
  CustomCheckboxFilter,
  Background,
  FilterTrext,
  FilterIcon,
  CrossIcn,
  SortTitle,
  Arrows,
} from "./styled";
import useDeviceType from "../../../../../Hooks/ResponsiveHook";
import { TabsWrapper } from "../../styled";

const CustomDropdown = ({
  items,
  title,
  width,
  heading,
  icon,
  onChange,
  onOrderByChange,
  headingConcate,
  onFilterNameChange,
  filters,
  filterName,
  multiple
}) => {
  const [selectedValues, setSelectedValues] = useState(["all"]);
  const [orderby, setOrderBy] = useState("descending");
  const [background, setBackground] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const screenSize = useDeviceType();

  useEffect(() => {
    if (!multiple) {
      return;
    }

    const handleClickOutside = (event) => {
      const $dropdown = document.getElementsByClassName("ant-dropdown ant-dropdown-placement-bottomLeft");

      if (!($dropdown && $dropdown.length && $dropdown[0] && $dropdown[0].contains(event.target))) {
        if (onFilterNameChange)
          onFilterNameChange('');
      }
    };
  
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
  
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [multiple, onFilterNameChange]);

  const handleChange = (checkedValues) => {
    if (heading === "Criteria") {
      setSelectedValues(checkedValues.target.value);
    } else {
      if (checkedValues.length === 0) {
        setSelectedValues(["all"]);
      } else if (checkedValues[0] === "all" && checkedValues.length > 1) {
        setSelectedValues(checkedValues.slice(1));
      } else {
        const isAllInArray = checkedValues.includes("all");
        setSelectedValues(isAllInArray ? ["all"] : [...checkedValues]);
      }
    }
  };

  const handleSingleChange = (value) => () => {
    if (heading === "Criteria") {
      setSelectedValues([value]);
    } else {
      if (value === "all") {
        // If 'all' is selected and no other values are selected, toggle 'all'
        setSelectedValues(["all"]);
      } else {
        // Remove 'all' if it's currently selected
        let newValues = selectedValues.filter(val => val !== "all");
        
        // Check if the value is already in the array
        if (newValues.includes(value)) {
          // If it is, remove it
          newValues = newValues.filter(val => val !== value);
        } else {
          // If it's not, add the value
          newValues.push(value);
        }
        
        // Update the selected values
        setSelectedValues(newValues);
      }
    }
  };  
 
  const singleArrayJourney = Array.isArray(filters?.journeys)
    ? filters.journeys.map((journey) => journey.id)
    : [];

  const singleArrayAreas = Array.isArray(filters?.areas)
    ? filters.areas.map((area) => area.id)
    : [];

  const singlefilterByType = Array.isArray(filters?.filterByType)
    ? filters.filterByType.map((area) => area.id)
    : [];

  const displayCheckbox = function () {
    if (filterName === "filterByType") {
      return singlefilterByType.length > 0 ? singlefilterByType : ["all"];
    } else if (filterName === "journey") {
      return singleArrayJourney.length > 0 ? singleArrayJourney : ["all"];
    } else {
      return singleArrayAreas.length > 0 ? singleArrayAreas : ["all"];
    }
  }();

  useEffect(() => {
    if (typeof onChange === "function") { 
      const _items = items.filter((item) => selectedValues.includes(item.id));
      onChange(_items);
    }
  }, [selectedValues]);

  const handleReset = () => {
    setSelectedValues(["all"]);
  };

  const handleorderby = (value) => {
    setOrderBy(value);
    if (typeof onOrderByChange === "function") {
      onOrderByChange(value);
    }
  };

  const handleVisibleChange = (flag) => {
    setIsClicked(flag);
  };

  const noBorder = !(heading !== "Criteria" && (selectedValues.length !== 1 || selectedValues[0] !== "all"));

  const menu = (
    <CustomMenu
      $criteriaheight={heading == "Criteria"}
      $mobile={screenSize !== "desktop" && title == "Filter by"}
      $Sort={screenSize === "mobile" && heading == "Criteria"}
      $Filter={screenSize === "mobile" && title == "Filter by"}
    >
      {screenSize === "desktop" ? (
        <Heading $criteria={heading == "Criteria"}>{heading === 'filterByType'?'ACTIVITY TYPE':heading}</Heading>
      ) : (
        <>
          {headingConcate ? (
            <TabsWrapper $filterWraper $height={screenSize === "mobile"}>
              
              {screenSize === "mobile" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #CED0D4",
                  }}
                >
                  <div style={{ display: "flex", margin: "3px" }}>
                    <FilterIcon>
                      <FilterBlack />
                    </FilterIcon>

                    <FilterTrext>FILTER PRIORITIES</FilterTrext>
                  </div>
                  <CrossIcn
                    onClick={() => {
                      setBackground(false);
                    }}
                  >
                    <CrossIcon />
                  </CrossIcn>
                </div>
              )} 

              <Tabs
                defaultActiveKey="1"
                onChange={onFilterNameChange}
                items={headingConcate.map((ele, i) => {
                  const customValue =
                    ele.key === "journey"
                      ? singleArrayJourney.length
                      : singleArrayAreas.length;
                  return {
                    label: (
                      <CustomLabel>
                        <span>{ele.label}</span>
                        {customValue ? <div>{ele.icon(customValue)}</div> : ""}
                      </CustomLabel>
                    ),
                    key: ele.key,
                  };
                })}
              /> 
            </TabsWrapper>
          ) : (
            <>
              <Heading $criteria={heading == "Criteria"}>{heading === 'filterByType'?'ACTIVITY TYPE':heading}</Heading>
            </>
          )}
        </>
      )}

      <ScrollBar $Filter={screenSize === "mobile" && title == "Filter by"}>
        {heading !== "Criteria" && (
          <StyledMenuItemCheck
            $checked={selectedValues.includes("all")}
            onClick={handleSingleChange("all")}
          >
            <CheckboxElement
              value="all"
              checked={selectedValues.includes("all")}
            />
            All
          </StyledMenuItemCheck>
        )}

        {items.map((item) =>
          item?.name ? (
            <StyledMenuItemCheck
              key={item.id}
              $checked={heading === "Criteria" ? (selectedValues.includes(item.id) || selectedValues.includes("all") && item.id === "urgency_score") : displayCheckbox.includes(item.id)}
              $mobile={screenSize === "mobile"}
              onClick={handleSingleChange(item.id)}
            >
              {heading === "Criteria" ? (
                <>
                  <RadioButton
                    value={item.id}
                    checked={selectedValues.includes(item.id) || selectedValues.includes("all") && item.id === "urgency_score"}
                  />
                  {item.name}
                </>
              ) : (
                <>
                  <CheckboxElement
                    value={item.id}
                    checked={displayCheckbox.includes(item.id)}
                  />
                  {item.name}
                </>
              )}
            </StyledMenuItemCheck>
          ) : null
        )}
      </ScrollBar>
     
      <ResetButton noBorder={noBorder} />

      {heading !== "Criteria" && (selectedValues.length !== 1 || selectedValues[0] !== "all") && (
        <div style={{ padding: '4px 4px 0 4px'}}>
          <CustomButton key="reset" onClick={handleReset} type="link">Reset</CustomButton>
        </div>
      )}
      
      {heading === "Criteria" && (
        <div>
          <Heading $criteria>ORDER</Heading>
          <StyledMenuItem
            $selected={orderby === "ascending"}
            key={"ascending"}
            onClick={() => handleorderby("ascending")}
          >
            <Label $selected={orderby === "ascending"}>
              <span>
                <Ascending />
              </span>
              Ascending
            </Label>
            {orderby === "ascending" && (
              <CustomCheckIcon style={{ width: "20px" }} />
            )}
          </StyledMenuItem>
          <StyledMenuItem
            $selected={orderby !== "ascending"}
            key={"descending"}
            onClick={() => handleorderby("descending")}
          >
            <Label $selected={orderby !== "ascending"}>
              <span>
                <Descending />
              </span>
              Descending
            </Label>
            {(orderby === "descending" || orderby === undefined) && (
              <CustomCheckIcon style={{ width: "20px" }} />
            )}
          </StyledMenuItem>
        </div>
      )}
    </CustomMenu>
  );

  return (
    <>
      {heading === "Criteria" ? (
        <StyledRadio
          style={{ width: "100%", color: "black" }}
          onChange={handleChange}
          defaultValue="urgency_score"
        >
          {screenSize === "mobile" && (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              onVisibleChange={handleVisibleChange}
              open={background}
            >
              <MobileTitle
                $isClicked={isClicked}
                onClick={() => setBackground(true)}
              >
                {icon ? icon : ""}
                {title}
              </MobileTitle>
            </Dropdown>
          )}
         {screenSize === "iPad" && (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              onVisibleChange={handleVisibleChange}
            >
              <Title $width={width}>
                {icon ? icon : ""}
                {title}
                <ArrowDown />
              </Title>
            </Dropdown>
          )}
          {screenSize === "desktop" && (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              onVisibleChange={handleVisibleChange}
            >
              <Title $width={width}>
                {icon ? icon : ""}
                {title}
                <ArrowDown />
              </Title>
            </Dropdown>
          )}
        </StyledRadio>
      ) : (
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleChange}
          value={displayCheckbox}
        >
          {screenSize === "mobile" && (
            <Dropdown overlay={menu} trigger={["click"]} open={background}>
              <MobileTitle
                $isClicked={isClicked}
                onClick={() => setBackground(true)}
              >
                <FilterGoal />
                {icon ? icon : ""}

                {title}
              </MobileTitle>
            </Dropdown>
          )}
           {screenSize === "iPad" && (
            <Dropdown overlay={menu} trigger={["click"]} {...(multiple ? { open: filterName === heading } : {})}>
              <Title onClick={() => onFilterNameChange(heading)}>
                {icon ? { icon } : ""}
                {title}
                <ArrowDown />
              </Title>
            </Dropdown>
          )}
          {screenSize === "desktop" && (
            <Dropdown overlay={menu} trigger={["click"]} {...(multiple ? { open: filterName === heading } : {})}>
              <Title onClick={() => onFilterNameChange(heading)}>
                {icon ? { icon } : ""}
                {title}
                <ArrowDown />
              </Title>
            </Dropdown>
          )}
        </Checkbox.Group>
      )}

      {background && (
        <Background
          onClick={() => {
            setBackground(false);
          }}
          $active={background}
        />
      )}
    </>
  );
};

export default memo(CustomDropdown);
