import axios from "axios";
import { HAT_API_URL } from "config";

const onError = async (error) => {
  return Promise.reject(error);
};

const request = async (options) => {
  let baseURL = HAT_API_URL;

  const client = axios.create({
    baseURL
  });

  return client(options).catch(onError);
};

export class HatServices {
  static get = (url, isSecure = true) => {
    return request(
      {
        url,
        method: "GET",
      },
      isSecure
    );
  };
}
