import { Link } from "react-router-dom";
import styled from "styled-components";

export const Spacer = styled.div`
  height: 72px;
`;

export const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  padding: 6px 6px 6px 12px;
  border-radius: 27px;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.20);
  gap: 10px;
  flex-shrink: 0;
  position: absolute;
  bottom: calc(13px + 56px);
  right: 20px;
  transition: transform 0.3s;
  z-index: 999;

  :hover {
    transform: scale(1.04);
  }

  span {
    color: var(--primary-500-main, #1271A6);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-transform: capitalize;
  }

  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0;
    background: var(--primary-500-main, #1271A6);
  }

  ${({ theme }) => theme.max("sm")`
    padding: 6px;

    span {
      display: none;
    }
  `};
`;
