import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;

`;

export const Row = styled.div`
  display: flex;
  padding-bottom: 12px;
  gap: 16px;

  ${({ theme }) => theme.max('sm')`
    flex-wrap: wrap;
  `}

  ${({ $primary }) => $primary && css`
    border-bottom: 1px solid #EDEEF2;
    margin-bottom: 12px;
  `}

  ${({ $secondary }) => $secondary && css`
    align-items: baseline;
  `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 470px;
  width: 100%;

  ${({ $payment }) => $payment && css`
    max-width: 580px;
  `}

  ${({ theme }) => theme.max('md')`
    
  `}

  ${({ theme }) => theme.max('sm')`

  `}

  ${({ $box }) => $box && css`
    padding: 16px;
    border: 1px solid #EDEEF2;
    min-height: 392px;
  `}
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #EDEEF2;
  padding-bottom: 12px;
`;

export const Title = styled.span`
  color: #1B2A3D;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;

export const Text = styled.span`
  color: var(--davys-gray, #4B545B);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
`;

export const FindCoach = styled(Link)`
  padding: 6px 12px;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  color: #1271A6;
  background: var(--primary-50, #ECF3F6);

  &:hover {
    text-decoration: none;
  }
`;

export const Disclaimer = styled.div`
  color: #737373;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;

  ${({ theme }) => theme.max('sm')`
    text-align: left;
  `}
`;
