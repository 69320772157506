export const ActionTypes = {
  SELECTED_GOAL_ACTION: "SELECTED_GOAL_ACTION",
  SELECTED_GOAL_ACTION_ADD_ACTION: "SELECTED_GOAL_ACTION_ADD_ACTION",
  SELECTED_GOAL_ACTION_REMOVE_ACTION: "SELECTED_GOAL_ACTION_REMOVE_ACTION",
  TOGGLE_SIDEBAR_ACTION: "TOGGLE_SIDEBAR_ACTION",
  DRAWER_MENU_ACTION: "DRAWER_MENU_ACTION",
  TOGGLE_INFO_SIDEBAR_ACTION: 'TOGGLE_INFO_SIDEBAR_ACTION',
  IS_DISABLED: "IS_DISABLED",
  SELECTED_CLIENT: "SELECTED_CLIENT",
  IS_EDITING: "IS_EDITING",
  IS_EDITING_ID:"IS_EDITING_ID"
};
