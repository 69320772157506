import React, { useEffect, useState, useRef } from 'react';
import {
  Route,
  Switch,
  useParams,
  useLocation
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import DefaultAvatar from '../../../assets/images/DefaultAvatar.png';
import PlusIcon from '../../../assets/images/profile/plus.svg';

// import { setUserProfilePicture } from '../../../modules/actions/UserActions';

import * as Styled from './styled';
import { UpdateUserData } from 'Redux/UpdateUserData/action';
// import { changeUserProfile } from 'api';

// import { isEmpty } from 'lodash';

const ProfilePicture = ({ data }) => {
  const dispatch = useDispatch();
  const { display_picture } = data;

  const imageUploader = useRef(null);

  const onPressHandler = () => {
    imageUploader.current.click();
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const fileUploadHandler = async (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);

    let formData = new FormData();
    formData.append('profile_picture', file);

    dispatch(UpdateUserData(formData, data.public_id || data.id));
  };

  return (
    <Styled.ProfileImageWrapper onClick={onPressHandler}>
      <Styled.ProfileImage
        src={imagePreviewUrl || display_picture || DefaultAvatar}
        alt=""
      />

      <Styled.EditProfileIcon title="Change profile picture">
        <img src={PlusIcon} width={28} alt="" />
      </Styled.EditProfileIcon>

      <Styled.FileUploader
        ref={imageUploader}
        type="file"
        name="avatar[image]"
        accept="image/*"
        onChange={fileUploadHandler}
      />
    </Styled.ProfileImageWrapper>
  );
};

export default ProfilePicture;
