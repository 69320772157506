import { deleteUserArea } from "api";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { Error } from "Responses/Response";
import { getCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";

export const DeleteUserArea = (id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await deleteUserArea(id);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 204) {
      let MasterPlanId = getCookie("MasterPlanId");
      if (MasterPlanId)
        return dispatch(RetrieveMasterPlanDetails(MasterPlanId));
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
