import axios from "axios";

import { ROOT_API_URL } from "config";
import { removeAllCookie } from "utils/StorageVariables";
import { Error } from "Responses/Response";
import { getToken } from "utils/utility";

const onError = async (error) => {
  if (error.response) {
    // Request was made but server responded with something
    // other than 2xx
    // if (error.response.status === 403) {
    //   removeAllCookie();
    //   window.location.pathname = "/";
    // }
    if (error.response.status === 401) {
      try {
        removeAllCookie();
        // const email = getCookie("login_email");
        // const { status } = await requestOtp({ email });
        // if (status === 200) {
        //   window.location.pathname = "/verify-otp";
        // }
        window.location.pathname = "/";
      } catch (error) {
        Error(error?.response?.data?.detail);
      }

      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
};

const request = async (options, isSecure) => {
  let baseURL = ROOT_API_URL;
  const token = getToken();

  let headers = {};

  if (isSecure) {
    headers["Authorization"] = `Token ${token}`;
  }

  const client = axios.create({
    baseURL,
    headers: { ...headers }
  });

  return client(options).catch(onError);
};

export class BaseService {
  static get = (url, isSecure = true) => {
    return request(
      {
        url,
        method: "GET",
      },
      isSecure
    );
  };

  static post = (url, data, isSecure = true) => {
    return request(
      {
        url,
        method: "POST",
        data,
      },
      isSecure
    );
  };

  static put = (url, data, isSecure = true) => {
    return request(
      {
        url,
        method: "PUT",
        data,
      },
      isSecure
    );
  };
  static patch = (url, data, isSecure = true) => {
    return request(
      {
        url,
        method: "PATCH",
        data,
      },
      isSecure
    );
  };

  static remove = (url, isSecure = true) => {
    return request(
      {
        url,
        method: "DELETE",
      },
      isSecure
    );
  };
}
