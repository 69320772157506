import { ActionTypes } from "./types";

let INITIAL_STATE = {
  loading: false,
  error: null,
  plan_progress: null,
  actions_progress: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.GOAL_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.GOAL_SUCCESS:
      return {
        ...state,
        loading: false,
        plan_progress: action.payload,
      };

    case ActionTypes.GOAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case ActionTypes.ACTION_LOADING:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        actions_progress: action.payload,
      };

    case ActionTypes.ACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
