import React from "react";

import Mission from "components/Journeys/Mission";
import ScreenLayout from "components/Journeys/SceneLayout";

const MissionScreen = () => {
  return (
    <ScreenLayout>
      <Mission />
    </ScreenLayout>
  );
};

export default MissionScreen;
