import React, { useEffect, useState } from "react";
import styled from "styled-components";

import SubHeader from "components/Scheduler/SubHeader";
import TableSettingsHeader, {
  SIMPLE_VIEW_TABS,
} from "components/Scheduler/TableSettingsHeader";

import InflurnceChartModal from "components/PriorityApp/SimpleView/ReturnFactorModal";
import SimpleView from "components/PriorityApp/SimpleView";
import ClientsListHeader from "components/PriorityApp/ClientsListHeader";

import { useJoyride } from "../../utils/JoyrideContext";
import { useSelector } from "react-redux";

const Priorities = () => {
  const [activeTab, setActiveTab] = useState(SIMPLE_VIEW_TABS.All);
  const [isChartOpen, setIsChartOpen] = useState(false);
  const [ipadView, setIpadView] = useState(false);
  const [background, setBackground] = useState(false);

  const prioritizedActions = useSelector((state) => state.prioritizedActions);

  const { isAPIRequest } = useSelector((state) => state.prioritizedActions);
  const { isTaskRequest } = useSelector((state) => state.listTasks);

  const { userDetail: userData } = useSelector(
    (state) => state.userDetailReducer
  );

  const userJoinDate = new Date(userData?.date_joined);
  const comparisonDate = new Date("2024-09-01");

  const { success } = useSelector((state) => state.retrieveUserDataReducer);

  const { listFocusedPrioritise: originalFocusedItems } = useSelector(
    (state) => state.listFocusedPrioritise
  );

  const onTabChangeHandle = (key) => {
    setActiveTab(key);
  };

  const { startTour } = useJoyride();

  useEffect(() => {
    if (isAPIRequest && isTaskRequest) {
      if (userJoinDate < comparisonDate) return;
      const isSkipInclude =
        success?.tutorial_progress &&
        Object.values(success?.tutorial_progress)?.includes("Skipped");

      const isDone1 = success?.tutorial_progress?.step_1 === "Done";
      const isDone2 = success?.tutorial_progress?.step_2 === "Done";
      const isDone5 = success?.tutorial_progress?.step_5 === "Done";
      const isDone6 = success?.tutorial_progress?.step_6 === "Done";
      const isDone7 = success?.tutorial_progress?.step_7 === "Done";
      const isDone8 = success?.tutorial_progress?.step_8 === "Done";

      const tourStepsfirst = [
        {
          target: "#react-joyride-step1",
          content: (
            <div className="react-joyride-steps">
              <h3>Add New Priority</h3>
              <h6>Let’s start by adding a priority to your dashboard.</h6>
            </div>
          ),
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          showSkipButton: true, // Ensure Skip button is shown
          locale: { next: "Continue", last: "Continue", skip: "Skip" },
        },
        {
          target: "#react-joyride-step",
          content: (
            <div className="react-joyride-steps">
              <h3>Add New Priority</h3>
              <h6>Click here to add a new priority.</h6>
            </div>
          ),
        },
      ];

      const tourSteps = [
        {
          target: "#react-joyride-step2",
          content: (
            <div className="react-joyride-steps">
              <h3>View and Edit Activity Details</h3>
              <h6>
                Make changes to your prioritized habits, projects, goals or
                actions.
              </h6>
            </div>
          ),
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          showSkipButton: true, // Ensure Skip button is shown
          locale: { next: "Continue", last: "Continue", skip: "Skip" },
        },
        {
          target: "#react-joyride-step",
          content: (
            <div className="react-joyride-steps">
              <h3>Add New Priority</h3>
              <h6>Click here to add a new priority.</h6>
            </div>
          ),
        },
      ];

      const tourStepsfifth = [
        {
          target: "#react-joyride-step6",
          content: (
            <div className="react-joyride-steps">
              <h3>Prioritize Your Activities</h3>
              <h6>
                Click to edit and add new activities to your priority dashboard.
              </h6>
            </div>
          ),
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          showSkipButton: true, // Ensure Skip button is shown
          locale: { next: "Continue", last: "Continue", skip: "Skip" },
        },
        {
          target: "#react-joyride-step",
          content: (
            <div className="react-joyride-steps">
              <h3>Add New Priority</h3>
              <h6>Click here to add a new priority.</h6>
            </div>
          ),
        },
      ];

      const tourStepsSeven = [
        {
          target: "#react-joyride-step7",
          content: (
            <div className="react-joyride-steps">
              <h3>Schedule Your Activities</h3>
              <h6>
                Click the calendar icon to schedule an activity in your priority
                dashboard.
              </h6>
            </div>
          ),
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          locale: { next: "Continue" },
        },
        {
          target: "#react-joyride-step8",
          content: (
            <div className="react-joyride-steps">
              <h3>Complete Activity</h3>
              <h6>Check to complete this activity.</h6>
            </div>
          ),
          disableBeacon: true,
          disableCloseOnEsc: true,
          disableOverlayClose: true,
          locale: { next: "Continue" },
        },
        {
          target: "#react-joyride-step",
          content: (
            <div className="react-joyride-steps">
              <h3>Add New Priority</h3>
              <h6>Click here to add a new priority.</h6>
            </div>
          ),
        },
      ];

      if (!isSkipInclude && !isDone1 && !isDone2) {
        setTimeout(() => {
          startTour(tourStepsfirst);
        }, 1000);
        // startTour(tourStepsfirst);
      }

      if (!isSkipInclude && !isDone2 && isDone1) {
        startTour(tourSteps);
      }

      if (!isSkipInclude && isDone1 && isDone5 && !isDone6) {
        startTour(tourStepsfifth);
      }

      if (
        !isSkipInclude &&
        isDone1 &&
        isDone6 &&
        originalFocusedItems?.length > 0 &&
        !isDone7 &&
        !isDone8
      ) {
        startTour(tourStepsSeven);
      }
    }
  }, [
    success,
    prioritizedActions,
    originalFocusedItems,
    isAPIRequest,
    isTaskRequest,
  ]);

  return (
    <MainContainer>
      <InnerContainer>
        <ClientsListHeader />

        <SubHeader
          title={"Priority Dashboard"}
          // isProView={isProView}
          setIsChartOpen={setIsChartOpen}
          isChartOpen={isChartOpen}
          // onViewChange={() => setIsProView(!isProView)}
          setIpadView={setIpadView}
          ipadView={ipadView}
          setBackground={setBackground}
        />

        {/* {screenSize === "iPad" && (
          <Progres>
            <ProgressBar />
            <Targt>
              <Target onClick={() => {
                setIpadView(true);
                setBackground(true);
              }} />
            </Targt>
          </Progres>
        )} */}

        <TableSettingsHeader
          isProView={false}
          tab={activeTab}
          onTabChange={onTabChangeHandle}
        />

        <SimpleView
          isFocusedOnly={activeTab === SIMPLE_VIEW_TABS.Focused}
          isViewOnly={activeTab === SIMPLE_VIEW_TABS.All}
          // isProView={isProView}
          ipadView={ipadView}
          setIpadView={setIpadView}
          setBackground={setBackground}
        />

        {/* {isProView ? (
          <DataTable />
        ) : (
          <SimpleView
            isFocusedOnly={activeTab === SIMPLE_VIEW_TABS.Focused}
            isViewOnly={activeTab === SIMPLE_VIEW_TABS.All}
            isProView={isProView}
            ipadView={ipadView}
            setIpadView={setIpadView}
            setBackground={setBackground}
          />
        )} */}
      </InnerContainer>
      {isChartOpen && (
        <InflurnceChartModal
          setIsChartOpen={setIsChartOpen}
          isChartOpen={isChartOpen}
        />
      )}
      {background && (
        <Background
          onClick={() => {
            setBackground(false);
            setIpadView(false);
          }}
          $active={background}
        />
      )}
    </MainContainer>
  );
};

export default Priorities;

const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px - 50px);
`;

const InnerContainer = styled.div`
  background-color: #eeeff5;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Progres = styled.div`
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 63px;
  display: flex;
`;

const Targt = styled.div`
  margin-left: 15px;
  cursor: pointer;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(79, 89, 102, 0.5);
  top: 0;
  left: 0;
  z-index: 8;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0.25s;

  ${({ $active }) =>
    $active &&
    `
    opacity: 1;
    visibility: visible;
    z-index: 9999999;
  `};
`;
