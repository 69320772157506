import React from "react";

import Measurement from "components/Journeys/Measurement";
import ScreenLayout from "components/Journeys/SceneLayout";

const MeasurementScreen = () => {
  return (
    <ScreenLayout>
      <Measurement />
    </ScreenLayout>
  );
};

export default MeasurementScreen;
