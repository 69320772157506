import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './styled';

import CreateAccount from '../../../components/Auth/CreateAccount';
import SignUp from '../../../components/Auth/SignUp';
import Layout from '../../../components/Auth/Layout';
import LegalTerms from '../../../components/Auth/LegalTerms';
import { SIGN_UP_PATH } from 'utils/routes';
import VerificationMain from "../../../components/Auth/Verification"

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasAcceptedTerms = location.state ? location.state?.hasAcceptedTerms : false;

  const queryParams = new URLSearchParams(location.search);
  const step = queryParams.get('step') || '1';

  const [animate, setAnimate] = useState(false);
  const [animation, setAnimation] = useState(false);

  const onLegalTermsHandle = () => {
    navigate(`${SIGN_UP_PATH}?step=2`, { state: { hasAcceptedTerms: true } });
  }

  const onSignUpStepHandle = () => {
    navigate(`${SIGN_UP_PATH}?step=3`, { state: { hasAcceptedTerms } });
  }

  const onOtpVerifyStepHandle = () => {
    navigate(`${SIGN_UP_PATH}?step=4`, { state: { hasAcceptedTerms } });
  }

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 100);
  }, [step]); 

  // No cheatin', user
  useEffect(() => {
    if ((step === '2' || step === '3') && !hasAcceptedTerms) {
      navigate(`${SIGN_UP_PATH}?step=1`);
    }
  }, [step, hasAcceptedTerms]);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []); 

  return (
    <Layout bg={step === '1' ? 'terms' : 'register'}>
      <>
        {step !== '2' && (
          <Styled.BackgroundWrapper>
            <Styled.Background $animate={animate} $animateDuration={500}>
              <Styled.Circle $animate={false} />
            </Styled.Background>
          </Styled.BackgroundWrapper>
        )}

        <LegalTerms active={step === '1'} onNext={onLegalTermsHandle} />
        <CreateAccount active={step === '2'} animate={animation} onNext={onSignUpStepHandle} />
        <SignUp active={step === '3'}  onNext={onOtpVerifyStepHandle} />
        <VerificationMain active={step==='4'}/>
      </>
    </Layout>
  );
}

export default Register;
