import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ClientsSelect from './ClientsSelect';

import {
  Wrapper,
  Content,
  DropdownGroup,
  Title,
  Col,
  Link
} from './styled';

import { IClient } from 'scenes/Coaching/UserRow';

// @ts-ignore
import { setClient } from "../../../Redux/APM/PrioritizedActions/action";

import { APM_PATH, COACHING_PATH } from 'utils/routes';

// @ts-ignore
import arrowLeftIcon from "assets/images/priorities/arrow-left.svg";

interface State {
  getMyClientListReducer: {
    success?: IClient[];
    loading: boolean;
  };
  retrieveUserDataReducer: {
    success?: {
      type: string;
    } 
  }
}

const ClientsListHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  // @ts-ignore
  const { success: data, loading } = useSelector((state: State) => state.getMyClientListReducer);

  // @ts-ignore
  const { success: userData } = useSelector((state: State) => state.retrieveUserDataReducer);

  useEffect(() => {
    dispatch(setClient(id));
  }, [id]);

  useEffect(() => {
    if (userData?.type === 'Coach')
      return;

    dispatch(setClient(null));
    navigate(APM_PATH);
  }, [userData?.type]);

  const handleChange = (value: string) => {
    navigate(`${APM_PATH}${COACHING_PATH}/${value}`);
  }

  if (!id || userData?.type !== 'Coach')
    return null;

  return (
    <Wrapper>
      <Content>
        <Col>
          <Link to={`${APM_PATH}${COACHING_PATH}`}>
            <img src={arrowLeftIcon} alt="" />
            All Clients
          </Link>
        </Col>

        <DropdownGroup>
          <Title>View Priorities For</Title>
          <ClientsSelect 
            value={id}
            options={data || []}
            onChange={handleChange}
          />
        </DropdownGroup>

        <Col />
      </Content>
    </Wrapper>
  );
}

export default ClientsListHeader;
