import { useState, useRef, useEffect, FC } from 'react';
import { useReactToPrint } from "react-to-print";
import moment from 'moment';

import MissionItem from './MissionItem';

// @ts-ignore
import { getCookie } from "utils/StorageVariables";

import {
  IconButton,

  Wrapper,

  Header,
  HeaderItem,
  Logo,
  Span,
  Heading,
  HeaderItemRight,
  HeaderTitle,
  HeaderAuthor,

  Body,
  Journey,
  Title,
  Text,
  List,

  Footer,
  Copy
} from './styled';

// @ts-ignore
import logo from "assets/images/header_logo_modern.svg";

// @ts-ignore
import printIcon from "assets/images/print-dark.svg";

import { IMissionStatement } from 'Redux/ListMissionStatements/types';

const pageStyles = () => {
  return `
    @page { size: portrait; }
    @page { margin-top: 32px; margin-bottom: 0; }
    @page :first { margin-top: 0; margin-bottom: 50px; }
  `;
};

interface IProps {
  data: IMissionStatement[];
  journeyData?: string;
}

const Print: FC<IProps> = ({ data, journeyData }) => {
  const [isPrint, setIsPrint] = useState(false);
  const $componentRef = useRef(null);

  const domainName = getCookie("domainName");
  const userData = JSON.parse(getCookie("userData"))

  const handlePrint = useReactToPrint({
    documentTitle: "Master Mission Statement",
    onAfterPrint: () => setIsPrint(false),
    content: () => $componentRef.current,
  });

  useEffect(() => {
    if (isPrint) {
      handlePrint();
    }
  }, [isPrint]);

  const handleClick = () => {
    setIsPrint(true);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <img src={printIcon} alt="Print" />
      </IconButton>

      <Wrapper ref={$componentRef}>
        <style>{pageStyles()}</style>

        <Header>
          <HeaderItem>
            <Logo src={logo} alt="Goal Manager" />
            <Span />
            <Heading>Journey: {domainName}</Heading>
          </HeaderItem>
          <HeaderItemRight>
            <HeaderTitle>Master Mission Statement</HeaderTitle>
            <HeaderAuthor>{userData?.name}</HeaderAuthor>
          </HeaderItemRight>
        </Header>

        <Body>
          {journeyData ? <Journey>
            <Title>Journey Mission Statement</Title>
            <Text>{journeyData}</Text>
          </Journey> : null}

          <Title>Goal Mission Statements</Title>
          <List>
            {data.map((item, index) => <MissionItem key={index} data={item} />)}
          </List>
        </Body>

        <Footer>
          <Copy>Copyright &copy; Arootah {moment().year()}. All Rights Reserved</Copy>
        </Footer>
      </Wrapper>
    </>
  );
}

export default Print;
