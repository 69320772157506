import { FC, useEffect, useState } from 'react';
import { Button, DatePicker } from 'elements';
import { useDispatch } from 'react-redux';
import moment from "moment";

import {
  Wrap,
  Background,
  Wrapper,
  Head,
  HeadCol,
  Title,
  Text,
  Form,
  FormGroup,
  Label,
  Footer,
} from './styled';
import { CloseBtn } from '../styled';

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as TargetIcon } from "assets/images/priorities/target-blue.svg";

import { updateAction } from "Redux/APM/CreateAction/action";

const dateFormat = "YYYY/MM/DD";

interface IFormProps {
  isOpen: boolean;
  id: string;
  data: IGoal;
  onClose: () => void;
}

interface IGoal {
  id: string;
  color: string;
  gap_percentage: number | null;
  goal_name: string;
  category_name: string;
  startline: string | null;
  deadline: string | null;
}

const FormPopup: FC<IFormProps> = ({ isOpen, id, data, onClose }) => {
  const dispatch = useDispatch();
  
  const { startline: iStartline, deadline: iDeadline } = data;

  const [startline, setStartline] = useState(iStartline);
  const [deadline, setDeadline] = useState(iDeadline);

  useEffect(() => {
    setStartline(iStartline);
    setDeadline(iDeadline);
  }, [id, iStartline, iDeadline]);

  const handleDateChange = (type: string) => (date: any) => {
    if (type === "startline") {
      setStartline(date ? date.format('YYYY-MM-DD') : null);
    } else if (type === "deadline") {
      setDeadline(date ? date.format('YYYY-MM-DD') : null);
    }
  }

  const handleSave = () => {
    const formData = new FormData();

    formData.append("prioritized_activity_id", id);

    if (startline)
      formData.append("goal_startline", startline);

    if (deadline)
      formData.append("goal_deadline", deadline);

    dispatch(updateAction(formData, onClose, { notify: false, loader: false }));
  }

  if (!isOpen) return null;
  
  return (
    <>
      <Wrapper $active={isOpen}>
        <Head>
          <HeadCol>
            <TargetIcon />
            <Title>Goal</Title>
          </HeadCol>

          <CloseBtn title="Close" onClick={onClose}>
            <CloseIcon />
          </CloseBtn>
        </Head>

        <Text>Get 4-5 Full Sleep Cycles which should be approximately 7-9 hours of Sleep Per Night.</Text>

        <Form>
          <FormGroup>
            <Label>Startline</Label>
            <DatePicker
              value={startline ? moment(startline) : null}
              format={dateFormat}
              placeholder="Set date"
              customSize="xs"
              inputReadOnly
              disabledDate={
                (current: any) => (
                  !current || current > moment().endOf('day')
                )
              }
              onChange={handleDateChange('startline')}
              allowClear={true}
            />
          </FormGroup>

          <FormGroup>
            <Label>Deadline*</Label>
            <DatePicker
              customSize="xs"
              value={deadline ? moment(deadline) : null}
              format={dateFormat}
              placeholder="Set date"
              inputReadOnly
              disabledDate={
                (current: any) => {
                  if (!current) return false;
                  const today = moment().startOf('day');
                  if (!startline) {
                    return current < today;
                  }
                  const startlineDate = moment(startline).startOf('day');
                  return current <= startlineDate || current < today;
                }
              }
              onChange={handleDateChange('deadline')}
              allowClear={false}
            />
          </FormGroup>
        </Form>

        <Footer>
          <Button styleType="link-primary" onClick={onClose}>Cancel</Button>
          <Button disabled={!deadline} styleType="primary" onClick={handleSave}>Save</Button>
        </Footer>
      </Wrapper>

      <Background
        $active={isOpen}
        onClick={onClose}
      />
    </>
  );
}

interface IProps {
  id: string;
  data: IGoal;
}

const UpdateGoalDates: FC<IProps> = ({ id, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrap>
      <FormPopup
        isOpen={isOpen}
        id={id}
        data={data}
        onClose={() => setIsOpen(false)}
      />

      <Button styleType="link-primary" onClick={() => setIsOpen(true)}>Update Goal Deadline</Button>
    </Wrap>
  );
}

export default UpdateGoalDates;
