import Notify from "utils/notify";
import { updateFocusedActivityPriorities } from "../apm-apis";
import { ActionTypes } from "./type";
import { listFocusedPrioritise } from "../ListFocusedPriorities/action";

export const updateFocusedPriorities = (payload, callback) => async (dispatch) => {
    try {
      dispatch({ type: ActionTypes.UPDATE_UPDATE_FOCUS_PRIORITIES });
  
      const { data } = await updateFocusedActivityPriorities(payload);
      dispatch(listFocusedPrioritise());
      dispatch({
        type: ActionTypes.UPDATE_UPDATE_FOCUS_PRIORITIES_SUCCESS,
        payload: data,
      });

    } catch (error) {
      Notify.error(error, true);
      dispatch({
        type: ActionTypes.UPDATE_UPDATE_FOCUS_PRIORITIES_FAILURE,
        payload: error,
      });
    }
  };