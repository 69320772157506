import { Spin } from "antd";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { HOME_PATH } from "utils/routes";

import { useToken } from 'utils/utility';
import { JOURNEY_PATH } from "utils/routes";

const Protected = ({ children }) => {
  const { token, success, loading } = useToken();

  if (loading || !success)
    return <StyledSpin />

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const Guest = ({ children }) => {
  const { token, success, loading } = useToken();

  if (loading || !success)
    return <StyledSpin />;

  if (token)
    return <Navigate to={JOURNEY_PATH} replace />;

  return children;
};

export { Protected, Guest };

const StyledSpin = styled(Spin)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
