import styled, { css } from 'styled-components';

export const MainWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 46px;
  min-height: 46px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #c1cad1;
  position: sticky;
  top: 0;
  background-color: #fff;

  @media screen and (max-width: 1024px) {
    border-bottom: 0;
    padding: 0;
  }

  @media screen and (max-width: 1024px) {
    ${({ $height }) => $height && css`
    height: ${$height}px;
  `}
  }

`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: inherit;
  flex-direction : row;

  @media screen and (max-width: 1024px) {
    flex-direction : column;
  }
`;
export const HeaderTitleMobile = styled.div`
  width: 100%;
  height: 45px;
  border-bottom: 1px solid lightgray; 
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (min-width: 768px) and (max-width: 1256px) {
    
  }

`;
export const Heading = styled.h2`
  text-transform: uppercase;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #1271a6;
  margin: 0;
  padding: 0;
  user-select: none;

  @media screen and (max-width: 1024px) {
    margin-top: 15px;
    width: calc(100% - 60px);
    margin-left: 15px;
  }
`;

export const ActionItemContainer = styled.div``;

export const PointWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Greetings = styled.p`
  color: #2f3238;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 0;
`;

/* export const PointInputHeading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: #6d7f8e;
  margin: 0;
  margin-right: 0.8rem;
`;

export const PointInput = styled.input`
  width: 72px;
  height: 24px;
  border: 0.4px solid #b2b8be;
  padding: 10px;
  color: #6d7f8e;
  background-color: ${({ theme }) => theme?.background?.light};
`; */

export const ThemeButtoncontainer = styled.div`
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  align-self: center;
`;

export const EmbededContent = styled.div`
  flex: 1;
  width: 100%;
  @media screen and (max-width: 1024px) {
    margin-top: 25px;
  }
`;

export const InfoToggle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  padding: 0;
`;

export const Button = styled.button`
  border: none;
  outline: none;
  margin-right: 0.5rem;
  background-color: transparent;
  position: relative;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;

  > span {
    color: var(--subheading, #4c545b);
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
  }
`;

/* export const IsProSwitchContainer = styled.div`
  padding: 10px;
  margin: 0 10px;
`; */

/* export const SwitchWrapper = styled.span`
  font-size: 0.75rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #4c545b;

  & .ant-switch-small {
    margin-left: 10px;
    background-color: #c3c3c3;
  }

  & .ant-switch-checked {
    background-color: #1371a6;
  }
`; */

export const Progress = styled.span`
  margin-top: 25px;
`;

export const TargetIcon = styled.span`
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
  }
`;
