import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 8px 16px;
`;

export const Title = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
`;

export const Body = styled.div`
  background-color: #ECEEF1;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  gap: 3px;
  overflow-y: auto;
  border-top: 4px solid #ECEEF1;
  border-bottom: 4px solid #ECEEF1;
`;

export const Label = styled.span`
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
