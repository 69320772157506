import React from "react";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import * as Styles from "./styled.js";

const ActionItem = ({ action, actionIndex, selectedGoalAction}) => {
  return (
    <Styles.ContentOne
      $color={selectedGoalAction?.color}
      // $isHighlighted={(actionIndex === 0 || actionIndex === 1) && !action?.completed}
    >
      <Styles.ActionRow $isFirst={actionIndex === 0}>
        <Styles.ActionsWrapper $first>
          <Styles.ActionCol $flex>
            <Styles.ActionContainer>
              <Styles.ActionHeading>{actionIndex + 1}</Styles.ActionHeading>

              <Styles.Label $mobile>6. Fill Actions</Styles.Label>

              <Styles.InputField>
                <Styles.ActionName>{action?.context}</Styles.ActionName>
              </Styles.InputField>
            </Styles.ActionContainer>
          </Styles.ActionCol>

          <Styles.ActionCol $dnd $width="80px" />
        </Styles.ActionsWrapper>

        <Styles.ActionsWrapper $action>
          <Styles.ActionCol $ml={-10} $startline $width="7vw">
            <Styles.Label $mobile>8. Startline</Styles.Label>
            <Styles.InputField className="datepicker-wrapper">
              <Styles.ActionName>{action.startline ? moment(action.startline).format('YYYY-MM-DD') : null}</Styles.ActionName>
            </Styles.InputField>
          </Styles.ActionCol>

          <Styles.ActionCol $ml={-40} $date $width="7vw">
            <Styles.Label $mobile>9. Deadline</Styles.Label>
            <Styles.InputField className="datepicker-wrapper">
              <Styles.ActionName>{action?.deadline ? moment(action?.deadline).format('YYYY-MM-DD') : null}</Styles.ActionName>
            </Styles.InputField>
          </Styles.ActionCol>
        </Styles.ActionsWrapper>

        <Styles.ActionsWrapper style={{ width: 'calc(14% + 20px)' }}>
          <Styles.ActionCol $accountability $flex>
            <Styles.Label $mobile>10. Accountability</Styles.Label>
            <Styles.MainWrapper>
              <Styles.ActionName>{action?.accountability || ' – '}</Styles.ActionName>
            </Styles.MainWrapper>
          </Styles.ActionCol>
        </Styles.ActionsWrapper>

        <Styles.ActionCol $width="120px" style={{ marginRight: 20 }}>
          <Styles.Label $mobile>Habit</Styles.Label>
          <Styles.Checkdiv>
            <Styles.ActionName>{action?.type ? (action.type === 'Action Item' ? 'Action' : action.type) : " – "}</Styles.ActionName>
          </Styles.Checkdiv>
        </Styles.ActionCol>

        <Styles.ActionCol $options />
      </Styles.ActionRow>
    </Styles.ContentOne>
  );
};

export default ActionItem;
