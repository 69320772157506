import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px - 46px);
  background: var(--primary-50, #ECF3F6);

  ${({ $secondary }) => $secondary && `
    height: initial;
  `}
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--primary-50, #ecf3f6);
`;

export const Dashboard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  gap: 18px;
`;
