import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  width: ${({ $mobile }: { $mobile: boolean }) => ($mobile ? "343px" : "380px")};
  padding: 6px 7px;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
  padding: 4px;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-family: 'Inter';

  svg {
    margin-right: 4px;
    width: 20px;
    transform: rotate(0.1deg);
  }

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #112538;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 6px 10px 9px 24px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: #858E99;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  margin-bottom: 5px;
`;

export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const Text = styled.span`
  color: #FFB800;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
`;

export const ListTitle = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #737373;
`;

export const DateRow = styled.div`
  display:flex; 
  align-items:center;
  justify-content:center; 
  margin-bottom:20px; 
  background:#F5F5F5; 
  border-radius: 6px;
  padding: 4px 4px 4px 8px;
`;
