import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  flex: 1;
  overflow: hidden;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

export const Title = styled.span`
  color: var(--Oxford-blue, #06203A);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`;

export const Body = styled.div`
  overflow-y: auto;
  flex: 1;
  padding-right: 6px;

  ::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #acb4bc;
    border-radius: 100px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
