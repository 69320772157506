import AddEventDetailModal from "components/Scheduler/AddEventDetailModal";
import AddNoteModal from "components/Scheduler/AddNoteModal/AddNoteModal";
import Schedular from "components/Scheduler/Calendar";
import QuadrantSummaryChart from "components/Scheduler/QuadrantSummaryChart";
import SettingModal from "components/Scheduler/SettingModal";
import SubHeader from "components/Scheduler/SubHeader";
import SyncModal from "components/Scheduler/SyncModal";
import TimelineModal from "components/Scheduler/TimelineModal";
import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import SchedulerPriorityNav from "components/Scheduler/SchedulerPriorityNav";
import Calendertimeline from "components/Scheduler/CalenderTimeline";

const Scheduler = () => {
  const [isSettingModal, setIsSettingModal] = useState(false);
  const [isSyncOpen, setIsSyncOpen] = useState(false);
  const [isOpenTimeline, setIsOpenTimeline] = useState(false);
  const [isOpenQuadrantModal, setIsOpenQuadrantModal] = useState(false);
  const [Add, setAdd] = useState(false);
  const [saveNote, setSaveNote] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpenQuadrantModal(!isOpenQuadrantModal);
  };
  const [draggedEvent, setDraggedEvent] = useState(null);

  const [dummyAppointment, setDummyAppointment] = useState([
    {
      id: 3,
      status: "CI",
      location: "Connecticut",
      resource: "Alex Hales",
      address: "1241 E Main St\n Stamford\n CT 06902\n United States",
      title: " Alex Hales",
    },
    {
      id: 4,
      status: "CID",
      location: "Uk",
      resource: "James chadwick",
      address: "1241 E Main St\n Stamford\n CT 06902\n United States",
      title: " James chadwick",
    },
    {
      id: 5,
      status: "CID",
      location: "Spain",
      resource: "Jordon",
      address: "1241 E Main St\n Stamford\n CT 06902\n Canada",
      title: "Jordon",
    },
    {
      id: 6,
      status: "CID",
      location: "Belguim",
      resource: "Elena",
      address: "1241 E Main St\n Stamford\n CT 06902\n Uganda",
      title: " Elena",
    },
  ]);


  return (
    <>
      <SubHeader title={"SCHEDULER"} onClick={handleOpenModal} setIsOpenQuadrantModal={setIsOpenQuadrantModal}
        isOpenQuadrantModal={isOpenQuadrantModal} />

      <MainContainer>
        <div>
          <SchedulerPriorityNav
            dummyAppointment={dummyAppointment}
            setDraggedEvent={setDraggedEvent}
            draggedEvent={draggedEvent}
            setDummyAppointment={setDummyAppointment}
            isSettingModal={isSettingModal}
            setIsSettingModal={setIsSettingModal}
          />
        </div>

        <TimelineContainer>
          <Calendertimeline isOpenTimeline={isOpenTimeline}
            setIsOpenTimeline={setIsOpenTimeline} />
        </TimelineContainer>

        <InnerContainer>
          <SchedulerWrapper>
            <Schedular
              draggedEvent={draggedEvent}
              setDraggedEvent={setDraggedEvent}
              dummyAppointment={dummyAppointment}
              setIsSyncOpen={setIsSyncOpen}
              isSyncOpen={isSyncOpen}
              setIsOpenTimeline={setIsOpenTimeline}
              isOpenTimeline={isOpenTimeline}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              Add={Add}
              setAdd={setAdd}
              saveNote={saveNote}
              setSaveNote={setSaveNote}
            />

            {isSettingModal && (
              <SettingModal
                isSettingModal={isSettingModal}
                setIsSettingModal={setIsSettingModal}
              />
            )}

            {isModalOpen && (
              <AddEventDetailModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            )}
            {Add && (
              <AddNoteModal
                Add={Add}
                setAdd={setAdd}
                saveNote={saveNote}
                setSaveNote={setSaveNote}
              />
            )}

            {(isSyncOpen || isOpenTimeline) && (
              <ModalWrapper>
                {isSyncOpen && (
                  <SyncModal
                    setIsSyncOpen={setIsSyncOpen}
                    isSyncOpen={isSyncOpen}
                  />
                )}
                {isOpenTimeline && (
                  <TimelineModal
                    setIsOpenTimeline={setIsOpenTimeline}
                    isOpenTimeline={isOpenTimeline}
                  />
                )}
              </ModalWrapper>
            )}
          </SchedulerWrapper>
          {isOpenQuadrantModal && (
            <QuadrantModalWrapper>
              <QuadrantSummaryChart
                isOpenQuadrantModal={isOpenQuadrantModal}
                setIsOpenQuadrantModal={setIsOpenQuadrantModal}
              />
            </QuadrantModalWrapper>
          )}
        </InnerContainer>
      </MainContainer>
    </>
  );
};

export default Scheduler;
const MainContainer = styled.div`
  display: flex;
  position: relative;
  
  
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* position: relative; */
  /* border: 1px solid red; */
`;

const SchedulerWrapper = styled.div`
  height: calc(100vh - 80px - 50px - 67px);
  overflow-y: auto;
  /* position: relative; */
  flex: 1;
  overflow: auto;
`;
const ModalWrapper = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  position: absolute;
  /* top: 0;
  left: 0; */
   top: 0px;
  left: 274.5px;
  z-index: 9999; 
  
`;

const QuadrantModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
`;

const TimelineContainer = styled.div`
  flex: none;
  position: sticky;
  /* position: relative; */
  left: 0;
  top: 0;
  z-index: 1; /* Ensure the timeline appears above the scrolling content */
  /* border: 1px solid purple; */
`;
