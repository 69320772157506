import styled from "styled-components";
import React from "react";
import { Collapse } from "antd";
import bgHero from "assets/images/workshop_hero.png";
import bgHeroMob from "assets/images/workshop_hero_mobile.png";
import { useNavigate } from "react-router-dom";

import arrowRight from "assets/images/arrowRight.svg";

import LegalModal from "components/LegalModal";
import Footer from "components/Footer";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 80px - 67px);
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  background-image: url(${bgHero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 208px;

  @media screen and (max-width: 540px) {
    background-image: url(${bgHeroMob});
  }
`;

const Body = styled.div`
  flex: 1;
`;

const Main = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Heading = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;

  @media screen and (max-width: 540px) {
    width: 90%;
    font-size: 20px;
    text-align: center;
  }
`;

const Description = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #06203a;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media screen and (max-width: 1150px) {
    font-size: 18px;
    white-space: pre-wrap;
  }
  @media screen and (max-width: 540px) {
    font-size: 20px;
  }
`;

const Info = styled.p`
  font-family: "Inter";
  font-style: normal;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #06203a;
  padding-bottom: 18px;
  padding-top: 6px;
  margin: 0;
  @media screen and (max-width: 1150px) {
    font-size: 14px;
  }
  @media screen and (max-width: 540px) {
    font-size: 16px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
  margin-bottom: 1.5rem;
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const ContentListContainer = styled.div`
  max-width: 1440px;
  display: flex;
  margin: auto;
`;

const Button = styled.button`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
  padding: 11px 18px;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  transition: background 0.3s;

  &:hover {
    background: linear-gradient(-161.19deg, #1263de 18.35%, #448fff 74.45%);
  }
`;

const AreaLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1rem;
  margin: auto;

  .ant-collapse-item {
    &.ant-collapse-item-active .ant-collapse-header {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
`;

const CollapseArrow = styled(Collapse)`
  background-color: transparent !important;
  width: 100%;

  & .ant-collapse-header {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: row-reverse !important;
    user-select: none;

    &::before {
      content: "";
      width: 22px;
      height: 22px;
      margin-left: 12px;
      background-image: url("${arrowRight}");
      background-position: center;
      position: relative;
      top: 2px;
      transition: transform 0.3s;
    }

    & .ant-collapse-expand-icon {
      display: none !important;
    }

    & .ant-collapse-header-text {
      flex: initial !important;
    }
  }

  & .ant-collapse-content-box {
    padding-top: 0.8rem !important;
  }
`;

const Legal = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const onModalSubmitHandle = () => {
    navigate("/choose-workshop", { replace: true });
  };

  return (
    <>
      <Wrapper>
        <Content>
          <Main>
            <Title>
              <Heading>Welcome! Please select your journey.</Heading>
            </Title>
          </Main>
        </Content>

        <Body>
          <LegalModal
            isEmbed={true}
            isActive={true}
            loading={false}
            onSubmit={onModalSubmitHandle}
          />
        </Body>
      </Wrapper>

      <Footer />
    </>
  );
};

export default Legal;
