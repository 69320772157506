import { IFocusedExtreme } from "../ListFocusedExtremes/type";

export const ActionTypes = {
  REQUEST: "FETCH_PENDING_PRIORITISE_REQUEST",
  SUCCESS: "FETCH_PENDING_PRIORITISE_SUCCESS",
  FAILURE: "FETCH_PENDING_PRIORITISE_FAILURE"
};

export interface ListPendingPrioritiesState {
  data: IFocusedExtreme[];
  loading: boolean;
  duration: number;
}
