import React from "react";

import Actions from "components/Journeys/Actions";
import ScreenLayout from "components/Journeys/SceneLayout";

const ActionsScreen = () => {
  return (
    <ScreenLayout>
      <Actions />
    </ScreenLayout>
  );
};

export default ActionsScreen;
