import { FC, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Tooltip } from 'elements';
import moment from 'moment';

// @ts-ignore
import chevronDownIcon from "assets/images/priorities/chevron-down.svg";

import RadialBar from "components/PriorityApp/SimpleView/RadialBar";

// @ts-ignore
import SimpleViewItem from "../SimpleViewItem";

import {
  Body,
  Header,
  Order,
  Head,
  HeaderText,
  AreaName,
  CategoryName,
  Title,
  Date,
  Wrapper,
  HeaderCol,
  HeadCol,
  // Quadrant,
  Footer,
  Count,
  Toggle
} from './styled';

interface IAction {
  id: string;
}

interface IGoal {
  id: string;
  gap_percentage: number | null;
  goal_name: string;
  category_name: string;
  startline: string | null;
  deadline: string | null;
}

interface IArea {
  id: string;
  color: string;
  name: string;
}

interface IGroup {
  id: string;
  actions: IAction[];
  goal: IGoal;
  user_area: IArea;
  quadrant: string;
  user: string;
}

interface IProps {
  id?: string;
  index: number;
  data: IGroup;
  onClick?: (id: string) => void;
  onEdit?: (id: string) => void;
  setVisible?: (id: boolean) => void;
  visible?: boolean;
}

const SimpleViewGroup: FC<IProps> = ({ index, data, visible, setVisible, onClick, onEdit }) => {
  const { goal, actions, user_area } = data;

  const [isActive, setIsActive] = useState(false);

  const goalProgress = goal.gap_percentage
    ? Math.ceil(Math.abs(100 - goal.gap_percentage))
    : 0;

  // const quadrant = data.quadrant;
  const topActions = actions.slice(0, 2);
  const goalDeadline = goal.deadline ? moment(goal.deadline).format("MM/DD/YYYY") : null;

  return (
    <Wrapper>
      <Header $color={user_area.color}>
        <HeaderCol>
          <HeadCol>
            {index && <Order>{index}</Order>}
            {/* https://linear.app/arootah/issue/ARO-2972 */}
            {/* {quadrant ? <Quadrant $quad={quadrant.toLowerCase()}>{quadrant}</Quadrant> : null} */}
          </HeadCol>

          <HeaderText>
            <Head>
              <AreaName>{user_area.name}</AreaName>
              <CategoryName>{goal.category_name}</CategoryName>
            </Head>
            <Title>{goal.goal_name || "Unnamed Goal"}</Title>
            {goalDeadline ? <Date title={moment(goal.deadline).format("ddd, MMM DD, YYYY")}>Due <span>{goalDeadline}</span></Date> : null}
          </HeaderText>
        </HeaderCol>

        <Tooltip title="Goal Progress">
          <div>
            <RadialBar value={goalProgress} />
          </div>
        </Tooltip>
      </Header>

      <Body $forTasks={false}>
        {topActions.map((action, index) => <SimpleViewItem
          key={action.id}
          setVisible={setVisible}
          visible={visible}
          index={index + 1}
          data={action}
          goal={{
            color: data?.user_area?.color,
            startline: data?.goal?.startline,
            deadline: data?.goal?.deadline
          }}
          isProView
          onClick={onClick}
          onEdit={onEdit}
        />)}

        <Accordion activeKey={isActive ? "1" : "0"}>
          <Accordion.Collapse eventKey={"1"}>
            <>
              {actions.slice(2, actions.length).map((action, index) => 
                <SimpleViewItem
                  key={action.id}
                  visible={visible}
                  setVisible={setVisible}
                  index={index + 3}
                  data={action}
                  goal={{
                    color: data?.user_area?.color,
                    startline: data?.goal?.startline,
                    deadline: data?.goal?.deadline
                  }}
                  isProView
                  onClick={onClick}
                  onEdit={onEdit}
                />
              )}
            </>
          </Accordion.Collapse>
        </Accordion>
      </Body>

      {actions.length > 2 ? <Footer onClick={() => setIsActive(!isActive)}>
        <Count>{isActive ? "Hide" : "More"} Activities ({actions.length - 2})</Count>
        
        <Tooltip title={isActive ? "Show Less" : "Show More"}>
          <Toggle $active={isActive}>
            <img src={chevronDownIcon} alt="" />
          </Toggle>
        </Tooltip>
      </Footer> : null}
    </Wrapper>
  );
}

interface ITask {
  id: string;
}

interface ITasksProps {
  data: ITask[];
  onClick?: (id: string) => void;
  onEdit?: (id: string) => void;
  setVisible?: (id: boolean) => void;
  visible?: boolean;
}

export const TasksGroup: FC<ITasksProps> = ({ data, onClick, onEdit, visible, setVisible }) => {
  return (
    <Wrapper>
      <Body $forTasks>
        {data.map((task, index) => <SimpleViewItem
          key={task.id}
          index={index + 1}
          data={task}
          isTask
          onClick={onClick}
          onEdit={onEdit}
          visible={visible}
          setVisible={setVisible}
        />)}
      </Body>
    </Wrapper>
  );
}

export default SimpleViewGroup;
