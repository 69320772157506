import { ActionTypes, IFocusedExtreme, ListFocusedExtremesState } from './type';

interface IRawData {
  most_time_consuming: IFocusedExtreme[];
  least_time_consuming: IFocusedExtreme[];
  least_priority: IFocusedExtreme[];
};

const initial = {
  data: {
    most_time_consuming: [],
    least_time_consuming: [],
    least_priority: [],
    itemsById: {}
  },
  popup: null,
  loading: false
} as ListFocusedExtremesState;

export default (state = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS: {
      const rawData = action.payload as IRawData;

      const mostTimeConsuming = rawData.most_time_consuming.map(item => item.id);
      const leastTimeConsuming = rawData.least_time_consuming.map(item => item.id);
      const leastPriority = rawData.least_priority.map(item => item.id);

      const itemsById: { [id: string]: IFocusedExtreme } = {};

      // Combine all the arrays and iterate over them to populate the unique items map
      [...rawData.most_time_consuming, ...rawData.least_time_consuming, ...rawData.least_priority].forEach(item => {
        // Use the 'id' as the key to ensure uniqueness in the Map
        if (!itemsById[item.id]) {
          itemsById[item.id] = item; // Store the unique item in the itemsById object
        }
      });

      return {
        ...state,
        data: {
          most_time_consuming: mostTimeConsuming,
          least_time_consuming: leastTimeConsuming,
          least_priority: leastPriority,
          itemsById
        },
        loading: false
      }
    }
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.OPEN:
      return {
        ...state,
        popup: action.payload
      };
    case ActionTypes.CLOSE:
      return {
        ...state,
        popup: null
      };
    default:
      return state;
  }
}
