import { ActionTypes } from "./type";

const initial = {
  data: [],
  length: 0,
  loading: false,
  isTaskRequest: null,
};

export default (state = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true,
        isTaskRequest: false,
      };
    case ActionTypes.SUCCESS:
      return {
        data: action.payload || [],
        length: (action.payload || []).length,
        loading: false,
        isTaskRequest: true,
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
