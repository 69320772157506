import { useState, useRef, ChangeEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, InputArea } from 'elements';
import { useNavigate } from 'react-router-dom';
import ModalContainer from 'react-modal-promise';

import { confirmModal } from './ConfirmModal';

import {
  Wrapper,
  ContentList,
  ContentListBody,

  BackLink,

  Icon,
  Title,
  Text,

  Form,
  Footer
} from './styled';

// @ts-ignore
import flagIcon from 'assets/images/priorities/flag-gray.svg';

// @ts-ignore
import arrowIcon from 'assets/images/priorities/arrow-gray.svg';

// @ts-ignore
import { UpdateLastStep } from "Redux/UpdateLastStep/action";

import { JOURNEY_PATH, MISSION_PATH } from 'utils/routes';

const Premise = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // @ts-ignore
  const { masterPlanDetails } = useSelector((state) => state.RetrieveMasterPlanDetailsReducer);

  const [isCreate, setIsCreate] = useState(false);
  const [value, setValue] = useState("");

  const $input = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isCreate) {
      setValue(masterPlanDetails?.premise || "");

      setTimeout(() => {
        if (isCreate && $input.current) {
          $input.current.focus();
        }
      }, 100);
    }
  }, [isCreate, masterPlanDetails]);

  const handleCreate = () => {
    setIsCreate(true);
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const stepFormData = new FormData();
    stepFormData.append("premise", value);

    dispatch(UpdateLastStep(stepFormData, false, false, handleSuccess));
  }

  const handleSuccess = () => {
    navigate(MISSION_PATH, { replace: true });
  }

  const handleSkip = () => {
    navigate(MISSION_PATH, { replace: true });
  }

  const handleBackSuccess = () => {
    navigate(JOURNEY_PATH, { replace: true });
  }

  const handleBack = async (e: any) => {
    if (value) {
      e.preventDefault();

      const save = await confirmModal({});

      if (save) {
        const stepFormData = new FormData();
        stepFormData.append("premise", value);

        dispatch(UpdateLastStep(stepFormData, false, false, handleBackSuccess));
      }
      else {
        handleBackSuccess();
      }
    }
  }

  return (
    <Wrapper>
      <BackLink onClick={handleBack} to={JOURNEY_PATH}>
        <img src={arrowIcon} alt="" />
        Back To Dashboard
      </BackLink>

      <ContentList>
        <ContentListBody>
          <Icon src={flagIcon} alt="" />

          <Title>Premise</Title>

          {isCreate
            ? (
              <>
                <Text>What are your fundamental beliefs for this journey?</Text>

                <Form onSubmit={handleSubmit}>
                  <InputArea
                    value={value}
                    placeholder="Enter your context"
                    ref={$input}
                    max={500}
                    counter
                    style={{ width: '100%', minHeight: 138, maxHeight: 180, overflowY: "auto" }}
                    onChange={handleChange}
                  />

                  <Button disabled={!value.length} style={{ width: 175 }}>Save & Build Your Goals</Button>
                </Form>
              </>
            ) : (
              <>
                <Text>Enter a premise for your ideal life. What do you want? Where are you going? This tool guides you through creating a mission statement that will guide you.</Text>
                <Footer>
                  <Button onClick={handleCreate} style={{ width: 175 }}>Create</Button>
                  <Button onClick={handleSkip} styleType="link-primary">Skip And Start Journey</Button>
                </Footer>
              </>
            )
          }
        </ContentListBody>
      </ContentList>

      <ModalContainer />
    </Wrapper>
  );
};

export default Premise;
