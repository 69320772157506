import { FC } from 'react';
import {
  Wrapper,
  Label,
  Value
} from "./styled";

interface IProps {
  value: number;
  secondary?: boolean;
}

const convertMinutesToTimeFormat = (time?: number) => {
  if (!time) return "0h 00m";

  const hours = Math.floor(time / 60);
  const mins = time % 60;

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }

  if (mins) {
    if (result) result += " ";
    result += `${mins > 9 ? mins : `0${mins}`}m`;
  } else {
    result += " 00m";
  }

  return result || `0h 00m`;
};

const RequiredHours: FC<IProps> = ({ value, secondary }) => {
  return (
    <Wrapper>
      <Label>{secondary ? "Available hours" : "Exceeded Hours"}</Label>
      <Value $secondary={secondary} $error={value > 0 && !secondary}>{convertMinutesToTimeFormat(value > 0 ? value : 0)}</Value>
    </Wrapper>
  );
}

export default RequiredHours;
