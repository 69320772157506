import { Accordion } from "react-bootstrap";
import { ReactComponent as CollapseIcon } from "assets/images/priorities/collapse-arrow.svg";

import { AreaItem } from "../AreaItem";

import {
  Wrapper,
  HeadToggle,
  Title,
  Body,
  BodyInner,
  Content
} from "./styled";

const GoalsGroup = ({ activeKey, data, goalsData, selectedGoal, onToggle, onGoalToggle }) => {
  const { id, current_state, name } = data;

  const onClickHandle = () => {
    onToggle(id);
  }

  const onGoalClickHandle = id => {
    onGoalToggle(id);
  }

  return (
    <Wrapper $active={activeKey === current_state.id}>
      <HeadToggle $active={activeKey === current_state.id} onClick={onClickHandle}>
        <CollapseIcon />
        <Title>{name}</Title>
      </HeadToggle>

      <Accordion.Collapse as={Body} eventKey={current_state.id}>
        <BodyInner>
          <Accordion as={Content} activeKey={selectedGoal}>
            {goalsData.filter(item => item?.goal?.completed).map(item => <AreaItem
              active={selectedGoal === item.id}
              data={item}
              onClick={onGoalClickHandle}
              key={item.id}
            />)}
          </Accordion>
        </BodyInner>
      </Accordion.Collapse>
    </Wrapper>
  );
}

export default GoalsGroup;
