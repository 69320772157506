import styled from "styled-components";

export const MainWrapper = styled.div`
  border-left: 1px solid rgb(217, 217, 217);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  padding: 10px 0 10px 10px;
  overflow-y: scroll;
  
  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #CED0D4;
    }
  `}

  ${({ theme }) => theme.max("sm")`
    padding: 10px;
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

export const AiSuggestionContianer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .ant-switch-checked {
    background-color: #1271a6 !important;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  span {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }
`;

export const Image = styled.img``;

export const ChipsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RegGernerateButton = styled.button`
  color: #1271a6;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
`;

export const Header = styled.div`
  display: flex;
  height: 48px;
  padding: 10px 9px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
`;

export const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  background: transparent;
  width: 20px;
  height: 20px;

  @media screen and (min-width: 1025px) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

export const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderTitle = styled.span`
  color: var(--Neutral-600, var(--Text, #525252));
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const SubHeader = styled.div`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
`;

export const SubHeaderTitle = styled.span`
  color: var(--Neutral-600, var(--Text, #525252));
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
`;
