export const Person = [
    {
      label: "All",
      key: "all",
    },
    {
      label: "Personal",
      key: "personal"
    },
    {
      label: "Due Diligence",
      key: "due-diligence"
    },
    {
      label: "Family Office",
      key: "family-office"
    },
    {
      label: "Portfolio Manager",
      key: "portfolio-manager"
    },
  ];

export const Goal = [
    {
      label: "All",
      key: "all",
    },
    {
      label: "Health",
      key: "health"
    },
    {
      label: "Environmente",
      key: "environment"
    },
    {
      label: "Leisure",
      key: "leisure"
    },
    {
      label: "Contribution",
      key: "contribution"
    },
    {
      label: "Social",
      key: "social"
    },
    {
      label: "Career",
      key: "career"
    },
    {
      label: "Partner",
      key: "partner"
    },
    {
      label: "Finances",
      key: "finances"
    },
    {
      label: "Family",
      key: "family"
    },
  ];

export const Sort_by = [
  {
    name: "Indirect Impact",
    id: "indirect_impact"
  },
  {
    name: "Start Date",
    id: "startline"
  },
  {
    name: "End Date",
    id: "deadline"
  },
  {
    name: "Urgency",
    id: "urgency_score"
  },
  {
    name: "Duration",
    id: "prioritized_activities__time_score"
  },
];
  