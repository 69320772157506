import { uid } from 'uid';
import { ActionTypes, ListTimeDistributionState } from "./types";

let INITIAL_STATE: ListTimeDistributionState = {
  data: [],
  loading: false,
  success: false,
  error: null,
};

function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

function convertKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToCamelCase(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = toCamelCase(key);
      acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        data: convertKeysToCamelCase((action.payload || [])
          .filter((item: any) => item.area_name !== null)
          .map((item: any) => ({ ...item, id: 'item_' + uid() }))),
        loading: false,
        success: true,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
