import React from 'react';
import styled from 'styled-components';

interface ImpactComponentProps {
  direct_impact: number;
  color: { color: string }[];
  indirect_impact: number;
}

const Container = styled.div`
  display: flex;
  background-color: #f0f0f0; // Color of the indicator line
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  width: 100%;
`;



const IndirectImpactContainer = styled.div<{ directImpact: number }>`
  height: 20px;
  display: flex;
  gap: 1px;
  width: ${({ directImpact }) => `${100 - directImpact}%`};
`;

const IndirectImpactSegment = styled.div<{ value: number; indirectTotal: number; color: string }>`
  height: 100%;
  background-color: ${({ color }) => color};
  width: ${({ value, indirectTotal }) => `${(value / indirectTotal) * 100}%`};
  transition: width 0.5s ease;
`;

const ImpactComponent: React.FC<ImpactComponentProps> = ({ direct_impact, color, indirect_impact }) => {

  return (
    <Container data-testid="direct-impact">

    </Container>
  );
};

export default ImpactComponent;
