import { Slider } from "antd";
import styled from "styled-components";

interface GoalProps {
  $color: string;
}

interface ItemWrapperProps {
  $active?: boolean;
}

interface ItemProps {
  $active?: boolean;
  $deleted?: boolean;
  $green?: boolean;
}

interface ItemColProps {
  $secondary?: boolean;
  $tertiary?: boolean;
}

interface ItemDelBtnProps {
  $disabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--Neutral-300, #CDCDCD);
  border-right: 1px solid var(--Neutral-300, #CDCDCD);
  border-bottom: 1px solid var(--Neutral-300, #CDCDCD);
}`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
  ${({ $active }) => $active && `
    padding: 4px 12px 12px 12px;
  `};
`;

export const Goal = styled.div<GoalProps>`
  display: flex;
  flex-direction: column;
  border-top: 3px solid ${({ $color }) => $color};
  padding: 3px 12px 7px;
}`;

export const GoalLabel = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}`;

export const GoalTitle = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}`;

export const GoalProgress = styled.div`
  margin-right: 6px;
}`;

export const Item = styled.div<ItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  padding: 8px 12px 8px 8px;
  background-color: #fff;
  border-top: 1px solid var(--Neutral-300, #CDCDCD);
  transition: opacity 0.25s;

  .goal-toggle {
    min-width: 74px;
  }

  :hover {
    .goal-toggle {
      visibility: visible;
    }
  }

  ${({ $active }) => $active && `
    box-shadow: 0px 0px 0px 3px rgba(245, 181, 15, 0.15);
    border: 1px solid #EF6C23;
  `};

  ${({ $green }) => $green && `
    box-shadow: 0px 0px 0px 3px rgba(42, 159, 54, 0.15);
    border: 1px solid #59A310;
  `};


  ${({ $deleted }) => $deleted && `
    opacity: 0.5;
  `};
}`;

export const ItemCol = styled.div<ItemColProps>`
  display: flex;
  align-items: center;

  ${({ $secondary }) => $secondary && `
    gap: 22px;
    min-width: 250px;
  `};

  ${({ $tertiary }) => $tertiary && `
    gap: 12px;
  `};
}`;

export const ItemTitle = styled.span`
  display: -webkit-box;
  overflow: hidden;
  color: var(--Fill-Frame-Dark-navy, #05192E);
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-height: 40px;
}`;

export const ItemTitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 4px;
}`;

export const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
}`;

export const ItemDataTitle = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}`;

export const ItemDataValue = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 8px 7px;
  background: #F5F5F5;
}`;

export const ItemDeleteBtn = styled.button<ItemDelBtnProps>`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;

  svg .st0 {
    fill: #8E97A3;
  }

  :hover {
    background-color: #F5F5F5;
  }

  /* &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  } */

  ${({ $disabled }) => $disabled && `
    pointer-events: none;
    visibility: hidden;
  `};
`;

export const CheckboxWrapper = styled.div`
  margin-right: 12px;
  user-select: none;
`;
