import { createActions } from "api";
import { getCookie } from "utils/StorageVariables";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
// import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { Error } from "Responses/Response";
// import { getCookie } from "Hooks/StorageVariables";
import { ActionTypes } from "./types";
import { SelectedGoalAddAction } from "Redux/Global/action";

export const CreateAction = (Data, callback, options = { update: true, fetchAll: false }) => async (dispatch) => {
  try {
    const userDetails = JSON.parse(getCookie("userData"));
    const selectedClient = getCookie("selectedClient");

    let query = ``;
    if (userDetails?.type === "Coach" && selectedClient) {
      query = `?coaching_user=${selectedClient}`;
    }

    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await createActions(Data, query);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 201) {
      if (options.update)
        dispatch(SelectedGoalAddAction(data));

      if (callback)
        callback(data);

      if (options.fetchAll) {
        let MasterPlanId = getCookie("MasterPlanId");

        if (MasterPlanId)
          return dispatch(RetrieveMasterPlanDetails(MasterPlanId, false));
      }
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
