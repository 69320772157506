import React from "react";
import { Modal } from "antd";
import ArootahLogo from "assets/images/header_logo_modern.svg";
import styled from "styled-components";
import Button from "../Button";

const CompleteActionItemModal = ({ open, setOpen, onComplete }) => {
  const handleCancel = () => {
    setOpen(null);
  };

  const handleClick = () => {
    onComplete(open);
  }

  return (
    <>
      <Modal open={!!open} footer={false} centered width={750} closable={null}>
        <Wrapper>
          <Logo src={ArootahLogo} alt="Arootah" />
          <Description>
            You’ve marked your Goal as completed.
            <br />
            Do you want to mark all Action Items as completed?
          </Description>
          <ActionButton color="white" onClick={handleClick}>
            Complete Action Items
          </ActionButton>
          <CancelButton onClick={handleCancel} color="#282C31">
            Cancel
          </CancelButton>
        </Wrapper>
      </Modal>
    </>
  );
};

export default CompleteActionItemModal;

const Wrapper = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.max("sm")`
    padding: 2rem 0rem;
  `}
`;
const Description = styled.p`
  text-align: center;
  font-family: "Inter";
  color: #4c545b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 0 45px 0;
`;
const Logo = styled.img`
  margin-bottom: 2rem;
  width: 12rem;
`;
const ActionButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);

  border-radius: 3px;
  color: ${({ color }) => color} !important;
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 196px;
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
const CancelButton = styled(Button)`
  background: transparent;
  color: ${({ color }) => color};
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  width: 196px;
  margin-top: 0.5rem;
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
