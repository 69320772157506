import styled, { css } from "styled-components";
import TextareaAutosize from 'react-autosize-textarea';

export const Wrapper = styled.div<{ $counter?: boolean }>`
  position: relative;
`;

export const TextareaStyled = styled(TextareaAutosize)<{
  $borderless?: boolean;
  $active?: boolean;
  $size?: "sm";
  $error?: boolean;
  $counter?: boolean;
  $disabled?: boolean;
}>`
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  color: var(--neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: 1px solid var(--neutral-200, #E5E5E5);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ${({ $counter }) =>
    $counter &&
    css`
      padding-bottom: 34px;
    `}

  ${({ $borderless }) =>
    $borderless &&
    css`
      border: none;
    `}

  ${({ $active }) =>
    $active &&
    css`
      border-color: #71aaca;
      box-shadow: 0px 0px 0px 2px #d0e3ed;
    `}

  ${({ $size }) =>
    $size &&
    $size === "sm" &&
    css`
      font-size: 12px;
      padding: 4px 6px;
    `}

  ${({ $error }) =>
    $error &&
    css`
      border-color: #ff4d4f;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}

  ::placeholder {
    color: #A3A3A3;
  }

  :empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block;
    color: var(--neutral-400, var(--Placeholder, #A3A3A3));
  }

  :focus,
  :active {
    border-color: #71AACA;
    box-shadow: 0px 0px 0px 2px #D0E3ED;
  }
};`;

export const Counter = styled.span`
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: var(--Neutral-400, #a3a3a3);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
`;
