import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import update from 'immutability-helper';
import { Tooltip } from 'elements';

import {
  Wrapper,
  Container,
  ScollableArea,
  Content,
  Title,
  Text,
  List,
  ListInner,

  Footer,
  Button,
  ActionWrapper
} from "./styled";

import { AreaItemDraggable } from "../AreaItem";

import { ReactComponent as InfoIcon } from "assets/images/priorities/info.svg";

import { data } from "./dataMock";

const GoalAreas = () => {
  const [items, setItems] = useState(data);

  const onDragEndHandle = result => {
    if (!result.destination || result.destination.index === result.source.index)
      return;

    const sortedData =
      update(items, {
        $splice: [
          [result.source.index, 1],
          [result.destination.index, 0, items[result.source.index]]
        ]
      }
    );

    setItems(sortedData);
  };

  return (
    <Wrapper>
      <Container>
        <ScollableArea>
          <Content>
            <Title>Rank your goals by importance</Title>

            <Text>Now, take a moment to reflect on the big picture—what are your most important goals and aspirations across all areas of your life?<br /><br />Rearrange your Goal Areas by priority, in descending order, with the top-most goal as most important. This will clarify what matters most to you and give a prioritized roadmap for your aspirations.</Text>

            <List>
              <DragDropContext onDragEnd={onDragEndHandle}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <ListInner {...provided.droppableProps} ref={provided.innerRef}>
                      {items.map((item, i) => <AreaItemDraggable data={item} draggable key={item.id} index={i} />)}
                      {provided.placeholder}
                    </ListInner>
                  )}
                </Droppable>
              </DragDropContext>
            </List>
          </Content>

          <Footer>
            <ActionWrapper>
              <Button>Apply Hierarchy</Button>
              <Tooltip title="🤷‍♂️">
                <InfoIcon />
              </Tooltip>
            </ActionWrapper>
          </Footer>
        </ScollableArea>
      </Container>
    </Wrapper>
  );
}

export default GoalAreas;
