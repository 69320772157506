import styled from "styled-components";

export const Wrapper = styled.div<{ $active: boolean; }>`
  display: flex;
  padding: 8px;
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  border-radius: 3px;
  background: var(--Neutral-100, #F5F5F5);
  border: 1px solid #fff;
  transition: all .15s ease-in-out;

  ${({ $active }) => $active && `
    border-color var(--Primary-200, #A0C6DB);
    background: var(--Primary-50, #ECF3F6);
  `}

  :hover {
    border-color var(--Primary-200, #A0C6DB);
    background: var(--Primary-50, #ECF3F6);
  }
`;

export const Label = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
`;
