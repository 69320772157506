import React from 'react';

import ResetPasswordContent from '../../../components/Auth/ResetPassword';
import Layout from '../../../components/Auth/Layout';

const ResetPassword = () => {
  return (
    <Layout bg="login">
      <ResetPasswordContent />
    </Layout>
  );
}

export default ResetPassword;
