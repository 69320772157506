import styled from "styled-components";

export const Wrapper = styled.div<{
  targetPopover?: boolean;
}>`
  width: ${({ targetPopover }) => (targetPopover ? "100%" : "214px")};
  height: fit-content;
  max-height: 96px;
  border-radius: 4px;
  border-top: ${({ targetPopover }) => (targetPopover ? "none" : "3px solid #1263de")};
  padding: 14px;
  background-color: #ffffff;
  gap: 4px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 20px;
`;

export const Title = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

export const SubTitle = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #404040;
  margin-top: 6px;
`;
