import { Button, Modal } from 'antd';

import styled from "styled-components";
export const StyledModel = styled(Modal)  `

.ant-modal-footer{
    border : none;
    height: 120px;
    margin-top: -43px;
}
.ant-modal-wrap {
     z-index: 99999;

}

.ant-modal-content {
    position: relative;
    top: -70px;
}
`
;

export const Alert = styled.div `
display:flex;
justify-content:center;
align-items:center;



`;
export const GoalText = styled.div `
font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: center;
margin-top:18px



`;
export const NoteText = styled.div  `

font-family: Poppins;
font-size: 13px;
font-weight: 400;
line-height: 20px;
text-align: center;
color:#737373;
margin-top:15px

`
;

export const Btn = styled.div  `
display:flex;
justify-content:center;
maargin-top:-20px;
flex-warp:warp;
flex-direction: ${({ $mobile }) => ($mobile ? 'column' : 'row')};
margin:  ${({ $mobile }) => ($mobile ? '3px' : '0px')};
gap:10px;
`;
export const TextModel = styled.div  `
padding:40px`