import { ReactComponent as NoteIcon } from "assets/images/dashboard/note.svg";

import { Wrapper, Head, Title, Text, Body, InnerHead, InnerFooter, Button } from "./styled";

const Reflections = () => {
  return (
    <Wrapper>
      <Head>
        <Title>Reflections</Title>
      </Head>

      <Body>
        <InnerHead>
          <NoteIcon />
          <Text>Write your first reflection here</Text>
        </InnerHead>
        <InnerFooter>
          <Button type="button">
            Submit
          </Button>
        </InnerFooter>
      </Body>
    </Wrapper>
  );
}

export default Reflections;
