import React from "react";

import Purpose from "components/Journeys/Purpose";
import ScreenLayout from "components/Journeys/SceneLayout";

const PurposeScreen = () => {
  return (
    <ScreenLayout>
      <Purpose />
    </ScreenLayout>
  );
};

export default PurposeScreen;
