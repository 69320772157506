import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import useWindowSize from '../../../utils/useWindowSize';

// import DMImg from '../../../assets/images/auth/decisionMatrix.png';
// import PPImg from '../../../assets/images/auth/projectPlanner.png';
import LaptopImg from "assets/images/auth/laptop2.png";

import * as Layout from '../styled';
import * as Styled from './styled';

import { BREAKPOINTS } from 'theme/mixins';
import { BackNavigate } from '../Components/BackNavigate';
import { SIGN_IN_PATH } from 'utils/routes';

const increaseBy = (num) => {
  let counter = num;
  return () => {
    const result = counter;
    counter += 50;
    return result;
  }
}

const CreateAccount = ({ active, onNext, userType, animate }) => {
  const { width } = useWindowSize();

  /* const queryParams = new URLSearchParams(location.search);
  const appParam = queryParams.get('app'); */

  const appParam = 'gps';

  const animateDelayCounter = increaseBy(100);

  /* const {
    signup: { loading, success }
  } = useSelector(({ auth }) => auth); */

  /* useEffect(() => {
    if (success) {
      history.replace('/dashboard');
    }
  }, [success]); */ 

  const onSubmitHandle = () => {
    onNext();
  }

  if (!active)
    return null;

  return (
    <Layout.Screen $signup>
      <BackNavigate to="/" />

      <Layout.Column $first>
        <Styled.FormStyled>
          <Layout.Title $animate={animate} $animateDelay={animateDelayCounter()}>Create an Account</Layout.Title>
          <Styled.FormText $animate={animate} $animateDelay={animateDelayCounter()}>The start of your peak performance journey is just minutes away.</Styled.FormText>

          <Styled.SubmitWrapper $animate={animate} $animateDelay={animateDelayCounter()}>
            <Layout.Submit onClick={onSubmitHandle}>Sign Up With Email</Layout.Submit>
          </Styled.SubmitWrapper>

          <Styled.Linkbox $animate={animate} $animateDelay={animateDelayCounter()}>
            Already a Member?
            <Link to={SIGN_IN_PATH}>Log In</Link>
          </Styled.Linkbox>
        </Styled.FormStyled>
      </Layout.Column>

      {width > BREAKPOINTS.sm && <Layout.Column $second $login>
        <Styled.Wrapper $animate={animate} $app={appParam}>
          {appParam === 'pp' && <img src="https://i.ibb.co/x7QctV5/project-Planner.png" alt="" />}
          {appParam === 'dm' && <img src="https://i.ibb.co/g6J06MV/decision-Matrix.png" alt="" />}
          {appParam === 'gps' && <img src={LaptopImg} alt="" />}
        </Styled.Wrapper>
      </Layout.Column>}

      {/* <Layout.Column $second $text>
        <Styled.Wrapper>
          <Styled.Heading $animate={animate} $animateDelay={100}>I’m ready, Habit Coach</Styled.Heading>
          <Styled.Subheading $animate={animate} $animateDelay={150}>Create an account to get started and join the Habit Coach community.</Styled.Subheading>
          <Styled.Features>
            <Styled.Feature $animate={animate} $animateDelay={200}>Discover the habits from the best coaches and advisors</Styled.Feature>
            <Styled.Feature $animate={animate} $animateDelay={250}>Unlock in-depth insights about your routines</Styled.Feature>
            <Styled.Feature $animate={animate} $animateDelay={300}>Manage all your most important habits in one place</Styled.Feature>
          </Styled.Features>
        </Styled.Wrapper>
      </Layout.Column> */}
    </Layout.Screen>
  );
}

export default CreateAccount;
