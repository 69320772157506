import React from 'react';
import Form from 'react-bootstrap/Form';
import isEqual from 'lodash.isequal';
import styled, { css } from 'styled-components';

import { Formik, Field } from "formik";
import * as yup from "yup";

import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

import TextBox from '../../../atoms/TextBox';
import TextBoxLabel from '../../../atoms/TextBoxLabel';
import Button from '../../../components/Button';

import 'react-phone-input-2/lib/style.css';

yup.addMethod(yup.string, "phone", function (errorMessage) {
  return this.test(`test-phone-number`, errorMessage, function (value) {
    const { path, createError } = this;

    // If the value undefined or an empty string, consider it not required and return true
    if (value === undefined || value === '') {
      return true;
    }

    return (
      isValidPhoneNumber('+' + value) ||
      createError({ path, message: errorMessage })
    );
  });
});

const schema = yup.object().shape({
  first_name: yup.string().trim()
    .matches(/^[A-Za-z]+$/, "First Name can't include special characters or numbers.")
    .required('First Name is required'),
  last_name: yup.string().trim()
    .matches(/^[A-Za-z]+$/, "Last Name can't include special characters or numbers.")
    .required('Last Name is required'),
  email: yup.string().email('Email must be a valid email').required('Email is required'),
  phone: yup.string().phone('Must be a valid phone number').nullable(),
  user_type: yup.string()
});

const PhoneField = ({ form, field }) => {
  const onChangeHandle = value => {
    form.setFieldValue(field.name, value);
  }

  return (
    <PhoneInput
      value={field.value}
      country={'us'}
      onChange={onChangeHandle}
      isValid={!form.errors[field.name]}
      inputStyle={{ width: '100%' }}
      className={`${form.errors[field.name] ? ' is-invalid' : ''}`}
    />
  );
}

const ProfileSection = ({ data, onSubmit }) => {
  const initialValues = {
    first_name: data.first_name || '',
    last_name: data.last_name || '',
    email: data.email || '',
    phone: data.phone || '',
  }

  const
    updateSpcificUserLoading = false,
    toggleUserTypeLoading = false;

  const onSubmitHandle = form => {
    onSubmit(form);
  }

  return (
    <Group>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitHandle}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleReset, handleChange, values, errors }) => (
          <FormStyled noValidate onSubmit={handleSubmit}>
            <FormBody>
              <FormGroup>
                <Label className="authLabel">First Name*</Label>
                <div className="flex-grow-1 position-relative">
                  <TextBoxs
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    isInvalid={errors.first_name}
                  />
                  <Form.Control.Feedback className="position-absolute mt-0" type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="authLabel">Last Name*</Label>
                <div className="flex-grow-1 position-relative">
                  <TextBoxs
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    isInvalid={errors.last_name}
                  />
                  <Form.Control.Feedback className="position-absolute mt-0" type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="authLabel">Email*</Label>
                <div className="flex-grow-1 position-relative w-100">
                  <TextBoxs
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={errors.email}
                  />
                  <Form.Control.Feedback className="position-absolute mt-0" type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="authLabel">Phone</Label>
                <div className="flex-grow-1 position-relative w-100">
                  <Field name="phone" component={PhoneField} />

                  <Form.Control.Feedback className="position-absolute mt-0" type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </div>
              </FormGroup>
            </FormBody>

            <FormFooter>
              <CancelButton
                type="Button"
                onClick={handleReset}
                loading={updateSpcificUserLoading || toggleUserTypeLoading}
                disabled={isEqual(initialValues, values)}
              >
                Cancel
              </CancelButton>
              <ActionButton
                type="submit"
                loading={updateSpcificUserLoading || toggleUserTypeLoading}
                disabled={isEqual(initialValues, values)}
                bgcolor={'#1271A6'}
              >
                Save Changes
              </ActionButton>
            </FormFooter>
          </FormStyled>
        )}
      </Formik>
    </Group>
  );
}

export { ProfileSection };

const Group = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.max('lg')`
    height: fit-content;
  `}
`;

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px;
  min-height: 430px;

  ${({ theme }) => theme.max('lg')`
    padding: 0 24px 24px;
    min-height: initial;
  `}

  ${({ theme }) => theme.max('sm')`
    padding: 0;
    min-height: initial;
  `}
`;

const FormGroup = styled.div`
  width: 100%;
  max-width: 388px;
  margin: 22px auto 0;

  ${({ theme }) => theme.max('lg')`
    margin: 0 auto;
  `}
`;

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme }) => theme.max('lg')`
    margin-top: 20px;
  `}

  ${({ theme }) => theme.max('sm')`
    
  `}
`;

const Label = styled(TextBoxLabel)`
  min-width: 100px;
  margin-bottom: 4px;

  ${({ theme }) => theme.max('lg')`
    padding: 15px 0 0;
  `}
`;

const TextBoxs = styled(TextBox)`
  width: 100%;
`;

const ActionButton = styled(Button)`
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
  width: 140px;
  margin-left: 5px;

  ${({ theme }) => theme.max('lg')`
    
  `}

  ${({ theme }) => theme.max('sm')`
    width: 120px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `}


  &:disabled {
    opacity: 0.5;
  }
`;

const CancelButton = styled(Button)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 20px;
  width: 48px;
  color: ${({ theme }) => theme.palette.primary.main};
  background: transparent;

  &:disabled {
    opacity: 0;
  }
`;
