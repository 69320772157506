import styled from "styled-components";

export const Wrap = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 100px;
`;

export const FilterWrap = styled.div`
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 48px;
`;

export const Container = styled.div`
  z-index: 1;
  background-color: #fff;
  width: fit-content;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c1cad1;
  width: 100%;
  height: 40px;
  position: sticky;
  top: 87px; /* Stick to the top of the parent container */
`;

export const Button = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  margin-right: 0.5rem;
  background-color: transparent;
  position: relative;
  border-right: ${({ isSubtract }) =>
    isSubtract ? "none" : "1px solid #b2b8be"};
  display: flex;
  align-items: center;
  height: 30px;
  gap: 0.3rem;
`;

export const Span = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  color: #418db8;

`;

export const InnerContainer = styled.div`
  width: 375px;
`;

export const FilterTabs = styled.div`
  width: 100%;
  background: rgb(255, 255, 255);
  height: 41px !important;
  display: flex;
  padding: 4px 0px 0px 17px;
  gap: 14px;
  padding:11px 16px 11px 16px;
  border-bottom: 1px solid;
  border-color: #C1CAD1;
`
export const FilterBy = styled.div`
  cursor: pointer;
  position: relative;
  span{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4C545B;
  padding-left: 4px;
  }
`
export const SortBy = styled.div`
  cursor: pointer;
  span{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4C545B;
    padding-left: 4px;
    }
`

export const NumberOfFilter = styled.div`
  position: absolute;
  left: 10px;
  top: 1px;
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.05em;
  text-align: center;
  width: 14.54px;
  height: 14.54px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  color: #FFFFFF !important;
  background-color: #1271A6;
  align-items: center;



`
export const TabsWrapper = styled.div`
  width: 100%;
  background: rgb(255, 255, 255);
  height:${({ $height }) => ($height ? "77px" : "44px")};
  border-bottom: 1px solid #CDCDCD;
  & .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    color: #6d7f8e !important;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
  
    text-shadow: initial;
    width: 187px;
    height: 44px;
    padding: 12px,24px,12px,24px;
    border: 0px,0px,1px,0px;
    gap: 8px;
    svg.active {
      display: none;
    }
  
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1271a6 !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    width:187px;
    height: 44px;
    padding: 12px,24px,12px,24px;
    border: 0px,0px,1px,0px;
    padding: 12px,24px,12px,24px;
    color: #CDCDCD;

    svg:not(.active) {
      display: none;
    }

    svg.active {
      display: block;
    }
  }

  & .ant-tabs-nav {
    margin: 0;
    width: 375px;
    gap: 0.5rem;
    height: 44px;

  }

  & .ant-tabs-nav::before {
    border-bottom: none;
  }

  & .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;

  }

  & .ant-tabs-ink-bar {
    background: #418db8 !important;
  }

  & .ant-tabs-tab,
  .ant-tabs-tab-active {
    padding: 0;
    height:44px;
    width: 187px;
    padding: 12px, 24px, 12px, 24px
    border: 0px, 0px, 2px, 0px
    gap: 8px;
  
  }

  & .ant-tabs-nav-wrap {
    height: 42px;

  }

  & .ant-tabs-nav-wrap::before {
    box-shadow: none;
  }
`;

export const TabItem = styled.span`
  display: flex;
 text-color: #CDCDCD;

linear-gradient(0deg, #FFFFFF, #FFFFFF);


  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;


  svg {
    margin-right: 8px;
  }
`;

export const Title = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #737373;
  text-transform: uppercase;
`;

export const SubTitle = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #737373;
  text-transform: uppercase;
  margin-right: -3px;
`;

export const SubHeader = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: 14px;
`;

export const EmptySpace = styled.span`
  width: 840px;
  padding: 16px 16px 12px;
`;

export const SubHeaderTitle = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: 70px;
  color: #4c545b;
`;

export const FilterItem = styled.span`
  display: flex;
  gap: 8px;
  height: 100%;

  span {
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    color: #4c545b;
  }
`;

export const Filter = styled.div`
  z-index: 1;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c1cad1;
  gap: 12px;
  height: 41px;
  padding: 11px 32px 8px 13px;
  position: sticky;
  top: 46px; /* Stick to the top of the parent container */
`;

export const SelectedLength = styled.div`
  width: 24px;
  height: 24px;
  padding: 2px 8px 2px 8px;
  border-radius: 24px;
  gap: 6px;
  background: #ECF3F6;
`;
export const Tabs = styled.span`
margin-left: 40px; 


`
;

export const Num = styled.div`
  width: Hug (24px);
  height: Hug (24px);
  padding: 2px 8px 2px 8px;
  border-radius: 24px;
  gap: 6px;
  color: #1271A6;
  background-color: #ECF3F6;



`;
export const Custombutton = styled.div`
width: 130px;
height: 32px;
padding: 6px 12px 6px 12px;
border-radius: 2px 0px 0px 0px;
opacity: 0px;
color:white;
background: #FF9900;
font-family:inter;
font-bold: 500;
font-size: 15px;
cursor: pointer;
margin-bottom:3px;
display:flex;
align-items:center;
justify-content:space-around;





`;

export const ActionText = styled.div`

width: 150px;
background: white;
display: flex;
align-items: center;
color:#FF9900;
border-bottom: 1px solid rgb(193, 202, 209);
cursor:pointer;
font-wight:500;
`