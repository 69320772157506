import Notify from "utils/notify";

// @ts-ignore
import { createActionItem, updateActionItem } from "../apm-apis";

import { ActionTypes } from "./type";

// @ts-ignore
import { fetchListPrioritizedActions, fetchListPrioritizedActionsGrouped } from 'Redux/APM/PrioritizedActions/action';

// @ts-ignore
import { listFocusedPrioritise } from "Redux/APM/ListFocusedPriorities/action";

export const createAction = (payload: any, callback: () => any) => async (dispatch: any, getState: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });
    const { data } = await createActionItem(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if(data?.action?.type === 'Habit'){
      Notify.success('Habit Successfully Created');
    }else {
      Notify.success('Action Successfully Created');
    }

    const { simpleView } = getState().prioritizedActions;

    if (simpleView)
      dispatch(fetchListPrioritizedActions());
    else
      dispatch(fetchListPrioritizedActionsGrouped());

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const updateAction = (payload: any, callback: () => any, options = { notify: true, loader: true }) => async (dispatch: any, getState: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });
    const { data } = await updateActionItem(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (options?.notify !== false){
      if(data?.action?.type === 'Habit'){
        Notify.success('Habit Successfully Updated');
      } else{
        Notify.success('Action Successfully Updated');
      }
    }

    const { simpleView } = getState().prioritizedActions;

    if (simpleView)
      dispatch(fetchListPrioritizedActions({ loader: options.loader }));
    else
      dispatch(fetchListPrioritizedActionsGrouped({ loader: options.loader }));

    dispatch(listFocusedPrioritise({ loader: options.loader }));

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
