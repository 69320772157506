import styled from "styled-components";

interface WrapperProps {
  $scroll?: boolean;
  $mobile: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  width: ${({$mobile}:{$mobile:boolean}) => ($mobile ? "350px !important" : "365px !important")};
  max-height: 340px;
  overflow-y: auto;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
  padding: 4px;
`;

export const MainWrappar = styled.div<{$popover:boolean}>`
  ${($popover) => $popover.$popover && `
    position: absolute;
    transform: translate(-100%);
    z-index: 99999;
    top: 0px;
    margin-left: 18px;
  `}
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-family: 'Inter';

  svg {
    margin-right: 4px;
    width: 20px;
    transform: rotate(0.1deg);
  }

  span {
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
`;

export const Body = styled.div`
  background-color: #ECEEF1;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  gap: 3px;
  overflow-y: auto;
  border-top: 4px solid #ECEEF1;
  border-bottom: 4px solid #ECEEF1;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    height: 100px;
    border-radius: 4px;
    background-color: #ACB4BC;
    overflow-y: auto;
  }
`;

export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
`;

interface AreaWrapperProps {
  $color?: string;
}

export const AreaWrapper = styled.div<AreaWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid transparent;
  background-color: #fff;
  padding: 3px 4px 3px 8px;

  ${({ $color }) => $color && `
    border-left-color: ${$color};
  `};
`;

export const Input = styled.input`
  width: 40px;
  text-align: right;
  color: #171717;
  padding: 4px 6px;
  border-radius: 3px;
  border: 0.5px solid #E5E5E5;
  background: #fff;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 7.5px;
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  height: 21px;
  gap: 1px !important;
`;

interface AreaItemProps {
  $color?: string;
  $directImpact?: boolean;
}

export const AreaItem = styled.div<AreaItemProps>`
  flex: 1;
  height: 100%;
  background-color: #ECEEF1;

  ${({ $color }) => $color && `
    background-color: ${$color};
  `};

  ${({ $directImpact }) => $directImpact && `
    width: '50%';
  `};
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  border-right: 0.5px solid #CED0D4;
  border-bottom: 0.5px solid #CED0D4;
  border-left: 0.5px solid #CED0D4;
  margin-top: 2px;
  height: 26px;
`;

export const TextWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

type TextProps = {
  $mr?: number;
};

export const Text = styled.span<TextProps>`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #1B2A3D
  margin-right: 2px;

  ${({ $mr }) => $mr && `
    margin-right: ${$mr}px;
  `};
`;

export const Score = styled.span`
  color: #1B2A3D;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-transform: capitalize;
  font-family: 'Inter', sans-serif;
`;

export const Empty = styled.div`
  flex: 1;
`;

export const BodyHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 8px 16px;
`;

export const BodyTitle = styled.span`
  color: #000;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 9px 12px;
  margin-bottom:2px;
`;
