import React, { useState } from "react";
import { Popover } from "antd";
import moment from "moment";

import {
  Wrapper,
  Content,
  Area,
  Category,
  Title,
  DateLabel,
  Date,
  Goal,
  GoalTitle,
} from "./styled";

//@ts-ignore
import { ReactComponent as GoalIcon } from "assets/images/priorities/goal.svg";

interface GoalPopoverProps {
  data: {
    area_name?: string;
    action?: {
      context?: string;
    };
    category_name?: string;
    goal_deadline?: string;
    goal_name?: string;
    color?: string;
  };
}

const GoalPopover: React.FC<GoalPopoverProps> = ({ data }) => {
  const area_name = data?.area_name;
  const category_name = data?.category_name;
  const goalDeadline = data?.goal_deadline ? moment(data?.goal_deadline).format("MM/DD/YYYY") : null;

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return (
      <Wrapper>
        <Content>
          <Area>{area_name}</Area>
          <Category>{category_name}</Category>
          <Goal></Goal>
          <Title>{data?.goal_name}</Title>

          <DateLabel>Due</DateLabel>
          <Date>{goalDeadline ? goalDeadline : '-'}</Date>
        </Content>
      </Wrapper>
    );
  };

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [0, 0] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="goal_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Goal className="goal-toggle" $visible={open}>
        <GoalIcon />
        <GoalTitle>See Goal</GoalTitle>
      </Goal>
    </Popover>
  );
};

export default GoalPopover;
