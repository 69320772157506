import styled, { css, keyframes } from 'styled-components';

import { Form, Or } from '../styled';
import { fadeInUpMixin } from '../animated';

const laptopAnimate = keyframes`
  0% {
    transform: translateX(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const FormStyled = styled(Form)`
  text-align: center;
`;

export const FormText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  margin-bottom: 44px;
  text-align: cneter;
  ${fadeInUpMixin}
  ${({ theme }) => theme.max('sm')`
    margin-top: 10px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 624px;

  img {
    transform: translateX(40px);
    opacity: 0;
  }

  ${({ $app }) => $app && $app === 'gps' && css`
    img {
      margin-left: 0%;
      width: 96vh;
    }

    ${({ theme }) => theme.max('lg')`
      justify-content: center;
      align-items: center;
      img {
        width: 96%;
        object-fit : contain;
      }
    `}
  `}

  ${({ $animate }) => $animate && css`
    img {
      animation: ${laptopAnimate} 0.6s cubic-bezier(0, 0, 0, 1) forwards;
    }
  `}
`;

export const Heading = styled.span`
  font-weight: 700;
  font-size: 50px;
  line-height: 160%;
  margin-bottom: 12px;

  ${({ theme }) => theme.max('lg')`
    font-size: 29px;
    line-height: 130%;
    margin-bottom: 15px;
  `}

  ${fadeInUpMixin}
`;

export const Subheading = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 34px;
  max-width: 465px;

  ${({ theme }) => theme.max('lg')`
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 18px;
  `}

  ${fadeInUpMixin}
`;

export const Features = styled.ul`
  list-style-type: none;

  ${({ theme }) => theme.max('lg')`
    padding-left: 20px;
  `}
`;

export const Feature = styled.li`
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin: 6px 0;
  color: #000;
  position: relative;

  ${({ theme }) => theme.max('lg')`
    margin: 8px 0;
  `}

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #000;
    margin-top: 5px;
    margin-left: -12px;
  }

  ${fadeInUpMixin}
`;

export const Disclaimer = styled.span`
  color: #4B545B;
  font-weight: 400;
  font-size: 10px;
  line-height: 113.5%;
  margin-top: 12px;
  ${fadeInUpMixin}
`;

export const OrStyled = styled(Or)`
  margin-bottom: 12px;
  ${fadeInUpMixin}
`;

export const Linkbox = styled.div`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 12px;
  line-height: 20px;
  color: #1B2A3D;
  ${fadeInUpMixin}

  a {
    margin-left: 7px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #1271A6;
  }
`;

export const SubmitWrapper = styled.div`
  width: 100%;
  ${fadeInUpMixin}
`;
