import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import update from 'immutability-helper';

import OverviewItem, { Type } from "./Item";

import { Wrapper, Head, Title, Body } from "./styled";

const data = [
  {
    id: Type.Decision,
    total: 3,
    status: {
      color: "#f00",
      title: "Action needed"
    }
  },
  {
    id: Type.Habit,
    total: 17,
    status: {
      color: "#39CB50",
      title: "All Habits Tracked, Good work!"
    }
  },
  {
    id: Type.Project,
    total: 2,
    status: {
      color: "#F90",
      title: "Action needed"
    }
  },
  {
    id: Type.Journie,
    total: 4,
    status: {
      color: "#f00",
      title: "Urgent"
    }
  }
];

const Overview = () => {
  const [items, setItems] = useState(data);

  const onDragEndHandle = result => {
    if (!result.destination || result.destination.index === result.source.index)
      return;

    const sortedData =
      update(items, {
        $splice: [
          [result.source.index, 1],
          [result.destination.index, 0, items[result.source.index]]
        ]
      }
    );

    setItems(sortedData);
  };

  return (
    <Wrapper>
      <Head>
        <Title>Overview</Title>
      </Head>

      <DragDropContext onDragEnd={onDragEndHandle}>
        <Droppable droppableId="droppable">
          {provided => (
            <Body {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, i) => (
                <OverviewItem
                  type={item.id}
                  total={item.total}
                  status={item.status}
                  key={item.key}
                  index={i}
                />
              ))}
              {provided.placeholder}
            </Body>
          )}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
}

export default Overview;
