export const ActionTypes = {
  REQUEST: "LIST_FOCUSED_PRIORITIES_REQUEST",
  SUCCESS: "LIST_FOCUSED_PRIORITIES_SUCCESS",
  FAILURE: "LIST_FOCUSED_PRIORITIES_FAILURE",

  OPEN: "PRIORITIZATION_POPUP_OPEN",
  CLOSE: "PRIORITIZATION_POPUP_CLOSE",
}

export interface IData {
  most_time_consuming: string[]; // Changed to an array of IDs
  least_time_consuming: string[]; // Changed to an array of IDs
  least_priority: string[]; // Changed to an array of IDs
  itemsById: { [id: string]: IFocusedExtreme }; // New field to store unique items
}

export interface ListFocusedExtremesState {
  data: IData;
  popup: IFocusedPopupItemData | null;
  loading: boolean;
}

export interface IAction {
  id: string;
  context: string;
  type: string;
}

export interface ITask {
  id: string;
  name: string;
  total_time: number;
  deadline: string;
  estimated_duration: number;
}

export interface IPrioritizedActivity {
  id: string;
  action: IAction;
  goal_name: string;
  category_name: string;
  area_name: string;
  color: string;
  calculated_priority: string;
  indirect_impact: string;
  total_time: number;
  estimated_weekly_duration: number;
  management_time: number;
  goal_gap_percentage: number;
  deadline: string;
}

export interface IFocusedExtreme {
  id: string;
  created_at: string;
  updated_at: string;
  priority: number;
  user: string;
  prioritized_activity: IPrioritizedActivity | null;
  task: ITask | null;
}

export interface IFocusedPopupItemData {
  prioritized_activity: string;
  task: string;
  user: string;
  week_starting: string;
}
