import { generateAreaCategories } from "api";
import { getCookie } from "utils/StorageVariables";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import {
  RetrieveMasterPlanDetails,
  // RetrieveMasterPlanDetailsNull,
} from "../RetrieveMasterPlan/action";

export const GenerateAreaCategories = (priority, callback) => async (dispatch) => {
  try {
    const userDetails = JSON.parse(getCookie("userData"));
    const selectedClient = getCookie("selectedClient");

    let masterplanId = getCookie("MasterPlanId");
    let query = "";
    if (masterplanId) {
      query = `?masterplan=${masterplanId}`;
    }
    if (priority) {
      query = `${query}&&priority=${priority}`;
    }
    if (userDetails?.type === "Coach" && selectedClient) {
      query = `${query}&&coaching_user=${selectedClient}`;
    }

    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await generateAreaCategories(query);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      dispatch(RetrieveMasterPlanDetails(masterplanId));

      if (callback)
        callback(data);
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
