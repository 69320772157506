import styled from "styled-components";

interface BackgroundProps {
  $active: boolean;
  // $higher: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.50);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

interface WrapperProps {
  $active: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 390px;
  height: calc(100vh - 70px - 16px);
  background: #fff;
  border-radius: 8px;
  right: 0;
  top: 0;
  margin-top: 70px;
  margin-right: 16px;
  z-index: 999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) =>
    $active &&
    `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Header = styled.div<{ $color: string }>`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 3px solid gray;
  background-color: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));

  ${({ $color }) => $color && `
    border-bottom-color: ${$color};
  `};
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const HeaderTitle = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  span {
    font-weight: 400;
  }
`;

export const HeaderText = styled.span`
  color: var(--Neutral-700, var(--Input-heading, #404040));
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  max-width: 293px;
`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg .st0 {
    fill: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  }

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  overflow-y: auto;
  margin-right: 6px;
  margin-top: 6px;
  gap: 10px;

  ::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #acb4bc;
    border-radius: 100px;
  }
`;

export const GoalName = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const AreasFrame = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 7px 3px;
`;

export const AreasList = styled.ul`
  display: flex;
  align-items: center;
  gap: 1px;
  list-style-type: none;
  height: 21px;
  margin: 0;
  padding: 0;
`;

export const AreaItem = styled.li<{ $color: string }>`
  flex: 1;
  height: 100%;
  background-color: ${({ $color }) => $color};

  &:first-child {
    border-radius: 4px 0 0 0;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
  }
`;

export const AreasImpact = styled.div`
  text-align: center;
  padding-bottom: 3px;

  color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;

  border-radius: 0px 0px 3px 3px;
  border-right: 0.5px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
  border-bottom: 0.5px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
  border-left: 0.5px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  gap: 10px;
  border-top: 1px solid #C1CAD1;
`;
