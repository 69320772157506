import React from "react";
import { Modal } from "antd";
import styled from "styled-components";
import LogoImg from "../../assets/images/header_logo.svg";
import Lock from "assets/images/lock_colored.svg";

const PvtDomainModal = ({ open, setPvtDomainModal }) => {
  const handleCancel = () => {
    setPvtDomainModal(false);
  };
  return (
    <>
      <Modal
        open={open}
        footer={false}
        centered
        width={750}
        onCancel={handleCancel}
      >
        <Wrapper>
          <Logo src={LogoImg} alt="Goal-Setting" />
          <CenterDiv>
            <LogoLock src={Lock} alt="lock" />
            <Description>Domain is private.</Description>
          </CenterDiv>
          <ActionButton onClick={handleCancel} color="white">
            Ok
          </ActionButton>
        </Wrapper>
      </Modal>
    </>
  );
};

export default PvtDomainModal;

const Wrapper = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.max("sm")`
    padding: 2rem 0rem;
  `}
`;
const Description = styled.p`
  width: 22rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #06203a;
  margin: 0.5rem;

  ${({ theme }) => theme.max("sm")`
     width: 19.5rem;
  `}
`;
const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
`;
const Logo = styled.img`
  width: 11.5rem;
`;
const LogoLock = styled.img`
  width: 2.5rem;
`;
const ActionButton = styled.a`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
  color: white;
  border-radius: 3px;
  color: ${({ color }) => color} !important;
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 196px;
  text-decoration: none;
  :hover {
    color: white !important;
  }
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;

const Span = styled.a`
  padding: 0 5px;
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
  color: #1263de;
  :hover {
    color: #1263de;
    text-decoration-line: underline;
  }
`;
