import { Dropdown, Menu } from "antd";
import styled from "styled-components";
//@ts-ignore
import { ReactComponent as Inprogress } from "assets/images/priorities/common/inprogress.svg";
//@ts-ignore
import { ReactComponent as Pencil } from "assets/images/priorities/pencil.svg";
//@ts-ignore
import { ReactComponent as MiniNav } from "assets/images/priorities/common/miniNav.svg";


export const Wrapper = styled.div`
    margin-right: 10px;
    possition: relative;

`;

export const CustomMenu = styled(Menu)`
    display: flex;
    flex-direction: column;
    width: 60px !important;
    padding: 13px 2px 9px 2px !important;
    border-radius: 8px !important;
    border: 1px solid #d9d9d9;
    gap: 12px;
    left: 12px;
    min-height: 200px !important;
    ${({ $simpleViewmissingData }: { $simpleViewmissingData: boolean }) => $simpleViewmissingData && `
    min-height: 200px !important;
    `}
`

export const CustomMenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content{
    display: flex;
    flex-direction: column;
    height: 20px;
    align-items: center;  

  }
    `
export const CustomInprogress = styled(Inprogress)`
  margin-right: 10px;
`;

export const CustomPencil = styled(Pencil)`
  margin-right: 10px;
`;

export const CustomNav = styled(MiniNav)`
  margin-right: 10px;
  cursor: pointer; 
  position: relative;
`;

export const Time = styled.span`
  color: var(--generic-black, #000);
  text-align: end;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 50px;
  display: flex;
  align-items: center;
  margin-left:-6px;
`;
export const CustomDropdowm = styled(Dropdown)`

`;

export const Background = styled.div<{$active:string | null | boolean}>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.50);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

${({ $active }) => $active && `
  opacity: 1;
  visibility: visible;
`};
`;
