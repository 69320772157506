import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "utils/StorageVariables";
import theme from "../theme";
import { SetThemeColor } from "Redux/SchedulerTheme/action";
export const useThemeMode = () => {
  const dispatch = useDispatch();
  let themeCookie = JSON.parse(getCookie("isThemeLight"));
  const { isThemeLight } = useSelector((state) => state.themeColorReducer);
  const [colorTheme, setColorTheme] = useState(
    isThemeLight
      ? theme?.schedular_palette_light
      : theme?.schedular_palette_dark
  );

  useEffect(() => {
    if (themeCookie != null) {
      dispatch(SetThemeColor(themeCookie));
      if (themeCookie) return setColorTheme(theme?.schedular_palette_light);
      return setColorTheme(theme?.schedular_palette_dark);
    }
    if (isThemeLight) return setColorTheme(theme?.schedular_palette_light);
    return setColorTheme(theme?.schedular_palette_dark);
  }, [isThemeLight, themeCookie]);

  return { colorTheme: theme?.schedular_palette_dark };

  // return { colorTheme };
};
