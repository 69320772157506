import { ActionTypes } from "./types";

let INITIAL_STATE = {
  data: [],
  loading: false,
  loading_update: false,
  success: false,
  error: null,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
      };

    case ActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading_update: true,
      };

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading_update: false,
        success: true,
      };

    case ActionTypes.UPDATE_ERROR:
      return {
        ...state,
        loading_update: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
