import { updateGoal } from "api";
import { ActionTypes } from "./types";

import Notify from 'utils/notify';

export const UpdateGoal = (id, formData, callback, updateSelectedGoal = true) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data } = await updateGoal(id, formData);

    dispatch({
      type: ActionTypes.SUCCESS,
    });

    if (updateSelectedGoal) {
      dispatch({
        type: ActionTypes.UPDATE_GOAL_SUCCESS,
        payload: data
      });
    }

    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error)
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.LOADING,
      payload: error
    });
  }
};
