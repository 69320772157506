export enum DataType {
  GOAL = "goal",
  REWARD = "reward",
  CONSEQUENCE = "consequence",
  MEASUREMENT = "measurement",
  ACTION = "action"
}

export interface Item {
  id: string;
  text: string;
  value?: string;
  label?: string;
  accountability?: string;
  type?: string;
}

const data: Record<DataType, Item[]> = {
  [DataType.GOAL]: [
    {
      id: '0001',
      text: 'I want to lose weight'
    },
    {
      id: '0002',
      text: 'I want to gain muscle'
    },
    {
      id: '0003',
      text: 'I want to improve my endurance'
    },
    {
      id: '0004',
      text: 'I want to eat healthier'
    },
    {
      id: '0005',
      text: 'I want to run a marathon'
    }
  ],
  [DataType.MEASUREMENT]: [
    {
      id: '0001',
      text: 'Minutes of exercise',
      value: '90 min'
    },
    {
      id: '0002',
      text: 'Calories burned',
      value: '120 cal'
    },
    {
      id: '0003',
      text: 'Number of steps',
      value: '10,000 steps'
    },
    {
      id: '0004',
      text: 'Distance covered',
      value: '5 km'
    },
    {
      id: '0005',
      text: 'Heart rate',
      value: '120 bpm'
    }
  ],
  [DataType.REWARD]: [
    {
      id: '0001',
      text: 'Treating myself to a nice meal'
    },
    {
      id: '0002',
      text: 'Buying new clothes'
    },
    {
      id: '0003',
      text: 'Going on a trip'
    },
    {
      id: '0004',
      text: 'Relaxing at the spa'
    },
    {
      id: '0005',
      text: 'Buying new tech gadgets'
    }
  ],
  [DataType.CONSEQUENCE]: [
    {
      id: '0001',
      text: 'Eat a whole pizza'
    },
    {
      id: '0002',
      text: 'Binge-watched Netflix'
    },
    {
      id: '0003',
      text: 'Ran a marathon'
    },
    {
      id: '0004',
      text: 'Eat a salad for lunch'
    },
    {
      id: '0005',
      text: 'Go to bed early'
    }
  ],
  [DataType.ACTION]: [
    {
      id: '0001',
      text: 'Create a Diverse Workout Plan',
      accountability: 'Share Plan',
      type: 'Decision'
    },
    {
      id: '0002',
      text: 'Establish a Weekly Exercise Schedule',
      accountability: 'Share Calendar',
      type: 'Decision'
    },
    {
      id: '0003',
      text: 'Set Realistic Goals',
      accountability: 'Partner Check-ins',
      type: 'Decision'
    },
    {
      id: '0004',
      text: 'Prepare Dedicated Workout Space',
      accountability: 'Share Pictures',
      type: 'Action'
    },
    {
      id: '0005',
      text: 'Track Progress and Adjust',
      accountability: 'Journal Sharing',
      type: 'Habit'
    }
  ]
}

function shuffleArray<T>(array: T[]): T[] {
  const arr = array.slice();
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export function getMockData(type: DataType) {
  const dataset = data[type];

  if (dataset) {
    return shuffleArray(dataset);
  } else {
    return [];
  }
}
