import EmptyPlaceholder from "../EmptyPlaceholder";

import { ReactComponent as GoalIcon } from "assets/images/dashboard/goal.svg";

import { Wrapper, Head, Title } from "./styled";

const PriorityActions = () => {
  return (
    <Wrapper>
      <Head>
        <Title>Priority Actions</Title>
      </Head>

      <EmptyPlaceholder
        title="You don’t have any activities at this moment."
        linkLabel="Start Your First Journey"
        linkStyle="thernary"
        linkPath={''}
        linkIcon={GoalIcon}
      />
    </Wrapper>
  );
}

export default PriorityActions;
