import React, { useRef, useState } from "react";
import { Popover, TimePicker, Switch, Input } from "antd";
import moment from "moment";
import styled from "styled-components";
import { BiTime } from "react-icons/bi";

const TimeRangePicker = () => {
  const [visible, setVisible] = useState(false);
  const [selectedStartHour, setSelectedStartHour] = useState("");
  const [selectedStartMinutes, setSelectedStartMinutes] = useState("00");
  const [startAM, setStartAM] = useState(true);
  const [selectedEndHour, setSelectedEndHour] = useState("00");
  const [selectedEndMinutes, setSelectedEndMinutes] = useState("00");
  const [EndAM, setEndAM] = useState(true);
  const [inputDisplayValue, setInputDisplayValue] = useState("");

  //   const handleStartTimeChange = (value) => {
  //     if (value && value.$d instanceof Date) {
  //       const formattedTime = moment(value.$d).format("h:mm A");
  //       setStartTime(formattedTime);
  //     }
  //   };

  //   const handleOk = () => {
  //     setVisible(false);
  //   };

  const openPopover = () => {
    setVisible(true);
  };

  const handleApply = () => {
    const formattedStartHour = formatValue(selectedStartHour);
    const formattedStartMinutes = formatValue(selectedStartMinutes);
    const startAMPM = startAM ? "AM" : "PM";

    const formattedEndHour = formatValue(selectedEndHour);
    const formattedEndMinutes = formatValue(selectedEndMinutes);
    const endAMPM = EndAM ? "AM" : "PM";

    const startTimeString = `${formattedStartHour}:${formattedStartMinutes} ${startAMPM}`;
    const endTimeString = `${formattedEndHour}:${formattedEndMinutes} ${endAMPM}`;

    const timeRange = `${startTimeString} - ${endTimeString}`;

    setInputDisplayValue(timeRange);

    setVisible(false);
  };

  const formatValue = (value) => {

    return value.padStart(2, "0");
  };

  const content = (
    <div>
      <Heading>Start Time</Heading>
      <StartTimeWrapper>
        <div className="number_input">
          <Input
            type="time"
            min={1}
            max={2}
            // value={selectedStartHour.toString().padStart(2, "0")}
            onChange={(e) => {
              console.log(e.target.value)
              // const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
              // setSelectedStartHour(value);
            }}
            style={{ marginRight: "4px" }}
            className="disable-arrow"
            pattern="\d*"
          />
        </div>

        <div className="number_input">
          <Input
            type="number"
            min={0}
            max={59}
            value={selectedStartMinutes.toString().padStart(2, "0")}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
              setSelectedStartMinutes(value);
            }}
            style={{ marginRight: "4px" }}
            className="disable-arrow"
            pattern="\d*"
          />
        </div>

        <Switch
          checkedChildren="AM"
          unCheckedChildren="PM"
          checked={startAM}
          onChange={(checked) => setStartAM(checked)}
          className="day-switch"
        />
      </StartTimeWrapper>
      <Heading ismargin>End Time</Heading>
      <EndTimeWrapper>
        <div className="number_input">
          <Input
            type="number"
            min={1}
            max={12}
            value={selectedEndHour.toString().padStart(2, "0")}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
              setSelectedEndHour(value);
            }}
            style={{ marginRight: "4px" }}
            className="disable-arrow"
            pattern="\d*"
          />
        </div>

        <div className="number_input">
          <Input
            type="number"
            min={0}
            max={59}
            value={selectedEndMinutes.toString().padStart(2, "0")}
            onChange={(e) => {
              const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
              setSelectedEndMinutes(value);
            }}
            style={{ marginRight: "4px" }}
            className="disable-arrow"
            pattern="\d*"
          />
        </div>

        <Switch
          checkedChildren="AM"
          unCheckedChildren="PM"
          checked={EndAM}
          onChange={(checked) => setEndAM(checked)}
          className="day-switch"
        />
      </EndTimeWrapper>
      <Divider />

      <ButtonContainer>
        <Button onClick={handleApply}>Set Duration</Button>
      </ButtonContainer>
    </div>
  );

  return (
    <Wrapper>
      <Popover
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        overlayClassName="timepicker"
        content={content}
        visible={visible}
        trigger="click"
        placement="bottomRight"
        onVisibleChange={(v) => setVisible(v)}
      >
        <div style={{ position: "relative" }}>
          <ReadonlyInput
            type="text"
            value={inputDisplayValue ? inputDisplayValue : "Select Time Range"}
            readOnly
            onClick={openPopover}
          />

          <span
            className="icon"
            style={{
              position: "absolute",
              top: "0.5px",
              right: "3px",
            }}
          >
            <BiTime style={{ color: "#1271A6", fontSize: "1.2rem" }} />
          </span>
        </div>
      </Popover>
    </Wrapper>
  );
};

export default TimeRangePicker;

const Wrapper = styled.div`
  /* width: 100%; */
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReadonlyInput = styled.input`
  padding: 5px 12px;
  /* border: 1px solid lightgray; */
  /* border: none; */
  border: 0.5px solid #cdcdcd;
  background: #fff;
  outline: none;
  border-radius: 3px;
  color: #a3a3a3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:focus {
    border: none;
    outline: 0.5px solid #ff8b00;
  }
`;

const StartTimeWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EndTimeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h3`
  margin-top: ${({ ismargin }) => (ismargin ? "0.65rem" : "0.2rem")};
  color: #112538;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: 0.048px;
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin-top: 0.7rem;
  border: none;
  background: #ced0d4;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.8rem;
`;

const Button = styled.button`
  padding: 6px 12px;
  border-radius: 2px;
  border: 1px solid #1271a6;
  color: #1271a6;
  background-color: transparent;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;
