import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "elements";
import moment from "moment";

import {
  Wrapper,
  Modal,
  Header,
  Heading,
  Title,
  Subtitle,
  CloseBtn,
  Body,
  Footer,
  Background,
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/Closee.svg";

// @ts-ignore
import { ReactComponent as FocusedIcon } from "./icon.svg";

import ActivityItem from "../components/ActivityItem";
import RequiredHours from "../components/RequiredHours";
import List from "../components/List";

import {
  listFocusedExtremes,
  closePopup,
} from "Redux/APM/ListFocusedExtremes/action";

// @ts-ignore
import { getPrioritizedActionData } from "Redux/APM/PrioritizedActions/action";

// @ts-ignore
import { removeFocusedPriorities } from "Redux/APM/ListFocusedPriorities/action";

// @ts-ignore
import { addPrioritizedActivityToFocused } from "Redux/APM/PrioritizedtoFocused/action";
import {
  IPrioritizedActivity,
  ITask,
  ListFocusedExtremesState,
} from "Redux/APM/ListFocusedExtremes/type";
import { getTask } from "Redux/APM/ListTasks/action";

const PrioritizationPopup = () => {
  const dispatch = useDispatch();

  const [isRendered, setIsRendered] = useState(false);
  const [isActiveState, setIsActiveState] = useState(false);

  const [items, setItems] = useState({} as { [id: string]: { id: string, estimation: number; original: number; deleted: boolean; } });

  const [selectedAction, setSelectedAction] = useState(
    null as IPrioritizedActivity | null
  );

  const [selectedTask, setSelectedTask] = useState(
    null as ITask | null
  );

  const [activityTime, setActivityTime] = useState(0);

  const { data, popup, loading } = useSelector(
    (state: any) => state.listFocusedExtremes as ListFocusedExtremesState
  );
  const { progress } = useSelector((state: any) => state.listFocusedPrioritise);

  const { completed, prioritized } = progress;

  useEffect(() => {
    if (selectedAction) {
      setActivityTime(selectedAction.estimated_weekly_duration);
    } else if (selectedTask) {
      setActivityTime(selectedTask.estimated_duration || 0);
    }
  }, [selectedAction, selectedTask]);

  const itemsMins = Object.values(items).reduce(
    (acc, { original, estimation }) => acc + original - estimation,
    0
  );

  const totalMins = 168 * 60; // Total available minutes for a week
  const availableMins = totalMins - completed - prioritized;
  const deletedMins = Object.values(items).reduce((acc, { deleted, estimation }) => (deleted ? acc + estimation : acc), 0);
  
  // Calculate the initial exceeded minutes based on activityTime alone
  const initialExceededMins = activityTime > availableMins ? activityTime - availableMins : 0;
  
  // Adjust the exceeded minutes by reducing the itemsMins or adding deletedMins back
  const exceededMins = Math.max(0, initialExceededMins - (itemsMins - deletedMins));

  useEffect(() => {
    const parsedData = Object.values(data.itemsById).reduce((acc, item) => {
      const activity = item.prioritized_activity;
      const value = activity ? activity.total_time : item.task ? item.task.estimated_duration : 0;
  
      // Add the item to the accumulated object using its ID as the key
      acc[item.id] = {
        id: item.id,
        estimation: value,
        original: value,
        deleted: false
      };
  
      return acc;
    }, {} as { [id: string]: { id: string; estimation: number; original: number; deleted: false } });
  
    setItems(parsedData);
  }, [data.itemsById]);

  useEffect(() => {
    if (popup) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50); // Delay setting $active to true by 50ms

      if (popup.task) {
        dispatch(getTask(popup.task, (data: ITask) => {
          setSelectedTask(data);
        }));
      }
      else {
        dispatch(
          getPrioritizedActionData(
            popup.prioritized_activity,
            (data: IPrioritizedActivity) => {
              setSelectedAction(data);
            }
          )
        );
      }

      dispatch(listFocusedExtremes());

      setTimeout(() => {
        if ($input && $input.current) {
          // @ts-ignore
          $input.current.focus();
        }
      }, 50);
    } else {
      setSelectedAction(null);
      setSelectedTask(null);
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500); // Delay unmounting by 500ms
    }
  }, [popup]);

  const $input = useRef(null);

  const handleClose = () => {
    dispatch(closePopup());
  };

  const handleActivityTimeChange = (_: string, __: string, value: number, original: number) => {
    setActivityTime(value);
  };

  const handleTimeChange = (id: string, _: string, value: number, original: number) => {
    setItems((prevItems) => {
      const item = prevItems[id];

      if (item) {
        return {
          ...prevItems,
          [id]: {
            ...item,
            estimation: value,
            original,
          },
        };
      } else {
        return prevItems;
      }
    });
  };

  const handleRemove = (id: string, time: number) => {
    setItems((prevItems) => {
      const item = prevItems[id];

      if (item) {
        return {
          ...prevItems,
          [id]: {
            ...item,
            deleted: !item.deleted,
          },
        };
      } else {
        return prevItems;
      }
    });
  };

  const handleSubmit = () => {
    processSubmit(false);
  };

  const processSubmit = (nextWeek: boolean) => {
    if (!popup) return;

    const activitiesToDelete = Object.values(items).filter(({ deleted }) => deleted);

    if (activitiesToDelete.length === 0) {
      addActivitiesToFocused(nextWeek);
      return;
    }

    dispatch(
      removeFocusedPriorities(
        activitiesToDelete.map((item) => item.id),
        () => {
          addActivitiesToFocused(nextWeek);
        }
      )
    );
  };

  const addActivitiesToFocused = (nextWeek: boolean) => {
    if (!popup) return;

    const weekStarting = nextWeek
      ? moment(popup.week_starting).add(7, "days").format("YYYY-MM-DD")
      : popup.week_starting;

    dispatch(
      addPrioritizedActivityToFocused({
        prioritized_activity: popup.prioritized_activity,
        task: popup.task,
        user: popup.user,
        override_limit: true,
        week_starting: weekStarting,
        estimated_duration: activityTime,
        focused_updates: Object.values(items).map(({ id, estimation }) => ({
          id,
          estimated_duration: estimation,
          type: data.itemsById[id].prioritized_activity ? "Prioritized Activity" : "Task",
        }))
      })
    );
  };

  const handlePostpone = () => {
    processSubmit(true);
  };

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <Wrapper $active={isActiveState}>
      <Modal $active={isActiveState}>
        <Header>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>

          <Heading>
            <FocusedIcon />
            <Title>You’ve scheduled over the maximum hours this week</Title>
            <Subtitle>
              This activity puts you over 168 weekly hours. Please adjust your
              activity estimates or schedule activities for a different week.
            </Subtitle>
          </Heading>
        </Header>

        <Body>
          {(selectedAction || selectedTask) && (
            <ActivityItem
              isStandalone
              activity={selectedAction}
              task={selectedTask}
              showGoal={!!selectedAction}
              showDetails
              onTimeChange={handleActivityTimeChange}
              // requiredMins={requiredMins}
            />
          )}

          <RequiredHours value={exceededMins} />

          <List
            data={data}
            items={items}
            loading={loading}
            onTimeChange={handleTimeChange}
            onRemove={handleRemove}
          />
        </Body>

        <Footer>
          <Button styleType="secondary" onClick={handlePostpone}>
            Add to Next Week
          </Button>
          <Button disabled={exceededMins > 0} onClick={handleSubmit}>
            Add Activity
          </Button>
        </Footer>
      </Modal>
      <Background />
    </Wrapper>,
    document.getElementById("portal") as HTMLElement
  );
};

export default PrioritizationPopup;
