import { FC, useEffect, useState } from 'react';
import { Tooltip } from 'elements';

import Popup from './Popup';
import IntroTooltip from './IntroTooltip';

// @ts-ignore
import leverageGreenIcon from "assets/images/priorities/leverage-green.svg";

// @ts-ignore
import leverageGrayIcon from "assets/images/priorities/leverage-gray.svg";

// @ts-ignore
import leverageGoldIcon from "assets/images/priorities/leverage-gold.svg";

import { Pointer } from './styled';

// @ts-ignore
// import { availableAreas } from "Redux/APM/AvailableAreas/action";

const renderLevarageIcon = (indirectImpact: number) => {
  if (indirectImpact > 5.9)
    return (
      <img src={leverageGoldIcon} width={24} height={24} alt="" />
    );
  else if (indirectImpact > 0.9)
    return (
      <img src={leverageGreenIcon} width={24} height={24} alt="" />
    );
  return (
    <img src={leverageGrayIcon} width={24} height={24} alt="" />
  );
};

interface IProps {
  tooltip?: boolean;
  color: string;
  goal: any;
}

const Leverage: FC<IProps> = ({ tooltip, color, goal }) => {
  const [isOpen, setIsOpen] = useState(false);

  const indirectImpact = parseFloat(goal?.leverage_score);

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <IntroTooltip open={tooltip}>
        <Pointer onClick={handleClick}>
          <Tooltip title="Adjust Leverage">
            {renderLevarageIcon(indirectImpact)}
          </Tooltip>
          <span>{goal?.leverage_score}</span>
        </Pointer>
      </IntroTooltip>

      <Popup
        color={color}
        goal={goal}
        isActive={isOpen}
        onClose={handleClick}
      />
    </>
  );
}

export default Leverage;
