import React from "react";
import { Wrapper, Head, SubTitle, Title } from "./styled";

const InDirectImpact: React.FC = () => {
  return (
    <Wrapper>
      <Head>
        <Title data-testid="Indirect-Impact">
          Leverage <span>(Indirect Impact)</span>
        </Title>
      </Head>
      <SubTitle data-testid="subtitle">
        The relative influence of{" "}
        <span style={{ textDecoration: "underline" }}>this area</span> on the
        other areas of your life.
      </SubTitle>
    </Wrapper>
  );
};

export default InDirectImpact;
