import React, { FC } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

interface ToggleProps {
  label?: string;
  labelFirst?: boolean;
  id: string;
  size?: "sm" | "md";
  checked?: boolean;
}

const Toggle: FC<ToggleProps> = ({
  label,
  labelFirst,
  id,
  size = "md",
  checked,
  ...props
}) => {
  return (
    <Wrapper>
      <Input
        data-testid="test-toggle"
        type="checkbox"
        id={id}
        checked={checked}
        {...props}
      />

      <ToogleContainer $first={labelFirst} $noLabel={!label}>
        <Label htmlFor={id} $size={size} $checked={checked}>
          <ToggleCircle $size={size} $checked={checked} />
        </Label>
        <Content>{label}</Content>
      </ToogleContainer>
    </Wrapper>
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  labelFirst: PropTypes.bool,
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["sm", "md"]),
};

const Wrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  position: fixed;
  width: 0;
  height: 0;
  z-index: -1;
  font-size: 0;
`;

const ToogleContainer = styled.label<{ $first?: boolean; $noLabel?: boolean }>`
  display: flex;
  align-items: center;
  gap: 11px;

  ${({ $noLabel }) =>
    $noLabel &&
    css`
      gap: 0;
    `}

  ${({ $first }) =>
    $first &&
    css`
      flex-direction: row-reverse;
    `}
`;

const Content = styled.span`
  font-family: Poppins;
  color: #737373;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  user-select: none;
`;

const Label = styled.label<{ $size?: string; $checked?: boolean }>`
  position: relative;
  width: 39px;
  height: 24px;
  background-color: #cdcdcd;
  border-radius: 12px;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &:focus,
  &:active {
    box-shadow: 0px 0px 0px 2px #d0e3ed;
  }

  ${({ $size }) =>
    $size === "sm" &&
    css`
      width: 26px;
      height: 16px;
    `}

  ${({ $checked }) =>
    $checked &&
    css`
      background-color: #1271a6;
    `}
`;

const ToggleCircle = styled.div<{ $size?: string; $checked?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: transform 0.3s;

  ${({ $size }) =>
    $size === "sm" &&
    css`
      width: 14px;
      height: 14px;
      top: 1px;
      left: 1px;
    `}

  ${({ $checked, $size }) =>
    $checked &&
    $size === "md" &&
    css`
      transform: translateX(15px);
    `}

  ${({ $checked, $size }) =>
    $checked &&
    $size === "sm" &&
    css`
      transform: translateX(10px);
    `}
`;

export default Toggle;
