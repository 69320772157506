import { ActionTypes } from './type';

const initial = {
  data: {},
  loading: false,
};

export default (state = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        data: action.payload?.results || [],
        loading: false
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
