import { WEBSOCKET_URL } from "config";

class WebSocketConnection {
  private socket: WebSocket | null;
  private messageCallback: ((message: string) => void) | null;
  private isConnecting: boolean;
  private reconnectInterval: number | NodeJS.Timeout | null;

  constructor() {
    this.socket = null;
    this.messageCallback = null;
    this.isConnecting = false;
    this.reconnectInterval = null;
  }

  connect(onopen?: () => void, onerror?: (event: Event) => void, onclose?: () => void): void {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    this.socket = new WebSocket(WEBSOCKET_URL);

    this.socket.onopen = () => {
      console.log("🟢 WebSocket connected");
      this.isConnecting = false;
      if (this.reconnectInterval) {
        clearInterval(this.reconnectInterval as NodeJS.Timeout);
        this.reconnectInterval = null;
      }
      onopen?.();
    };

    this.socket.onmessage = (event: MessageEvent) => {
      const message = event.data;
      if (this.messageCallback) {
        this.messageCallback(message);
      }
    };

    this.socket.onclose = () => {
      console.log("🔴 WebSocket disconnected");
      this.isConnecting = false;
      this.reconnect();
      onclose?.();
    };

    this.socket.onerror = (event: Event) => {
      this.isConnecting = false;
      this.reconnect();
      onerror?.(event);
    };
  }

  send(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.log("🟡 WebSocket connection not established");
    }
  }

  close(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  setMessageCallback(callback: (message: string) => void): void {
    this.messageCallback = callback;
  }

  private reconnect(): void {
    if (!this.reconnectInterval) {
      this.reconnectInterval = setInterval(() => {
        console.log("Attempting to reconnect...");
        this.connect();
      }, 5000); // Attempt to reconnect every 5 seconds
    }
  }
}

export const websocketConnection = new WebSocketConnection();
