import { RemoveToken } from "Redux/Token/action";
import { ActionTypes } from "./types";
import { removeAllCookie } from "utils/StorageVariables";
import { IsUser } from "Redux/User/action";
import { Success } from "Responses/Response";
import { deleteAccount } from "api";

/* async function deleteAccount() {
  // For debugging purpose:
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 500);
  });
} */

export const DeleteAccount = id => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { status } = await deleteAccount(id);

    if (status === 204) {
      dispatch({
        type: ActionTypes.SUCCESS
      });

      dispatch(RemoveToken());
      removeAllCookie();
      dispatch(IsUser(null));

      Success('Account Deleted');
    }
  } catch (error) {
    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};
