import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

interface ButtonProps {
  styleType?: 'primary' | 'secondary' | 'link' | 'link-primary';
  size?: 'sm' | 'md';
  [key: string]: any; // Add specific props as needed
}

const VeriyButton = forwardRef<HTMLButtonElement, ButtonProps>(({ styleType = 'primary', size = 'sm', children, ...rest }, ref) => {
  return (
    <ButtonStyled $type={styleType} $size={size} ref={ref} {...rest}>
      {children}
    </ButtonStyled>
  );
});

const ButtonPropTypes = {
  styleType: PropTypes.oneOf(['primary', 'secondary', 'link', 'link-primary']),
  size: PropTypes.oneOf(['default', 'medium']),
  children: PropTypes.node
};

VeriyButton.propTypes = ButtonPropTypes;

export const ButtonStyled = styled.button<{ $size?: 'sm' | 'md'; $type?: 'primary' | 'secondary' | 'link' | 'link-primary'; }>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 3px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ${({ $size }) => $size && $size === 'sm' && css`
    height: 36px;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 20px;
  `}

  ${({ $size }) => $size && $size === 'md' && css`
    font-size: 14px;
    padding: 10px 12px;
  `}

  ${({ $type }) => $type && $type === 'primary' && css`
    color: #fff;
    background-color: #1271A6;

    :focus {
      box-shadow: 0 0 0 0.25rem #1271A67F;
    }

    :hover {
      background-color: #116595;
    }

    :disabled {
      background-color: #71AACA;
    }
  `}

  ${({ $type }) => $type && $type === 'secondary' && css`
    color: #1271A6;
    background-color: #ECF3F6;

    :focus {
      box-shadow: 0 0 0 0.25rem #ECF3F67F;
    }

    :hover {
      background-color: #D0E3ED;
    }

    :disabled {
      background-color: #ECF3F6;
    }
  `}

  ${({ $type }) => $type && $type === 'link' && css`
    color: #FF9900;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    :disabled {
      background-color: #ECF3F6;
    }
  `}

  ${({ $type }) => $type && $type === 'link-primary' && css`
    color: #1271A6;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    :disabled {
      background-color: #ECF3F6;
      opacity: .5;
    }
  `}
`;

export default VeriyButton;
