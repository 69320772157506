import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserRow from "./UserRow";

// @ts-ignore
import { GetMyClients } from "Redux/MyClients/action";

import {
  Wrapper,
  Header,
  Title,
  Head,
  Column,
  Body
} from "./styled";

// @ts-ignore
import { useToken } from "utils/utility";
import { APM_PATH } from "utils/routes";

import { IClient } from "./UserRow";

interface State {
  getMyClientListReducer: {
    success?: IClient[];
    loading: boolean;
  };
}

const Coaching = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useToken();

  // @ts-ignore
  const { success: data, loading } = useSelector((state: State) => state.getMyClientListReducer);

  // @ts-ignore
  const { success: userData } = useSelector((state: State) => state.retrieveUserDataReducer);

  useEffect(() => {
    if (!token)
      return;

    dispatch(GetMyClients({ loading: true }));
  }, [token]);

  useEffect(() => {
    if (userData?.type === 'Coach')
      return;

    navigate(APM_PATH);
  }, [userData?.type]);

  return (
    <Wrapper>
      <Header>
        <Title>COACHING / All Clients</Title>
      </Header>

      <Head>
        <Column>Client name</Column>
        <Column>Client email</Column>
      </Head>

      <Body>
        {data?.map((item: IClient) => <UserRow data={item} key={item.id} />)}
      </Body>
    </Wrapper>
  );
};

export default Coaching;
