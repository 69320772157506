import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "elements";

//@ts-ignore
import { ReactComponent as FlashGreenIcon } from "assets/images/priorities/lightning-green.svg";
//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";
//@ts-ignore
import { ReactComponent as QuestionMarkIcon } from "assets/images/priorities/question-mark.svg";

import {
  AreaWrapper,
  Wrapper,
  Head,
  Body,
  MainWrappar,
  Title,
  CloseBtn,
  Header,
  List,
  AreaItem,
  Description,
  TextWrap,
  Text,
  Score,
  Empty,
  BodyHead,
  BodyTitle,
  Footer,
} from "./styled";

import { Checkbox, Input } from "../Form";

//@ts-ignore
// import { availableAreas } from "Redux/APM/AvailableAreas/action";
import PopoverCustom from "../PopoverCustom";
import InDirectImpact from "components/PriorityApp/IndirectImpactPopover";
//@ts-ignore
import useDeviceType from "../../../../Hooks/ResponsiveHook";

interface Goal {
  weight: number;
}

interface Area {
  name?: string;
  color?: string;
  id?: string;
}
interface ImpactArea {
  user_area?: string;
  score?: string;
}

interface AreaData {
  area: Area;
  goal?: Goal;
  impactArea: ImpactArea;
}

interface AvailableAreaState {
  availablearea: {
    areas: Area[];
    indirect_impact_area_scores: ImpactArea[];
  };
}

interface RootState {
  availableAreas: AvailableAreaState;
}

export const AreaColor: React.FC<{ color: string }> = ({ color }) => (
  <AreaItem data-testid="area-item" $color={color} />
);

export const Area: React.FC<{
  data: AreaData;
  onCheckboxChange: (areaId: string) => void;
  onInputChange: (areaId: string, newScore: string) => void;
}> = ({ data, onCheckboxChange, onInputChange }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { name, color, id } = data.area;

  const score: number | '' =
    data?.impactArea?.score && data?.impactArea?.score !== "-"
      ? parseInt(data?.impactArea?.score)
      : '';

  const checked =
    data?.impactArea?.score && data?.impactArea?.score !== "-" ? true : false;  

  const handleCheckboxChange = () => { 
    onCheckboxChange(id || "");
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!(value === "" || (/^[0-9]$|^10$/).test(value))) {
      return;
    }

    if (value === '') {
      setIsEditMode(true);
      onInputChange(id || "", "");
    }

    const inputValue = parseInt(value);

    if (inputValue < 0)
      return;

    const newScore = inputValue;
    const editScore = (newScore > 10 ? 10 : newScore < 0 ? 0 : newScore) || 0;

    setIsEditMode(true);
    onInputChange(id || "", editScore.toString());
  };

  const handleFocus = () => {
    setIsEditMode(true);
  };

  const handleBlur = () => {
    setIsEditMode(false);
  };

  return name ? (
    <AreaWrapper $color={color}>
      <Checkbox checked={checked} onChange={handleCheckboxChange}>
        {name}
      </Checkbox>
      <Input
        type="text"
        step="any"
        placeholder="-"
        value={
          isEditMode
            ? score
            : typeof score === "number"
              ? score
              : score ?? ""
        }
        onChange={handleInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </AreaWrapper>
  ) : null;
};

const LeveragePopover: React.FC<{
  className?: string;
  selectedItem?: {
    direct_impact: string;
    indirect_impact: string;
  };
  style?: React.CSSProperties;
  onClose?: (areaData?: AreaData[]) => void;
  onModalClose?: (areaData?: AreaData[]) => void;
  id?: string;
  dropDownMobile?: boolean | undefined;
  setOutsideAreaData?: (data: AreaData[]) => void;
  onCloseModal?: (data: boolean) => void;
  setPopover?: (value: boolean) => void;
  setBackground?: (value: string | null) => void;
}> = ({
  className,
  selectedItem,
  dropDownMobile,
  style,
  onCloseModal,
  onClose = () => {},
  onModalClose = () => {},
  id,
  setOutsideAreaData,
  setPopover = () => {},
  setBackground = () => {},
}) => {
  const [areaData, setAreaData] = useState<AreaData[]>([]);
  const [hoveredComponent, setHoveredComponent] = useState<string>("");
  const { availablearea } = useSelector(
    (state: RootState) => state.availableAreas
  );
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const { direct_impact, indirect_impact } = selectedItem || {};
  const screenSize: string = useDeviceType();
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const formattedData = availablearea?.areas
      ?.filter((area) => area?.name !== "")
      .map((area) => ({
        area,
        impactArea:
          availablearea?.indirect_impact_area_scores.find(
            (impactArea) => impactArea.user_area === area.id
          ) || {},
      }));

    setCustomAreaData(formattedData);
  }, [availablearea, id]);

  const setCustomAreaData = (data: AreaData[]) => {
    setAreaData(data);
    if (setOutsideAreaData && typeof setOutsideAreaData === "function") {
      setOutsideAreaData(data);
    }
  };

  useEffect(() => {
    if (
      areaData?.every(
        (item) =>
          item.impactArea.score !== undefined && item.impactArea.score !== "-"
      )
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [areaData]);

  const handleSelectAllChange = (isChecked: boolean) => {
    setIsChanged(true);
    setSelectAll(isChecked);
    //@ts-ignore
    setCustomAreaData((prevData: AreaData[]) =>
      prevData.map((item: AreaData) => ({
        ...item,
        impactArea: {
          ...item?.impactArea,
          user_area: item?.area?.id,
          score: isChecked ? "10" : "-",
        },
      }))
    );
  };

  const handleCheckboxChange = (areaId: string) => {
    setIsChanged(true);
    //@ts-ignore
    setCustomAreaData((prevData) =>
    prevData.map((item: AreaData) => {
        return item.area.id === areaId
          ? {
              ...item,
              impactArea: {
                ...item.impactArea,
                user_area: item?.impactArea?.user_area
                  ? item?.impactArea?.user_area
                  : item.area.id,
                score: item.impactArea.score === '-' || item.impactArea.score === undefined ? "10" : "-",
              },
            }
          : item;
      })
    );
  };

  const handleInputChange = (areaId: string, newScore: string) => {
    setIsChanged(true);
    //@ts-ignore
    setCustomAreaData((prevData) =>
      prevData.map((item: AreaData) =>
        item.area.id === areaId
          ? {
              ...item,
              impactArea: {
                ...item.impactArea,
                user_area: item?.impactArea?.user_area
                  ? item?.impactArea?.user_area
                  : item.area.id,
                score: newScore,
              },
            }
          : item
      )
    );
  };

  const handleClose = () => {
    onClose();
    // setAreaData([]);

    if (screenSize === "mobile") {
      setPopover(false);
      setBackground(null);
    }
  };

  const handleSave = () => {
    onClose(areaData);
    onModalClose(areaData);
    // setAreaData([]);
    setIsChanged(false); // reset isChanged

    if (screenSize === "mobile") {
      setPopover(false);
      setBackground(null);
    }
  };

  const handleMouseEnter = (componentName: string) => {
    setHoveredComponent(componentName);
  };

  const handleMouseLeave = () => {
    setHoveredComponent("");
  };

  const indirectImpact = (function () {
    let sum_of_weighted_scores = 0;
    let total_area_weight = 0;
  
    areaData
      ?.filter(({ impactArea }) => impactArea.score && impactArea.score !== '-')
      .forEach(({ area, impactArea }) => {
        // @ts-ignore
        const area_weight = parseInt(area.goal.weight, 10);
        const weighted_score = area_weight * parseInt(impactArea.score || '0');
  
        sum_of_weighted_scores += weighted_score;
        total_area_weight += area_weight;
      });
  
    const result = (sum_of_weighted_scores / total_area_weight) || 0;
    return Math.round(result * 10) / 10;
  })();
  
  return (
    <MainWrappar
      $popover={
        screenSize === "mobile" && (dropDownMobile ? dropDownMobile : false)
      }
    >
      <Wrapper
        $mobile={screenSize !== "desktop"}
        className={`leverage-popover ${className ? className : ""}`}
        style={style}
      >
        <Head>
          <Title>
            <FlashGreenIcon />
            <span>Leverage</span>
          </Title>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Head>

        <Header>
          <List>
            {availablearea?.areas
              ?.filter((area) => area.name !== "")
              .map((area) => (
                <AreaColor color={area?.color || ""} key={area.id} />
              ))}
          </List>
          <Description>
            <TextWrap>
              <Text $mr={4}>Indirect Impact</Text>
              <div
                data-testid="hover-div"
                onMouseEnter={() => handleMouseEnter("indirect")}
                onMouseLeave={handleMouseLeave}
              >
                <PopoverCustom
                  data-testid="hovered-component"
                  content={<InDirectImpact />}
                  popoverStyle={{
                    arrowStyle: {
                      top: "102px",
                      left: "230px",
                      zIndex: 9999,
                      rotate: "-270deg",
                    },
                    containerStyle: { top: "-124px", left: "-65px" },
                  }}
                  hover={hoveredComponent === "indirect"}
                >
                  <QuestionMarkIcon />
                </PopoverCustom>
              </div>
            </TextWrap>
            <Score>{indirectImpact}</Score>
            {/* <Score>{indirect_impact}</Score> */}
            <Empty />
          </Description>
          <BodyHead></BodyHead>
        </Header>

        <BodyHead>
          <Checkbox
            checked={selectAll}
            onChange={(e) => handleSelectAllChange(e.target.checked)}
          >
            Select All
          </Checkbox>
          <BodyTitle>Score</BodyTitle>
        </BodyHead>

        <Body>
          {areaData
            ?.filter((area) => area.area.name !== "")
            .map((area) => (
              <Area
                data={area}
                key={area.area.id}
                onCheckboxChange={handleCheckboxChange}
                onInputChange={handleInputChange}
              />
            ))}
        </Body>

        <Footer>
          <Button onClick={handleSave} disabled={!isChanged} >
            Save
          </Button>
        </Footer>
      </Wrapper>
    </MainWrappar>
  );
};

export default LeveragePopover;
