import Cookies from "js-cookie";
import { verifyOtp } from "api";
import { setCookie } from "utils/StorageVariables";
import { IsUser } from "Redux/User/action";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { SetToken } from "Redux/Token/action";
import { JOURNEY_PATH } from "utils/routes";
import { getSimplifiedError } from "../../utils/error"

export const VerifyOtp = (Data, Navigate) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await verifyOtp(Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 201) {
      setCookie("userDetail", JSON.stringify(data?.user));

      const goalSettingWorkbookKey = data.user.external_accounts.find(account => "goal_setting_workbook" in account);

      if (goalSettingWorkbookKey) {
        const goalSettingWorkbookValue = goalSettingWorkbookKey.goal_setting_workbook;

        setCookie("gps_id", goalSettingWorkbookValue);

      } else {
        setCookie("gps_id", null);
      }

      if (process.env.NODE_ENV === 'development') {
        Cookies.set('token-local', JSON.stringify(data));
      }

      dispatch(IsUser(data?.user));
      dispatch(SetToken(data?.token));

      Navigate(WELCOME_PATH, { replace: true });
    }
  } catch (error) {
    const getError = getSimplifiedError(error)
    if (error.response && error.response.status === 403) {
      Error('Server error');
    } else {
      Error(error);
    }
    dispatch({
      type: ActionTypes.ERROR,
      payload: getError.length > 0 ? getError[0] : error
    })
  }
};
