import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 6px;
  border-right: 1px solid #C1CAD1;
  height: 100%;
  min-width: 294px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 14px;
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #BAC6D3;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
