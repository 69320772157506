import { Modal } from "antd";
import styled, { css } from 'styled-components';

export const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  padding: 0 12px 12px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Head = styled.div`
  padding: 9px 4px 9px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
`;

export const HeadWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.span`
  color: var(--stroke-text-caption-prussian-blue, #1B2A3D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const Close = styled.button`
  display: flex;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;

  svg {
    transform: rotate(0deg);
    transition: transform .3s;
  }

  :hover {
    svg {
      transform: rotate(90deg);
    }
  }
`;

export const Body = styled.div`
  padding: 12px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ $footer }) => $footer && css`
    border-bottom: 1px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
  `}
`;

export const Footer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
`;
