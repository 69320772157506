import { forwardRef, ReactNode, CSSProperties, ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: 'primary' | 'secondary' | 'danger' | 'gray-outlined' | 'link' | 'link-primary';
  size?: 'default' | 'medium' | 'small';
  style?: CSSProperties;
  children?: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ styleType = 'primary', size = 'default', style, children, ...rest }, ref) => {
  return (
    <ButtonStyled $type={styleType} $size={size} style={style} ref={ref} {...rest}>
      {children}
    </ButtonStyled>
  );
});

export const ButtonStyled = styled.button<{ $size?: string; $type?: string }>`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 3px;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  ${({ $size }) => $size && $size === 'default' && css`
    height: 36px;
    padding: 6px 12px;
    font-size: 12px;
    line-height: 20px;
  `}

  ${({ $size }) => $size && $size === 'medium' && css`
    font-size: 14px;
    padding: 10px 12px;
  `}

  ${({ $size }) => $size && $size === 'small' && css`
    font-size: 12px;
    line-height: 20px;
    padding: 6px 12px;
  `}

  ${({ $type }) => $type && $type === 'primary' && css`
    color: #fff;
    background-color: #1271A6;

    :focus {
      box-shadow: 0 0 0 0.25rem #1271A67F;
    }

    :hover {
      background-color: #116595;
    }

    :disabled {
      background-color: #71AACA;
    }
  `}

  ${({ $type }) => $type && $type === 'secondary' && css`
    color: #1271A6;
    background-color: #ECF3F6;

    :focus {
      box-shadow: 0 0 0 0.25rem #ECF3F67F;
    }

    :hover {
      background-color: #D0E3ED;
    }

    :disabled {
      background-color: #ECF3F6;
      opacity: .6;
    }
  `}

  ${({ $type }) => $type && $type === 'danger' && css`
    color: #F00000;
    background-color: #FFF0F0;

    :focus {
      box-shadow: 0 0 0 0.25rem #F00000;
    }

    :hover {
      background-color: #FAD6D6;
    }

    :disabled {
      background-color: #FA8B8B;
    }
  `}

  ${({ $type }) => $type && $type === 'gray-outlined' && css`
    color: #525252;
    background-color: #fff;
    border: 1px solid #737373;

    :focus {
      box-shadow: 0 0 0 0.25rem #e9e9e9;
    }

    :hover {
      background-color: #e9e9e9;
    }

    :disabled {
      background-color: #fff;
      opacity: .5;
    }
  `}

  ${({ $type }) => $type && $type === 'link' && css`
    color: #FF9900;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    :disabled {
      background-color: #ECF3F6;
    }
  `}

  ${({ $type }) => $type && $type === 'link-primary' && css`
    color: #1271A6;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    :disabled {
      background-color: #ECF3F6;
      opacity: .5;
    }
  `}
`;

export default Button;
