import { useEffect, useState } from "react";
import { Modal } from "antd";
import {
  AnimationCard,
  AnimationContainer,
  CenterLine,
  GenerateSchedule,
  ScheduleBody,
  ScheduleDate,
  ScheduleFooter,
  ScheduleHeader,
  ScheduleLoader,
  ScheduleTable,
  ScheduleTitle,
  Wrapper,
} from "./styled";

// @ts-ignore
import { ReactComponent as ScheduleDark } from "assets/images/priorities/scheduleDark.svg";
import ScheduleList from "../scheduleList";

// @ts-ignore
import { ReactComponent as Close } from "assets/images/priorities/Iconclose.svg";
// @ts-ignore
import { CursorPointer } from "../../styled";
// @ts-ignore
import scheduleStars from "assets/images/priorities/scheduleStars.gif";

const ScheduleModel = ({
  open,
  onClose,
  startWeekDate,
  endWeekDate,
  scheduleData,
  scheduleLoading,
}: {
  open: boolean;
  onClose: () => void;
  startWeekDate: string | null;
  endWeekDate: string | null;
  scheduleData?: any;
  scheduleLoading?: boolean;
}) => {
  const [schudelAnimation, setScheduleAnimation] = useState(false);

  const schedule = scheduleData?.schedule || scheduleData?.weekly_schedule || scheduleData?.activities;

  const allOccurrences = schedule?.flatMap((activity: any) => {
    return activity.occurrences.map((occurrence: any) => ({
      ...occurrence,
      activity_name: activity.activity_name,
      activity_type: activity.activity_type,
    }));
  });

  const formatDateRange = (startDate: string, endDate: string) => {
    const options = { month: "short", day: "numeric" };
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startFormat = new Intl.DateTimeFormat(
      "en-US",
      options as Intl.DateTimeFormatOptions | undefined
    ).format(start);
    const endFormat = new Intl.DateTimeFormat(
      "en-US",
      options as Intl.DateTimeFormatOptions | undefined
    ).format(end);

    if (start.getMonth() === end.getMonth()) {
      return `${startFormat}-${endFormat.split(" ")[1]}`;
    } else {
      return `${startFormat}-${endFormat}`;
    }
  };

  const getWeekDates = (startDate: string) => {
    const dates = [];
    const start = new Date(startDate);

    for (let i = 0; i < 7; i++) {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      dates.push(date);
    }

    return dates;
  };

  const weekDates = getWeekDates(startWeekDate as string);

  const formattedRange = formatDateRange(
    startWeekDate as string,
    endWeekDate as string
  );

  useEffect(() => {
    if (!open) {
      return setScheduleAnimation(false);
    }
    const interval = setInterval(() => {
      setScheduleAnimation((prevState) => !prevState);
    }, 5000);

    return () => clearInterval(interval);
  }, [open]);

  return (
    <Modal
      bodyStyle={{
        padding: "0px 12px",
      }}
      width={1230}
      open={open}
      centered
      footer={null}
      closable={false}
      onCancel={onClose}
    >
      <Wrapper>
        <ScheduleHeader>
          <div>
            <ScheduleTitle>
              <ScheduleDark />
              YOUR SCHEDULE
            </ScheduleTitle>
            <CenterLine></CenterLine>
            <ScheduleDate>{formattedRange}</ScheduleDate>
          </div>
          <CursorPointer onClick={onClose}>
            <Close />
          </CursorPointer>
        </ScheduleHeader>
        {scheduleLoading ? (
          schudelAnimation ? (
            <GenerateSchedule>
              <img src={scheduleStars} alt="" width={80} height={80} />
              Generating your schedule...
            </GenerateSchedule>
          ) : (
            <ScheduleLoader>
              <AnimationContainer>
                {Array(7)
                  .fill(null)
                  .map((_, index) => (
                    <AnimationCard key={index} />
                  ))}
              </AnimationContainer>
            </ScheduleLoader>
          )
        ) : (
          <>
            <ScheduleBody>
              <ScheduleTable>
                {weekDates.map((date, index) => (
                  <ScheduleList key={index} date={date} data={allOccurrences} />
                ))}
              </ScheduleTable>
            </ScheduleBody>

            <ScheduleFooter>
              This schedule is view-only and updated when you add new activities
              to your week.
            </ScheduleFooter>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default ScheduleModel;
