import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import Button from '../../../components/Button';
import { zoomInSlightlyMixin } from '../../../components/Auth/animated';

// Define the keyframes
const fadeIn = keyframes`
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeInMixin = css`
  transform: translateY(5px);
  opacity: 0;

  ${({ $animate, $animateDelay = 0 }) => $animate && css`
    animation: ${fadeIn} 0.4s ease-out forwards ${$animateDelay}ms;
  `}
`;

const circleAreaMove = keyframes`
  0% {
    transform: translateX(120px) translateY(-120px) scale(1.2);
  }
  100% {
    transform: translateX(0) translateY(-0) scale(0.8);
  }
`;

const circleAreaMoveMobile = keyframes`
  0% {
    transform: translateX(45px) translateY(-45px) scale(0.8);
  }
  100% {
    transform: translateX(0) translateY(-0) scale(1);
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${({ $first }) => $first && css`
    width: 45%;
    justify-content: center;

    ${({ theme }) => theme.max('lg')`
      width: 100%;
    `}
  `}

  ${({ $second }) => $second && css`
    width: 55%;
    overflow: hidden;

    ${({ theme }) => theme.max('lg')`
      justify-content: center;
      width: 100%;
      left: 0;
      position: relative;
    `}
  `}
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  ${zoomInSlightlyMixin}
`;

export const Circle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12vw;
  margin-top: -20vw;
  background: #85C4C9;
  opacity: 0.1;
  border-radius: 50%;
  width: 44vw;
  height: 44vw;
  transform: translateX(120px) translateY(-120px) scale(1.2);

  ${({ theme }) => theme.max("xl2")`
    margin-top: -25vw;
    width: 54vw;
    height: 54vw;
  `}

  ${({ theme }) => theme.max("md")`
    margin-top: -64vw;
    margin-left: -46vw;
    width: 80vw;
    height: 80vw;
  `}

  ${({ $animate }) => $animate && css`
    animation: ${circleAreaMove} 0.8s cubic-bezier(0, 0, 0, 1) forwards;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 135vw;
    height: 135vw;
    top: 0;
    left: initial;
    right: 0;
    margin-left: initial;
    margin-right: -44vw;
    margin-top: -19vw;
    transform: translateX(45px) translateY(-45px) scale(0.8);

    ${({ $animate }) => $animate && css`
      animation: ${circleAreaMoveMobile} 0.8s cubic-bezier(0, 0, 0, 1) forwards;
    `}
  `}
`;

export const Screen = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 76px);

  ${({ theme }) => theme.max('lg')`
    flex-direction: column-reverse;
    min-height: calc(100vh - 50px);
    padding: 12vh 0 8vh;
  `}

  ${({ theme }) => theme.max('md')`
    padding: 20vh 0 8vh;
  `}

  ${({ theme }) => theme.max('sm')`
    padding: 10vh 0 8vh;
  `}
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  padding: 0 20px;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 65.5%;
  margin-bottom: 12px;
  color: rgba(#000, 0.87);
  ${fadeInMixin}
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #4B545B;
  margin-bottom: 45px;
  ${fadeInMixin}
`;

export const AreaButton = styled.button`
  background: #ECF3F7;
  border-radius: 2px;
  border: none;
  padding: 10px 0;
  color: #1271A6;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  width: 100%;
  transition: all .3s;

  &:hover {
    background: #e1e7eb;
    color: #1271A6;
  }

  ${({ $active }) => $active && css`
    background: #1271A6 !important;
    color: #fff !important;
  `}

  ${fadeInMixin}
`;

export const Next = styled(Link)`
  color: #4B545B;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  margin-top: 50px;

  img {
    position: absolute;
    top: 6px;
    margin-left: 7px;
    transition: transform .3s;
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: 62px;
    height: 62px;
    background: #85C4C9;
    opacity: 0.1;
    top: 50%;
    left: 50%;
    margin-top: -31px;
    margin-left: -31px;
    transition: transform .3s;
  }

  &:hover {
    color: #4B545B;
    text-decoration: none;

    &::before {
      transform: scale(1.1);
    }
  }

  ${fadeInMixin}
`;

export const Submit = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  text-transform: initial;
  background: #1271A6;
  font-weight: 500;
  border-radius: 2px;
  color: #4B545B;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 34px;
  max-width: 39vw;

  ${({ theme }) => theme.max('md')`
    max-width: 600px;
  `}

  ${({ theme }) => theme.max('sm')`
    max-width: initial;
  `}
`;

export const Div = styled.div`
  ${({ $mb }) => $mb && css`
    margin-bottom ${$mb}px;
  `}
`;

export const AreaIconsList = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

// Define the keyframes
const easeIn = keyframes`
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Area = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: -6px;
  position: relative;
  transform: translateY(5px);
  opacity: 0;

  ${({ $color }) => $color && css`
    background: ${$color};
  `}

  ${({ $index, $animate }) => $index && $animate && css`
    z-index: ${10-$index};
    animation: ${easeIn} 0.4s cubic-bezier(0.80, 0.01, 0.05, 1) forwards ${(10-$index)*50}ms;
  `}
`;

export const TypeTitle = styled.span`
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.15px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 16px;
  transform: translateY(5px);
  opacity: 0;

  ${({ theme }) => theme.max('lg')`
    font-size: 32px;
    line-height: 130%;
  `}

  ${({ $animate }) => $animate && css`
    animation: ${easeIn} 0.4s ease-out forwards 200ms;
  `}
`;

export const TypeText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06203A;
  transform: translateY(5px);
  opacity: 0;

  ${({ $animate }) => $animate && css`
    animation: ${easeIn} 0.4s ease-out forwards 400ms;
  `}
`;
