import { ActionTypes } from './type'

const initialAvailablearea = {
    availablearea: [],
    loading: false,
};

export default (state = initialAvailablearea, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_AVAILABLE_AREAS_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_AVAILABLE_AREAS_SUCCESS:
            return { availablearea: action.payload, loading: false };
        case ActionTypes.FETCH_AVAILABLE_AREAS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
}