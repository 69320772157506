import styled from 'styled-components'

const MissionButton = ({ text, onClick, disabled, marginTop }) => {

    return (
        <Wrapper style={{ marginTop: marginTop }}>
            <Button onClick={onClick} disabled={disabled}>{text}</Button>
        </Wrapper>
    )
}

export default MissionButton;

const Wrapper = styled.div`
/* height: 36px; */

/* margin-top: 0.7rem; */


`
const Button = styled.button`

display: flex;
height: 36px;
padding: 6px 12px;
justify-content: center;
align-items: center;
/* gap: 6px; */
background:${({ disabled }) => disabled ? "#71AACA " : "#1271A6"};
border-radius: 3px;
color: #fff;
border: none;
outline: none;



`;