import { IFocusedExtreme } from '../ListFocusedExtremes/type';
import { ActionTypes } from './type';

const initialState = {
  data: [],
  loading: false,
  duration: 0
};

const calcTotalMins = (data: IFocusedExtreme[]) => {
  return data.reduce((total, item) => {
    return (
      total +
      Number(
        item?.prioritized_activity?.total_time
          ? item?.prioritized_activity?.estimated_weekly_duration
          : item?.task?.estimated_duration || 0
      )
    );
  }, 0);
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        duration: calcTotalMins(action.payload)
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}