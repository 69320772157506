import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Setting } from "assets/images/setting_wheel.svg";
import { ReactComponent as Expand } from "assets/images/expand_vertical.svg";
import { ReactComponent as Add } from "assets/images/collapse_add.svg";
import { ReactComponent as Prioritize } from "assets/images/prioritize.svg";
import { ReactComponent as PrioritizeBold } from "assets/images/DragBold.svg";
import { ReactComponent as Life } from "assets/images/life_vector.svg";
import { ReactComponent as Options } from "assets/images/option_icon.svg";
import { ReactComponent as GreenTip } from "assets/images/green_tip.svg";
import { ReactComponent as PrioritizeVertical } from "assets/images/vertical_prioritize.svg";
import { Collapse, Select } from "antd";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AppointmentEvent from "../Calendar/AppointmentEvent";
import { EVENTS } from "../Calendar/Constants";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const SchedulerPriorityNav = ({
  dummyAppointment,
  draggedEvent,
  setDraggedEvent,
  setDummyAppointment,
  isSettingModal,
  setIsSettingModal,
}) => {
  const [events, setEvents] = useState(EVENTS);

  const handleSettingModal = () => {
    setIsSettingModal(!isSettingModal);
  };

  // const dummyAppointment = [
  //   {
  //     id: 3,
  //     status: "CI",
  //     location: "Connecticut",
  //     resource: "Alex Hales",
  //     address: "1241 E Main St\n Stamford\n CT 06902\n United States",
  //   },
  //   {
  //     id: 4,
  //     status: "CID",
  //     location: "Connecticutivy Xys",
  //     resource: "James chadwick",
  //     address: "1241 E Main St\n Stamford\n CT 06902\n United States",
  //   },
  // ];
  // const sideNavDummy = [
  //   {
  //     id: 1,
  //     healthsymbol: "H",
  //     title: " Sleep 8 hours enjoying",
  //     start: new Date(2023, 7, 28, 11, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 7, 28, 12, 0),
  //   },
  //   {
  //     id: 1,
  //     healthsymbol: "H",
  //     title: " Sleep 8 hours enjoying",
  //     start: new Date(2023, 7, 28, 11, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 7, 28, 12, 0),
  //   },
  //   {
  //     id: 1,
  //     healthsymbol: "H",
  //     title: " Sleep 8 hours enjoying",
  //     start: new Date(2023, 7, 28, 11, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 7, 28, 12, 0),
  //   },
  //   {
  //     id: 1,
  //     healthsymbol: "H",
  //     title: " Sleep 8 hours enjoying",
  //     start: new Date(2023, 7, 28, 11, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 7, 28, 12, 0),
  //   },
  //   {
  //     id: 1,
  //     healthsymbol: "H",
  //     title: " Sleep 8 hours enjoying",
  //     start: new Date(2023, 7, 28, 11, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 7, 28, 12, 0),
  //   },
  // ];

  const [activeKey, setActiveKey] = useState(['1']);

  const handleCollapse = (key) => {
    setActiveKey(key);
  };

  const handleAddClick = () => {
    alert("Add icon clicked");
  };

  const handlePrioritizeClick = () => {
    alert("Prioritize icon clicked");
  };
  const handleOptions = () => {
    alert("Option icon clicked");
  };
  const getPopupContainer = (triggerNode) => triggerNode.parentNode;
  const { selectedGoalAction } = useSelector((state) => state.globalReducer);

  const onParentDragEnd = (result) => {
    const { destination, source } = result;

    console.log({ destination, source }, "azeem");
  };

  const onNestedDragEnd = (result) => {
    console.log(result);
  };
  const getItemStyle = (isDragging, draggableStyle) => {
    let copyDraggableStyle = draggableStyle;
    copyDraggableStyle.position = isDragging && "fixed";
    return {
      userSelect: "none",
      background: "#102339",
      ...copyDraggableStyle,
    };
  };

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver && "transparent",
    background: "#0f2339",
  });

  // const [draggedForReorder, setDraggedForReorder] = useState(false);

  const onDragStart = (e, apm) => {
    console.log("Drag Start Event:", e);
    setDraggedEvent(apm);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    // console.log("Drag Over Event:", e);
  };

  const onDrop = (e) => {
    e.preventDefault();
    // console.log("Drop Event:", e);
    const newOrder = [...dummyAppointment];

    const draggedIndex = newOrder.findIndex(
      (apm) => apm.id === draggedEvent.id
    );
    // console.log("Drag event", draggedIndex);

    const dropElement = e.currentTarget;
    // console.log("Drop Element:", dropElement);

    const dropItemId = parseInt(dropElement.dataset.id);
    // console.log("Dropped Item ID:", dropItemId);

    if (!isNaN(dropItemId)) {
      const dropIndex = newOrder.findIndex((apm) => apm.id === dropItemId);

      if (draggedIndex !== -1 && dropIndex !== -1) {
        const [draggedItem] = newOrder.splice(draggedIndex, 1);
        newOrder.splice(dropIndex, 0, draggedItem);
        setDummyAppointment(newOrder);
      }
      // Handle your reordering logic here
    }

    // Clear the dragged event
    setDraggedEvent(null);
  };
  console.log(dummyAppointment, "harim");

  return (
    <MainContainer>
      <Wrapper>
        <Head>
          <div style={{ marginLeft: "10px" }}>
            <Heading white>PRIORITIES</Heading>
          </div>
          <IconWrapper>
            <Icon onClick={handleSettingModal}>
              <Setting />
            </Icon>
            <Icon>
              <Expand />
            </Icon>
          </IconWrapper>
        </Head>
        <Collapse
          className="parent-collapse"
          activeKey={activeKey}

          onChange={handleCollapse}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header={
              <ParentCollapse onClick={(e) => e.stopPropagation()}>
                <Heading white>TOP WEEKLY PRIORITIES</Heading>
              </ParentCollapse>
            }
            key={"1"}
          >
            {dummyAppointment?.length <= 0 ? (
              <div style={{ borderTop: "4px solid #fff" }}>
                <EmptyDiv>
                  <EmptyText>
                    Here you can see top 10 priorities for current week.
                  </EmptyText>
                </EmptyDiv>
              </div>
            ) : (
              <>
                {dummyAppointment?.map((apm) => (
                  <div
                    // onDragStart={() => setDraggedEvent(apm)}
                    key={apm.id}
                    data-id={apm.id} // store the id in a data attribute
                    onDragStart={(event) => onDragStart(event, apm)}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    draggable
                  >
                    <div>
                      <AppointmentEvent appointment={apm} />
                    </div>
                  </div>
                ))}
              </>
            )}

            {/* {dummyAppointment?.map((apm) => (
              <div
                // onDragStart={() => setDraggedEvent(apm)}
                key={apm.id}
                data-id={apm.id} // store the id in a data attribute
                onDragStart={(event) => onDragStart(event, apm)}
                onDragOver={onDragOver}
                onDrop={onDrop}
                draggable
              >
                <div>
                  <AppointmentEvent appointment={apm} />
                </div>
              </div>
            ))} */}

            {/* <HeadingCenter>
            Here you can see top 10 priorities for current week.
          </HeadingCenter> */}
            {/* <DragDropContext onDragEnd={onParentDragEnd}>
            <Droppable droppableId="goal-actions-list-nested">
              {(provided, snapshot) => (
                <div
                  className="characters-nested"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {sideNavDummy?.map((goalAction, index) => (
                    <Draggable
                      key={goalAction}
                      draggableId={`${"goal-action"}-${index}}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <GoalAction width="100%">
                            <Actions
                              startColor={"#2F4358"}
                              endColor={"#2F4358"}
                              width="100%"
                            >
                              <div className="d-flex justify-content-between w-100">
                                <div className="d-flex w-75">
                                  <Index bg={"#f06517"}>{goalAction?.id}</Index>
                                  <Index bordered>
                                    {goalAction?.healthsymbol}
                                  </Index>
                                  <SubHeading width="77%" className="mx-2" sm>
                                    {goalAction?.title}
                                  </SubHeading>
                                </div>
                                <div>
                                  <Icon
                                    style={{
                                      transform: "translate(5px)",
                                    }}
                                    onClick={handlePrioritizeClick}
                                  >
                                    <Prioritize />
                                  </Icon>
                                </div>
                              </div>
                            </Actions>
                          </GoalAction>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext> */}
          </Panel>
        </Collapse>

        <Collapse
          className="parent-collapse"
          activeKey={activeKey}
          onChange={handleCollapse}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            header={
              <ParentCollapse onClick={(e) => e.stopPropagation()}>
                <Heading white>MASTER TASK LIST</Heading>
                <CollapseActions>
                  <Icon onClick={handleAddClick}>
                    <Add />
                  </Icon>
                </CollapseActions>
              </ParentCollapse>
            }
            key="2"
          >
            <SelectWrapper>
              <GoalActionWrapper>
                <GoalAction width={"100%"}>
                  <Actions
                    width={"100%"}
                    startColor={"#4a937a"}
                    endColor={"#55d2a8"}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex">
                        <Index bg={"#B4C3D2"}>1</Index>
                        <Index bordered bg={"#EBEEF2"}>
                          T
                        </Index>
                        <SubHeading width={"70%"} className="mx-2" sm>
                          Buy a House
                        </SubHeading>
                      </div>
                      {/* <GoalsHeading>22/03/2023</GoalsHeading> */}
                      <div>
                        <Icon
                          style={{
                            transform: "translate(5px)",
                          }}
                          onClick={handlePrioritizeClick}
                        >
                          <Prioritize />
                        </Icon>
                      </div>
                    </div>
                  </Actions>
                </GoalAction>
              </GoalActionWrapper>
            </SelectWrapper>
          </Panel>
        </Collapse>
      </Wrapper>

      {/* <PriorityItemContainer>
        <DragDropContext onDragEnd={onParentDragEnd}>
          <Droppable droppableId="goal-actions-list">
            {(provided, snapshot) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {Array(4)
                  .fill()
                  .map((goalAction, index) => (
                    <Draggable
                      key={goalAction}
                      draggableId={`${"goal-action"}-${index}}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Collapse
                            className="parent-collapse"
                            activeKey={activeKey}
                            onChange={handleCollapse}
                          >
                            <Panel
                              header={
                                <ParentCollapse
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Heading white>GOALS</Heading>
                                  <CollapseActions>
                                    <Icon onClick={handleAddClick}>
                                      <Add />
                                    </Icon>
                                    <Icon onClick={handlePrioritizeClick}>
                                      <PrioritizeBold
                                        style={{
                                          height: "23px",
                                          width: "23px",
                                        }}
                                      />
                                    </Icon>
                                  </CollapseActions>
                                </ParentCollapse>
                              }
                              key={index}
                            >
                              <Collapse
                                className="child-collapse"
                                defaultActiveKey="1"
                              >
                                <Panel
                                  header={
                                    <NestedCollapse
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Heading grey className="d-flex ">
                                        <Icon className="me-2">
                                          <Life />
                                        </Icon>
                                        LIFE
                                      </Heading>
                                      <CollapseActions>
                                        <Icon onClick={handleOptions}>
                                          <Options />
                                        </Icon>
                                      </CollapseActions>
                                    </NestedCollapse>
                                  }
                                  key={index}
                                >
                                  <Collapse className="nested-collapse">
                                    <Panel
                                      header={
                                        <NestedCollapse
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Heading
                                            nested
                                            white
                                            className="d-flex "
                                          >
                                            <Index>1</Index>
                                            <Icon sm className="mx-2">
                                              <GreenTip />
                                            </Icon>
                                            Health
                                            <span>&nbsp; {">"} Sleep</span>
                                          </Heading>
                                          <CollapseActions>
                                            <Icon onClick={handleOptions}>
                                              <Options />
                                            </Icon>
                                          </CollapseActions>
                                        </NestedCollapse>
                                      }
                                      key={index}
                                    >
                                      <DragDropContext
                                        onDragEnd={onParentDragEnd}
                                      >
                                        <Droppable droppableId="goal-actions-list-nested">
                                          {(provided, snapshot) => (
                                            <div
                                              className="characters-nested"
                                              {...provided.droppableProps}
                                              ref={provided.innerRef}
                                              style={getListStyle(
                                                snapshot.isDraggingOver
                                              )}
                                            >
                                              {Array(4)
                                                .fill()
                                                .map((goalAction, index) => (
                                                  <Draggable
                                                    key={goalAction}
                                                    draggableId={`${"goal-action"}-${index}}`}
                                                    index={index}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided
                                                            .draggableProps
                                                            .style
                                                        )}
                                                      >
                                                        <GoalAction>
                                                          <Actions
                                                            startColor={
                                                              "#4a937a"
                                                            }
                                                            endColor={"#55d2a8"}
                                                          >
                                                            <Index>1</Index>
                                                            <Index bordered>
                                                              H
                                                            </Index>
                                                            <SubHeading
                                                              className="mx-2"
                                                              sm
                                                            >
                                                              Sleep 8 hours
                                                              enjoying
                                                            </SubHeading>
                                                            <GoalsHeading>
                                                              Habit
                                                            </GoalsHeading>
                                                            <Icon
                                                              style={{
                                                                transform:
                                                                  "translate(5px)",
                                                              }}
                                                              onClick={
                                                                handlePrioritizeClick
                                                              }
                                                            >
                                                              <PrioritizeVertical />
                                                              <Prioritize />
                                                            </Icon>
                                                          </Actions>
                                                        </GoalAction>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </Panel>
                                  </Collapse>
                                </Panel>
                              </Collapse>
                            </Panel>
                          </Collapse>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Collapse
          className="parent-collapse"
          activeKey={activeKey}
          onChange={handleCollapse}
        >
          <Panel
            header={
              <ParentCollapse onClick={(e) => e.stopPropagation()}>
                <Heading white>TOP DECISIONS</Heading>
                <CollapseActions>
                  <Icon onClick={handleAddClick}>
                    <Add />
                  </Icon>
                  <Icon onClick={handlePrioritizeClick}>
                    <PrioritizeBold style={{ height: "23px", width: "23px" }} />
                  </Icon>
                </CollapseActions>
              </ParentCollapse>
            }
            key="2"
          >
            <SelectWrapper>
              <GoalActionWrapper>
                <GoalAction width={"100%"}>
                  <Actions
                    width={"100%"}
                    startColor={"#4a937a"}
                    endColor={"#55d2a8"}
                  >
                    <div className="d-flex justify-content-between w-100">
                      <div className="d-flex">
                        <Index>1</Index>
                        <Index bordered>H</Index>
                        <SubHeading width={"70%"} className="mx-2" sm>
                          Buy a House
                        </SubHeading>
                      </div>
                      <GoalsHeading>22/03/2023</GoalsHeading>
                      <div>
                        <Icon
                          style={{
                            transform: "translate(5px)",
                          }}
                          onClick={handlePrioritizeClick}
                        >
                          <Prioritize />
                        </Icon>
                      </div>
                    </div>
                  </Actions>
                </GoalAction>
                <GoalAction width={"100%"}>
                  <Actions
                    width={"100%"}
                    startColor={"#4a937a"}
                    endColor={"#55d2a8"}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <Index>2</Index>
                        <Index bordered>H</Index>
                        <SubHeading width={"70%"} className="mx-2" sm>
                          Top Fund Manager Branch
                        </SubHeading>
                      </div>
                      <div>
                        <Icon
                          style={{
                            transform: "translate(5px)",
                          }}
                          onClick={handlePrioritizeClick}
                        >
                          <Prioritize />
                        </Icon>
                      </div>
                    </div>
                  </Actions>
                </GoalAction>
              </GoalActionWrapper>

              <PriorityDropdown>
                <span style={{ marginRight: "8px" }}>1.</span>
                <Select
                  getPopupContainer={getPopupContainer}
                  popupClassName="select_priority"
                  className="priority_select"
                  showSearch
                  placeholder="Choose From Decisions"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "Not Identified",
                    },
                    {
                      value: "2",
                      label: "Closed",
                    },
                    {
                      value: "3",
                      label: "Communicated",
                    },
                    {
                      value: "4",
                      label: "Identified",
                    },
                    {
                      value: "5",
                      label: "Resolved",
                    },
                    {
                      value: "6",
                      label: "Cancelled",
                    },
                  ]}
                />
              </PriorityDropdown>
              <PriorityDropdown>
                <span style={{ marginRight: "8px" }}>2.</span>
                <Select
                  getPopupContainer={getPopupContainer}
                  popupClassName="select_priority"
                  className="priority_select"
                  showSearch
                  placeholder="Choose From Decisions"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "Not Identified",
                    },
                    {
                      value: "2",
                      label: "Closed",
                    },
                    {
                      value: "3",
                      label: "Communicated",
                    },
                    {
                      value: "4",
                      label: "Identified",
                    },
                    {
                      value: "5",
                      label: "Resolved",
                    },
                    {
                      value: "6",
                      label: "Cancelled",
                    },
                  ]}
                />
              </PriorityDropdown>
            </SelectWrapper>
          </Panel>
        </Collapse>
      </PriorityItemContainer> */}
    </MainContainer>
  );
};

export default SchedulerPriorityNav;

const MainContainer = styled.div`
  padding: 8px 12px 8px 8px;
  /* background-color: #fff; */
  background-color: ${({ theme }) => theme && theme?.text?.white};
  width: 274px;
  height: calc(100vh - 67px - 80px);
  border-right: 1px solid #c1cad1;
`;

const Wrapper = styled.div`
  /* border: 1px solid red; */
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 4px;
`;
const HeadingCenter = styled.h1`
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c959f;
  text-align: center;
  margin: auto;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: #152a3e;
  padding: 0 10px;
`;

const Heading = styled.h2`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: ${({ nested }) => (nested ? "14px" : "14px")};
  line-height: 20px;
  /* color: ${({ theme, white, grey }) =>
    (white && theme?.text?.white) || (grey && theme?.text?.secondaryLight)}; */
  color: ${({ theme }) => theme && theme?.text?.black};
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: ${({ theme }) => theme?.text?.white};
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-2px);
`;
const Icon = styled.div`
  margin: 0 4px;
  cursor: pointer;
  svg {
    width: ${({ sm, md }) => (sm && "10px") || (md ? "14px" : "16px")};
    height: ${({ sm, md }) => (sm && "10px") || (md ? "14px" : "16px")};
  }
`;
const Index = styled.div`
user-select: none;
  width: 18px;
  height: 18px;
  border-radius: ${({ bordered }) => (bordered ? "3px" : " 50%")};
  background: ${({ bg }) => (bg ? bg : "#2f4358")};
  color: ${({ bordered, theme }) =>
    bordered ? theme?.text?.primary_light : theme?.text?.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: ${({ bordered }) => bordered && "bold"};
  margin-left: ${({ bordered }) => bordered && "0.5rem"};
  padding: 0 0.5rem;
`;
const PriorityItemContainer = styled.div`
  height: calc(100vh - 67px - 130px);
  overflow-y: auto;
  background-color: #04192e;
`;
const ParentCollapse = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: end;
  .ant-collapse-header {
    width: 100% !important;
  }
`;
const NestedCollapse = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: end;
  .ant-collapse-header {
    width: 100% !important;
  }
`;

const CollapseActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoalAction = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: ${({ width }) => (width ? width : "218px")};
  border-top: 2px solid #e6e8ea !important;
`;
const Actions = styled.div`
  background-color: ${({ theme }) => theme && theme?.common?.white};
  width: ${({ width }) => (width ? width : "218px")};
  /* height: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-left: 4px solid;
  border-image: linear-gradient(
      38.44deg,
      ${(props) => props.startColor} 14.59%,
      ${(props) => props.endColor} 71.58%
    )
    1;
`;

const SubHeading = styled.p`
user-select: none;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  padding: 0;
  width: ${({ width }) => (width ? width : "120px")};
  white-space: nowrap;
  color: ${({ theme }) => theme && theme?.text?.black};
  overflow: hidden;
  text-overflow: ellipsis;
`;
const GoalsHeading = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #4f9be8;
  margin: 0;
  padding: 0;
`;

const PriorityDropdown = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #102339;
  border-bottom: 1px solid #798b99;
  margin: 4px 0px;
  & span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #586573;
    padding-left: 10px;
  }
  & .ant-select {
    max-width: 100% !important;
    width: inherit !important;
    background-color: #0f2339 !important;
    border: none !important;
  }
  & .ant-select-selection-placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #586573 !important;
  }
  & .ant-select-selection-search {
    padding: 0;
  }
  & .ant-select-selection-search > .ant-select-selection-search-input {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #586573;
    transform: translateY(2px);
    padding: 0;
  }
  & .ant-select-selection-item {
    color: #586573 !important;
  }
  & .ant-select-dropdown {
    background-color: #0f2339 !important;
  }
`;

const SelectWrapper = styled.div`
  background-color: #e6e8ea !important;
`;

const GoalActionWrapper = styled.div`
  padding: 3px 4px;
`;

const EmptyDiv = styled.div`
  border: 2px solid #ced0d4;
  height: 336px;
  padding: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyText = styled.h4`
  width: 219px;
  color: #8c959f;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
