import { ActionTypes } from "./type";

let INITIAL_STATE = {
  loading: false,
  error: null,
  success: false,
  data: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_UPDATE_FOCUS_PRIORITIES:
      return {
        ...state,
        loading: true
      };

    case ActionTypes.UPDATE_UPDATE_FOCUS_PRIORITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };

    case ActionTypes.UPDATE_UPDATE_FOCUS_PRIORITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      default:
        return state;   
    }
}