import { useRef, useState, useEffect } from 'react';
import styled from "styled-components";
import { Modal } from "antd";
import ReactPlayer from "react-player/lazy";

const getTiming = step => {
  switch(step) {
    case 1:
      return 0;
    case 2:
      return 60;
    case 3:
      return 120;
    case 4:
      return 180;
    case 5:
      return 240;
    case 6:
      return 300;
    case 7:
      return 360;
    default:
      return 0;
  }
}

const VideoPlayer = ({ isOpen, step, onClose }) => { 
  const $player = useRef(null);
  const [canPlay, setCanPlay] = useState(false);

  const timing = getTiming(step);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        $player.current.seekTo(timing);

        if ($player.current) {
          setCanPlay(true);
        }
      }, 250);
    }

    if (!isOpen) {
      setCanPlay(false);
    }
  }, [isOpen]);

  return (
    <CustomModal
      width="688"
      height="408"
      centered
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      destroyOnClose
    >
      <ReactPlayer
        url="https://vimeo.com/778638378/d7b5583561"
        playing={canPlay}
        controls
        className="react-player"
        ref={$player}
      />
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-close {
      margin-right: -3px;
      margin-top: 24px;
      .ant-modal-close-x {
        background: #fff;
        width: 28px;
        height: 28px;
        line-height: 20px;
      }
    }
    .ant-modal-header {
      display: none;
    }
    iframe {
      border: 2px solid #fff;
    }
    .ant-modal-footer {
      display: none;
    }
  }
`;

export default VideoPlayer;
