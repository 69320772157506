import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
import { deleteValues } from "api";

export const DeleteValues = (id, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data, status } = await deleteValues(id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (callback) {
      return callback();
    }

    // if (status === 204) {
    //   let MasterPlanId = getCookie("MasterPlanId");
    //   if (MasterPlanId)
    //     return dispatch(RetrieveMasterPlanDetails(MasterPlanId));
    // }
  } catch (error) {
    Error(error?.response?.data);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });

    if (callback) {
      return callback();
    }
  }
};
