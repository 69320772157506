import { FC } from 'react';

import { Select } from "antd";

import OptionLabel from './OptionLabel';

// @ts-ignore
import styled from './styled.module.scss';
import { IClient } from 'scenes/Coaching/UserRow';

type IProps = {
  value?: string;
  options: IClient[];
  onChange: (value: string) => void;
}

const ClientsSelect: FC<IProps> = ({ value, options, onChange, ...props }) => {
  const handleChange = (value: string) => {
    onChange(value);
  }

  const verifiedValue = options.findIndex((item) => item.id === value) >= 0 ? value : null;

  return (
    <>
      <Select
        style={{
          width: "100%",
          minWidth: '280px',
          maxWidth: 'initial'
        }}
        className={styled.dropdown}
        value={verifiedValue}
        getPopupContainer={(trigger) => trigger.parentNode}
        options={options.map(item => ({ ...item, value: item.id, label: <OptionLabel data={item} key={item.id} /> }))}
        filterOption={false}
        placeholder="Select"
        onChange={handleChange}
        {...props}
      >
      </Select>
    </>
  );
}

export default ClientsSelect;
