import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './styled';

import DeleteAccountModal from '../DeleteAccountModal';

import { DeleteAccount } from 'Redux/DeleteAccount/action';

const DeleteAccountSection = ({ data }) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.deleteAccount);

  const [isOpen, setIsOpen] = useState(false);

  const onDeleteConfirmHandle = isDelete => {
    if (isDelete)
      dispatch(DeleteAccount(data.public_id || data.id));
    else
      setIsOpen(false);
  }

  return (
    <Styled.Wrapper>
      <Styled.Button onClick={() => setIsOpen(true)} type="button">Delete Account</Styled.Button>
      <DeleteAccountModal show={isOpen} loading={loading} onClose={onDeleteConfirmHandle} />
    </Styled.Wrapper>
  );
};

export default DeleteAccountSection;
