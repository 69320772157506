import { min, max, between, BREAKPOINTS } from "./mixins";

const colors = {
  peach: "#FEAB3D",
  denim: "#1271A6",
  white: "#fff",
  black: "#000",
};

export default {
  breakpoints: BREAKPOINTS,

  schedular_palette_light: {
    active: "#ECF3F6",

    primary: {
      light: "#418DB8",
      main: "#116595",
      dark: "#04192e",
    },
 

    secondary: {
      main: "#FF9900",
    },
    success: {
      main: "#789f08",
    },
    common: {
      black: "#000",
      white: "#fff",
      grey: "#F5F5F5",
    },
    background: {
      dark: "#04192e",
      light: "#0F2338",
    },
    link: {
      main: "#00fff0",
    },
    text: {
      primary: "#1B2A3D",
      secondary: "#6D7F8E",
      secondaryLight: "#A3A3A3",
      white: "#fff",
      black: "#000000",
      primary_dark: "#1B2A3D",
      primary_light: "#116595;",
    },
    quadrant: {
      q1: "#9B39E8",
      q2: "#E38F27",
      q3: "#DE3131",
      q4: "#3BB748",
    },
  },
  schedular_palette_dark: {
    active: "#ECF3F6",
    primary: {
      light: "#418DB8",
      main: "#116595",
      dark: "#04192e",
    },
    secondary: {
      main: "#FF9900",
    },
    success: {
      main: "#789f08",
    },
    common: {
      black: "#000",
      white: "#fff",
      grey: "#F5F5F5",
    },
    background: {
      dark: "#04192e",
      light: "#ffffff",
    },
    link: {
      main: "#00fff0",
    },
    text: {
      primary: "#1B2A3D",
      secondary: "#6D7F8E",
      secondaryLight: "#A3A3A3",
      white: "#fff",
      black: "#000000",
      primary_dark: "#1B2A3D",
      primary_light: "#116595;",
    },
    quadrant: {
      q1: "#9B39E8",
      q2: "#E38F27",
      q3: "#DE3131",
      q4: "#3BB748",
    },
  },

  ///GPS THEME
  colors,
  scheme: {
    primary: colors.denim,
    secondary: colors.peach,
  },

  palette: {
    primary: {
      light: "#47b0eb",
      main: "#1271A6",
      dark: "#04192E",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FF9900",
    },
    success: {
      main: "#789f08",
    },
    common: {
      black: "#000",
      white: "#fff",
      grey: "#F5F5F5",
    },
    background: {
      main: "#f9f9f9",
    },
    link: {
      main: "#00fff0",
    },
    oxfordblue: {
      main: "#06203A",
    },
    text: {
      primary: "#1B2A3D",
      secondary: "#8E97A3",
      secondaryLight: "#E3E5E9",
      white: "#fff",
    },
  },
  fonts: {
    primary: "Poppins",
    secondary: "Inter",
    thernary: "Prata",
  },
  min,
  max,
  between,
};
