import { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Dropdown, Spinner } from 'elements';

import {
  Wrapper,
  Modal,
  Head,
  HeadTitle,
  Hr,
  CloseBtn,
  Background,
  Header,
  HeaderCol,
  Title,
  Text,
  Body,
  SpinnerWrapper,
  ChartArea,
  Legend
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

import LegendItem from "../LegendItem";
import DonutChart from "../DonutChart";
import GroupSelector, { IGroup } from "../GroupSelector";
import CustomDatePicker from "../CustomDatePicker";

import { ListTimeDistributionState } from "Redux/APM/ListTimeDistribution/types";

import { listTimeDistribution } from "Redux/APM/ListTimeDistribution/action";

interface IProps {
  active: boolean;
  onClose: () => void;
}

const TimeAllocationPopup: FC<IProps> = ({ active, onClose }) => {
  const dispatch = useDispatch();

  const $body = useRef<HTMLDivElement>(null);

  const [isRendered, setIsRendered] = useState(false);
  const [isActiveState, setIsActiveState] = useState(false);

  const [hoverItemId, setHoverItemId] = useState<string | null>(null);
  const [size, setSize] = useState(488);
  const [group, setGroup] = useState<IGroup>({ key: 'category', element: 'By Category' });
  const [dateRange, setDateRange] = useState<any>([moment().startOf('week'), moment().endOf('week')]);

  const { data, loading } = useSelector(
    (state: any) => state.listTimeDistribution as ListTimeDistributionState
  );

  useEffect(() => {
    if (active) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50);
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500);
    }
  }, [active]);

  useEffect(() => {
    if (!isRendered || !dateRange || dateRange.length < 2) 
      return;

    const startDate = dateRange[0].format('YYYY-MM-DD');
    const endDate = dateRange[1].format('YYYY-MM-DD');

    const params = {
      start_date: startDate,
      end_date: endDate,
      group_by: group.key,
    }

    dispatch(listTimeDistribution(params));
  }, [dateRange, group, isRendered]);

  useEffect(() => {
    const updateSize = () => {
      if ($body.current) {
        setSize($body.current.offsetHeight);
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [data]);

  const handleClose = () => {
    onClose();
  }

  const handleHover = (id: string | null) => {
    setHoverItemId(id);
  }

  const handleGroupChange = (value: IGroup) => {
    setGroup(value);
  }

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
  }

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <Wrapper $active={isActiveState}>
      <Modal $active={isActiveState}>
        <Head>
          <HeadTitle>
            <span>TIME ALLOCATION</span>
          </HeadTitle>

          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Head>

        <Hr />

        <Header>
          <HeaderCol $left>
            <Title>Time allocation across areas & Categories</Title>
            <Text>Pie chart visually representing the total time prioritized based on different Areas and Categories.</Text>
          </HeaderCol>

          <HeaderCol $right>
            <GroupSelector
              value={group}
              onChange={handleGroupChange}
            />
            <CustomDatePicker
              value={dateRange}
              onChange={handleDateRangeChange}
            />
          </HeaderCol>
        </Header>

        <Hr />

        <Body ref={$body}>
          {loading ? <SpinnerWrapper>
            <Spinner variant="secondary" />  
          </SpinnerWrapper> : null}

          <ChartArea>
            <DonutChart
              data={data}
              size={size}
              onHover={handleHover}
            />
          </ChartArea>

          <Legend>
            {data.map((item) => <LegendItem group={group} data={item} active={hoverItemId === item.id} key={item.id} />)}
          </Legend>
        </Body>
      </Modal>
      <Background />
    </Wrapper>,
    document.getElementById("portal") as HTMLElement
  );
}

export default TimeAllocationPopup;
