import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SampleViewModal from "components/SampleViewerModal";

import { ReactComponent as DragIcon } from "assets/images/journeys/drag.svg";
import { ReactComponent as InfoIcon } from "assets/images/journeys/info-dark.svg";
import { ReactComponent as CloseIcon } from "assets/images/journeys/close.svg";
import { ReactComponent as PlayIcon } from "assets/images/playButton.svg";

import { getCookie } from "utils/StorageVariables";

import { GetInstruction } from "Redux/GetInstruction/action";
import { ToggleInfoSidebarAction } from "Redux/Global/action";

import * as Styled from "./styled";
import VideoPlayer from "./Player";

import { step4 } from "./data";

export const useCookie = (cookieName) => {
  const location = useLocation();
  const [cookie, setCookie] = useState(getCookie(cookieName));

  useEffect(() => {
    const currentCookie = getCookie(cookieName);

    if (currentCookie !== cookie) {
      setCookie(currentCookie);
    }
  }, [location.pathname]);

  return cookie;
}

const InstructionsNav = ({ step: propStep }) => {
  const dispatch = useDispatch();
  const domainId = useCookie("domainId");

  const { success: dataInstructions, loading } = useSelector(
    (state) => state.getInstructionReducer
  );

  const { infoSidebar: isActiveState } = useSelector(
    (state) => state.globalReducer
  );

  const [playVideo, setPlayVideo] = useState();
  const [isViewSample, setIsViewSample] = useState(false);

  const wrapperRef = useRef(null);
  const isActiveStateRef = useRef(null);

  const step = propStep > 6 ? 7 : propStep;

  useEffect(() => {
    if (domainId)
      dispatch(GetInstruction({ domain: domainId }));
  }, [domainId]);

  useEffect(() => {
    isActiveStateRef.current = isActiveState;
  }, [isActiveState]);

  useEffect(() => {
    if (!step && isActiveStateRef.current) {
      dispatch(ToggleInfoSidebarAction());
    }
  }, [step]);

  const handleClose = () => {
    dispatch(ToggleInfoSidebarAction());
  };

  const onSampleClickHandle = (evt) => {
    evt.preventDefault();
    setIsViewSample(true);
  };

  const onSampleCloseHandle = () => {
    setIsViewSample(false);
  };

  // console.log('step', step);

  const data =
    step === 4
      ? step4
      : dataInstructions && dataInstructions.length
        ? dataInstructions.find((item) => item.step === step)
        : null;

  if (!step || !isActiveState)
    return null;

  return (
    <>
      <Styled.Wrapper ref={wrapperRef} $active={isActiveState}>
        <Styled.Header>
          <Styled.HeaderCol>
            <DragIcon />
            <InfoIcon />
            <Styled.Title>Instructions</Styled.Title>
          </Styled.HeaderCol>

          <Styled.Close type="button" onClick={handleClose}>
            <CloseIcon />
          </Styled.Close>
        </Styled.Header>

        <Styled.Content isPadding>
          {data && !loading && (
            <>
              {data.image && (
                <Styled.InstructionThumbWrapper onClick={onSampleClickHandle}>
                  <Styled.InstructionThumb src={data.image} />
                </Styled.InstructionThumbWrapper>
              )}

              <Styled.InstructionHeading>
                {data.title}
              </Styled.InstructionHeading>

              {data.context && <Styled.Paragraph
                dangerouslySetInnerHTML={{ __html: data.context }}
              />}

              {data.body && <Styled.Paragraph>{data.body}</Styled.Paragraph>}
            </>
          )}
        </Styled.Content>

        <Styled.Footer>
          <Styled.Play onClick={() => setPlayVideo(true)}>
            Watch Tutorial
            <PlayIcon />
          </Styled.Play>
        </Styled.Footer>
      </Styled.Wrapper>

      <VideoPlayer
        isOpen={playVideo}
        step={step}
        onClose={() => setPlayVideo(false)}
      />

      {data && (
        <SampleViewModal
          active={isViewSample}
          image={data.image}
          onClose={onSampleCloseHandle}
        />
      )}
    </>
  );
};

export default InstructionsNav;
