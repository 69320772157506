import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Lottie from "lottie-react";
import { Button } from "elements";
import { Modal } from "antd";
import styled from "styled-components";

import LogoImg from "../../assets/images/header_logo_modern.svg";

import { UpdateLastStep } from "Redux/UpdateLastStep/action.js";

import animation1 from "./lottie1.json";
import styles from './styles.module.scss';

const CongratsModal = ({ isActive }) => {
  const dispatch = useDispatch();

  const [playAnimation, setPlayAnimation] = useState(false);

  const handleConfirm = () => {
    const stepFormData = new FormData();
    stepFormData.append("viewed_masterplan_screen", true);

    dispatch(UpdateLastStep(stepFormData));
  };

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setPlayAnimation(true);
      }, 350);
    } else {
      setPlayAnimation(false);
    }
  }, [isActive]);

  return (
    <CustomModal
      open={isActive}
      footer={false}
      centered
      width={700}
      closable={false}
      rootClassName={styles.modal}
    >
      <Wrapper>
        <Logo src={LogoImg} alt="Arootah" width={160} />
        <Title>Bravo.</Title>
        <Description>You’ve successfully created your Master Plan.</Description>

        <Button size="medium" onClick={handleConfirm}>
          View Master Plan
        </Button>

        {playAnimation && (
          <CustomLottie animationData={animation1} loop={false} />
        )}
      </Wrapper>
    </CustomModal>
  );
};

export default CongratsModal;

const CustomModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  @media screen and (max-width: 743px) {
    .ant-modal-content {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }

`;

const Wrapper = styled.div`
  padding: 34px 32px 76px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CustomLottie = styled(Lottie)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1200;
  pointer-events: none;
`;

const Title = styled.p`
  font-family: "Inter";
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  margin-bottom: 3px;
  color: #06203a;
  position: relative;
  z-index: 1;
`;

const Description = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 78px;
  color: #06203a;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 743px) {
    text-align: center;
  }
`;

const Logo = styled.img`
  margin-bottom: 76px;
  position: relative;
  z-index: 1;
`;
