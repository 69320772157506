import styled from "styled-components";

const ButtonAtom = styled.button`
  font-family: "Inter";
  height: 40px;
  position: relative;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
  text-decoration: none !important;
  border-radius: 3px;
  border: none;
  padding: 0 16px;

  ${({ styles }) => styles}
    &:disabled {
      opacity: ${({ noOpacity }) => (noOpacity ? 1 : 0.6)};
    }
`;

export default ButtonAtom;
