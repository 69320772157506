// @ts-ignore
import { getAccountability, createAccountability, deleteAccountability } from "api";
import { ActionTypes } from "./types";

// @ts-ignore
import { getCookie } from "utils/StorageVariables";
import Notify from "utils/notify";

export const ListAccountabilities = (domain: string, custom: boolean = false) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const user = getCookie('userData') ? JSON.parse(getCookie('userData')).id : null

    const filtersParams: { [key: string]: any } = {
      is_custom: custom
    };
    
    if (custom) {
      filtersParams.user = user;
    } else {
      filtersParams.domain = domain;
    }

    const { data } = await getAccountability(filtersParams);

    dispatch({
      type: custom ? ActionTypes.CUSTOM_SUCCESS : ActionTypes.SUCCESS,
      payload: data
    });
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

export const CreateAccountability = (payload: FormData, callback: any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING_CREATE });

    const { data } = await createAccountability(payload);

    dispatch({
      type: ActionTypes.SUCCESS_CREATE,
      payload: data
    });

    Notify.success('Accountability Created Successfully');

    callback(data);
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR_CREATE,
      payload: error
    });

    callback(null);
  }
};

export const DeleteAccountability = (id: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING_DELETE });

    await deleteAccountability(id);

    dispatch({
      type: ActionTypes.SUCCESS_DELETE,
      payload: id
    });

    Notify.success('Accountability Deleted Successfully');
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR_DELETE,
      payload: error
    });
  }
};
