import styled from "styled-components";

export const Wrapper = styled.div<{ $separator: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 20px 0px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeadTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  
  div {
    font-family: Inter;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFB800;
    width: 67px;
  }
`;

export const HeadToggle = styled.button`
  display: flex;
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  background-color: transparent;
  transition: background-color .25s, transform .25s;

  &:hover {
    background-color: #9fa4ad29;
  }

  &:active {
    transform: scale(0.9);
  }
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, #112538);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 4px;
  text-transform: uppercase;
`;

export const Text = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: 359px;
  opacity: 70%;
  color: #112538;
`;

export const Body = styled.div`
  padding-top: 2px;
`;

