import { Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const AddNoteModal = ({ Add, setAdd, saveNote, setSaveNote }) => {
  const HandleSave = () => {
    console.log(NoteValue);
  };

  const [NoteValue, setNoteValue] = useState("");
  //   const [saveNote, setSaveNote] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  // const showNode = () => {
  //   setAdd(true);
  // };
  // const handleOk = () => {
  //   setAdd(false);
  // };
  const handleCancel = () => {
    setAdd(false);
  };
  const SaveHandler = () => {
    setSaveNote(NoteValue);
  };
  const HandleEdit = () => {
    setIsEdit(!isEdit);
  };
  const words = saveNote?.split(" ");
  const [showAll, setShowAll] = useState(false);

  const toggleShow = () => {
    setShowAll(!showAll);
  };

  return (
    <Modal
      title={
        <AddTitle>
          <NoteHeading>Q3</NoteHeading>
          <TitleName>Note</TitleName>
        </AddTitle>
      }
      open={Add}
      footer={false}
      // onOk={handleOk}
      onCancel={handleCancel}
    >
      {saveNote === "" || isEdit ? (
        <TextArea
          value={(NoteValue && NoteValue) || (saveNote && saveNote)}
          onChange={(event) => {
            event.stopPropagation();
            setNoteValue(event.target.value);
          }}
          placeholder="Add your note here"
        ></TextArea>
      ) : (
        <Para>
          {showAll ? saveNote : words.slice(0, 20).join(" ")}
          {words.length > 20 && (
            <Span onClick={toggleShow}>
              {showAll ? " Show Less" : " Show More"}
            </Span>
          )}
        </Para>
      )}

      <ModalBtnWrapper>
        {saveNote === "" || isEdit ? (
          <CustomButton
            onClick={(event) => {
              event.preventDefault();
              SaveHandler();
              handleCancel();
            }}
          >
            Save
          </CustomButton>
        ) : (
          <CustomButton
            edit
            onClick={(event) => {
              event.preventDefault();
              HandleEdit();
            }}
          >
            Edit
          </CustomButton>
        )}
      </ModalBtnWrapper>
    </Modal>
  );
};

export default AddNoteModal;

const AddTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
const TitleName = styled.p`
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const NoteHeading = styled.p`
  margin: 0;
  padding: 0;
  background-color: #e48e27;
  display: flex;
  height: 20px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;
const TextArea = styled.textarea`
  padding: 0.5rem;
  height: 74px;
  width: 100%;
  color: #4a5e73;
  /* Paragraph/XSmall/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border: 1px solid #71aaca;
  background: #fff;
  box-shadow: 0px 0px 0px 2px rgba(208, 227, 237, 0.2);
  margin-bottom: 0.4rem;
  &::placeholder {
    color: #4a5e73;
    /* Paragraph/XSmall/Regular */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
const ModalBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
const CustomButton = styled.div`
  border: none;
  outline: none;
  border-radius: 2px;
  background: ${({ edit }) => (edit ? "transparent" : "#1271a6")};
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  color: ${({ edit }) => (edit ? "#1271a6" : "#fff")};
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 0.7rem;
  cursor: pointer;
  border: ${({ edit }) => edit && "1px solid #1271a6"};
`;
const Para = styled.p`
  margin: 0;
  padding: 0;
  color: #112538;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const Span = styled.span`
  color: #2e8be3;
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
  display: block;
  margin-bottom: 0.4rem;
`;
