import styled from "styled-components";

interface BackgroundProps {
  $active: boolean;
  // $higher: boolean;
}

export const Background = styled.div<BackgroundProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.50);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

interface WrapperProps {
  $active: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  max-width: 710px;
  width: 100%;
  height: calc(100vh - 70px - 16px);
  background: #fff;
  border-radius: 8px;
  right: 0;
  top: 0;
  margin-top: 70px;
  margin-right: 16px;
  z-index: 999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ theme }) => theme.max("md")`
    width: calc(100% - 32px);
  `}

  ${({ $active }) => $active && `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Header = styled.div`
  padding: 14px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px 12px 0 0;
  background-color: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  border-bottom: 1px solid var(--Neutral-300, #CDCDCD);
`;

export const HeaderHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const HeaderTitle = styled.span`
  color: var(--Fill-Button-Prussian-blue, var(--Header, #112538));
  font-family: Inter;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg .st0 {
    fill: var(--Fill-Frame-White, var(--Dark-mode-bg, #FFF));
  }

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const PrintRow = styled.div`
  padding: 0 12px;
`;

export const PrintRowContent = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--Neutral-300, #CDCDCD);
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 12px;
  margin-right: 6px;
  gap: 12px;

  ::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #acb4bc;
    border-radius: 100px;
  }
`;

export const ListTitle = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
