import { ActionTypes } from './type'

const initialUnprioritizedActionsState = {
    UnprioritizedData: [],
    loading: false,
};

export default (state = initialUnprioritizedActionsState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_LIST_UNPRIORITIZED_ACTIONS_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_LIST_UNPRIORITIZED_ACTIONS_SUCCESS:
            return { UnprioritizedData: action.payload, loading: false };
        case ActionTypes.FETCH_LIST_UNPRIORITIZED_ACTIONS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
}