import styled from "styled-components";

export const Wrapper = styled.div<{$active: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 6px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.09);
  transform: scale(1);
  transition: transform .3s ease-in-out;

  ${({ $active }) => $active && `
    transform: scale(1.05);
  `};
}`;

export const Col = styled.div`
  display: flex;
  gap: 6px;
}`;

export const Label = styled.span`
  width: 5px;
  height: 35px;
  border-radius: 4px;
  background-color: #ced0d4;
}`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
}`;

export const Title = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}`;

export const Text = styled.span`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
}`;

export const Time = styled.span`
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;

  span {
    font-weight: 600;
  }
}`;
