// @ts-ignore
import { getLeverageScores, updateLeverageScores } from "api";

import Notify from "utils/notify";
import { ActionTypes } from "./types";

// @ts-ignore
import { ActionTypes as GoalActionTypes } from 'Redux/UpdateGoal/types';

export const ListLeverageScores = (id: string) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data } = await getLeverageScores(id);

    const processedAreas = data.areas.map((item: any) => {
      // Find the matching leverage_score item by area_name
      const matchingScore = data.leverage_scores.find(
        (scoreItem: any) => scoreItem.area_name === item.name
      );
    
      return {
        // Spread the item to retain other fields
        ...item,
        id: item.name.replaceAll(' ', '_').toLowerCase(),
        color: item.color,
        label: item.name,
        // If a matching score exists, use its score; otherwise, keep null
        value: matchingScore ? parseInt(matchingScore.score).toFixed(0) : null,
      };
    });

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: processedAreas
    });
  } catch (error) {
    console.error(error);
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error
    });
  }
};

export const UpdateLeverageScores = (payload: any, callback?: (success: boolean) => void) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_LOADING });
    const { data } = await updateLeverageScores(payload);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS
    });

    // Debug:
    // data.leverage_score = `${Math.floor(Math.random() * 9) + 1}.0`;

    dispatch({
      type: GoalActionTypes.UPDATE_GOAL_SUCCESS,
      payload: data
    });

    if (callback) {
      callback(true);
    }
  } catch (error) {
    console.error(error);
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.UPDATE_ERROR,
      payload: error
    });

    if (callback) {
      callback(false);
    }
  }
};
