import { useEffect, useRef, React, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "elements";

import TimeWidget from "../TimeWidget";
import CalendarWidget from "../CalendarWidget";
import LeverageWidget from "../LeverageWidget";

import MiniBar from "../MiniBar";
import EditDropdown from "../EditDropdown";
import GoalPopover from "../GoalPopover";
import ActionItemForm from "../ActionItemForm";
import PriorityEdit from "../PriorityEdit";
import PriorityScorePopover from "../PriorityScorePopover";

import { Hla, Type } from "../Elements";

import { ReactComponent as Target } from "assets/images/priorities/target.svg";
import { ReactComponent as TargetActive } from "assets/images/priorities/target-active.svg";

import leverageGreenIcon from "assets/images/priorities/leverage-green.svg";
import leverageGrayIcon from "assets/images/priorities/leverage-gray.svg";
import leverageGoldIcon from "assets/images/priorities/leverage-gold.svg";
import leverageInactiveIcon from "assets/images/priorities/leverage-inactive.svg";

import targetSecondaryIcon from "assets/images/priorities/target-secondary.svg";
import targetInfoIcon from "assets/images/priorities/target-info.svg";

import {
  Wrapper,
  Content,
  Body,
  Order,
  AreaBadge,
  Title,
  TitleWrapper,
  Priority,
  Icon,
  Actions,
  ActionButton,
  MobileScreens,
  Void,
} from "./styled";

import { availableAreas } from "Redux/APM/AvailableAreas/action";
import { removeFocusedPriority } from "Redux/APM/ListFocusedPriorities/action";
import { fetchListPrioritizedActions } from "Redux/APM/PrioritizedActions/action";
import { addPrioritizedActivityToFocused } from "Redux/APM/PrioritizedtoFocused/action";
import { updateIndirectScore } from "Redux/APM/UpdateIndirectScores/action";
import usePopoverPosition from "./usePopoverPosition";

const SimpleViewItem = forwardRef(({
  data,
  goal,
  index,
  isTask,
  isActive,
  isProView,
  onClick,
  screenSizes,
  visible,
  setVisible,
}, ref) => {
  const [close, setClose] = useState(false);

  const lastDifferences = useRef(null);
  const [areaData, setAreaData] = useState([]);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [actions, setActions] = useState(undefined);

  const [selectedItem, setSelectedItem] = useState([]);
  const [rowVisible, setRowVisible] = useState(null);
  const [handleDelete, setHandleDelete] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { isEdit: isEditDrawer } = useSelector((state) => state.globalReducer);

  const { id, color, deadline, startline, calculated_priority } = data;

  const { listFocusedPrioritise: focusedItems, week } = useSelector(
    (state) => state.listFocusedPrioritise
  );

  const { loading: areaLoading } = useSelector((state) => state.availableAreas);
  const { loading } = useSelector((state) => state.updateIndirectScores);

  const isFocused = focusedItems?.find(
    (ele) => ele?.prioritized_activity?.id === id || ele?.task?.id === id
  );

  const dispatch = useDispatch();

  const onClickHandle = () => {
    onClick(id);
  };

  useEffect(() => {
    if (!visible) {
      lastDifferences.current = screenHeight - mousePosition.y;
    }
  }, [visible, screenHeight, mousePosition.y]);

  const onEditHandle = () => {
    if (isTask)
      return;

    dispatch({type: 'IS_EDITING_ID', payload: data?.id})
    setIsEdit(true);
  };

  const onFocusClickHandle = () => {
    // if not focused then add to focused q1
    if (!isFocused) {
      // if (focusedItems?.length < 10) {
      dispatch(
        addPrioritizedActivityToFocused({
          [isTask ? "task" : "prioritized_activity"]: data?.id,
          user: data?.user,
          week_starting: week?.startDate
        })
      );
      /* } else {
        Notify.error("The maximum limit for Focused Priorities is 10");
      } */
    } else {
      const focusId = focusedItems?.find(
        (ele) => ele?.prioritized_activity?.id === id || ele?.task?.id === id
      )?.id;

      dispatch(removeFocusedPriority(focusId));
      dispatch(fetchListPrioritizedActions());
    }
  };

  const handleLeverage = async () => {
    const id = data?.id;
    if (id) {
      dispatch(availableAreas(id));
      setSelectedItem(data);
      setVisible && setVisible(true);
    }
  };

  const onLeverageClose = (values) => {
    if (values && Array.isArray(values) && values.length > 0) {
      const ad = Array.isArray(values) && values.length > 0 ? values : areaData;

      const impactAreaArray = ad
        .filter((item) => item.impactArea.user_area)
        .map((item) => item.impactArea);

      const payload = {
        updated_scores: {
          prioritized_activity: id,
          scores: impactAreaArray
            .filter((item) => item.score !== "0.0" && item.score !== 0 && item.score !== '-')
            .map((item) => ({
              user_area: item.user_area,
              score: item.score,
            })),
        },
      };

      if (!areaLoading) {
        dispatch(updateIndirectScore(payload));
      }
    }

    setAreaData([]);
    setClose(!close);
    setVisible && setVisible(false);
  };

  function calculateDaysLeft(startDateStr, endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const timeDifference = endDate - startDate;

    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  const handleEditClose = () => {
    dispatch({ type: 'IS_EDITING', payload: false })
    dispatch({ type: 'IS_EDITING_ID', payload: null })
    setIsEdit(false);
  };

  const currentDate = new Date().toISOString().split("T")[0];
  const daysLeft = calculateDaysLeft(currentDate, deadline);
  // const isDateSet = deadline;

  const content = isTask ? data?.name : data?.action?.context || data?.action?.name;

  const difference = 0;

  return (
    <>
      <Wrapper $Zindex={rowVisible === id}>
        <Content
          $active={isActive}
          $task={isTask}
          $Zindex={rowVisible === id}
          $first={!isProView && index === 1}
          id={index === 1 ? "react-joyride-step2" : ""}
        >
          <Body>
            {isTask || isProView ? null : <AreaBadge $color={color} />}
            {/* {isTask ? null : <AreaBadge $color={color} />} */}

            {index && !isTask ? <PriorityScorePopover
              value={calculated_priority}
            >
              <Order>{index}</Order>
            </PriorityScorePopover> : null}

            {index && isTask ? <Order>{index}</Order> : null}

            <Type type={isTask ? "task" : data?.action?.type} />

            {/* <Chart
              onMouseEnter={() => handleMouseEnter("chart")}
              onMouseLeave={handleMouseLeave}
            >
              <PopoverCustom
                content={
                  <ReturnPopover
                    directImpact={data?.direct_impact}
                    indirectImpact={data?.indirect_impact}
                    activityWeight={data?.activity_weight}
                    duration={data?.time_score}
                    urgency={data?.urgency_score}
                  />
                }
                popoverStyle={{
                  arrowStyle: { top: "0px", left: "4px", zIndex: 9999 },
                  containerStyle: { top: "-12px", left: "175px" },
                }}
                hover={hoveredComponent === "chart"}
              >
                <RadialBar value={Math.floor(data?.calculated_priority)} />
              </PopoverCustom>
            </Chart> */}

            <TitleWrapper $task={isTask} onClick={onEditHandle}>
              <Title $pencil>
                {screenSizes === "mobile" ? (
                  <Tooltip title={content}>
                    {content}
                  </Tooltip>
                ) : (
                  content
                )}
              </Title>

              {data?.indirect_impact >= 6 ? (
                <Tooltip title="High Leverage Activity">
                  <Hla>Hla</Hla>
                </Tooltip>
              ) : null}

              {isTask ? (
                <Priority $priority={data?.priority.toLowerCase()}>
                  {data?.priority}
                </Priority>
              ) : null}
            </TitleWrapper>

            {(isTask || isProView) ? null : <GoalPopover data={data} />}

            <Void onClick={onEditHandle} />
          </Body>

          {screenSizes === "mobile" ? (
            <MobileScreens>
              {isFocused && (
                <Tooltip title="Focus">
                  <ActionButton onClick={onFocusClickHandle}>
                    <TargetActive />
                  </ActionButton>
                </Tooltip>
              )}

              {!isFocused && (
                <Tooltip title="Focus">
                  <ActionButton onClick={onFocusClickHandle}>
                    <Target />
                  </ActionButton>
                </Tooltip>
              )}

              <Actions>
                <MiniBar
                  isFocused={isFocused}
                  onFocusClickHandle={onFocusClickHandle}
                  setAreaData={setAreaData}
                  items={data}
                  loading={loading}
                  selectedItem={selectedItem}
                  difference={difference}
                  onLeverageClose={onLeverageClose}
                  handleLeverage={handleLeverage}
                  daysLeft={daysLeft}
                  onEdit={onEditHandle}
                  id={id}
                  onClose={setClose}
                  close={close}
                  onClickHandle={onClickHandle}
                  setRowVisible={setRowVisible}
                  handleDelete={handleDelete}
                  setHandleDelete={setHandleDelete}
                />{" "}
              </Actions>
            </MobileScreens>
          ) : (
            <Actions
              onClick={(e) => {
                setVisible(true);
              }}
            >
              <ActionButton $free style={{ marginRight: 4 }}>
                <TimeWidget
                  isTask={isTask}
                  data={data}
                />
              </ActionButton>

              <CalendarWidget
                daysLeft={daysLeft}
                isTask={isTask}
                data={data}
                goal={goal}
              />

              {!data?.action ? (
                <ActionButton $free $disabled>
                  <Icon src={leverageInactiveIcon} alt="" width={24} height={24} />
                </ActionButton>
              ) : (
                <LeverageWidget
                  data={data}
                  selectedItem={selectedItem}
                  indirectImpact={data?.indirect_impact}
                  setOutsideAreaData={setAreaData}
                  setSelectedItem={setSelectedItem}
                  onClose={onLeverageClose}
                />
              )}

              {/* ISSUE: https://linear.app/arootah/issue/ARO-1483/when-the-user-removes-the-activity-from-the-focused-section-by */}
              <main id="react-joyride-step6">
                {isFocused ? (
                  <Tooltip title="Remove from Focused">
                    <ActionButton onClick={onFocusClickHandle}>
                      <Icon src={targetInfoIcon} alt="" width={24} height={24} />
                    </ActionButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Focus">
                    <ActionButton onClick={onFocusClickHandle}>
                      <Icon
                        src={targetSecondaryIcon}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </ActionButton>
                  </Tooltip>
                )}
              </main>


              <EditDropdown
                id={data?.action?.id || id}
                title={data?.action?.context || data?.name}
                type={isTask ? "Task" : data?.action?.type || "Action"}
                setActions={setActions}
                handleDelete={handleDelete}
                setHandleDelete={setHandleDelete}
              />
            </Actions>
          )}
        </Content>
      </Wrapper>

      {actions ? <ActionItemForm
        active={actions}
        setActions={setActions}
        data={data}
        goal={goal}
        onClose={() => { }}
      /> : null}

      {(isEdit || isEditDrawer) ? <PriorityEdit
        data={data}
        goal={goal}
        index={index}
        active={isEdit || isEditDrawer}
        onClose={handleEditClose}
      /> : null}
    </>
  );
})

export default SimpleViewItem;
