import styled from "styled-components";

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(79, 89, 102, 0.30);
  top: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  height: calc(100vh - 10px - 50px);
  background: #fff;
  right: 0;
  top: 0;
  box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 999;
  transform: translateX(100%);
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    transform: translateX(0%);
    opacity: 1;
    visibility: visible;
  `};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid var(--Color-3, #D9D9D9);
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, var(--Header, #112538));
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const Content = styled.div`
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  height: calc(100% - 24px);
  flex-direction: column;
  padding: 12px;
`;

export const Close = styled.button`
  display: flex;
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;

  svg {
    transform: rotate(0deg);
    transition: transform .3s;
  }

  :hover {
    svg {
      transform: rotate(90deg);
    }
  }
`;
