import { retrieveMasterPlanDetails } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";
// import store from "../store";
import { RetrieveUserData } from "Redux/RetrieveUserData/action";
import { getCookie } from "utils/StorageVariables";
// import { GetProgress } from "Redux/GetProgress/action";

export const RetrieveMasterPlanDetails =
  (id, isLoading) => async (dispatch) => {
    try {
      // let selectedClient = store.getState().globalReducer?.SelectedClient?.id;
      let selectedClient = getCookie("selectedClient");

      if (!isLoading) {
      } else {
        dispatch({ type: ActionTypes.LOADING });
      }
      let query = "";
      if (selectedClient) {
        query = `?client=${selectedClient}`;
      }
      const { data, status } = await retrieveMasterPlanDetails(id, query);
      dispatch({

        type: ActionTypes.SUCCESS,
        payload: data,
      });
      if (status === 200) {
        dispatch(RetrieveUserData());
        // dispatch(GetProgress(id))
      }
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };

export const RetrieveMasterPlanDetailsNull = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};

export const MasterPlanPrioritizeActionItems = payload => async (dispatch) => {
  dispatch({
    type: ActionTypes.PRIORITIZE,
    payload,
  });
};
