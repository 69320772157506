import { useState } from 'react';
import { DatePicker } from "elements";
import {
  Wrapper,
  Title,

  StyledButtonGroup,
  ToggleButton,

  BorderGroup,
  BorderGroupHead,
  BorderGroupTitle,
  BorderGroupLabel,
  TimePicker,
  ErrorStyle,

  TabsList,
  TabItem,

  Row,
  Col,
  Label,

  PeriodicSettings,
  PeriodicRepeatTimesWrapper,
  PeriodicTypeWrapper,
  DateRangeWrapper,
  MonthListOfDays,
  MonthDayButton,
  ButtonToggle,
  WeekdaysWrapper
} from './styled';

const tabs = ['off', 'daily', 'periodic'];
const dateFormat = "MM/DD/YYYY";

const PeriodicType = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  PERIODIC: 'periodic'
}

const WeekDays = {
  SUN: 'Sunday',
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday'
}

const PeriodicTypeList = [PeriodicType.WEEKLY, PeriodicType.MONTHLY, PeriodicType.PERIODIC];
const WeekdaysList = [WeekDays.SUN, WeekDays.MON, WeekDays.TUE, WeekDays.WED, WeekDays.THU, WeekDays.FRI, WeekDays.SAT];

export function timeToConvert(time24: string) {
  let ts = time24;
  let H = +ts.slice(0, 2);
  let h = (H % 12) || 12;

  const hStr = (h < 10) ? ("0" + h) : h.toString(); // leading 0 at the left for 1 digit hours
  const ampm = H < 12 ? " AM" : " PM";

  return (hStr + ts.slice(2, 5) + ampm);
};
 
const RemindersSection = () => {
  const [tab, setTab] = useState('off');
  const [time, setTime] = useState('');
  const [periodicType, setPeriodicType] = useState(PeriodicType.WEEKLY);
  const [repeatDays, setRepeatDays] = useState<string[]>([]);
  const [periodicRepeatType, setPeriodicRepeatType] = useState({ label: 'Months', value: 'months' });
  const [monthlyDays, setMonthlyDays] = useState({ [(new Date()).getDate()]: true } /*[(new Date()).getDate()]*/);
  const [repeatTimes, setRepeatTimes] = useState({ label: 1, value: 1 });

  const handleTabChange = (t: string) => () => {
    setTab(t);
  };

  const handleTimeChange = (e: any) => {
    setTime(e.target.value);
    // setTimeError('');
  };

  const handlePeriodicTypeChange = (item: string) => () => {
    setPeriodicType(item);
  }

  const onWeekdayClickHandle = (day: string) => () => {
    if (repeatDays.find(d => d === day))
      setRepeatDays(repeatDays.filter(d => d !== day));
    else
      setRepeatDays([...repeatDays, day]);
  }

  const onmonthlyDaysChangeHandle = (value: any) => () => {
    let monthlyDayssCopy = { ...monthlyDays };

    if (monthlyDays[value])
      delete monthlyDayssCopy[value];
    else
      monthlyDayssCopy = {...monthlyDayssCopy, [value]: true}

    setMonthlyDays(monthlyDayssCopy);
  }

  const repeatLabel = (type: string, days: any[], monthlyDays: any, range: any) => {
    if (PeriodicType.WEEKLY === type) {
      if (!days.length)
        return '';

      if (days.length === WeekdaysList.length)
        return 'Everyday';

      if (days.length === 1)
        return `Every ${days.join(', ')}`

      return `Every ${WeekdaysList.filter(day => days.includes(day)).map(day => day.slice(0, 3)).join(', ')}`;
    }

    if (PeriodicType.MONTHLY === type) {
      const dayStr = monthlyDays.toString();
      const lastNumber = dayStr.charAt(dayStr.length - 1);

      let ordinal = '';

      switch(lastNumber) {
        case '1':
          ordinal = 'st';
          break;
        case '2':
          ordinal = 'nd';
          break;
        case '3':
          ordinal = 'rd';
          break;
        default:
          ordinal = 'th';
      }

      return `${monthlyDays}${ordinal} Every ${range} Month${range > 1 ? 's' : ''}`;
    }

    if (PeriodicType.PERIODIC === type) {
      return `Every ${range} Day${range > 1 ? 's' : ''}`;
    }
  }

  const DailyReminder = () => (
    <BorderGroup>
      <BorderGroupHead>
        <BorderGroupTitle>Time<span className="text-danger">*</span></BorderGroupTitle>
        <BorderGroupLabel>{time ? timeToConvert(time) : time}</BorderGroupLabel>
      </BorderGroupHead>

      <TimePicker className="form-control" value={time} onChange={handleTimeChange} type="time" name="appt" />

      {/* {timeError && <ErrorStyle>{timeError}</ErrorStyle>} */}
    </BorderGroup>
  );

  const PeriodicReminder = () => (
    <>
      <TabsList>
        {PeriodicTypeList.map(item => <TabItem type="button" $active={item === periodicType} onClick={handlePeriodicTypeChange(item)} key={item}>
          <span />
          <span>{item}</span>
        </TabItem>)}
      </TabsList>

      <BorderGroup>
        {periodicType === PeriodicType.PERIODIC && (
          <BorderGroupHead>
            <BorderGroupTitle>Repeat every</BorderGroupTitle>
            <PeriodicSettings>
              {periodicRepeatType.value !== 'years' && (
                <PeriodicRepeatTimesWrapper>
                  {/* <Select
                    placeholder=""
                    options={ DaysList.map(date => ({ label: date, value: date })) }
                    styles={areaDropdownStyles}
                    value={repeatTimes}
                    onChange={onRepeatTimesChangeHandle}
                    components={{
                      IndicatorSeparator: null,
                    }}
                  /> */}
                </PeriodicRepeatTimesWrapper>
              )}
              <PeriodicTypeWrapper>
                {/* <Select
                  placeholder=""
                  options={PeriodicList}
                  styles={areaDropdownStyles}
                  value={periodicRepeatType}
                  onChange={onRepeatTypeChangeHandle}
                  components={{
                    IndicatorSeparator: null,
                  }}
                /> */}
              </PeriodicTypeWrapper>
            </PeriodicSettings>
          </BorderGroupHead>
        )}

        {/* <DateRangeWrapper> */}
          <Row>
            <Col>
              <Label>Startline*</Label>
              <DatePicker
                format={dateFormat}
                placeholder={dateFormat}
              />
            </Col>
            <Col>
              <Label>Deadline*</Label>
              <DatePicker
                format={dateFormat}
                placeholder={dateFormat}
              />
            </Col>
          </Row>
        {/* </DateRangeWrapper> */}

        {(periodicType === PeriodicType.WEEKLY) && (
          <BorderGroup>
            <BorderGroupHead>
              <BorderGroupTitle>Repeat</BorderGroupTitle>
              <BorderGroupLabel>{repeatLabel(periodicType, repeatDays, monthlyDays, repeatTimes)}</BorderGroupLabel>
            </BorderGroupHead>

            <WeekdaysWrapper>
              <div className="d-flex justify-content-between align-items-center">
                {WeekdaysList.map(day => <ButtonToggle type="button" $active={repeatDays.includes(day)} onClick={onWeekdayClickHandle(day)} key={day}>{day.substring(0, 2).toUpperCase()}</ButtonToggle>)}
              </div>
            </WeekdaysWrapper>
          </BorderGroup>
        )}

        {periodicType === PeriodicType.MONTHLY && (
          <BorderGroup>
            <BorderGroupHead>
              <BorderGroupTitle>Recurrence Day(s)</BorderGroupTitle>
            </BorderGroupHead>
            <MonthListOfDays>
              {Array.from(Array(31)).map((n, i) => <MonthDayButton type="button" $active={monthlyDays[i+1]} onClick={onmonthlyDaysChangeHandle(i+1)} key={i}>{i+1}</MonthDayButton>)}
            </MonthListOfDays>
          </BorderGroup>
        )}

        <BorderGroup>
          <BorderGroupHead>
            <BorderGroupTitle>Time<span className="text-danger">*</span></BorderGroupTitle>
            <BorderGroupLabel>{time ? timeToConvert(time) : time}</BorderGroupLabel>
          </BorderGroupHead>

          <TimePicker className="form-control" value={time} onChange={handleTimeChange} type="time" name="appt" />

          {/* {timeError && <ErrorStyle>{timeError}</ErrorStyle>} */}
        </BorderGroup>
      </BorderGroup>
    </>
  );

  return (
    <Wrapper>
      <Title>Reminder</Title>

      <StyledButtonGroup>
        {tabs.map((t) => (
          <ToggleButton
            key={t}
            $active={t === tab}
            onClick={handleTabChange(t)}
          >
            {t}
          </ToggleButton>
        ))}
      </StyledButtonGroup>

      {tab === 'daily' && <DailyReminder />}
      {tab === 'periodic' && <PeriodicReminder />}
    </Wrapper>
  )
};

export default RemindersSection;
