import styled, { css } from 'styled-components';

export const DraggableWrapper = styled.div`
  width: 100%;
  padding: 2px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 4px 8px 6px;
  background-color: #fff;
  border-left: 3px solid;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  transition: border-color .2s;

  ${({ $color }) => $color && css`
    border-left-color: ${$color};
  `}

  ${({ $draggable }) => $draggable && css`
    border-left-width: 4px;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  `}

  ${({ $highlighted }) => $highlighted && css`
    border-top-color: #FF8B00;
    border-bottom-color: #FF8B00;
    border-right-color: #FF8B00;
  `}

  ${({ $clickable }) => $clickable && css`
    cursor: pointer;
  `}
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

export const NameGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5px;
  flex: 1;
  overflow: hidden;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

export const Order = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #CAE4F1;
  color: #1689CA;
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  line-height: 20px;
`;

export const Title = styled.span`
  font-family: Inter;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.12px;
  color: var(--fill-button-prussian-blue, #112538);
  font-weight: 500;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $draggable }) => $draggable && css`
    color: rgba(#112538, 0.7);
    font-size: 10px;
    font-weight: 400;
    letter-spacing: initial;
  `}
`;

export const Subtitle = styled.span`
  color: #525252;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $draggable }) => $draggable && css`
    font-size: 12px;
    color: #112538;
    letter-spacing: -0.12px;
    line-height: 14px;
  `}
`;

export const DragButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
`;
