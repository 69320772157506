import React from 'react';

import Layout from '../../../components/Auth/Layout';
import PasswordSuccess from 'components/Auth/PasswordSuccess';

const ForgotPassword = () => {
  return (
    <Layout bg="login">
      <PasswordSuccess />
    </Layout>
  );
}

export default ForgotPassword;
