import { Dropdown as AntdDropdown, MenuProps } from 'antd';
//@ts-ignore
import { ReactComponent as Dots } from "assets/images/priorities/dots-vertical.svg";
import { Toggle, Item } from './styled';
//@ts-ignore
import styles from './styles.module.scss';

interface MenuItem {
  label: JSX.Element;
  key: string | number;
  disabled?: boolean;
}

interface DropdownProps {
  value: string | number;
  menuItems: {
    key?: string | number;
    hide?: boolean;
    disabled?: boolean;
    element: JSX.Element | string;
    onClick?: () => void;
  }[];
  secondary?: boolean;
  overlayClassName?: string;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  children?: JSX.Element;
}

const Dropdown: React.FC<DropdownProps> = ({ value, menuItems, secondary, overlayClassName, onChange, disabled, children, ...rest }) => {
  const items: MenuItem[] = menuItems
    .filter(item => !item.hide)
    .map((item, key) => ({
      label: (
        <Item data-testid={`menu-item-${item.key}`} $active={item.key === value} $secondary={secondary} $disabled={item?.disabled} onClick={item.onClick}>
          {item.element}
        </Item>
      ),
      key: item?.key || key,
      disabled: item?.disabled
    }));

  return (
    <div data-testid="default-toggle">
      <AntdDropdown
        //@ts-ignore
        menu={{ items, onClick: onChange } as MenuProps}
        trigger={['click']}
        overlayClassName={`${secondary ? styles.secondary : styles.dropdown} ${overlayClassName || ''}`}
        disabled={disabled}
        {...rest}
      >
        {children || (
          <Toggle $secondary={secondary}>
            <Dots />
          </Toggle>
        )}
      </AntdDropdown>
    </div>
  );
}

export default Dropdown;
