import { WSH_PATH } from "utils/routes";

import Item from "./Item";
import EmptyPlaceholder from "../EmptyPlaceholder";

import { ReactComponent as CalendarIcon } from "assets/images/dashboard/calendar.svg";

import { Wrapper, Head, Title } from "./styled";

const items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const DailyTimeline = () => {
  return (
    <Wrapper>
      <Head>
        <Title>Today’s Timeline</Title>
      </Head>

      {/* <Body>
        <List>
          {items.map(item => <Item key={item} />)}
        </List>
      </Body> */}

      <EmptyPlaceholder
        title="You don’t have any tasks today."
        linkLabel="Open Scheduler"
        linkStyle="secondary"
        linkPath={WSH_PATH}
        linkIcon={CalendarIcon}
      />
    </Wrapper>
  );
}

export default DailyTimeline;
