import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.span`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`;

export const EditButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
`;
