import styled from "styled-components";

export const WeekPickerButton = styled.div`
  cursor: pointer;
  color: #8e97a3;
`;

export const WeekDatePicker = styled.div`
  .ant-picker {
    margin: 0 !important;
    background-color: transparent !important;
    border: none !important;
    justify-content: center !important;
    padding: 4px !important;
    box-shadow: none !important;

    input {
      text-align: center !important;
      font-size: 12px !important;
      font-weight: 700 !important;
      color: #262626 !important;
      font-family: "Inter";

      @media screen and (max-width: 500px) {
        font-size: 10px !important;
      }
    }
  }
  .ant-picker-suffix,
  .ant-picker-clear {
    display: none;
  }
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
