import Notify from "utils/notify";
import Cookies from "js-cookie";

import { removeAuth } from "api";
import { IsUser } from "Redux/User/action";
import { removeAllCookie } from "utils/StorageVariables";
import { ActionTypes } from "./types";
import { RemoveToken } from "Redux/Token/action";
import { ToggleInfoSidebarAction, ToggleSidebarAction, setSelectedClient } from "Redux/Global/action";

export const Logout = (Navigate) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });

    const { data: response } = await removeAuth();

    dispatch({
      type: ActionTypes.SUCCESS
    });

    if (response) {
      if (process.env.NODE_ENV === 'development')
        Cookies.remove('token-local');

      dispatch(RemoveToken());
      removeAllCookie();
      dispatch(IsUser(null));
      dispatch(ToggleSidebarAction('left'));
      dispatch(ToggleInfoSidebarAction(false));
      dispatch(setSelectedClient(false));

      Navigate("/", { replace: true });
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });

    removeAllCookie();
    dispatch(IsUser(null));
    Navigate("/", { replace: true });
  }
};
