import React from 'react';

import SignIn from '../../../components/Auth/SignIn';
import Layout from '../../../components/Auth/Layout';

const Login = () => {
  return (
    <Layout bg="login">
      <SignIn />
    </Layout>
  );
}

export default Login;
