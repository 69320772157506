import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 23px;
  background: var(--Neutral-100, #f5f5f5);
  gap: 20px;
`;

export const Title = styled.span`
  color: var(--Oxford-blue, #06203a);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableCol = styled.span`
  flex: 1;
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;

  :last-child {
    margin-left: 24px;
  }
`;

export const Time = styled.span`
  color: var(--Oxford-blue, #06203A);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;

  > span {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const GroupHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
