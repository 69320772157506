import React, { useState, useEffect } from 'react';

import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg";
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";
import { ReactComponent as LogoAPM } from "assets/images/auth/logoAPM.svg";

import * as Layout from '../styled';
import * as Styled from './styled';

import { APM_PATH, JOURNEY_PATH } from 'utils/routes';

const increaseBy = (num) => {
  let counter = num;
  return () => {
    const result = counter;
    counter += 50;
    return result;
  }
}

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validURLs = [
    'goal-setting-workbo-37-staging.botics.co',
    'localhost',
    '127.0.0.1'
  ];

  if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
    return {
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      ppURL: 'project-planner-36424-staging.botics.co'
    }
  }

  return {
    hcURL: 'app.arootah.com',
    ppURL: 'apps.arootah.com'
  }
}

const Landing = () => {
  const animateDelayCounter = increaseBy(100);

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, []);

  const { hcURL, ppURL } = getPath();

  return (
    <Layout.Screen $landing>
      <Styled.FormWrapper>
        <Layout.Title $animate={animate} $animateDelay={animateDelayCounter()}>Maximize your potential</Layout.Title>
        <Styled.Text $animate={animate} $animateDelay={animateDelayCounter()}>What will you accomplish today?</Styled.Text>

        <Styled.AppsList>
          <Styled.AppItem to={JOURNEY_PATH} $animate={animate} $animateDelay={animateDelayCounter()} data-testid="get-started">
            <LogoGPS />
            <Styled.AppItemTitle>Goal Manager</Styled.AppItemTitle>
          </Styled.AppItem>

          <Styled.AppItem to={APM_PATH} $animate={animate} $animateDelay={animateDelayCounter()} data-testid="apm">
            <LogoAPM />
            <Styled.AppItemTitle>Time Manager</Styled.AppItemTitle>
          </Styled.AppItem>

          <Styled.AppLink href={`https://${hcURL}`} target="_blank" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoHC />
            <Styled.AppItemTitle>Habit Manager</Styled.AppItemTitle>
          </Styled.AppLink>

          <Styled.AppLink href={`https://${ppURL}/decision-manager`} target="_blank" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoDM />
            <Styled.AppItemTitle>Decision Manager</Styled.AppItemTitle>
          </Styled.AppLink>

          <Styled.AppLink href={`https://${ppURL}/project-planner`} target="_blank" $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoPP />
            <Styled.AppItemTitle>Project Manager</Styled.AppItemTitle>
          </Styled.AppLink>

          {/* {process.env.NODE_ENV === 'development' && (<Styled.AppItem to={APM_PATH} $animate={animate} $animateDelay={animateDelayCounter()}>
            <LogoGPS style={{ opacity: 0.4 }} />
            <Styled.AppItemTitle style={{ opacity: 0.4 }}>Developer</Styled.AppItemTitle>
          </Styled.AppItem>)} */}
        </Styled.AppsList>
      </Styled.FormWrapper>
    </Layout.Screen>
  );
}

export default Landing;
