import { getProgress, getActionsProgress } from "api";

import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const GetProgress = (masterPlanId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GOAL_LOADING });
    const { data } = await getProgress(masterPlanId);

    dispatch({
      type: ActionTypes.GOAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);

    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.GOAL_ERROR,
      payload: error,
    });
  }
};

export const GetActionsProgress = (goalId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.ACTION_LOADING });
    const { data } = await getActionsProgress(goalId);

    dispatch({
      type: ActionTypes.ACTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);

    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ACTION_ERROR,
      payload: error,
    });
  }
};
