import { ActionTypes } from "./types";
import { updateTutorialProgress } from "../../api";
import { RetrieveUserData } from "Redux/RetrieveUserData/action";

export const UpdateTutorialProgress = (Data, id) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await updateTutorialProgress(Data, id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      dispatch(RetrieveUserData());
    }
  } catch (error) {
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
