import styled from "styled-components";

export const Wrapper = styled.div`
  width: 180px;
  padding-right: 6px;
  padding-left: 6px;
  border-right: 1px solid #d9d9d9;

  :nth-last-child(1) {
    border: none;
  }
`;

export const TableDate = styled.div<{
  today?: boolean;
}>`
  padding: 4px;
  font-size: 16px;
  color: ${({ today }) => (today ? "#1271A6" : "#586573")};
  text-align: center;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  line-height: 20px;

  span {
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
`;

export const TableCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TableCard = styled.div`
  background-color: #f5f5f5;
  padding: 8px 5px;
`;

export const CardTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #262626;
  font-family: "Inter", sans-serif;
  margin-bottom: 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 5;
`;

export const CardAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 4px;
`;

export const CardAvatar = styled.div`
  background-color: #ebeef2;
  padding: 3px, 4px, 3px, 4px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f9be8;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
`;
export const CardTime = styled.div`
  color: #116595;
  font-size: 11px;
  line-height: 16px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  white-space: nowrap;
`;
