import styled from "styled-components";

export const Label = styled.span`
  margin-left: 9px;
  border-radius: 24px;
  background: var(--warning-500-main, #F90);
  padding: 0 6px;
  color: var(--Generic-White, var(--Dark-mode-bg, #FFF));
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  cursor: default;
  margin-right:10px;
`;
