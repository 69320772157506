import { Button, Select } from "antd";
import styled from "styled-components";

export const MainFilter = styled.div`
    border: 1px solid #e3e5e9;
    display: flex;
    gap: 17px;
    padding: 10px;
    margin-right: 10px;
    margin-top: 5px;
    align-items: center;
    width: ${({ $mobile }: { $mobile: boolean }) => ($mobile ? "100%" : "200px")};


`

export const Heading = styled.div`
 font-family: Inter;
 font-size: 14px;
 font-weight: 400;
 line-height: 20px;
 letter-spacing: 0em;
 text-align: left;

`

export const FilterStyle = styled.div`
 display: flex;
 border-bottom: 1px solid #CED0D4;
 padding-bottom: 6px;
 flex-direction: ${({$mobile}:{$mobile:boolean})=>($mobile?'column':'row')};

`
export const CustomSelect = styled(Select)`
 position: relative !important;
 margin-top: 5px;
 z-index: 999;
 max-width: ${({ $mobile }: { $mobile: boolean }) => ($mobile ? "100% !important" : "200px")};
 .ant-select-selection-item{
    padding-left: 20px !important;
    padding-top: 2px !important;
  }
  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: grey;
    left: 8px;
    z-index: 999;
  }
  
    ${({ $mobile }: { $mobile: boolean }) => $mobile && `
    &&.ant-select {
      width: 100% !important;
    }`}

`
export const CustomBotton = styled(Button)`
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #112538;
    

`
