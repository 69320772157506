import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border-bottom: 3px solid #fff;
  background: #fff;
  padding: 12px;
  width: 100%;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 20px;

  ${({ $color }) => $color && `
    border-bottom-color: ${$color};
  `};
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeadWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DragButton = styled.span`
  display: flex;
  width: 22px;
  height: 22px;
`;

export const HeadContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.span`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
`;

export const StatusWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StatusIndicator = styled.span`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  gap: 4px;

  ${({ $color }) => $color && `
    background-color: ${$color};
  `};
`;

export const StatusText = styled.span`
  color: var(--fill-frame-cadet-gray, #8E97A3);
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`;

export const HeadCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Counter = styled.span`
  padding: 10px 14px 10px 13px;
  border-radius: 4px;
  background: #EEEFF5;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const CollapseToggle = styled.button`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 10px;
  padding: 12px 0;
  border-top: 1px solid #CCD6DE;
  border-bottom: 1px solid #CCD6DE;
`;

// Item
export const ItemWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemTitle = styled.span`
  flex: 1;
  color: var(--fill-frame-dark-navy, #05192E);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const ItemProgress = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
`;

export const ItemProgressHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemProgressLabel = styled.span`
  color: var(--neutral-900, #171717);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
`;

export const ItemProgressValue = styled.span`
  color: var(--neutral-500, #737373);
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
`;

export const ItemProgressBar = styled.div`
  height: 6px;
  border-radius: 100px;
  background: var(--neutral-100, #F5F5F5);
`;

// Footer
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
`;

export const FooterAction = styled.a`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 2px;
  background: var(--primary-500-main, #1271A6);
  color: var(--generic-white, #FFF);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    color: var(--generic-white, #FFF);
  }
`;

export const FooterProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const FooterProgressText = styled.span`
  color: var(--fill-frame-cadet-gray, #8E97A3);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 24px;
`;

// Progress Circle
export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px inset #D9D9D9;
`;

export const CircleValue = styled.span`
  position: absolute;
  color: var(--fill-icon-davys-gray, #4C545B);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;
