import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Scheduler.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { MdOutlineSync } from "react-icons/md";
import { RiFilter3Line } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Modal, Select } from "antd";
// import "../../QuadrantSelect/Overide.scss";
import { Option } from "antd/es/mentions";

import CheckboxPopover from "../CheckboxModal";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { EVENTS } from "./Constants";


const SelectDayTime = () => {
  const Arr = [
    {
      value: "Morning",
      num: 0,
    },
    {
      value: "Afternoon",
      num: 1,
    },
    {
      value: "Night",
      num: 2,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState();

  useEffect(() => {
    const savedIndex = localStorage.getItem("currentIndex");
    if (savedIndex === null) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(parseInt(savedIndex));
    }
  }, []);

  const handleIncrement = () => {
    if (currentIndex < Arr.length - 1) {
      setCurrentIndex(currentIndex + 1);

      localStorage.setItem("currentIndex", currentIndex + 1);
    }
    // console.log({ value: Arr?.filter((item) => item?.num === currentIndex + 1).map((item) => console.log(item, 'hello')) })
    // console.log(currentIndex < Arr.length - 1, "hhhh");
  };

  const handleDecrement = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      localStorage.setItem("currentIndex", currentIndex - 1);
    }
    // console.log({ value: Arr?.filter((item) => item?.num === currentIndex - 1).map((item) => console.log(item, 'hello')) })
  };

  return (
    <Box>
      {Arr?.map(
        (item, i) =>
          item.num === currentIndex && <Desc key={i}>{item.value}</Desc>
      )}

      <BtnWrap>
        <ButtonUp onClick={handleIncrement}>
          <IoIosArrowUp style={{ fontSize: "14px" }} />
        </ButtonUp>
        <ButtonDown onClick={handleDecrement}>
          <IoIosArrowDown style={{ fontSize: "14px" }} />
        </ButtonDown>
      </BtnWrap>




    </Box>
  );
};



const QuadrantSelect = () => {
  const [selectOption, setSelectOption] = useState("Q1");

  const [optionItem, setOptionItem] = useState([
    {
      value: "Q1",
      label: "Q1",
    },
    {
      value: "Q2",
      label: "Q2",
    },
    {
      value: "Q3",
      label: "Q3",
    },
    {
      value: "Q4",
      label: "Q4",
    },
    {
      value: "None",
      label: "None",
    },
  ]);

  const handleOptionChange = (value) => {
    setSelectOption(value);
  };
  const styleHandler = (optionValue) => {
    switch (optionValue) {
      case "Q1":
        return { borderBottom: "2px solid #9B39E8" };
      case "Q2":
        return { borderBottom: "2px solid #E38F27" };
      case "Q3":
        return { borderBottom: "2px solid #DE3131" };
      case "Q4":
        return { borderBottom: "2px solid #3BB748" };
      default:
        return {};
    }
  };

  return (
    <div className="quadrant-select-container">
      <Select

        popupClassName="quadrant_select"
        showArrow={false}
        className="select_quadrant"
        value={selectOption}
        onChange={handleOptionChange}
      >
        {optionItem.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            style={styleHandler(option.value)}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

const DnDCalendar = withDragAndDrop(BigCalendar);

const Schedular = ({
  isSyncOpen,
  setIsSyncOpen,
  // isOpenTimeline,
  // setIsOpenTimeline,
  dummyAppointment,
  setDraggedEvent,
  draggedEvent,
  isModalOpen,
  setIsModalOpen,
  Add,
  setAdd,
  saveNote,
  setSaveNote,
}) => {


  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };

  const [events, setEvents] = useState(EVENTS);

  const onChangeEventTime = useCallback(
    ({ event, start, end, resourceId, ...rest }) => {
      setEvents((prevEvents) =>
        prevEvents.map((prevEvent) =>
          prevEvent?.data?.appointment?.id === event?.data?.appointment?.id
            ? { ...event, start, end, resourceId }
            : prevEvent
        )
      );
    },
    []
  );

  // const [draggedEvent, setDraggedEvent] = useState(null);
  console.log({ draggedEvent });
  const onDroppedFromOutside = useCallback(
    ({ start, end, resource }) => {
      if (draggedEvent === "undroppable") return;
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          id: draggedEvent?.id,
          title: `Appointment with ${draggedEvent?.resource}`,
          start,
          end,
          data: { appointment: draggedEvent },
          isDraggable: true,
          isResizable: true,
        },
      ]);
    },

    [draggedEvent]
  );

  // console.log(events, "harim");

  // const dummyAppointment = {
  //   id: 3,
  //   status: "CI",
  //   location: "Connecticut",
  //   resource: "Alex Hales",
  //   address: "1241 E Main St\n Stamford\n CT 06902\n United States",
  // };

  // const CustomEvent = ({ event }) => {
  //   const { start, end, title } = event;

  //   return (
  //     <>
  //       {" "}
  //       <div>
  //         <p>{title}</p>
  //         <p>
  //           {moment(start).format("h:mm A")} - {moment(end).format("h:mm A")}
  //         </p>
  //       </div>
  //     </>
  //   );
  // };

  // const [myevents, setEvents] = useState([
  //   {
  //     id: 1,
  //     title: "Meeting with John",
  // start: new Date(2023, 7, 24, 12, 0), // year, month (0-based), day, hour, minute
  // end: new Date(2023, 7, 24, 1, 0),
  //   },
  //   {
  //     id: 2,
  //     title: "Meeting with Shaarif",
  //     start: new Date(2023, 7, 24, 2, 0), // year, month (0-based), day, hour, minute
  //     end: new Date(2023, 7, 24, 3, 0),
  //   },
  // ]);

  const localizer = momentLocalizer(moment);

  // const myEventsList = [
  //   {
  //     title: "Meeting with John",
  //     start: new Date(
  //       today.getFullYear(),
  //       today.getMonth(),
  //       today.getDate(),
  //       6,
  //       0
  //     ),
  //     end: new Date(
  //       today.getFullYear(),
  //       today.getMonth(),
  //       today.getDate(),
  //       7,
  //       0
  //     ),
  //   },
  //   {
  //     title: "Lunch with Sarah",
  //     start: new Date(
  //       today.getFullYear(),
  //       today.getMonth(),
  //       today.getDate(),
  //       9,
  //       0
  //     ),
  //     end: new Date(
  //       today.getFullYear(),
  //       today.getMonth(),
  //       today.getDate(),
  //       10,
  //       0
  //     ),
  //   },
  // ];

  const CustomEvent = ({ event }) => {
    const { start, title, id } = event;

    const startHour = start.getHours();

    const showModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    const showAddnote = () => {
      setAdd(!Add);
    };

    // if (title === "Appointment with harim") {
    //   return (
    //     <>
    //       <EventContainer>
    //         {/* <div className="custom-event-title">{event.title}</div> */}
    //       </EventContainer>
    //       <EventContent className="custom-event-content">
    //         <UserDescription>
    //           <NumberContent>
    //             <NumberHeading onClick={() => showModal()}>9</NumberHeading>
    //           </NumberContent>
    //           <ActiveStatus></ActiveStatus>
    //           <PersonalInfo>
    //             <Wrap>
    //               <PersonalHeading>Personal Hygiene Routine</PersonalHeading>
    //               <AddNote saveNote={saveNote} onClick={() => setAdd(!Add)}>
    //                 {saveNote === "" ? "Add Note" : "View Note"}
    //               </AddNote>
    //               <AddNoteModal
    //                 Add={Add}
    //                 setAdd={setAdd}
    //                 saveNote={saveNote}
    //                 setSaveNote={setSaveNote}
    //               />
    //             </Wrap>
    //             <PersonalDescription>Build</PersonalDescription>
    //           </PersonalInfo>
    //         </UserDescription>

    //         <Userhabbit>
    //           <QuadrantSelect />
    //           <UserHeading>Habit</UserHeading>
    //         </Userhabbit>
    //       </EventContent>
    //       <AddEventDetailModal
    //         isModalOpen={isModalOpen}
    //         setIsModalOpen={setIsModalOpen}
    //       />
    //     </>
    //   );
    // }
    if (id === 3) {
      return (
        <>
          <EventContainer id={id}>
            {/* <div className="custom-event-title">{event.title}</div> */}
          </EventContainer>
          <EventContent className="custom-event-content">
            <UserDescription>
              <NumberContent>
                <NumberHeading>10</NumberHeading>
              </NumberContent>
              <ActiveStatus>
                <Image src="/Assets/activeStatus.svg" alt="" />
              </ActiveStatus>
              <PersonalInfo>
                <PersonalHeading>Sleep 8 hours</PersonalHeading>
                <PersonalDescription iscolor={true}>
                  11/05/2023
                </PersonalDescription>
              </PersonalInfo>
            </UserDescription>

            <Userhabbit id={id}>
              <Label>Q2</Label>
              <UserHeading>Action item</UserHeading>
            </Userhabbit>
          </EventContent>
        </>
      );
    }
    if (id === 4) {
      return (
        <>
          <EventContainer id={id}>
            {/* <div className="custom-event-title">{event.title}</div> */}
          </EventContainer>
          <EventContent className="custom-event-content">
            <UserDescription>
              <NumberContent>
                <NumberHeading onClick={() => showModal()}>9</NumberHeading>
              </NumberContent>
              <ActiveStatus></ActiveStatus>
              <PersonalInfo>
                <Wrap>
                  <PersonalHeading>Personal Hygiene Routine</PersonalHeading>
                  <AddNote saveNote={saveNote} onClick={() => showAddnote()}>
                    {saveNote === "" ? "Add Note" : "View Note"}
                  </AddNote>
                </Wrap>
                <PersonalDescription>Build</PersonalDescription>
              </PersonalInfo>
            </UserDescription>

            <Userhabbit id={id}>
              <QuadrantSelect />
              <UserHeading>Habit</UserHeading>
            </Userhabbit>
          </EventContent>
          {/* <AddEventDetailModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          /> */}
        </>
      );
    }
    if (id === 5) {
      return (
        <>
          <EventContainer id={id}>
            {/* <div className="custom-event-title">{event.title}</div> */}
          </EventContainer>
          <EventContent className="custom-event-content">
            <UserDescription>
              <NumberContent>
                <NumberHeading>10</NumberHeading>
              </NumberContent>
              <ActiveStatus>
                <Image src="/Assets/activeStatus.svg" alt="" />
              </ActiveStatus>
              <PersonalInfo>
                <PersonalHeading>Director of Investments</PersonalHeading>
                <PersonalDescription iscolor={true}>
                  11/05/2023
                </PersonalDescription>
              </PersonalInfo>
            </UserDescription>

            <Userhabbit id={id}>
              <Label>Q2</Label>
              <UserHeading>Decision</UserHeading>
            </Userhabbit>
          </EventContent>
        </>
      );
    }
    if (id === 6) {
      return (
        <>
          <EventContainer id={id}>
            {/* <div className="custom-event-title">{event.title}</div> */}
          </EventContainer>
          <EventContent className="custom-event-content">
            <UserDescription>
              <NumberContent>
                <NumberHeading>10</NumberHeading>
              </NumberContent>
              <ActiveStatus>
                <Image src="/Assets/activeStatus.svg" alt="" />
              </ActiveStatus>
              <PersonalInfo>
                <PersonalHeading>
                  Engage in Meaningful Conversation
                </PersonalHeading>
                <PersonalDescription iscolor={true}>
                  11/05/2023
                </PersonalDescription>
              </PersonalInfo>
            </UserDescription>

            <Userhabbit id={id}>
              <Label>Q2</Label>
              <UserHeading>Habbit</UserHeading>
            </Userhabbit>
          </EventContent>
        </>
      );
    }

    return null;
  };

  const CustomHeader = ({ date }) => {
    const dateNumber = moment(date).format("DD");
    const day = moment(date).format("ddd");

    return (
      <>
        <HeaderContent className="rbc-header-content">
          <HeaderDate className="rbc-header-date">{dateNumber}</HeaderDate>
          <HeaderDay className="rbc-header-day">{day}</HeaderDay>
        </HeaderContent>
      </>
    );
  };

  const Toolbar = ({ label, onView, view, onNavigate, localizer }) => {
    const formattedDate = moment(label, "MMM DD").format("MMMM YYYY");

    const [isFilter, setIsFilter] = useState(false);

    const handleSync = () => {
      setIsSyncOpen(!isSyncOpen);
    };
    // const handleTimeline = () => {
    //   setIsOpenTimeline(!isOpenTimeline);
    // };

    const handleFilterPopup = () => {
      setIsFilter(!isFilter);
    };

    return (
      <MainToolbarContainer className="my-toolbar">
        <ContainerOne>
          <SyncButtonContainer>
            <SyncButton onClick={handleSync}>
              <MdOutlineSync />
              <SyncHeading>Sync</SyncHeading>
            </SyncButton>
          </SyncButtonContainer>

          <Header>
            <ButtonContent>
              <Button1 onClick={() => onNavigate("PREV")}>
                <LeftOutlined />
              </Button1>
              <Button1
                style={{ marginLeft: "0.7rem" }}
                onClick={() => onNavigate("NEXT")}
              >
                <RightOutlined />
              </Button1>
              <Span>{formattedDate}</Span> {/* Display formatted date */}
            </ButtonContent>
          </Header>
        </ContainerOne>

        <ContainerTwo>
          <FilterButton>
            <RiFilter3Line onClick={() => handleFilterPopup()} />
            <CheckboxPopover isFilter={isFilter} />
          </FilterButton>
          <FilterHeading>(5)</FilterHeading>
          <TimeHeading onClick={''}>Time of day</TimeHeading>

          <SelectDayTime />
        </ContainerTwo>
      </MainToolbarContainer>
    );
  };

  const formats = {
    timeGutterFormat: (date, culture, localizer) => {
      // Get the hour value from the date
      return localizer.format(date, "HA");
    },
  };

  ////////////////////////// Slot Selected Modal////////////////////////////////////

  const [savedOption, setSavedOption] = useState(null);
  const [highlightedRanges, setHighlightedRanges] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const optionValues = ["Q1", "Q2", "Q3", "Q4", "None"];
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");

  // console.log(showOptions, "hsrim");

  const handleOptionClick = (value) => {
    if (selectedStart && selectedEnd) {
      const newHighlightedRange = {
        start: selectedStart,
        end: selectedEnd,
        color: value,
      };

      setHighlightedRanges([...highlightedRanges, newHighlightedRange]);
      setSelectedOption(value);
      // setShowOptions(true);
      setSelectedStart(null);
      setSelectedEnd(null);
    }
  };

  const isSelected = (option) => option === selectedOption;

  const handleDropdownToggle = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    setSavedOption(selectedOption);
  }, [selectedOption]);

  const NewselectedStyle = (selectedOption) => {
    if (selectedOption === "Q1") {
      return { backgroundColor: "#9B39E8", opacity: "0.2" };
    } else if (selectedOption === "Q2") {
      return { backgroundColor: "#E38F27", opacity: "0.2" };
    } else if (selectedOption === "Q3") {
      return { backgroundColor: "#DE3131", opacity: "0.2" };
    } else if (selectedOption === "Q4") {
      return { backgroundColor: "#3BB748", opacity: "0.2" };
    } else {
      return {};
    }
  };

  const slotPropGetter = (date) => {
    for (let i = 0; i < highlightedRanges.length; i++) {
      const highlightedRange = highlightedRanges[i];
      if (date >= highlightedRange.start && date <= highlightedRange.end) {
        return {
          style: {
            backgroundColor: highlightedRange.color,
            ...NewselectedStyle(highlightedRange.color),
          },
        };
      }
    }

    return null;
  };

  const handleSelectSlot = ({ start, end }) => {
    setSelectedStart(start);
    setSelectedEnd(end);
    setShowOptions(!showOptions);
  };

  const CustomTimeGutterHeader = () => {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GMTheading>GMT+4</GMTheading>
      </div>
    );
  };

  return (
    <Container>
      {/* <div
        style={{ width: "200px", padding: "1rem" }}
        onDragStart={() => setDraggedEvent(dummyAppointment)}
        draggable
      >
        <AppointmentEvent appointment={dummyAppointment} />
      </div> */}

      <DnDCalendar
        selectable
        events={events}
        draggableAccessor={(event) => !!event.isDraggable}
        resizableAccessor={"isResizable"}
        onEventDrop={onChangeEventTime}
        // onEventResize={onChangeEventTime}
        onDropFromOutside={onDroppedFromOutside}
        onSelectSlot={handleSelectSlot}
        slotPropGetter={slotPropGetter}
        // formats={formats}
        view="week"
        localizer={localizer}
        // events={myEventsList}
        // events={myevents}
        startAccessor="start"
        endAccessor="end"
        style={{}}
        className="my-calendar-class"
        components={{
          toolbar: Toolbar,
          event: CustomEvent,
          timeGutterHeader: CustomTimeGutterHeader,

          week: {
            header: CustomHeader,
            // timeContentWrapper: CustomTimeContentWrapper,
          },
        }}
      // min={new Date(0, 0, 0, 0, 0, 0)}
      // max={new Date(0, 0, 0, 12, 1, 0)}
      />
      {/* <DnDCalendar
        
        selectable
        onSelectSlot={handleSelectSlot}
        slotPropGetter={slotPropGetter}
        formats={formats}
        view="week"
        localizer={localizer}
        // events={myEventsList}
        // events={myevents}
        startAccessor="start"
        endAccessor="end"
        style={{}}
        className="my-calendar-class"
        components={{
          toolbar: Toolbar,
          // event: CustomEvent,

          week: {
            header: CustomHeader,
            // timeContentWrapper: CustomTimeContentWrapper,
          },
        }}
        min={new Date(0, 0, 0, 6, 0, 0)}
        max={new Date(0, 0, 0, 13, 0, 0)}
      /> */}
      <Modal
        footer={false}
        open={showOptions}
        closable={false}
        className="only_dropdown"
      >
        <SelectDropdown onClick={handleDropdownToggle}>
          {optionValues.map((option) => (
            <List
              key={option}
              className={`custom-select-option ${isSelected(option) ? "selected" : ""
                }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </List>
          ))}
        </SelectDropdown>
      </Modal>
    </Container>
  );
};

export default Schedular;
const Container = styled.div`
  background-color: #fff;
`;

const MainToolbarContainer = styled.div`
  background-color: #fff;
  border-top: 1px solid #c1cad1;
  border-bottom: 1px solid #c1cad1;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContainerOne = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
`;

const ContainerTwo = styled.div`
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
`;
const FilterButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;

  svg {
    color: #6d7f8e;
    font-size: 1.5rem;
  }
`;
const FilterHeading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 8px;
  color: #418db8;
  margin: 0;
  margin-right: 1rem;
  user-select: none;
`;
const TimeHeading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #1271a6;
  margin: 0;
  margin-right: 1rem;
  user-select: none ;
`;
const Box = styled.div`
  margin: 0;
  padding: 0;
  border: 2px solid #e5e5e5;
  margin-right: 2rem;
  width: 140px;
  display: flex;
  padding: 3px 0px;
  justify-content: space-evenly;
  padding-inline: 1.5rem;
  /* justify-content: center; */
  align-items: center;
  /* gap: 11px; */
  /* border: 1px solid green; */
  /* align-self: stretch; */
`;

const Desc = styled.p`
  margin: 0;
  user-select: none;
  /* border: 1px solid green; */
  min-width: 85px;
  /* width: 90px; */
`;

const BtnWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  height: 100%;
  /* border: 1px solid green; */
  /* transform: translateY(-4px); */
`;
const ButtonUp = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  height: 17px;
  display: flex;
  align-items: flex-end;
`;
const ButtonDown = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  height: 17px;
  display: flex;
  align-items: flex-start;
`;
const Header = styled.div`
  /* border: 1px solid red; */
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10px;
`;
const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;
const Span = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6d7f8e;
  margin-left: 1rem;
  white-space: nowrap;
  user-select: none;
`;
const Button1 = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 9px;
  cursor: pointer;

  svg {
    color: #6d7f8e;
  }
`;

const HeaderContent = styled.div`
  min-height: 50px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  user-select: none;
`;
const HeaderDay = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #586573;
  text-transform: uppercase;
`;
const HeaderDate = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #586573;
`;

const SyncButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SyncButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0px;

  svg {
    font-size: 1.6rem;
    color: #6d7f8e;
  }
`;

const SyncHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #1271a6;
  margin-left: 0.5rem;
  user-select: none;
`;

const EventContainer = styled.div`
box-shadow: 0px 1px 4px 0px rgba(21, 29, 41, 0.20);
  border: 1.5px solid
    ${({ id }) =>
    (id === 3 && "#FFC01E") ||
    (id === 4 && "#448FFF") ||
    (id === 6 && "#448FFF") ||
    (id === 5 && "darkgreen")};
`;
const EventContent = styled.div`
  min-height: 145px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 15px 0px 0px rgba(21, 29, 41, 0.20);
 

  /* border: 1px solid green; */
`;

const UserDescription = styled.div`
  padding: 0 5px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* justify-content: space-evenly; */
  /* align-items: center; */

  /* min-height: 108px; */
`;
const AddTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;
const TitleName = styled.p`
  margin: 0;
  padding: 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;
const NoteHeading = styled.p`
  margin: 0;
  padding: 0;
  background-color: #e48e27;
  display: flex;
  height: 20px;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;
const NumberContent = styled.div`
  background-color: #c1cedb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 21px;
  width: 21px;
  margin-top: 0.4rem;
  margin-left: 6.5px;
  /* margin-left: 0.5rem; */
  /* margin-right: 0.5rem; */
`;
const NumberHeading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 9px;
  color: #58728c;
  margin: 0;
`;

const ActiveStatus = styled.div`
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  margin-top: 12px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: linear-gradient(39deg, #4a937a 0%, #55d2a8 80.6%);
`;
const Image = styled.img``;
const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
`;
const PersonalHeading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-feature-settings: "tnum" on, "lnum" on;
  width: 100px;
  color: #1b2a3d;
  margin: 0;
  margin-top: 0.3rem;
`;

const PersonalDescription = styled.p`
  font-family: "Inter";
  font-style: italic;
  font-weight: ${({ iscolor }) => (iscolor ? 700 : 400)};
  font-size: 8px;
  line-height: 16px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: ${({ iscolor }) => (iscolor ? "#4F9BE8" : "#35AA22")};
  margin: 0;
`;

const Userhabbit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ id }) =>
    (id === 3 && "#E38F27") ||
    (id === 4 && "#9B39E8") ||
    (id === 5 && "#4BC583") ||
    (id === 6 && "#DE3131")};
  height: 40px;
`;

const Label = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-left: 0.5rem;
`;
const UserHeading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #ffffff;
  margin: 0;
  margin-right: 0.5rem;
`;

const SelectDropdown = styled.ul`
  margin: 0;
  /* border: 1px solid green; */
  width: 50px;
  height: auto;
  padding: 6px;
  background: #04192e;
`;

const List = styled.li`
cursor: pointer;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 9px;
  border-radius: 2px;
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;

  :nth-child(1) {
    border-bottom: 2px solid #9b39e8;
  }

  :nth-child(2) {
    border-bottom: 2px solid #e38f27;
  }
  :nth-child(3) {
    border-bottom: 2px solid #de3131;
  }
  :nth-child(4) {
    border-bottom: 2px solid #3bb748;
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`;
const AddNote = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid
    ${({ saveNote }) => (saveNote === "" ? "#9B39E8" : "#e38f27")};
  color: ${({ saveNote }) => (saveNote === "" ? "#9B39E8" : "#e38f27")};
  margin: 0;
  padding: 0;
  margin-top: 0.2rem;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`;

const GMTheading = styled.h5`
  color: #6d7f8e;
  text-align: center;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 133.333% */
  transform: rotate(-90deg);
  margin: 0;
  user-select: none;
`;
