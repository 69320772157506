import { ActionTypes } from "./types";

export const IsUser = (Data) => async (dispatch) => {
  dispatch({
    type: ActionTypes.USER_DETAIL,
    payload: Data,
  });
};
export const IsLoggedIn = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.USER_LOGGED_IN,
    payload: decision,
  });
};
