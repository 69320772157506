import { FC } from 'react';

import {
  Spacer,
  Wrapper,
} from './styled';

// @ts-ignore
import plusIcon from "assets/images/journeys/master-plan-shortcut.svg";

interface IProps {
  isVisible: boolean;
}

const PlanShortcut: FC<IProps> = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Spacer />

      <Wrapper to="/journeys/master-plan">
        <span>Go To Master Plan</span>
        <figure>
          <img src={plusIcon} alt="" />
        </figure>
      </Wrapper>
    </>
  );
}

export default PlanShortcut;
