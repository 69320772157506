import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media not print {
    display: none;
  }
`;

export const Col = styled.div`
  display: flex;
  align-items: center;
`;

export const ColSecondary = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 2px;
  height: 26px;
  margin: 0 23px;
  background: var(--primary-500-main, #1271A6);
`;

export const Logo = styled.img``;

export const Title = styled.span`
  color: var(--Primary-600, #116595);
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
`;

export const SubTitle = styled.span`
  color: var(--Neutral-800, #262626);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
  white-space: nowrap;
`;

export const User = styled.span`
  color: var(--Neutral-600, var(--Text, #525252));
  font-family: Poppins;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
`;
