import React from "react";
import { Modal } from "antd";
import ArootahLogo from "assets/images/logo.png";
import styled from "styled-components";
import Button from "../../components/Button";
import { getCookie } from "utils/StorageVariables";

const CreditModal = ({ availableCredit, domain, open, setOpen }) => {
  let userDetails = JSON.parse(getCookie("userData"));
  const handleCancel = () => {
    setOpen();
  };

  return (
    <>
      <Modal
        open={open}
        footer={false}
        centered
        width={750}
        onCancel={handleCancel}
      >
        <Wrapper>
          <Logo src={ArootahLogo} alt="Arootah" />
          <Description>
            You have <b>{availableCredit}</b> credits to start creating a master
            plan for the <b>{domain}</b> Journey click purchase below to add
            more credits to your account.
          </Description>
          <ActionButton
            href={`https://buy.stripe.com/eVa3cf8Ws3REdSEdRg?prefilled_email=${userDetails?.email}`}
            target="_blank"
            color="white"
          >
            Purchase
          </ActionButton>
          <CancelButton onClick={handleCancel} color="#282C31">
            Cancel
          </CancelButton>
        </Wrapper>
      </Modal>
    </>
  );
};

export default CreditModal;

const Wrapper = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.max("sm")`
    padding: 2rem 0rem;
  `}
`;
const Description = styled.p`
  margin: 2rem 0rem;
  text-align: center;
  font-family: "Inter";
  color: #4c545b;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;
const Logo = styled.img``;
const ActionButton = styled.a`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);

  border-radius: 3px;
  color: ${({ color }) => color} !important;
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 196px;
  text-decoration: none;
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
const CancelButton = styled(Button)`
  background: transparent;
  color: ${({ color }) => color};
  width: 100%;
  text-transform: initial;
  height: 36px;
  line-height: 36px;
  width: 196px;
  margin-top: 0.5rem;
  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  &:disabled {
    opacity: 0.5;
  }
`;
