import React from "react";

import Prioritize from "components/Journeys/Prioritize";
import ScreenLayout from "components/Journeys/SceneLayout";

const PrioritizeScreen = () => {
  return (
    <ScreenLayout>
      <Prioritize />
    </ScreenLayout>
  );
};

export default PrioritizeScreen;
