import { useState } from "react";
import moment from "moment";
import DailyTimeline from "../DailyTimeline";

import dailyImage from "assets/images/dashboard/daily.png";

import { ReactComponent as LeftIcon } from "assets/images/priorities/chevron-left.svg";
import { ReactComponent as RightIcon } from "assets/images/priorities/chevron-right.svg";

import {
  Wrapper,
  Head,
  Title,
  ImageContainer,
  Image,

  // Switcher
  DaySwitch,
  DaySwitchBtn,
  SelectedDay
} from "./styled";

import Quote from "../Quote";

const DailySummary = () => {
  const [selectedDate, setSelectedDate] = useState(moment());

  const handlePrevClick = () => {
    setSelectedDate(selectedDate.clone().subtract(1, 'day'));
  };

  const handleNextClick = () => {
    setSelectedDate(selectedDate.clone().add(1, 'day'));
  };

  return (
    <Wrapper>
      <Head>
        <Title>Daily Summary</Title>
        <DaySwitch>
          <DaySwitchBtn type="button" title="Prev" onClick={handlePrevClick}>
            <LeftIcon />
          </DaySwitchBtn>
          <SelectedDay>{selectedDate.format('ddd, MMMM D')}</SelectedDay>
          <DaySwitchBtn type="button" title="Next" onClick={handleNextClick}>
            <RightIcon />
          </DaySwitchBtn>
        </DaySwitch>
      </Head>

      <ImageContainer>
        <Image src={dailyImage} height={105} alt="" />
      </ImageContainer>

      <DailyTimeline />
      <Quote />
    </Wrapper>
  );
}

export default DailySummary;
