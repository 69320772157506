import { ACTIONS_PATH, GOALS_PATH, MEASUREMENT_PATH, MISSION_PATH, PRIORITIZE_PATH, PURPOSE_PATH } from "utils/routes";

export const items = [
  {
    title: "Area/Category",
    path: MISSION_PATH,
    step: 1,
    color: "#627CFC",
    label: "1"
  },
  {
    path: PRIORITIZE_PATH,
    title: "Prioritization",
    step: 2,
    color: "#6D71F5",
    label: "2"
  },
  {
    title: "Goal Statement",
    path: MEASUREMENT_PATH, // GOALS_PATH
    step: 4, // 3
    color: "#904DE0",
    label: "3"
  },
  {
    title: "Measurement",
    path: MEASUREMENT_PATH,
    step: 4,
    color: "#815DE9",
    label: "4"
  },
  {
    title: "Purpose",
    path: PURPOSE_PATH,
    step: 5,
    color: "#A03CD6",
    label: "5"
  },
  {
    title: "Actions",
    path: ACTIONS_PATH,
    step: 6,
    color: "#B02CCC",
    label: "6"
  },
  {
    title: "Action Priority",
    path: ACTIONS_PATH,
    step: 6,
    color: "#A647C5",
    label: "7"
  },
  {
    title: "Due",
    path: ACTIONS_PATH,
    step: 6,
    color: "#9370BF",
    label: "8"
  },
  {
    title: "Start",
    path: ACTIONS_PATH,
    step: 6,
    color: "#8199B9",
    label: "9"
  },
  {
    title: "Accountability",
    path: ACTIONS_PATH,
    step: 6,
    color: "#6FC2B2",
    label: "10"
  },
  {
    title: "Master Plan",
    path: false,
    step: 7,
    color: "#61E1AE",
    label: "Master Plan",
    labelWidth: 64
  }
];
