import styled from "styled-components";
import { Link as LinkItem } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  min-height: 40px;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 0 10px;
}`;

export const ProfilePicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1.5px solid var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.15), 0px 1px 2px -1px rgba(16, 24, 40, 0.15);
  margin-right: 13px;
}`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
}`;

export const Name = styled.span`
  width: 212px;
  margin-right: 68px;
  color: var(--Neutral-900, #171717);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}`;

export const Email = styled.span`
  flex: 1;
  color: var(--Neutral-700, #404040);
  font-family: Poppins;
  font-size: 14px;
  line-height: 20px;
}`;

export const Link = styled(LinkItem)`
  font-family: Inter;
  font-weight: 500;
  border-radius: 3px;
  padding: 6px 0px;
  font-size: 12px;
  line-height: 20px;
  color: rgb(18, 113, 166);
}`;
