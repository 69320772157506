import styled from "styled-components";

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  margin-top: 2px;
  padding: 2px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  background: #fff;
  border-radius: 50%;

  span {
    position: absolute;
    display: block;
    top: 0;
    margin-top: 36px;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    color: #3E3E3E;
    // font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
}`;

interface WrapperProps {
  width: number;
  height: number;
}
