// @ts-ignore
import { listCategories, listCustomCategories } from "../apm-apis";

import { ActionTypes } from "./type";

export const fetchCategories = (payload: string) => async (dispatch: any, getState: any) => {
  const { client } = getState().prioritizedActions;

  const filtersParams = {
    category_name: payload
  };

  const filtersCustomParams = {
    name: payload
  };

  if (client) {
    // @ts-ignore
    filtersParams.client = client;
    
    // @ts-ignore
    filtersCustomParams.client = client;
  }

  try {
    dispatch({ type: ActionTypes.REQUEST });

    const [categoriesResponse, customCategoriesResponse] = await Promise.all([
      listCategories(filtersParams),
      listCustomCategories(filtersCustomParams)
    ]);
  
    const { data } = categoriesResponse;
    const { data: customData } = customCategoriesResponse;

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: {
        data,
        customData
      }
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });
  }
};
