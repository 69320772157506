export const data = [
  {
    id: '1',
    order: 10,
    title: 'Investments',
    category: 'Finance',
    color: '#03539D'
  },
  {
    id: '2',
    order: 9,
    title: 'Volunteer',
    category: 'Contribution',
    color: '#85D2B8'
  },
  {
    id: '3',
    order: 8,
    title: 'Physical Health',
    category: 'Health',
    color: '#85D2B8'
  },
  {
    id: '4',
    order: 7,
    title: 'Home',
    category: 'Environment',
    color: '#8D7538'
  },
  {
    id: '5',
    order: 6,
    title: 'Culture',
    category: 'Leisure',
    color: '#DF5495'
  },
  {
    id: '6',
    order: 5,
    title: 'Investments',
    category: 'Finance',
    color: '#03539D'
  },
  {
    id: '7',
    order: 4,
    title: 'Volunteer',
    category: 'Contribution',
    color: '#85D2B8'
  },
  {
    id: '8',
    order: 3,
    title: 'Physical Health',
    category: 'Health',
    color: '#85D2B8'
  },
  {
    id: '9',
    order: 2,
    title: 'Home',
    category: 'Environment',
    color: '#8D7538'
  },
  {
    id: '10',
    order: 1,
    title: 'Culture',
    category: 'Leisure',
    color: '#DF5495'
  }
];
