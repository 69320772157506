import { Tooltip } from "elements";
import { useNavigate } from "react-router-dom";
import useDeviceType from "../../../Hooks/ResponsiveHook";

import {
  Wrapper,
  Bar,
  Line,
  CircleLink,
  CircleDiv,
  LineMobile,
  SLine,
} from "./styled";

const Steps = ({ items, step, withLabels, withLinks }) => {
  function filterSteps(items) {
    let output = [];
    if (step < 1) step = 1;
    if (step > 10) step = 10;

    const startIndex =
      step === 1 || step === 2 || step === 4
        ? Math.max(0, step - 3)
        : Math.max(1, step - 2);
    output =
      step === 10
        ? [items[0], items[8], items[9]]
        : items.slice(startIndex, startIndex + 3);
    output.push(items[items.length - 1]);
    return output;
  }

  const navigate = useNavigate();
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";

  const handleClick = (step) => (e) => {
    if (step === 1) {
      e.preventDefault();
      navigate(`?redirect=${e.target.pathname}`);
    }
  };

  const stepItems = isMobile ? filterSteps(items) : items;

  return (
    <Wrapper>
      {/* <Text style={{ display: notShow ? "none" : "block" }}>Step {step} of 10</Text> */}

      <Bar $withLabels={withLabels}>
        {isMobile ? (
          <>
            {step === 10 ? (
              <LineMobile>
                <SLine $left={"2%"} $color />
                <SLine $left={"6%"} $color />
                <SLine $left={"10%"} $color />
                <SLine $left={"14%"} $color />
                <SLine $left={"18%"} $color />
                <SLine $left={"22%"} $color />
                <SLine $left={"26%"} $color />
                <Line  $left={"30%"}/>
              </LineMobile>
            ) : (
              <LineMobile>
                <Line />
                <SLine />
                <SLine $left={"76%"} />
                <SLine $left={"80%"} />
                <SLine $left={"84%"} />
                <SLine $left={"88%"} />
                <SLine $left={"92%"} />
              </LineMobile>
            )}
          </>
        ) : (
          <Line />
        )}
        {isMobile ? (
          <>
            {" "}
            {stepItems?.map((item) =>
              withLinks ? (
                <Tooltip title={item.title} key={item.title}>
                  <CircleLink
                    to={item.path}
                    onClick={handleClick(step)}
                    $active={item.step === step}
                    $completed={item.step < step}
                    $color={item.color}
                    $label={item.label}
                    $labelWidth={item.labelWidth}
                    $withLabels={withLabels}
                  />
                </Tooltip>
              ) : (
                <CircleDiv
                  $active={item.step === step}
                  $completed={item.step < step}
                  $color={item.color}
                  $label={item.label}
                  $labelWidth={item.labelWidth}
                  $withLabels={withLabels}
                  key={item.title}
                />
              )
            )}
          </>
        ) : (
          <>
            {items?.map((item) =>
              withLinks ? (
                <Tooltip title={item.title} key={item.title}>
                  <CircleLink
                    to={item.path}
                    onClick={handleClick(step)}
                    $active={item.step === step}
                    $completed={item.step < step}
                    $color={item.color}
                    $label={item.label}
                    $labelWidth={item.labelWidth}
                    $withLabels={withLabels}
                  />
                </Tooltip>
              ) : (
                <CircleDiv
                  $active={item.step === step}
                  $completed={item.step < step}
                  $color={item.color}
                  $label={item.label}
                  $labelWidth={item.labelWidth}
                  $withLabels={withLabels}
                  key={item.title}
                />
              )
            )}
          </>
        )}
      </Bar>
    </Wrapper>
  );
};

export default Steps;
