import { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

// @ts-ignore
import trashIcon from "assets/svg/trash-outlined.svg";

import { DeleteAccountability } from 'Redux/ListAccountability/action';

import { Wrapper, Button } from './styled';

interface IProps {
  value: string;
  label: string;
  onDelete: (value: string) => void;
}

const CustomOption: FC<IProps> = ({ value, label, onDelete }) => {
  const dispatch = useDispatch();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(DeleteAccountability(value));
    onDelete(value);
  }

  return (
    <Wrapper>
      <span>{label}</span>

      <Button onClick={handleClick} title="Delete">
        <img src={trashIcon} alt="" />
      </Button>
    </Wrapper>
  );
}

export default CustomOption;
