import { FC } from 'react';
import { Modal } from "antd";
import { Button } from 'elements';

// @ts-ignore
import statmentIcon from "assets/svg/StatmentIconBlue.svg";

import {
  Container,
  Content,
  Image,
  Heading,
  Text,
  ButtonsGroup
} from "./styled";

interface IProps {
  isOpen: boolean;
  onOK: () => void;
  onCancel: () => void;
}

const ExitConfirmModal: FC<IProps> = ({
  isOpen,
  onOK,
  onCancel
}) => {
  return (
    <Container>
      <Modal
        bodyStyle={{
          padding: 0,
        }}
        open={isOpen}
        width={616}
        centered
        footer={null}
        onCancel={onCancel}
      >
        <Content>
          <Image src={statmentIcon} />

          <Heading>Cancel changes?</Heading>
          <Text>Your edits won’t be saved.</Text>

          <ButtonsGroup>
            <Button styleType="secondary" onClick={onCancel}>Return to Editing</Button>
            <Button styleType="primary" onClick={onOK}>Continue Without Saving</Button>
          </ButtonsGroup>
        </Content>
      </Modal>
    </Container>
  );
};

export default ExitConfirmModal;
