import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import Joyride, { STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTutorialProgress } from "../Redux/UpdateTutorialProgress/action";

const JoyrideContext = createContext();

export const useJoyride = () => useContext(JoyrideContext);

export const JoyrideProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);
  const [isClear, setIsClear] = useState(false);

  const [tutorialProgress, setTutorialProgress] = useState({});
  const [disableScroll, setDisableScroll] = useState(true);

  const { userDetail: userData } = useSelector(
    (state) => state.userDetailReducer
  );

  const startTour = useCallback((tourSteps, clearState) => {
    setIsClear(clearState || false);
    setDisableScroll(clearState ? false : true);
    setSteps(tourSteps);
    setRun(true);
  }, []);

  const prioritizedActions = useSelector((state) => state.prioritizedActions);
  const data = prioritizedActions?.prioritizedDataGrouped?.[0]?.actions?.[0];

  const updateProgress = (index, id, status) => {
    const stepNumber = parseInt(id.split("step")[1]);

    const isStepTwo = id === "#react-joyride-step2";
    const isStepFive = id === "#react-joyride-step5";

    setTutorialProgress((prevProgress) => {
      // Create a copy of the previous progress
      const newProgress = { ...prevProgress };

      // Update previous steps to 'Done'
      for (let i = 1; i < stepNumber; i++) {
        newProgress[`step_${i}`] = "Done";
      }

      // Update the current step with the new status
      newProgress[`step_${stepNumber}`] = status;

      return newProgress;
    });

    if (isStepTwo && status !== "Skipped") {
      dispatch({ type: "IS_EDITING", payload: true });
      dispatch({ type: "IS_EDITING_ID", payload: data?.id });
    } else if (isStepFive) {
      dispatch({ type: "IS_EDITING", payload: false });
      dispatch({ type: "IS_EDITING_ID", payload: data?.id });
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, index, action, step } = data;

    const id = step?.target;

    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (action === "next" || action === "last") {
      updateProgress(index, id, "Done");
    }

    if (status === STATUS.SKIPPED) {
      updateProgress(index, id, "Skipped");
    }

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedUpdateUserData = useCallback(
    debounce(async (progress) => {
      if (progress === undefined) return;

      if (Object.keys(progress).length > 0 && userData) {
        if ("step_NaN" in progress) {
          delete progress.step_NaN;
        }
        const tutorial_progress = {
          tutorial_progress: progress,
        };

        await dispatch(
          UpdateTutorialProgress(
            tutorial_progress,
            userData.public_id || userData.id
          )
        );
      }
    }, 300),
    [dispatch, userData]
  );

  useEffect(() => {
    debouncedUpdateUserData(tutorialProgress);
  }, [tutorialProgress]);

  useEffect(() => {
    if (isClear) {
      setTutorialProgress({});
    }
  }, [isClear]);

  return (
    <JoyrideContext.Provider value={{ startTour }}>
      {children}
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        run={run}
        continuous
        disableScrolling={disableScroll}
        showSkipButton={true}
        styles={{
          options: {
            zIndex: 999999,
          },
          beaconInner: {
            padding: "0px",
          },
          buttonNext: {
            background: "#1271A6",
            fontSize: "12px",
            padding: "12px 8px",
            fontWeight: 500,
            fontFamily: "Inter",
          },
          buttonSkip: {
            color: "#1271A6",
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
          },
          buttonBack: {
            display: "none",
          },
          buttonClose: {
            display: "none",
          },
          tooltipContent: {
            padding: "0px",
          },
          tooltipFooter: {
            marginTop: "20px",
          },
          tooltip: {
            padding: "16px ",
          },
        }}
      />
    </JoyrideContext.Provider>
  );
};
