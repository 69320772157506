import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";

import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";

import { DatePickerWrapper, WeekDatePicker, WeekPickerButton } from "./styled";

// @ts-ignore
import { setWeek } from "Redux/APM/ListFocusedPriorities/action";

const CustomWeekPicker = () => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(moment());

  const handlePrevWeek = () => {
    setCurrentDate(currentDate.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setCurrentDate(currentDate.clone().add(1, "week"));
  };

  const startOfWeek = currentDate.clone().startOf("week");
  const endOfWeek = currentDate.clone().endOf("week");
  const formateStartOfWeek = startOfWeek.format("YYYY-MM-DD");
  const formateEndOfWeek = endOfWeek.format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(
      setWeek({
        startDate: formateStartOfWeek,
        endDate: formateEndOfWeek,
      })
    );
  }, [formateStartOfWeek]);

  return (
    <DatePickerWrapper>
      <WeekPickerButton>
        <MdOutlineKeyboardArrowLeft
          onClick={handlePrevWeek}
          style={{
            fontSize: "20px",
          }}
        />
      </WeekPickerButton>

      <WeekDatePicker>
        <DatePicker
          value={startOfWeek}
          picker="week"
          dropdownClassName="custom-week-picker"
          onChange={(date) => setCurrentDate(date as any)}
          style={{ margin: "0 8px" }}
          format={(value) =>
            `${value.startOf("week").format("MMM DD")} - ${value
              .endOf("week")
              .format("MMM DD")}`
          }
        />
      </WeekDatePicker>

      <WeekPickerButton>
        <MdOutlineKeyboardArrowRight
          onClick={handleNextWeek}
          style={{
            fontSize: "20px",
          }}
        />
      </WeekPickerButton>
    </DatePickerWrapper>
  );
};

export default CustomWeekPicker;
