import { ActionTypes } from './type'

const initialListFocusedPrioritise = {
  listFocusedPrioritise: [],
  listCompleteFocusedPrioritise: [],
  week: {
    startDate: null,
    endDate: null,
  },
  progress: {
    completed: 0,
    prioritized: 0
  },
  loading: false,
  completeLoading: false,
};

const calcTotalMins = (data) => {
  return data.reduce((total, item) => {
    return (
      total +
      Number(
        item?.prioritized_activity?.total_time
          ? item?.prioritized_activity?.estimated_weekly_duration
          : item?.task?.estimated_duration || 0
      )
    );
  }, 0);
}

export default (state = initialListFocusedPrioritise, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_FOCUSED_PRIORITISE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.FETCH_FOCUSED_PRIORITISE_SUCCESS:
      return {
        ...state,
        listFocusedPrioritise: action.payload,
        progress: {
          ...state.progress,
          prioritized: calcTotalMins(action.payload)
        },
        loading: false
      };

    case ActionTypes.FETCH_COMPLETE_FOCUSED_PRIORITISE_REQUEST:
      return {
        ...state,
        completeLoading: true
      };
    case ActionTypes.FETCH_COMPLETE_FOCUSED_PRIORITISE_SUCCESS:
      return {
        ...state,
        listCompleteFocusedPrioritise: action.payload,
        progress: {
          ...state.progress,
          completed: calcTotalMins(action.payload)
        },
        completeLoading: false
      };
    case ActionTypes.FETCH_LIST_UNPRIORITIZED_ACTIONS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.REMOVE_FOCUSED_PRIORITISE_REQUEST: {
      // remove focused priority from listFocusedPrioritise based on id
      const { focusedPriorityId } = action.payload;
      const listFocusedPrioritise = state.listFocusedPrioritise.filter(item => item.id !== focusedPriorityId);

      return {
        ...state,
        listFocusedPrioritise,
        progress: {
          ...state.progress,
          prioritized: calcTotalMins(listFocusedPrioritise)
        },
        loading: true
      };
    }
    case ActionTypes.REMOVE_FOCUSED_PRIORITISE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ActionTypes.SET_WEEK_FOCUSED_PRIORITISE:
      return {
        ...state,
        week: {
          ...state.week,
          ...action.payload
        }
      };

    default:
      return state;
  }
}