export const ActionTypes = {
  LOADING: "ACCOUNTABILITY_LOADING",
  ERROR: "ACCOUNTABILITY_ERROR",
  SUCCESS: "ACCOUNTABILITY_SUCCESS",
  CUSTOM_SUCCESS: "ACCOUNTABILITY_CUSTOM_SUCCESS",

  LOADING_CREATE: "ACCOUNTABILITY_LOADING_CREATE",
  SUCCESS_CREATE: "ACCOUNTABILITY_SUCCESS_CREATE",
  ERROR_CREATE: "ACCOUNTABILITY_ERROR_CREATE",

  LOADING_DELETE: "ACCOUNTABILITY_LOADING_DELETE",
  SUCCESS_DELETE: "ACCOUNTABILITY_SUCCESS_DELETE",
  ERROR_DELETE: "ACCOUNTABILITY_ERROR_DELETE",
};

export interface IAccountability {
  id: string;
  domain: string;
  is_custom: boolean;
  name: string;
  order: number;
  user: string | null;
}
