import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
}`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  min-height: 47px;
  border-bottom: 1px solid #C1CAD1;
  background: var(--Dark-mode-bg, #FFF);
}`;

export const Title = styled.span`
  color: var(--primary-500-main, #1271A6);
  leading-trim: both;
  text-edge: cap;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  background: var(--Dark-mode-bg, #FFF);
  border-bottom: 1px solid #C1CAD1;
  min-height: 32px;
  padding: 0 30px;
}`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 318px;

  color: var(--Neutral-500, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 4px;
  background: var(--Primary-50, #ECF3F6);
  overflow-y: auto;

  ${({ theme }) => theme.min("sm")`
    ::-webkit-scrollbar {
      width: 26px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    ::-webkit-scrollbar-thumb {
      border: 10px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #acb4bc;
    }
  `}
}`;
