import React from "react";
import styled from "styled-components";
import Lock from "assets/images/locked_screen_icon.svg";
import { ReactSVG } from "react-svg";
const LockedOver = ({
  bgColor,
  iconColor,
  textColor,
  display,
  isBorder,
  index,
  width,
}) => {
  return (
    <MainLockContainer isBorder={isBorder} bgColor={bgColor}>
      <LockContent display={display}>
        <Icon width={width} $iconColor={iconColor} src={Lock} alt="lock" />
        <Content display={display} textColor={textColor}>
          Action Plan {index} is private
        </Content>
      </LockContent>
    </MainLockContainer>
  );
};

export default LockedOver;

const MainLockContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
  backdrop-filter: ${({ bgColor }) => !bgColor && `blur(3px)`};
  background-color: ${({ bgColor }) =>
    bgColor ? bgColor : "rgba(0, 0, 0, 0.3)"};
  /* pointer-events: none; */
  z-index: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ isBorder }) => isBorder && `5px solid ${isBorder}`};
`;
const LockContent = styled.div`
  display: flex;
  flex-direction: ${({ display }) => (display ? display : "column")};
  align-items: center;
  justify-content: center;
`;
const Icon = styled(ReactSVG)`
  svg {
    width: ${({ width }) => width && width};
    height: auto;
    path {
      fill: ${({ $iconColor }) => ($iconColor ? $iconColor : "#FFFFFF")};
    }
  }
`;
const Content = styled.p`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ textColor }) => (textColor ? textColor : "#FFFFFF")};
  padding: 0;
  padding-top: ${({ display }) => !display && "0.5rem"};
  margin: 0;
  padding-left: ${({ display }) => display && "0.5rem"};

  /* ${({ theme }) => theme.max("sm")`
   width:70% !important;
  `}
  ${({ theme }) => theme.max("xs")`
   width:90% !important;
  `} */
`;
