import { ActionTypes } from './type';
const initialState = {
    setAction: false,
};
const actionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_ACTION_ID:
            return {
                ...state,
                setAction: action.payload,
            };
        default:
            return state;
    }
};


export default actionReducer;