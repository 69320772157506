import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import tickIcon from "assets/images/journeys/tick.svg";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 36px;
  width: 100%;
 
 
`;

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20px;
  max-width: 820px;
  position: relative;

  ${({ $withLabels }) => $withLabels && `
    margin-top: -17px;
  `};
  @media screen and (max-width: 1256px) {
    width: 85%;
  }
`;

export const LineMobile = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
 
`;
export const SLine = styled.div`
  position: absolute;
  width: 2%;
  height: 2px;
  left: 72%;
  background: #5FE5AD;
  ${({ $left }) => $left && `
    left: ${$left};
  `};
  ${({ $color }) => $color && `
    background: #5E81FF;
  `};
  
  
 
`;
export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #5E81FF 0%, #B328CA 53.65%, #5FE5AD 98.96%);
  @media screen and (max-width: 500px) {
    width: 70%;
    ${({ $left }) => $left && `
    left: ${$left};
    background: linear-gradient(90deg, #5E81FF 10%, #B328CA 30.65%, #5FE5AD 70.96%);
  `};
  }
`;

const circle = css`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid transparent;

  ${({ $color, $active }) => $color && !$active && `
    border-color: ${$color};
  `};

  ${({ $active }) => $active && `
    // width: 20px;
    // height: 20px;
    background-color: #627CFC;
    border-color #fff;
    box-shadow: 0 0 4px 1px rgba(0,0,0,.2);
    box-sizing: content-box;
  `};

  ${({ $color, $completed }) => $color && $completed && `
    background-color: ${$color};
    background-image: url('${tickIcon}');
    background-repeat: no-repeat;
    background-position: center;
  `};

  ${({ $label, $withLabels }) => $label && $withLabels && `
    ::after {
      content: "${$label}";
      display: block;
      width: 12x;
      margin-left: -2px;
      margin-top: 19px;
      color: var(--neutral-500, var(--Input-header, #737373));
      text-align: center;
      font-family: Inter;
      font-size: 11px;
      font-weight: 700;
      line-height: 10px;
      white-space: nowrap;
    }
  `};

  ${({ $labelWidth, $withLabels }) => $labelWidth && $withLabels && `
    ::after {
      width: ${$labelWidth}px;
      margin-left: -${$labelWidth/2-8}px;
    }
  `};
`;

export const CircleDiv = styled.div`
  ${circle};
`;

export const CircleLink = styled(Link)`
  ${circle};
  text-decoration: none;
`;
