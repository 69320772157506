import React from "react";
import styled from "styled-components";
import { MdOutlineSync } from "react-icons/md";
import { BsLink45Deg } from "react-icons/bs";
import { ReactComponent as Close } from "assets/images/close.svg";
import { ReactComponent as GoogleCalender } from "assets/images/google_calender.svg";
import { ReactComponent as Outlook } from "assets/images/outlook.svg";
import { ReactComponent as Popover } from "assets/images/Popover_instruction.svg";
import { ReactComponent as Apple } from "assets/images/Apple_Calendar.svg";

import { Input, Tooltip } from "antd";

const SyncModal = ({ isSyncOpen, setIsSyncOpen }) => {
  return (
    <Wrapper>
      <Content>
        <Header>
          <SyncHeading>
            <MdOutlineSync style={{ color: "#112538", fontSize: "1.5rem" }} />
            <Heading>SYNC WITH YOUR CALENDAR</Heading>
          </SyncHeading>
          <CloseButton onClick={() => setIsSyncOpen(!isSyncOpen)}>
            <Close />
          </CloseButton>
        </Header>

        <AppButtonContainer>
          <ButtonItem>
            <GoogleCalender />
            <BtnTitle>Google</BtnTitle>
          </ButtonItem>
          <ButtonItem>
            <Apple />
            <BtnTitle>Apple</BtnTitle>
          </ButtonItem>
          <ButtonItem>
            <Outlook />
            <BtnTitle>Outlook</BtnTitle>
          </ButtonItem>
        </AppButtonContainer>

        <FootarContent>
          <Input placeholder="https://calendar.google.com/calendar/hello/harim" />
          <CopyLinkButton>
            Copy Link
            <BsLink45Deg />
          </CopyLinkButton>
        </FootarContent>

        <Instruction>
          <Tooltip
            placement="bottom"
            title="Read the Instuction Carefully or you can loose everything"
          >
            <TooltipHeading>
              <Popover />
              View Instructions
            </TooltipHeading>
          </Tooltip>
        </Instruction>
      </Content>
    </Wrapper>
  );
};

export default SyncModal;

const Wrapper = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* width: 100%;  */
  /* height: 100%;
  border: 4px solid yellow;
  /* position: absolute;
  top: 0; */
`;

const Content = styled.div`
  width: 423px;
  min-height: 220px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 1rem;
`;

const Heading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.12em;
  color: #112538;
  margin-left: 6px;
  margin-bottom: 0.4rem;
`;

const Header = styled.div`
  /* height: 5.5vh; */
  border-bottom: 1px solid #ced0d4;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0 0.3rem 0;
`;

const SyncHeading = styled.div`
  display: flex;
  align-items: center;
`;
const CloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 75px; */
  border-bottom: 1px solid #ced0d4;
  margin-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

const ButtonItem = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BtnTitle = styled.h3`
  margin: 0;
  margin-left: 4px;
  color: #112538;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
`;

const FootarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.85rem;

  & .ant-input {
    border: 1px solid #ced0d4;
    background: #fff;
    border-radius: 0px;
    width: 277px;
    height: 36px;
    color: #333;
  }

  & ::placeholder {
    color: #333;
  }
`;
const CopyLinkButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 6px 12px; */
  width: 100px;
  height: 32px;
  background: #1271a6;
 border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;

  svg {
    font-size: 1.1rem;
    margin-left: 0.3rem;
  }
`;

const Instruction = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const TooltipHeading = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #112538;
  gap: 0.3rem;
`;
