import { updateUserArea } from "api";
import { getCookie } from "utils/StorageVariables";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const UpdateUserArea = (id, Data) => async (dispatch) => {
  try {
    let MasterPlanId = getCookie("MasterPlanId");

    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await updateUserArea(id, Data);
    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
    if (status === 200) {
      dispatch(RetrieveMasterPlanDetails(MasterPlanId, false));
    }
  } catch (error) {
    Error(error?.response?.data?.error);
    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
