import React from 'react';

import VerificationMain from '../../../components/Auth/Verification';

// @ts-ignore
import Layout from '../../../components/Auth/Layout';
// import { getCookie } from "../../../utils/StorageVariables"

const Verification = () => {
  return (
    <>
      <VerificationMain active="true"/> 
    </>
  );
}

export default Verification;
