import styled from "styled-components";
import { FC, useState } from 'react';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import en_US from "antd/es/date-picker/locale/en_US";
import moment from 'moment';

// @ts-ignore
import chevronRightIcon from "assets/images/journeys/chevron-right.svg";

// @ts-ignore
import calendarDarkIcon from "assets/images/journeys/calendar-dark.svg";

// @ts-ignore
import styles from './styles.module.scss';

const { RangePicker } = DatePicker;

interface IProps {
  value: RangePickerProps['value'];
  onChange: RangePickerProps['onChange'];
}

const locale = {
  ...en_US,
  lang: {
    ...en_US.lang,
    monthFormat: "MMMM"
  }
};

const CustomDatePicker: FC<IProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 50);
  };

  const dateRanges = {
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'This Year': [moment().startOf('year'), moment().endOf('year')],
  };

  const getLabel = () => {
    if (!value)
      return 'Select';

    for (const [label, range] of Object.entries(dateRanges)) {
      if (moment(value[0]).isSame(range[0], 'day') && moment(value[1]).isSame(range[1], 'day')) {
        return label;
      }
    }

    // Format as "DD MMM - DD MMM" if no predefined range matches
    return `${moment(value[0]).format('DD MMM')} - ${moment(value[1]).format('DD MMM')}`;
  };

  return (
    <Wrapper>
      <DropdownToggler onClick={handleOpen}>
        <img src={calendarDarkIcon} alt="" />
        {getLabel()}
        <img src={chevronRightIcon} alt="" />
      </DropdownToggler>

      <RangePicker
        open={isOpen}
        locale={locale}
        // @ts-ignore
        ranges={dateRanges}
        className={styles.input}
        popupClassName={styles.popup}
        placement="bottomRight"
        onChange={onChange}
        onOpenChange={handleClose}
      />
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  position: relative;
  max-width: 180px;
`;

export const DropdownToggler = styled.button<{ $disabled?: boolean; $empty?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  color: #1B2A3D;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: box-shadow .3s;
  outline: none;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid var(--Neutral-200, #E5E5E5);
  background: var(--Dark-mode-bg, #FFF);

  img:last-of-type {
    transform: rotate(90deg);
  }

  ${({ $disabled }) => !$disabled && `
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 2px #D0E3ED;
    }
  `}

  ${({ $disabled }) => $disabled && `
    cursor: default;
  `}

  ${({ $empty }) => $empty && `
    color: #A3A3A3;
  `}
`;

export default CustomDatePicker;
