import { FC, ChangeEvent, FormEvent, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button, InputArea } from "elements";
import { Wrapper, Header, Title, IconButton, Text, Form, FormFooter } from "./styled";

// @ts-ignore
import { ReactComponent as PencilIcon } from "assets/images/journeys/pencil-outline.svg";

// @ts-ignore
import { UpdateLastStep } from "Redux/UpdateLastStep/action.js";

interface IProps {
  data: string;
}

const JourneyStatement: FC<IProps> = ({ data }) => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(data);

  const $input = useRef(null);

  const handleClick = () => {
    setIsEdit(true);
    setValue(data);

    setTimeout(() => {
      if ($input && $input.current) {
        // @ts-ignore
        $input.current.focus();
      }
    }, 50);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const stepFormData = new FormData();
    stepFormData.append("mission_statement", value);

    dispatch(UpdateLastStep(stepFormData));

    setIsEdit(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  if (!data)
    return null;

  return (
    <Wrapper>
      <Header>
        <Title>Journey Mission Statement</Title>
        {isEdit ? null : <IconButton onClick={handleClick}>
          <PencilIcon />
        </IconButton>}
      </Header>

      {isEdit ? (
        <Form onSubmit={handleSubmit}>
          <InputArea
            value={value}
            placeholder="..."
            ref={$input}
            max={1280}
            counter
            style={{ maxHeight: 180, overflowY: "auto" }}
            counterStyle={{ textAlign: 'right', background: '#fff', bottom: 7, right: 10, width: `calc(100% - 16px)`, lineHeight: '28px' }}
            error={value.length > 1280}
            onChange={handleChange}
          />

          <FormFooter>
            <Button styleType="link-primary" onClick={handleCancel}>Cancel</Button>
            <Button styleType="secondary" type="submit">Save</Button>
          </FormFooter>
        </Form>
      ) : (
        <Text>{data}</Text>
      )}
    </Wrapper>
  );
};

export default JourneyStatement;
