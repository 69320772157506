import styled from "styled-components";
import { Link as LinkItem } from "react-router-dom";

export const Wrapper = styled.div`
  padding: 12px;
}`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;]
  align-items: center;
  min-height: 50px;
  padding: 0 12px;
  border: 1px solid var(--Neutral-300, #CDCDCD);
  background: var(--Dark-mode-bg, #FFF);
}`;

export const DropdownGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
}`;

export const Title = styled.span`
  color: var(--Neutral-600, #525252);
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  white-space: nowrap;
}`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
}`;

export const Link = styled(LinkItem)`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: Inter;
  font-weight: 500;
  border-radius: 3px;
  height: 36px;
  padding: 6px 0px;
  font-size: 12px;
  line-height: 20px;
  color: rgb(18, 113, 166);
}`;
