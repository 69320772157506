export const data = [
  {
    id: 'domain_1',
    title: 'PERSONAL GOALS PORTFOLIO',
    goals: [
      {
        id: 'goal_1',
        title: 'Personal',
        areas: [
          {
            id: 'area_1',
            title: 'Spiritual Growth & Practice',
            category: 'Growth',
            color: '#E88614',
            order: 1
          },
          {
            id: '1',
            order: 2,
            title: 'Investments',
            category: 'Finance',
            color: '#03539D'
          },
          {
            id: '2',
            order: 3,
            title: 'Volunteer',
            category: 'Contribution',
            color: '#85D2B8'
          },
          {
            id: '3',
            order: 4,
            title: 'Physical Health',
            category: 'Health',
            color: '#85D2B8'
          },
          {
            id: '4',
            order: 5,
            title: 'Home',
            category: 'Environment',
            color: '#8D7538'
          },
          {
            id: '5',
            order: 6,
            title: 'Culture',
            category: 'Leisure',
            color: '#DF5495'
          },
        ]
      },
      {
        id: 'goal_2',
        title: 'Health',
        areas: [
          {
            id: 'area_1',
            title: 'Spiritual Growth & Practice',
            category: 'Growth',
            color: '#E88614',
            order: 1
          }
        ]
      }
    ]
  },
  {
    id: 'domain_2',
    title: 'Business Goals Portfolio',
    goals: [
      {
        id: 'goal_1',
        title: 'Service Providers',
        areas: [
          {
            id: 'area_1',
            title: 'Long Term Collaboration',
            category: 'Product Development',
            color: '#4FCC2C',
            order: 1
          }
        ]
      }
    ]
  },
  {
    id: 'domain_3',
    title: 'Professional Goals Portfolio',
    goals: [
      {
        id: 'goal_1',
        title: 'oviders',
        areas: [
          {
            id: 'area_1',
            title: 'Long Term Collaboration',
            category: 'Product Development',
            color: '#4FCC2C',
            order: 1
          }
        ]
      }
    ]
  }
]
