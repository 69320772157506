import { listDomain } from "api";
import { Error } from "Responses/Response";
import { ActionTypes } from "./types";

export const ListDomain =
  (id = false) =>
  async (dispatch) => {
    try {
      let query = "";
      if (id) {
        query = `?client=${id}`;
      }
      dispatch({ type: ActionTypes.LOADING });
      const { data } = await listDomain(query);
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data,
      });
    } catch (error) {
      Error(error?.response?.data?.error);
      dispatch({
        type: ActionTypes.ERROR,
        payload: error,
      });
    }
  };

export const EmptyListDomain = (decision) => async (dispatch) => {
  dispatch({
    type: ActionTypes.SUCCESS,
    payload: decision,
  });
};
