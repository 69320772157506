import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Button = styled.button`
  background: transparent;
  padding: 0;
  border: none;
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;
