import React from 'react';
import { Wrapper, Head, SubTitle, Title } from './styled';

interface DirectImpactProps {}

const DirectImpact: React.FC<DirectImpactProps> = ({}) => {
  return (
    <Wrapper>
      <Head>
        <Title>
          Direct Impact
        </Title>
      </Head>
      <SubTitle>
        The relative importance of this activity on this area.
      </SubTitle>
    </Wrapper>
  );
}

export default DirectImpact;