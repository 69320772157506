import { WEBSOCKET_URL } from "config";
import Notify from "./notify";

class WebSocketConnection {
  private socket: WebSocket | null;
  private messageCallback: ((message: string) => void) | null;
  private isConnecting: boolean;
  private isRequestPending: boolean;
  private latestPayload: string | null;
  private reconnectInterval: number | NodeJS.Timeout | null;

  constructor() {
    this.socket = null;
    this.messageCallback = null;
    this.isConnecting = false;
    this.isRequestPending = false;
    this.latestPayload = null;
    this.reconnectInterval = null;
  }

  connect(onopen?: () => void, onerror?: (event: Event) => void, onclose?: () => void): void {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    this.socket = new WebSocket(WEBSOCKET_URL);

    this.socket.onopen = () => {
      console.log("🟢 WebSocket connected");
      this.isConnecting = false;

      if (this.reconnectInterval) {
        clearInterval(this.reconnectInterval as NodeJS.Timeout);
        this.reconnectInterval = null;
      }

      onopen?.();
    };

    this.socket.onmessage = (event: MessageEvent) => {
      /* if (this.latestPayload) {
        this.isRequestPending = false;
        this.processNextPayload();
      }
      else { */
        const message = event.data;
        this.isRequestPending = false;

        if (this.messageCallback) {
          this.messageCallback(message);
        }
      // }
    };

    this.socket.onclose = () => {
      console.log("🔴 WebSocket disconnected");
      this.isConnecting = false;
      this.reconnect();
      onclose?.();
    };

    this.socket.onerror = (event: Event) => {
      this.isConnecting = false;
      this.reconnect();
      onerror?.(event);
    };
  }

  send(message: string): void {
    /* if (this.isRequestPending) {
      // If a request is already in progress, store the latest payload
      this.latestPayload = message;
    } else  */if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      // Mark the new request as pending
      this.isRequestPending = true;
      this.latestPayload = null; // Clear any previously stored payload
      this.socket.send(message);
    } else {
      console.log("🟡 WebSocket connection not established");
    }
  }

  close(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  setMessageCallback(callback: (message: string) => void): void {
    this.messageCallback = callback;
  }

  private processNextPayload(): void {
    if (this.latestPayload && this.socket && this.socket.readyState === WebSocket.OPEN) {
      // If there is a stored payload, send it
      const nextPayload = this.latestPayload;
      this.latestPayload = null; // Clear the stored payload
      this.send(nextPayload); // Send the stored payload
    }
  }

  private reconnect(): void {
    if (!this.reconnectInterval) {
      this.reconnectInterval = setInterval(() => {
        console.log("Attempting to reconnect...");
        this.connect();
      }, 5000); // Attempt to reconnect every 5 seconds
    }
  }
}

export const websocketConnection = new WebSocketConnection();
