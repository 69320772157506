import { FC, useState } from "react";
import { Button } from 'elements';

// @ts-ignore
import { ReactComponent as AlertIcon } from "assets/images/priorities/AlertIcon.svg";
import {
  StyledModel,
  Btn,
  Alert,
  GoalText,
  NoteText,
  TextModel
  // @ts-ignore
} from "./styled";

import { useDispatch } from "react-redux";

// @ts-ignore
import { fetchListPrioritizedActions, fetchListPrioritizedActionsGrouped } from "Redux/APM/PrioritizedActions/action";

// @ts-ignore
import { DeleteActions } from "Redux/DeleteActions/action";
import { deleteTask } from "Redux/APM/CreateTask/action";

// @ts-ignore
import { listFocusedPrioritise } from "Redux/APM/ListFocusedPriorities/action";

// @ts-ignore
import useDeviceType from "Hooks/ResponsiveHook";

interface IProps {
  id: string;
  title: string;
  isTask: boolean;
  setHandleDelete: (id: any) => void;
  type?: string;
}

const DeleteItemModal: FC<IProps> = ({ id, title, isTask, setHandleDelete , type }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    setConfirmLoading(true);

    if (isTask)
      dispatch(deleteTask(id, handleCallback));
    else
      dispatch(DeleteActions(id, handleCallback , type));
  };

  const handleCallback = () => {
    if (!isTask) {
      dispatch(fetchListPrioritizedActions());
      dispatch(fetchListPrioritizedActionsGrouped());
    }

    dispatch(listFocusedPrioritise());

    setConfirmLoading(false);
    setHandleDelete(null);
  }

  const screenSizes = useDeviceType();

  const handleCancel = () => {
    setHandleDelete(null);
  };

  return (
    <>
      <StyledModel
        closable={false}
        open={id}
        style={{
          top: "40%",
        }}
        width={771}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Btn $mobile={screenSizes === "mobile"}>
            <Button
              styleType="primary"
              disabled={confirmLoading}
              onClick={handleCancel}
            >
              Keep {isTask ? "Task" : "Action"}
            </Button>
            <Button
              styleType="danger"
              disabled={confirmLoading}
              onClick={handleOk}
            >
              Delete {isTask ? "Task" : "Action"}
            </Button>
          </Btn>,
        ]}
      >
        <TextModel>
          <Alert>
            <AlertIcon />
          </Alert>

          <GoalText>
            Are you sure you want to delete the <strong>"{title}"</strong> {isTask ? "task" : "action" }?
          </GoalText>

          {isTask ? null : <NoteText>
            Note: All actions created under this goal will also be deleted from
            your
            <br /> dashboard.
          </NoteText>}
        </TextModel>
      </StyledModel>
    </>
  );
};

export default DeleteItemModal;
