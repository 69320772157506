import React, { useState } from "react";
import { Select } from "antd";

import AddCustomRoleForm from "../AddCustomRoleForm";
import CustomOption from '../CustomOption';

import styled from "styled-components";

export const getOptions = (step, roles, values, callback) => {
  const items = (step === 1 ? roles : values) || [];

  const standardOptions = items.filter(i => !i.custom);
  const customOptions = items
    .filter(i => i.custom)
    .map(i => ({
      ...i,
      label: <CustomOption value={i.value} label={i.label} type={step === 1 ? 'roles' : 'values'} onDelete={callback} />,
      originalLabel: i.label,
    }));

  return [
    {
      label: "",
      options: [...standardOptions],
    },
    {
      label: customOptions.length > 0 ? (step === 1 ? "Custom Roles" : "Custom Values") : "",
      options: [...customOptions],
    },
  ];
};

const MissionSelect = ({ text, options, error, type, onSelect, onCreate, value, isWidth, ...rest }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  }

  const handleAdd = (value, callback) => {
    onCreate(value, callback);
    setSearchValue("");
  }

  const handleSelect = (value) => {
    onSelect(value);
    setSearchValue("");
  }

  return (
    <Container>
      <Content>
        <Label>{text}</Label>
        <Wrapper $error={error} className="search_select">
          <Select
            {...rest}
            getPopupContainer={(trigger) => trigger.parentNode.parentNode}
            mode="multiple"
            popupClassName="mission_select"
            value={value}
            showSearch
            searchValue={searchValue}
            onSelect={handleSelect}
            style={{
              maxWidth: "100%",
              width: value && value[0]?.length > 50 ? "550px" || isWidth && "100px" : '100%'
            }}
            optionFilterProp="value"
            autoClearSearchValue={false}
            filterOption={(input, option) =>
              ((typeof option?.label === 'string' ? option.label : option?.originalLabel).toLowerCase() ?? "").includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            listHeight={200}
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <AddCustomRoleForm type={type} onAdd={handleAdd} />
                </>
              )
            }}
            options={options}
            onSearch={handleSearch}
            placement="bottomLeft"
          />
        </Wrapper>

        {error ? <Error>{error}</Error> : null}
      </Content>
    </Container>
  );
};

export default MissionSelect;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  .rc-virtual-list-holder-inner {
    cursor: default;
  }

  .ant-select-item-option-grouped {
    padding-left: 12px;
  }

  .ant-select-item {
    padding: 6px 10px 6px 10px;
  }

  .ant-select-item .ant-select-item-option-content {
    color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
    font-family: Inter;
    font-size: 12px;
    line-height: 20px;
  }

  .ant-select-item.ant-select-item-group {
    padding: 6px 10px 6px 8px;
    color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    pointer-events: none;
    cursor: default !important;

    &:first-of-type,
    &:empty {
      display: none;
    }
  }

  ${({ $error }) => $error && `
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-bottom: 1px solid #F00000 !important;
    }
  `}
`;

const Label = styled.h3`
  margin: 0;
  margin-bottom: 0.2rem;
  color: #737373;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;

const Error = styled.span`
  color: var(--destructive-500-main, #F00000);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  display: block;
`;
