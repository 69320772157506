import React, { FC, useRef } from 'react';
import { Popover } from 'antd';

// @ts-ignore
import { Tooltip } from "elements";

import TimePopover from "../TimePopover";

// @ts-ignore
import hourglassWarningIcon from "assets/images/priorities/hourglass-warning.svg";

// @ts-ignore
import hourglassDangerIcon from "assets/images/priorities/hourglass-danger.svg";

import { Time } from "./styled";

interface IProps {
  isTask: boolean;
  data: any;
}

const TimeWidget: FC<IProps> = ({ isTask, data }) => {
  const time = isTask ? data?.estimated_duration : data?.total_time;
  const isTimeSet = isTask ? data?.estimated_duration : (data?.delegation_time || data?.management_time);

  const [open, setOpen] = React.useState(false);

  const $timePopover = useRef();

  const handleClose = () => {
    setOpen(false);

    if ($timePopover.current) {
      // @ts-ignore
      $timePopover.current.clear();
    }
  };

  const convertMinutesToTimeFormat = (time: number) => {
    const hours = Math.floor(time / 60);
    const mins = time % 60;

    let result = '';

    if (hours) {
      result += `${hours}h`;
    }

    if (mins) {
      if (result) result += ' ';
      result += `${mins}m`;
    }

    return result || `0h`;
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return (
      <TimePopover
        id={data?.id}
        isTask={isTask}
        estimation_time={isTask ? data?.estimated_duration : data?.delegation_time}
        delegation_time={data?.management_time}
        onClose={handleClose}
        ref={$timePopover}
      />
    );
  }

  return (
    <div className="d-flex align-items-center">
      <Time>{isTimeSet ? convertMinutesToTimeFormat(time) : '-'}</Time>

      <Popover
        // @ts-ignore
        getPopupContainer={(trigger) => trigger.parentNode}
        align={{ offset: [144, 0] }}
        content={PopoverContent}
        title={null}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottomRight"
        overlayClassName="time_popover"
        overlayStyle={{ zIndex: 9999 }}
      >
        <Tooltip title="Time">
          <img src={isTimeSet ? hourglassWarningIcon : hourglassDangerIcon} alt="" width={24} height={24} />
        </Tooltip>
      </Popover>
    </div>
  );
}

export default TimeWidget;
