import Notify from "utils/notify";
import { resetPassword } from "api";
import { ActionTypes } from "./types";
import { removeCookie } from "utils/StorageVariables";

export const ResetPassword = (Data, Token, callback) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.LOADING });
    const { data, status } = await resetPassword(Data, Token);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });

    if (status === 200) {
      removeCookie("workbook-token");
      callback();
    }
  } catch (error) {
    Notify.error(error, true);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
