import React, { useEffect, useRef, useState } from "react";
import { Tabs } from "antd";

import { ReactComponent as SearchIcon } from "assets/images/Search.svg";
import { ReactComponent as DragIcon } from "assets/images/drags.svg";
import { ReactComponent as Subtract } from "assets/images/Subtract.svg";
import { ReactComponent as General } from "assets/images/priorities/common/general.svg";
import { ReactComponent as FilterSort } from "assets/images/priorities/common/filterSort.svg";

import CustomizeTablePopup from "components/PriorityApp/CustomizeTablePopup";
import FilterByPopup from "components/PriorityApp/FilterByPopup";
import CustomDropdown from "./Component/Dropdown";
import ActionDropdown from "../../PriorityApp/SimpleView/ActionDropdown";

import {
  Wrap,
  FilterWrap,
  Container,
  Button,
  // EmptySpace,
  Span,
  TabsWrapper,
  TabItem,
  /* SubTitle,
  Title,
  SubHeader, */
  SubHeaderTitle,
  Filter,
  FilterItem,
  InnerContainer,
  FilterTabs,
  FilterBy,
  SortBy,
  NumberOfFilter,
  // SelectedLength,
  Num,/* 
  Custombutton,
  ActionText, */
} from "./styled";
import { /* Goal, Person,  */Sort_by } from "./Component/Dropdown/data";
import { listJourneyFilters, listAreaFilters } from "Redux/APM/apm-apis";
import { updatePriorizedFilters } from "Redux/APM/PrioritizedActions/action";
import { useDispatch, useSelector } from "react-redux";
import useDeviceType from "../../../Hooks/ResponsiveHook";
import { CustomLabel } from "./Component/Dropdown/styled";

export const SIMPLE_VIEW_TABS = {
  All: 1,
  Focused: 2,
};

const FILTER_BY_TYPE = [
  { name:'Habits',id:'Habit'},
  { name:'Actions',id:'Action Item'},
];

const defaultSort = [
  {
    name: "Urgency",
    id: "urgency_score"
  },
];

const TableSettingsHeader = ({ isProView, tab, onTabChange }) => {
  const [isCustomizePopupOpen, setIsCustomizePopupOpen] = useState(false);
  const [isFilterByPopupOpen, setIsFilterByPopupOpen] = useState(false);
  const [myJounery, setMyJounery] = useState([]);
  const [loadingMyJourney, setLoadingMyJourney] = useState(false);
  const [myAreas, setMyAreas] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [loadingMyAreas, setLoadingMyAreas] = useState(false);
  const { listFocusedPrioritise: originalFocusedItems, loading } = useSelector(
    (state) => state.listFocusedPrioritise
  );
  const { client, length: actionsLength } = useSelector((state) => state.prioritizedActions);
  const { length: tasksLength } = useSelector((state) => state.listTasks);

  const dispatch = useDispatch();
  const screenSize = useDeviceType();

  const [filters, setFilters] = useState({
    journeys: [],
    areas: [],
    filterByType:[],
    sort: [
      {
        name: "Urgency",
        id: "urgency_score"
      },
    ],
    order: "descending",
  });

  const [filtersPre, setFiltersPre] = useState({
    journeys: [],
    areas: [],
    filterByType:[],
    sort: [
      {
        name: "Urgency",
        id: "urgency_score"
      },
    ],
    order: "descending",
  });

  const onFilterChange = (key, value) => {
    let updatedValue = value;
    if (key === "sort" && value.length === 0) {
      updatedValue = defaultSort;
    }

    if ((key === "journeys" || key === "areas" || key === "filterByType") && value.length === 0) {
      updatedValue = [];
    }

    setFilters({
      ...filters,
      [key]: updatedValue,
    });
  };

  const prevFiltersRef = useRef(filters);

  useEffect(() => {
    setFiltersPre(prevFiltersRef.current);

    const filtersWithOnlyIds = {
      journeys: filters.journeys.map((journey) => journey.id),
      areas: filters.areas.map((area) => area.id),
      filterByType: filters.filterByType.map((area) => area.id),
      sort: filters.sort.length > 0 ? filters.sort[0].id : "",
      order: filters.order,
    };

    if (prevFiltersRef.current !== filters) {
      dispatch(updatePriorizedFilters(filtersWithOnlyIds));
    }
    prevFiltersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    const filtersParams = {};

    if (client) {
      // @ts-ignore
      filtersParams.client = client;
    }

    const fetchMyJourney = async () => {
      try {
        setLoadingMyJourney(true);
        const response = await listJourneyFilters(filtersParams);
        setMyJounery(response.data);
        setLoadingMyJourney(false);
      } catch (error) {
        setLoadingMyJourney(false);
      }
    };

    fetchMyJourney();
  }, [client]);

  useEffect(() => {
    const fetchMyAreas = async () => {
      try {
        setLoadingMyAreas(true);
        const response = await listAreaFilters();
        setMyAreas(response.data);
        setLoadingMyAreas(false);
      } catch (error) {
        setLoadingMyAreas(false);
      }
    };
    fetchMyAreas();
  }, []);

  const onChange = (key) => {
    onTabChange(key);
  };

  const onFilterNameChange = (key) => {
    setFilterName(filterName => filterName === key ? "" : key);
  };

  const simpleViewTabs = [
    {
      key: SIMPLE_VIEW_TABS.All,
      label: <TabItem>Activities</TabItem>,
    },
    {
      key: SIMPLE_VIEW_TABS.Focused,
      label: <TabItem>Focused Priorities</TabItem>,
      icon: <Num>{originalFocusedItems?.length}</Num>,
    },
  ];

  const journeyFilterTitle = filters.journeys.length > 0 ? filters.journeys.length > 1 ? `${filters.journeys[0].name}, ` : `${filters.journeys[0].name}` : "All";
  const journeyFilterTitleMore = filters.journeys.length > 1 ? `+${filters.journeys.length - 1} more` : "";

  const FilterType =
    filters.filterByType.length > 0
      ? filters.filterByType.length > 1
        ? `${filters.filterByType[0].name}, `
        : `${filters.filterByType[0].name}`
      : "All";
  const FilterTypeMore =
    filters.filterByType.length > 1 ? `+${filters.filterByType.length - 1} more` : "";

  const sortTitle = filters.sort.length > 0 ? filters.sort[0].name : "None";
  const sortTitleAsc =
    filters.order === "descending" && sortTitle !== "None" ? "(Desc)" : "(Asc)";
  const sortTitleFinal = `${sortTitle} ${sortTitleAsc}`;
  const sortTitleHeader = `${sortTitle}`;
  const handleCustomizePopup = () => {
    setIsCustomizePopupOpen(!isCustomizePopupOpen);
  };

  const handleFilterByPopup = () => {
    setIsFilterByPopupOpen(true);
  };

  const getSortingOrder = (sortingString) => {
    if (sortingString.includes("(Desc)")) {
      return "desc";
    } else {
      return "asc";
    }
  };

  const singleArrayJourney = Array.isArray(filters?.journeys)
    ? filters.journeys.map((journey) => journey.id)
    : [];
  const singleArrayAreas = Array.isArray(filters?.areas)
    ? filters.areas.map((area) => area.id)
    : [];
  const indexItem = [...singleArrayJourney, ...singleArrayAreas];

  const sortingOrder = getSortingOrder(sortTitleFinal);

  // If "simple" view
  if (!isProView) {
    return (
      <>
        {screenSize === "desktop" || screenSize === 'iPad' ? (
          <>
            {" "}
            <Filter>
              {(actionsLength || tasksLength) ? (
                <ActionDropdown/>
              ):<div></div>}

              <FilterItem>
                <SubHeaderTitle>FILTER BY</SubHeaderTitle>

                <span>Journey</span>

                <CustomDropdown
                  filters={filters}
                  title={`${journeyFilterTitle} ${journeyFilterTitleMore}`}
                  items={myJounery}
                  width={79}
                  filterName={filterName}
                  onFilterNameChange={onFilterNameChange}
                  heading={"journey"}
                  multiple
                  onChange={(values) => {
                    onFilterChange("journeys", values);
                  }}
                />

                <span>Activity type</span>

                <CustomDropdown
                  filters={filters}
                  title={`${FilterType} ${FilterTypeMore}`}
                  items={FILTER_BY_TYPE}
                  width={79}
                  filterName={filterName}
                  onFilterNameChange={onFilterNameChange}
                  heading={"filterByType"}
                  onChange={(values) => {
                    onFilterChange("filterByType", values);
                  }}
                />

                {/* <span>Goal</span>
                <CustomDropdown
                  filters={filters}
                  title={`${areasFilterTitle} ${areasFilterTitleMore}`}
                  items={myAreas}
                  onFilterNameChange={onFilterNameChange}
                  width={173}
                  filterName={filterName}
                  heading={"Goal"}
                  onChange={(values) => {
                    onFilterChange("areas", values);
                  }}
                /> */}

                <span>SORT BY</span>

                <CustomDropdown
                  title={sortTitleHeader}
                  items={Sort_by}
                  width={127}
                  heading={"Criteria"}
                  icon={
                    sortingOrder === "asc" ? (
                      <General style={{ transform: "rotate(180deg)" }} />
                    ) : (
                      <General />
                    )
                  }
                  onChange={(value) => {
                    onFilterChange("sort", value);
                  }}
                  onOrderByChange={(value) => {
                    onFilterChange("order", value);
                  }}
                />
              </FilterItem>
            </Filter>

            {/* <Container>
              <SubHeader>
                <Title>Activities</Title>
                <SubTitle>Actions</SubTitle>
              </SubHeader>
              <EmptySpace />
            </Container> */}
          </>
        ) : (
          <>
            <TabsWrapper $filterWraper={"blue"}>
              <InnerContainer>
                {/* <Tabs
                  defaultActiveKey={tab}
                  items={simpleViewTabs}
                  onChange={onChange}
                /> */}
                <Tabs
                  defaultActiveKey={tab}
                  onChange={onChange}
                  items={simpleViewTabs.map((ele, i) => {
                    return {
                      label: (
                        <CustomLabel>
                          <span>{ele.label}</span>
                          {ele.icon ? <div>{ele.icon}</div> : ""}
                        </CustomLabel>
                      ),
                      key: ele.key,
                    };
                  })}
                />
              </InnerContainer>
            </TabsWrapper>
         <div style={{display:"flex", justifyContent:"center"}}>
            <FilterTabs >


            
              <FilterBy>
                {indexItem.length > 0 && (
                  <NumberOfFilter>{indexItem.length}</NumberOfFilter>
                )}
                            <CustomDropdown
                  filters={filters}
                  title={`Filter by`}
                  items={FILTER_BY_TYPE}
                  width={79}
                  filterName={filterName}
                  onFilterNameChange={onFilterNameChange}
                  heading={"filterByType"}
                  onChange={(values) => {
                    onFilterChange("filterByType", values);
                  }}
                />
              </FilterBy>
              <SortBy>
                <CustomDropdown
                  $top={"1px"}
                  title={"Sort by"}
                  items={Sort_by}
                  width={127}
                  heading={"Criteria"}
                  icon={<FilterSort />}
                  onChange={(value) => {
                    onFilterChange("sort", value);
                  }}
                  onOrderByChange={(value) => {
                    onFilterChange("order", value);

                  }}
                />
              </SortBy>
              

             
            </FilterTabs>
          {/* {screenSize === "iPad" &&
            <ActionText onClick={() => dispatch(newAction(true))} >
                Add action <PlusSmall/>
              </ActionText>} */}
              </div>

          
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Container>
        <Button>
          <SearchIcon />
        </Button>
        <Button onClick={handleFilterByPopup}>
          <DragIcon />
          <Span>(5)</Span>
        </Button>
        <Button isSubtract onClick={handleCustomizePopup}>
          <Subtract />
        </Button>
        {isFilterByPopupOpen && (
          <FilterWrap>
            <FilterByPopup
              setIsFilterByPopupOpen={setIsFilterByPopupOpen}
              isFilterByPopupOpen={isFilterByPopupOpen}
            />
          </FilterWrap>
        )}
        {isCustomizePopupOpen && (
          <Wrap>
            <CustomizeTablePopup
              setIsCustomizePopupOpen={setIsCustomizePopupOpen}
              isCustomizePopupOpen={isCustomizePopupOpen}
            />
          </Wrap>
        )}
      </Container>
      
    </>
  );
};

export default TableSettingsHeader;
