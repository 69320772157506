import { FC, useEffect, useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { InputArea } from 'elements';

import { Wrapper, Title, EditButton } from './styled';

//@ts-ignore
import { ReactComponent as PencilIcon } from "assets/images/priorities/pencil-dark.svg";

interface IProps {
  value: string;
  onChange: (value: string) => void;
}

const TitleInput: FC<IProps> = ({ value, onChange }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const $input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        if ($input && $input.current) {
          $input.current.focus();
          $input.current.select();
        }
      }, 50);
    }
  }, [isEdit]);

  const handleClick = () => {
    setIsEdit(prev => !prev);
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onChange(inputValue || value);
    } else if (e.key === 'Escape') {
      onChange(value);
    }

    if (e.key === 'Enter' || e.key === 'Escape') {
      setIsEdit(false);
    }
  }

  const handleBlur = () => {
    setIsEdit(false);
    onChange(inputValue || value);
  }

  if (isEdit) {
    return (
      <InputArea
        value={inputValue}
        placeholder="Enter Name..."
        ref={$input}
        max={150}
        // counter
        style={{ maxHeight: 180, overflowY: "auto" }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    );
  }

  return (
    <Wrapper>
      <div className='text-oneline'>
        <Title>{value}</Title>
      </div>

      <EditButton onClick={handleClick}>
        <PencilIcon />
      </EditButton>
    </Wrapper>
  );
}

export default TitleInput;
