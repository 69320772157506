import { FC } from 'react';
import { Dropdown } from 'elements';

import { DropdownToggler } from "./styled";

// @ts-ignore
import ChevronRightIcon from "assets/images/journeys/chevron-right.svg";

export interface IGroup {
  key: string;
  element: string;
}

interface IProps {
  value: IGroup;
  onChange: (value: IGroup) => void;
}

const groupItems = [
  {
    key: 'category',
    element: 'By Category',
  },
  {
    key: 'area',
    element: 'By Area',
  }
];

const GroupSelector: FC<IProps> = ({ value, onChange }) => {
  const handleChange = (value: any) => {
    const selectedItem = groupItems.find(item => item.key === value?.key);
    
    if (selectedItem) {
      onChange(selectedItem);
    }
  };

  return (
    <Dropdown
      menuItems={groupItems}
      value={value.key}
      secondary
      onChange={handleChange}
    >
      <DropdownToggler>
        {value.element}
        <img src={ChevronRightIcon} alt="" />
      </DropdownToggler>
    </Dropdown>
  );
}

export default GroupSelector;
