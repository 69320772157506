import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Time } from "assets/images/time.svg";
import { ReactComponent as Close } from "assets/images/close.svg";
import { ReactComponent as Scroll } from "assets/images/scroll.svg";
import { ReactComponent as Add } from "assets/images/add.svg";
import { ReactComponent as Paint } from "assets/images/Icon Button.svg";
import { Select, TimePicker } from "antd";
import TimeRangePicker from "components/TimeRangePopover";

const TimelineModal = ({ setIsOpenTimeline, isOpenTimeline }) => {
  const [isShown, setisshown] = useState(false);

  const onChange = () => {
    console.log();
  };

  const OptionList = [
    {
      value: "GMT+0",
      label: "GMT+0",
    },
    {
      value: "GMT+1",
      label: "GMT+1",
    },
    {
      value: "GMT+3",
      label: "GMT+3",
    },
    {
      value: "GMT+4",
      label: "GMT+4",
    },
    {
      value: "GMT+5",
      label: "GM5+5",
    },
    {
      value: "GMT+6",
      label: "GMT+6",
    },
    {
      value: "GMT+7",
      label: "GMT+7",
    },
    {
      value: "GMT+8",
      label: "GMT+8",
    },
    {
      value: "GMT+9",
      label: "GMT+9",
    },
    {
      value: "GMT+10",
      label: "GMT+10",
    },
    {
      value: "GMT+11",
      label: "GMT+11",
    },
    {
      value: "GMT+12",
      label: "GMT+12",
    },
    {
      value: "GMT-1",
      label: "GMT-1",
    },
    {
      value: "GMT-2",
      label: "GMT-2",
    },
    {
      value: "GMT-3",
      label: "GMT-3",
    },
    {
      value: "GMT-4",
      label: "GMT-4",
    },
    {
      value: "GMT-5",
      label: "GMT-5",
    },
    {
      value: "GMT-6",
      label: "GMT-6",
    },
    {
      value: "GMT-7",
      label: "GMT-7",
    },
    {
      value: "GMT-8",
      label: "GMT-8",
    },
    {
      value: "GMT-9",
      label: "GMT-9",
    },
  ];

  const dataArray = [
    {
      backgroundColor: "#8ab2d9",
      dayText: "Morning",
      timeText: "12:00 PM - 6:00 AM",
    },
    {
      backgroundColor: "#47BE80",
      dayText: "Deep Work",
      timeText: "8:00 AM - 12:00 AM",
    },
    {
      backgroundColor: "#FFA178",
      dayText: "Afternoon",
      timeText: "12:00 AM - 6:00 PM",
    },
    {
      backgroundColor: "#80888D",
      dayText: "Evening",
      timeText: "6:00 PM - 12:00 PM",
    },
    {
      backgroundColor: "#603679",
      dayText: "Night",
      timeText: "12:00 PM - 6:00 AM",
    },
  ];

  return (
    <Wrapper>
      <Content>
        <Header>
          <TimelineHeading>
            <Time />
            <Heading>Timeline settings</Heading>
          </TimelineHeading>
          <CloseButton onClick={() => setIsOpenTimeline(!isOpenTimeline)}>
            <Close />
          </CloseButton>
        </Header>

        <TimezoneWrapper>
          <TimezoneInput>
            <TimezoneHeading>Timezone </TimezoneHeading>
            <SelectInputWrapper>
              <div className="timezone">
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  popupClassName="timezone_dropdown"
                  showSearch
                  defaultValue={"GMT+4"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={OptionList}
                />
              </div>
            </SelectInputWrapper>
          </TimezoneInput>
          <TimezoneInput>
            <TimezoneHeading>Timezone </TimezoneHeading>
            <SelectInputWrapper>
              <div className="timezone">
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  popupClassName="timezone_dropdown"
                  showSearch
                  placeholder="Select additional timezone"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={OptionList}
                />
              </div>
            </SelectInputWrapper>
            <Addbutton>
              <Add />
            </Addbutton>
          </TimezoneInput>
        </TimezoneWrapper>

        <Timepickerwrapper>
          {dataArray?.map((item) => (
            <TimeDiv>
              <InputWrapper>
                <ColorLine
                  style={{ background: item?.backgroundColor }}
                ></ColorLine>
                <DayInput>{item?.dayText}</DayInput>
              </InputWrapper>

              <TimeWrapper>
                <TimeInput
                  type="text"
                  readOnly
                  value={item?.timeText}
                ></TimeInput>
              </TimeWrapper>
            </TimeDiv>
          ))}
        </Timepickerwrapper>

        <CustomTimeWrapper>
          {isShown ? (
            <CustomTimeDiv>
              <div
                style={{ display: "flex", alignItems: "center", width: "60%" }}
              >
                <Paint />
                <div
                  style={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    backgroundColor: "#C7FF51",
                    marginRight: "0.9rem",
                  }}
                ></div>
                <Textinput type="text" placeholder="Enter Name"></Textinput>
              </div>
              <TimePickerpopover>
                <TimeRangePicker />
              </TimePickerpopover>
            </CustomTimeDiv>
          ) : null}

          <AddTimeSlot>
            {isShown ? (
              <Customaddbtn>Save Time Slot</Customaddbtn>
            ) : (
              <Customaddbtn onClick={() => setisshown(!isShown)}>
                Add Custom Time Slot
              </Customaddbtn>
            )}
          </AddTimeSlot>
        </CustomTimeWrapper>

        <SaveButtonContainer>
          <SaveBtn>Save</SaveBtn>
        </SaveButtonContainer>
      </Content>
    </Wrapper>
  );
};

export default TimelineModal;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
 
`;

const Content = styled.div`
  width: 475px;
  min-height: 540px;
  max-height: 100%;
  background: #fff;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.25);
  padding: 0px 12px 12px 12px;
   z-index: 9999;
`;

const Heading = styled.h3`
  margin: 0;
  color: #1b2a3d;
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 0.5rem;
`;

const Header = styled.div`
  height: 3rem;

  border-bottom: 1px solid #ced0d4;
  display: flex;
  justify-content: space-between;
`;

const TimelineHeading = styled.div`
  display: flex;
  align-items: center;
  /* padding-bottom: 10px; */
`;
const CloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

const TimezoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ced0d4;
  /* border: 1px solid green; */
  margin-top: 1.5rem;
`;

const Timepickerwrapper = styled.div`
  /* height: 40vh; */
  /* padding: 1rem; */
  margin-top: 0.3rem;
  padding-bottom: 0.25rem;
  min-height: 240px;
  max-height: 100%;
  /* border: 1px solid green; */
`;

const TimeDiv = styled.div`
  /* border: 1px solid yellow; */
  padding: 0.4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TimeHeading = styled.h3`
  margin: 0;
  margin-right: 0.7rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1271a6;
`;
const InputWrapper = styled.div`
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  width: 174.5px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.7rem;
`;

const SaveBtn = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 2px;
  background: #1271a6;
  padding: 6px 12px;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

const TimezoneInput = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid green; */
  margin-bottom: 1rem;
`;

const SelectInputWrapper = styled.div`
  /* border: 1px solid red; */
`;

const TimezoneHeading = styled.h3`
  color: #1271a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-right: 1.5rem;
`;

const Addbutton = styled.button`
  width: 40px;
  height: 32px;
  cursor: pointer;
  background: #1271a6;
  border-radius: 2px;
  border: none;
  outline: none;
  margin-left: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorLine = styled.div`
  width: 4px;
  height: 14px;
  margin-right: 6px;
`;

// const InputDiv = styled.div``;

const DayInput = styled.div`
  box-sizing: border-box;
  width: max-content;
  border-radius: 3px;
  padding: 4px 5px;
  border: 0.5px solid #cdcdcd;
  background: #fff;
  color: #171717;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 20px;
`;

const TimeWrapper = styled.div`
  display: flex;
  width: 160px;
  padding: 3px 14px;
  justify-content: center;
  align-items: center;
  background: #f3f4fa;
  padding: 3px 14px;
`;

const TimeInput = styled.input`
  width: 126px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #171717;
  padding: 4px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const CustomTimeWrapper = styled.div`
  border-top: 1px solid #ced0d4;
  border-bottom: 1px solid #ced0d4;
  /* height: 80px; */
  display: flex;
  flex-direction: column;
  /* background: #0f2338; */
  /* display: flex; */
`;

const CustomTimeDiv = styled.div`
  background: #f3f4fa;
  height: 2.7rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  /* border: 1px solid green; */
  /* padding: 7px 14px; */
`;

const Textinput = styled.input`
  border-radius: 3px;
  width: 80px;
  padding: 4px 6px;
  border: 0.5px solid #71aaca;
  background: #fff;
  box-shadow: 0px 0px 0px 2px rgba(208, 227, 237, 0.3);
  color: #a3a3a3;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &:focus {
    outline: none;
  }
`;

const TimePickerpopover = styled.div`
  /* border: 1px solid green; */
  width: 40%;
`;

const AddTimeSlot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0.6rem 0rem;
`;

const Customaddbtn = styled.button`
  border-radius: 2px;
  border: 1px solid #1271a6;
  background: transparent;
  padding: 6px 12px;
  color: #1271a6;
  cursor: pointer;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;
