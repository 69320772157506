import React from 'react';

import ForgotPasswordContent from '../../../components/Auth/ForgotPassword';
import Layout from '../../../components/Auth/Layout';

const ForgotPassword = () => {
  return (
    <Layout bg="login">
      <ForgotPasswordContent />
    </Layout>
  );
}

export default ForgotPassword;
