import React from "react";
import styled from "styled-components";

import SubHeader from "components/Scheduler/SubHeader";
import ChooseWorkshop from "components/Journeys/ChooseWorkshop";

const Journeys = () => {
  return (
    <MainContainer>
      <InnerContainer>
        <SubHeader
          title={"Journeys"}
        />

        <Dashboard>
          <ChooseWorkshop />
        </Dashboard>
      </InnerContainer>
    </MainContainer>
  );
};

export default Journeys;

const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 26px); // 60
  background: var(--primary-50, #ECF3F6);
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--primary-50, #ECF3F6);
`;

const Dashboard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  gap: 18px;
`;
