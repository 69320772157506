import styled from 'styled-components';

export const LegalWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1100px;
  max-height: 75vh;
  height: 100vh;
  width: 100%;

  ${({ theme }) => theme.max("lg")`
    max-height: calc(100vh - 50px);
  `}
`;
