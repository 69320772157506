import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';

import { Tooltip } from 'elements';

import LeveragePopover from "../LeveragePopover";

// @ts-ignore
import leverageGreenIcon from "assets/images/priorities/leverage-green.svg";

// @ts-ignore
import leverageGrayIcon from "assets/images/priorities/leverage-gray.svg";

// @ts-ignore
import leverageGoldIcon from "assets/images/priorities/leverage-gold.svg";

// @ts-ignore
import { availableAreas } from "Redux/APM/AvailableAreas/action";

import {
  Pointer
} from "./styled";

interface IProps {
  data: any;
  selectedItem: any;
  setAreaData: any;
  setSelectedItem: any;
  indirectImpact: number;
  onClose: any;
}

const LeverageWidget: FC<IProps> = ({ data, selectedItem, setAreaData, setSelectedItem, indirectImpact, onClose }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const { id } = data;

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);

    if (id) {
      dispatch(availableAreas(id));
      setSelectedItem(data);
    };
  };

  const handleClose = (values: any) => {
    setOpen(false);
    onClose(values);
  };

  const renderLevarageIcon = (indirectImpact: number) => {
    if (indirectImpact > 5.9)
      return (
        <img src={leverageGoldIcon} width={24} height={24} alt="" />
      );
    else if (indirectImpact > 0.9)
      return (
        <img src={leverageGreenIcon} width={24} height={24} alt="" />
      );
    return (
      <img src={leverageGrayIcon} width={24} height={24} alt="" />
    );
  };

  const PopoverContent = () => {
    return (
      <LeveragePopover
        selectedItem={selectedItem}
        id={id}
        onClose={handleClose}
        onCloseModal={handleClose}
        setOutsideAreaData={setAreaData}
      />
    );
  }

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [108, 0] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="custom_popover leverage_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Tooltip title="Leverage Score">
        <Pointer>
          {renderLevarageIcon(indirectImpact)}
        </Pointer>
      </Tooltip>
    </Popover>
  );
};

export default LeverageWidget;
