import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  margin-bottom: 10px;
  color: var(--Fill-Button-Prussian-blue, #112538);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #E5E5E5;
  padding: 2px;
  gap: 8px;
`;

type ToggleButtonProps = {
  $active: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  flex: 1;
  width: 100%;
  border: none;
  background-color: #fff;
  color: #1271A6;
  padding: 8px 12px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;

  ${({ $active }) => $active && css`
    background: #1271A6;
    color: #fff;
  `}
`;

export const BorderGroup = styled.div`
  padding: 12px 0;
  border: none;
`;

export const BorderGroupHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const BorderGroupTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const BorderGroupLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.scheme.primary};
`;

export const TimePicker = styled.input``;

export const ErrorStyle = styled.p`
  color: red;
  margin: 0;
  padding: 0;
  font-size: 12px;
`;

export const TabsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 12px 0;
  padding: 0 13px;

  ${({ theme }) => theme.max("sm")`
    padding: 0;
  `}
`;

type TabItemProps = {
  $active: boolean;
}

export const TabItem = styled.button<TabItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  padding: 5px 0;
  background: transparent;
  user-select: none;
  position: relative;

  span {
    :first-of-type {
      position: relative;
      width: 20px;
      height: 20px;

      ::before,
      ::after {
        content: '';
        box-sizing: border-box;
        border-radius: 50%;
        display: block;
      }

      ::before {
        width: 20px;
        height: 20px;
        border: 2px solid #E3E5E9;
      }

      ::after {
        width: 12px;
        height: 12px;
        background-color: #E3E5E9;
        border: 4px solid transparent;
        position: absolute;
        left: 4px;
        top: 4px;
        opacity: 0;
      }
    }

    :last-of-type {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: #293849;
      margin-left: 13px;
      text-transform: capitalize;
    }
  }

  :hover {
    span::after {
      opacity: 1;
    }
  }

  ${({ $active }) => $active && css`
    cursor: default !important;

    span:first-of-type {
      ::before {
        border-color: #1271A6;
      }

      ::after {
        opacity: 1;
        background-color: #1271A6;
      }
    }
  `}
`;

export const PeriodicSettings = styled.div`
  display: flex;
  align-items: center;
`;

export const PeriodicRepeatTimesWrapper = styled.div`
  min-width: 68px;
  margin-right: 12px;
`;

export const PeriodicTypeWrapper = styled.div`
  min-width: 120px;
`;

export const MonthListOfDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #E3E5E9;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
`;

export const MonthDayButton = styled.button<TabItemProps>`
  text-align: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  margin: 8px;
  border: none;
  outline: none;
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.palette.text.primary};
  background: transparent;

  :hover {
    background: #E3E5E9;
  }

  ${({ $active }) => $active && css`
    color: ${({ theme }) => theme.palette.text.white};
    background: ${({ theme }) => theme.palette.text.primary} !important;
  `}
`;

export const DateRangeWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 12px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Label = styled.label`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const ButtonToggle = styled.button<TabItemProps>`
  background: #E3E5E9;
  border-radius: 4px;
  height: 32px;
  min-width: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8E97A3;
  border: none;
  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ $active }) => $active && css`
    color: #fff;
    background: ${({ theme }) => theme.scheme.primary};
  `}
`;

export const WeekdaysWrapper = styled.div``;
