import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadToggle = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 4px 4px 0;
  border: none;
  background: transparent;
  transition: background .2s;

  ${({ $active }) => $active && `
    background: #fff;
  `}
`;

export const HeadCollapseIcon = styled.div`
  display: flex;
  width: 16px;
  height: 16px;

  svg {
    transform: rotate(0deg);
    transition: transform .2s;
  }

  ${({ $active }) => $active && `
    svg {
      transform: rotate(90deg);
    }
  `}
`;

export const Title = styled.span`
  color: #8C959F;
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin-left: 7px;
`;

export const Body = styled.div`
  width: 100%;
`;

export const BodyInner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px 0 4px 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 8px;
  gap: 3px;
`;
