import { Col, Row } from "antd";
import ApexChartgradient from "atoms/SchedulerElements/Charts/QuadrantChart";
import styled from "styled-components";
import { ReactComponent as Clock } from "assets/images/clock.svg";
import { ReactComponent as Cross } from "assets/images/cross.svg";
import { ReactComponent as Refresh } from "assets/images/refresh.svg";
import { ReactComponent as TabsIcon } from "assets/images/tab_icon.svg";
import { ReactComponent as Question } from "assets/images/quest_mark.svg";


const QuadrantSummaryChart = ({
  setIsOpenQuadrantModal,
  isOpenQuadrantModal,
}) => {
  const dataMatrix = [
    [20000, 5871, 8916, 2868, 2868],
    [20000, 5871, 8916, 2868, 2868],
    [20000, 5871, 8916, 2868, 2868],
    [20000, 5871, 8916, 2868, 2868],
  ];
  const chartWidth = 500;
  const chartHeight = 600;
  return (
    <>
      {isOpenQuadrantModal && (
        <Container>
          <Item>
            <Header>
              <IconWrapper>
                <Clock />
                <Heading>QUADRANTS SUMMARY CHART</Heading>
                <Question />
              </IconWrapper>
              <Cross
                cursor={"pointer"}
                onClick={() => setIsOpenQuadrantModal(!isOpenQuadrantModal)}
              />
            </Header>

            <ChartWrapper>
              <Row>
                <Col span={22}>
                  <DonutChartContainer>
                    <ApexChartgradient
                      width={chartWidth}
                      height={chartHeight}
                      dataMatrix={dataMatrix}
                    />
                  </DonutChartContainer>
                </Col>
                <Col span={2}>
                  <Icons>
                    <Refresh />
                    <TabsIcon />
                  </Icons>
                </Col>
              </Row>
            </ChartWrapper>
          </Item>
        </Container>
      )}
    </>
  );
};

export default QuadrantSummaryChart;
const Container = styled.div`
  width: 550px;
  height: 550px;
  padding: 0.5rem;
  position: absolute;
  right: 3px;
  background-color: #fff ; 
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
`;
const Item = styled.div`
  color: aliceblue;
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #CED0D4;
  padding-bottom: 0.5rem;
`;
const IconWrapper = styled.div`
  display: flex;
  padding-left: ${({ isGap }) => isGap && "0.5rem"};
  justify-content: flex-start;
  align-items: center;
  gap: ${({ isGap }) => (isGap ? "1.2rem" : "0.3rem")};
  
  svg{
    path{
    fill: #2f4358 !important;

    }
  }
`;
const Heading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-left: 0.5rem;
  color: #112538;
`;

const ChartWrapper = styled.div`
  z-index: 0;
  background-color: #fff ;
`;
const DonutChartContainer = styled.div``;

const Icons = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-right: 0.5rem;
  margin-top: 1.9rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;
