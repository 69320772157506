import styled from 'styled-components';

// @ts-ignore
import tickIcon from "assets/images/journeys/tick.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 16px;
`;

export const Step = styled.button<{ $active?: boolean }>`
  background: transparent;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  min-height: 86px;
  text-align: left;
  cursor: default;

  :not(:disabled):hover {
    background: var(--Color-4, #F5F5F5);
    cursor: pointer;
  }
`;

interface ICircleWrap {
  $first?: boolean;
  $last?: boolean;
  $active?: boolean;
  $next?: boolean;
}

export const CircleWrap = styled.div<ICircleWrap>`
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
  align-self: normal;

  ::before,
  ::after {
    content: "";
    width: 2px;
    height: calc(50% - 12px);
    left: 50%;
    margin-left: -1px;
    background: #E5E5E5;
    position: absolute;
  }

  ::before {
    top: 0;
  }

  ::after {
    bottom: 0;
  }

  ${({ $first }) => $first && `
    ::before {
      display: none;
    }
  `};

  ${({ $last }) => $last && `
    ::after {
      display: none;
    }
  `};

  ${({ $active }) => $active && `
    ::before,
    ::after {
      background: #1271A6;
    }
  `};

  ${({ $next }) => $next && `
    ::before {
      background: #1271A6;
    }
  `};
`;

interface ICircle {
  $active?: boolean;
  $next?: boolean;
  $completed?: boolean;
}

export const Circle = styled.div<ICircle>`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #E5E5E5;

  ${({ $completed }) => $completed && `
    border-color: #1271A6;
    background-color: #1271A6;
    background-image: url('${tickIcon}');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
  `};

  ${({ $next }) => $next && `
    border-color: #1271A6;
  `};

  ${({ $active }) => $active && `
    align-items: center;
    justify-content: center;
    background-image: none;
    background-color: transparent;
    border-color: #1271A6;
    box-shadow: 0 0 2px 0 #D0E3ED;

    ::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #1271A6;
    }
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px 10px 0;
`;

export const Title = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const Text = styled.span`
  color: var(--Neutral-800, #262626);
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
