import React from "react";
import { Wrapper, Head, SubTitle, Title } from "./styled";

const DelegationPopup: React.FC = () => {
  return (
    <Wrapper>
      <Head>
        <Title>Delegation Overhead</Title>
      </Head>
      <SubTitle data-testid="subtitle">
        How much time will it take to delegate this task including instructions,
        material, and access.
      </SubTitle>
    </Wrapper>
  );
};

export default DelegationPopup;
