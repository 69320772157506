import {
  Wrapper,
  Group,
  NameGroup,
  Head,
  Order,
  Title,
  Subtitle
} from "./styled";

const Area = ({ data }) => {
  const { name, goal } = data;

  return (
    <Group>
      <Head>
        <Order>{goal?.priority}</Order>
        <NameGroup>
          <Title>{name}</Title>
          <Subtitle>{goal?.category_name}</Subtitle>
        </NameGroup>
      </Head>
    </Group>
  );
}

const AreaItem = ({ data, active, onClick }) => {
  const { id, color } = data;

  const handleClick = () => {
    onClick(id);
  }

  return (
    <Wrapper $color={color} $highlighted={active} $clickable onClick={handleClick}>
      <Area data={data} />
    </Wrapper>
  );
}

export { AreaItem };
