import { ActionTypes } from './type';

const initial = {
  data: [],
  loading: false,
};

export default (state = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        data: [
          ...action.payload.data.results,
          ...action.payload.customData
            .map((item: any) => ({
              ...item,
              id: item.category_name,
              name: item.category_name,
              area: {
                name: item.user_area_name,
                subdomain: {
                  domain: {
                    name: item.journey_name
                  }
                }
              }
            }))
        ],
        loading: false
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
