import { FC, useEffect, useRef } from 'react';
import { ITimeDistribution } from 'Redux/APM/ListTimeDistribution/types';
import {
  Wrapper,
  Col,
  Details,
  Title,
  Text,
  Time,
  Label
} from './styled';

import { IGroup } from '../GroupSelector';

interface IProps {
  data: ITimeDistribution
  active: boolean
  group: IGroup
}

const convertMinutesToTimeFormat = (time?: number | number) => {
  if (!time) return "0h";

  const hours = Math.floor(time / 60);
  const mins = time % 60;

  let result = "";

  if (hours) {
    result += `${hours}h`;
  }

  if (mins) {
    if (result) result += " ";
    result += `${mins}m`;
  }

  return result || `0h`;
};

const LegendItem: FC<IProps> = ({ data, active, group }) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  // Scroll into view when the item becomes active
  useEffect(() => {
    if (active && itemRef.current) {
      itemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [active]);

  return (
    <Wrapper $active={active} ref={itemRef}>
      <Col>
        <Label style={{ backgroundColor: data.areaColor || "#ced0d4" }} />

        <Details>
          {group.key === 'category' ? (
            <>
              <Title>{data.categoryName}</Title>
              <Text>{data.areaName}</Text>
            </>
          ) : null}

          {group.key === 'area' ? (
            <Title>{data.areaName}</Title>
          ) : null}
        </Details>
      </Col>

      <Time>{convertMinutesToTimeFormat(data.totalTime)}</Time>
      {/* <Time><span>15</span>hr <span>20</span>m</Time> */}
    </Wrapper>
  )
}

export default LegendItem;