import styled from 'styled-components';

interface IWrapperProps {
  $color?: string;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 4px solid transparent;
  background-color: #fff;
  padding: 3px 4px 3px 8px;

  ${({ $color }) => $color && `
    border-left-color: ${$color};
  `};
`;

export const Input = styled.input`
  width: 40px;
  text-align: center;
  color: #171717;
  padding: 4px 6px;
  border-radius: 3px;
  border: 0.5px solid #E5E5E5;
  background: #fff;
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
  outline: none;

  :focus {
    border-color: rgb(113, 170, 202);
    box-shadow: rgb(208, 227, 237) 0 0 0 2px;
  }
`;

export const Label = styled.span`
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
