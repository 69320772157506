import { useState, useEffect, forwardRef, FC, ChangeEvent, useRef, useImperativeHandle } from 'react';
import { InputField, Button } from "elements";
import { Popover } from 'antd';

//@ts-ignore
import { ReactComponent as HourglassIcon } from "assets/images/priorities/hourglass.svg";

//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

//@ts-ignore
import { ReactComponent as DelegateIcon } from "assets/images/priorities/delegate.svg";

import {
  Toggle,
  Wrapper,
  Head,
  BodyTime,
  Title,
  CloseBtn,
  Row,
  Col,
  Text,
  Footer
} from "./styled";

interface IState {
  estimated: {
    h: string;
    m: string;
  };
  delegated: {
    h: string;
    m: string;
  };
}

interface IContentProps {
  open: boolean;
  estimated: number;
  delegated: number;
  disabled?: boolean;
  onClose: () => void;
  onChange: (estimated: number, delegated: number) => void;
}

const Content = forwardRef(
  (
    {
      open,
      estimated,
      delegated,
      disabled,
      onClose,
      onChange,
    }: IContentProps,
    ref
  ) => {
  const [showError, setShowError] = useState(false);

  const [values, setValues] = useState<IState>({
    estimated: {
      h: "",
      m: "",
    },
    delegated: {
      h: "",
      m: "",
    },
  });

  useEffect(() => {
    setValues({
      estimated: {
        h: isNaN(Math.floor(estimated / 60))
          ? ""
          : Math.floor(estimated / 60).toString(),
        m: isNaN(estimated % 60)
          ? ""
          : (estimated % 60).toString(),
      },
      delegated: {
        h: isNaN(Math.floor(delegated / 60))
          ? ""
          : Math.floor(delegated / 60).toString(),
        m: isNaN(delegated % 60)
          ? ""
          : (delegated % 60).toString(),
      },
    });

    setShowError(true);
  }, [estimated, delegated, open]);
  
  const handleTimeChange = (field: string, unit: string) => (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length) {
      setValues({
        ...values,
        [field]: {
          // @ts-ignore
          ...values[field],
          [unit]: "",
        },
      });

      return;
    }

    let value = parseInt(e.target.value);

    if (isNaN(value)) {
      return;
    }

    if (unit === "h") {
      value = Math.min(999, Math.max(0, value));
    } else if (unit === "m") {
      value = Math.min(59, Math.max(0, value));
    }

    setValues({
      ...values,
      [field]: {
        // @ts-ignore
        ...values[field],
        [unit]: value,
      },
    });
  };

  const onSubmit = () => {
    const estimated = parseInt(values.estimated.h) * 60 + parseInt(values.estimated.m);
    const delegated = parseInt(values.delegated.h) * 60 + parseInt(values.delegated.m);

    onChange(estimated, delegated);
    onClose();
  }

  return (
    <Wrapper>
      <Head>
        <Title>
          <HourglassIcon />
          <span>Delegate Activity</span>
        </Title>
        <CloseBtn onClick={() => onClose && onClose()}>
          <CloseIcon />
        </CloseBtn>
      </Head>

      <BodyTime>
        {disabled ? null : <Col>
          <Text>Estimated Duration*</Text>

          <Row>
            <InputField
              /* @ts-ignore */
              style={{ width: 70, paddingRight: 12 }}
              value={values.estimated.h}
              size="xs"
              placeholder="-"
              suffix={"h"}
              error={
                showError &&
                (!values.estimated.h || values.estimated.h === "0") &&
                (!values.estimated.m || values.estimated.m === "0")
              }
              onChange={handleTimeChange("estimated", "h")}
            />
            <InputField
              /* @ts-ignore */
              style={{ width: 70 }}
              value={values.estimated.m}
              size="xs"
              placeholder="-"
              suffix={"m"}
              error={
                showError &&
                (!values.estimated.h || values.estimated.h === "0") &&
                (!values.estimated.m || values.estimated.m === "0")
              }
              onChange={handleTimeChange("estimated", "m")}
            />
          </Row>
        </Col>}

        <Col>
          <Text>Delegation overhead</Text>

          <Row>
            <InputField
              /* @ts-ignore */
              style={{ width: 70, paddingRight: 12 }}
              value={values.delegated.h}
              size="xs"
              placeholder="-"
              suffix={"h"}
              onChange={handleTimeChange("delegated", "h")}
            />
            <InputField
              /* @ts-ignore */
              style={{ width: 70 }}
              value={values.delegated.m}
              size="xs"
              placeholder="-"
              suffix={"m"}
              onChange={handleTimeChange("delegated", "m")}
            />
          </Row>
        </Col>

        <Footer>
          <Button size="small" styleType="link-primary" onClick={onClose}>Cancel</Button>
          <Button size="small" onClick={onSubmit}>Save</Button>
        </Footer>
      </BodyTime>
    </Wrapper>
  );
});

interface IProps {
  estimated: number;
  delegated: number;
  disabled?: boolean;
  onChange: (estimated: number, delegated: number) => void;
}

const DelegatePopover: FC<IProps> = ({ estimated, delegated, disabled, onChange }) => {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const PopoverContent = () => {
    return (
      <Content
        open={open}
        estimated={estimated}
        delegated={delegated}
        disabled={disabled}
        onClose={() => handleOpenChange(false)}
        onChange={onChange}
      />
    );
  }

  return (
    <Popover
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode}
      align={{ offset: [144, 0] }}
      content={PopoverContent}
      title={null}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      placement="bottomRight"
      overlayClassName="delegate_time_popover"
      overlayStyle={{ zIndex: 9999 }}
    >
      <Toggle>
        <DelegateIcon />
      </Toggle>
    </Popover>
  );
}

export default DelegatePopover;
