import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 6px 0 0;
  width: 292px;
  border-right: 1px solid #C1CAD1;
`;

export const Title = styled.span`
  color: var(--fill-button-prussian-blue, #112538);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  padding: 6px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 5px 10px 5px 6px;
  overflow-y: auto;

  ::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  ::-webkit-scrollbar-track {
    background: transparent !important ;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #D7DADE;
    border-radius: 100px;
  }
`;

export const Area = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 3px solid #ecf3f6;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10);

  ${({ $color }) => $color && `
    border-left-color: ${$color};
  `};
`;

export const AreaHead = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10.5px 4px 10.5px 8px;
`;

export const AreaHeadGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AreaIcon = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ecf3f6;
`;

export const AreaTitle = styled.span`
  color: var(--fill-button-prussian-blue, #112538);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.12px;
`;

export const AreaToggle = styled.button`
  width: 14px;
  height: 14px;
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AreaBody = styled.div`
  width: 100%;
`;

export const AreaBodyInner = styled.div`
  padding: 10px 4px 10px 7px;
  border-top: 1px solid #8E97A37F;
  color: var(--fill-button-prussian-blue, var(--Header, #112538));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
`;
