import styled, { css } from 'styled-components';
import Switch from "react-switch";
import { Avatar } from "antd";

import calendarIcon from "assets/images/calendar-icon.svg";

import Button from "components/Button";

const hexToRgba = (hex, opacity) => {
  const trimmedHex = hex.replace('#', '');
  const r = parseInt(trimmedHex.substring(0, 2), 16);
  const g = parseInt(trimmedHex.substring(2, 4), 16);
  const b = parseInt(trimmedHex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const Container = styled.div`
  width: 100%;
`;

export const Heading = styled.h1`
  color: #06203a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
  margin: 0;

  ${({ theme }) => theme.max("sm")`
    font-size: 14px;
  `}
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const ContainerItem = styled.div`
  width: 100%;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 12px 4px 12px 12px;
  gap: 7px;
`;

export const Step1 = styled.div`
  width: 100%;
  width: ${({ isWidth }) => isWidth && "100%"};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 0;
  margin: ${({ isMargin }) => isMargin && "0"};

  ${({ theme }) => theme.max("sm")`
    display: none;
  `}
`;

export const Row1 = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  border: 3px solid #1589ca;
  margin-bottom: 0.68rem;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);

  ${({ border }) => `&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    margin-left: -3px;
    height: calc(100%);
    background: ${border};
  }`}

  ${({ theme }) => theme.max("sm")`
    flex-wrap: nowrap;
    flex-direction: column;
  `}
`;

export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 18.5%;
  padding: 0.35rem 0;

  ${({ theme }) => theme.max("md")`
    width: 30%;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
    padding: 0;
  `}
`;

export const TopSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;

  ${({ theme }) => theme.max("sm")`
    width: initial;
  `}
`;

export const TopSectionWrapperRight = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-left: 1rem;

  ${({ theme }) => theme.max("sm")`
    width: initial;
    flex; initial;
    flex-direction: column;
    padding: 3px 0 2px 0;
    align-items: flex-start;
  `}
`;

export const Text = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  ${({ $area }) => $area && css`
    font-size: 12px;
    line-height: 24px;
    font-weight: 400;
    color: #525252;
  `}

  ${({ $category }) => $category && css`
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    color: #171717;
  `}

  ${({ $goal }) => $goal && css`
    line-height: 20px;
    color: #171717;
    font-size: 14px;
    font-weight: 500;
  `}

  @media screen and (max-width: 1439px) {
    font-size: ${({ tablet }) => tablet && "12px"};
  }

  @media screen and (max-width: 540px) {
    max-width: 100%;
    font-size: 12px;
    font-weight: ${({ isMob }) => (isMob ? "500" : "600")};
    line-height: 20px;
  }
`;

export const WrapHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(28% + 80px);
`;

export const GoalHeading = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const GoalText = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const MidSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 37%;

  ${({ theme }) => theme.max("md")`
    width: 35%;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}
`;

export const Input = styled.input`
  width: 100%;
  color: #1b2a3d;
  border: none;
  background-color: white;
  color: #1b2a3d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 300;
    color: #8e97a3;
  }
`;

export const HeaderText = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin: 0;

  ${({ $priority }) =>
    $priority &&
    `
    padding-left: 20px;
    text-align: left;
  `};
  ${({ $area }) =>
    $area &&
    `
    padding-left: 20px;
    text-align: left;
  `};
`;

export const RightSection = styled.div`
  width: 40%;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.max("md")`
    width: 35%;
  `}
  ${({ theme }) => theme.max("md")`
    width: 100%;
  `}
`;

export const IdAvatar = styled(Avatar)`
  background-color: #e7f1f6 !important;
  margin: 0rem 1.45rem;

  .ant-avatar-string {
    color: #1193ea !important;
    font-family: "Inter" !important ;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    vertical-align: middle !important;
  }

  ${({ theme }) => theme.max("md")`
    margin: 0 30px;
  `}
  ${({ theme }) => theme.max("sm")`
    margin: 0 0px 0px 20px;
  `}
`;

export const HeaderZeroSection = styled.div`
  width: calc(20px + 44px + 15px);

  @media screen and (max-width: 1439px) {
    transform: translateX(10px);
  }
`;

export const HeaderLeftSection = styled.div`
  width: 18.8%;
  display: flex;
  ${({ theme }) => theme.max("md")`
    width: 19%;
    padding-left:1rem;
  `}
`;

export const HeaderMidSection = styled.div`
  width: 34%;
  display: flex;
  ${({ theme }) => theme.max("md")`
  width: 19%;

  `}
`;

export const HeaderRightSection = styled.div`
  width: 38%;
  display: flex;
  ${({ theme }) => theme.max("md")`
  width: 30%;

  `}
`;

export const BtnWrapper = styled.div`
  margin-left: 15px;

  ${({ theme, isRight }) => theme.max("sm")`
    margin-left: 0;
  margin-right: ${({ isRight }) => isRight && "16px"};
    
  `}
`;

export const PurposeWrapper = styled.div`
  width: 100%;
  padding: 0 6px 6px 9px;
`;

export const PurposeContent = styled.div`
  display: flex;
  height: 100%;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
  `}
`;

export const PurposeTitle = styled.h3`
  color: #fff;
  width: 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  background-color: #85c4c9;

  ${({ theme }) => theme.max("sm")`
    width: 100%;
    margin-bottom: 0;
    line-heigh: 24px;
    height: 24px;
  `}
`;

export const PurposeContainer = styled.div`
  display: flex;
  padding: 6px 13px 6px 16px;
  align-items: center;
  width: 100%;
  background-color: #d1eeea;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
    padding: 4.5px 7px 7px 7px;
  `}
`;

export const InputWrapper = styled.div`
  width: 100%;
  padding-left: 5px;

  ${({ theme }) => theme.max("sm")`
    padding-left: 0;
  `}
`;

export const Label = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #262626;
  margin-bottom: 2px;

  ${({ theme }) => theme.max("sm")`
    line-height: 24px;
    margin-bottom: 0;
  `}

  ${({ theme }) => theme.min("sm")`
    ${({ $mobile }) =>
      $mobile &&
      `
      display: none;
    `}
  `}
`;

export const InputText = styled.p`
  font-family: "Inter";
  line-break: strict;

  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  margin: 0;
`;

export const InputLabel = styled.label`
  color: var(--Input-header, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 9px;
`;

export const Disclaimer = styled.span`
  color: var(--Neutral-500, var(--Input-header, #737373));
  font-size: 12px;
  line-height: 16px;
`;

export const Planwrapper = styled.div`
  width: 100%;
  background-color: #fff;
  // border-top: 3px solid #94D8C1;
  padding: 12px 12px 0 12px;
  border-radius: 0 0 5px 5px;
  // transition: border-top-color .2s;

  /* ${({ $color }) => $color && `
    border-top-color: ${$color};
  `} */
`;

export const PlanwrapperHeading = styled.div`
  width: 100%;
  height: 36px;
  margin-top: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-checkbox + span {
    color: var(--Input-header, #737373);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    user-select: none;
    position: relative;
    top: -2px;
  }
`;

export const CompletedActionsList = styled.div`
  padding-bottom: 12px;
`;

export const Title = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`;

export const Div = styled.div`
  height: 100%;
`;

export const Item = styled.span`
  color: var(--Input-header, #737373);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  width: 100%;

  ${({ $type }) => $type && css`
    display: flex;
    align-items: center;
    gap: 6px;
  `}
`;

export const Goalsitem = styled.div`
  width: 100%;
`;

export const GoalsRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 4px 0 0;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
    padding: 0 4px 4px;
  `}
`;

export const GoalsCol = styled.div`
  ${({ $goal }) =>
    $goal &&
    `
    width: 90px;
  `}

  ${({ $accountability }) => $accountability && `
    display: flex;
    align-items: center;
    width: 14%;
    margin-right: 20px;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}

  ${({ $name }) =>
    $name &&
    `
    flex: 1;
    padding-left: 17px;
    padding-right: 3px;
  `}

  ${({ $date }) => $date && `
    width: 7vw;
    display: flex;
    flex-direction: column;
  `}

  ${({ $dropdown }) =>
    $dropdown &&
    `
    width: 180px;
    margin-left: 6px;
    padding-bottom: 8px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}

  ${({ $space }) => $space && `
    width: 70px;
  `}

  ${({ $habit }) => $habit && `
    display: flex;
    align-items: center;
    // width: calc(140px + 51px);
    gap: 11px;

    .ant-btn-compact-first-item {
      display: none;
    }

    .ant-btn {
      background-color: transparent;
      border: none;
      transform: rotate(90deg);
    }
  `}

  ${({ $dot }) =>
    $dot &&
    `
    width: 61px;
    display:flex;
    justify-content: center ;
    // align-items: center;
    margin-top:0.28rem;
    float:right;
    `}

  ${({ theme }) => theme.max("sm")`
    ${({ $name }) =>
      $name &&
      `
      padding: 10px 8px;
    `}

    ${({ $date }) =>
      $date &&
      `
      margin-left: 0;

      &:first-of-type {
        margin-right: 3.5px;
      }

      &:last-of-type {
        margin-left: 3.5px;
      }
    `}

    ${({ $dropdown }) =>
      $dropdown &&
      `
      margin-left: 0;
      padding: 0 7px 5px 7px;
      background: #E28F21;
    `}
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  height: 100%;
  margin-bottom: 4px;
  border-bottom: 1px solid #D9D9D9;
  padding: 0 0 12px 0;
`;

export const ContentOne = styled.div`
  height: 100%;
  position: relative;
  background-color: #fff;
  transition: background-color .2s;

  ${({ $isHighlighted }) => $isHighlighted && css`
    background-color: rgba(255, 244, 228, 0.50);
  `}

  ${({ $color }) => $color && css`
    /* ${({ $isHighlighted }) => $isHighlighted && css`
      background-color: ${hexToRgba($color, .05)};
    `} */

    ::after {
      content: '';
      height: calc(100% - 12px);
      width: 5px;
      border-radius: 0 4px 4px 0;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 6px;
      background-color: ${$color};
      transition: background-color .2s;
    }
  `}
`;

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 4px 7px 0;
  border-bottom: 1px solid var(--neutral-300, #CDCDCD);

  ${({ $isFirst }) => $isFirst && css`
    border-top: 1px solid var(--neutral-300, #CDCDCD);
  `}
`;

export const ActionCol = styled.div`
  // border: 1px solid red;

  @media screen and (min-width: 1024px) {
    ${({ $ml }) => $ml && css`
      margin-left: ${$ml}px;
    `}
  }

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $flex }) => $flex && css`
    flex: 1;
  `}

  ${({ $dnd }) => $dnd && css`
    display: flex;
    justify-content: center;
  `}

  ${({ $startline }) => $startline && css`
    margin-right: 2vw;
  `}

  ${({ $accountability }) => $accountability && css`
    margin-right: 20px;
  `}

  ${({ $options }) => $options && css`
    width: 51px;
  `}
`;

export const ContentTitle = styled.div`
  color: #fff;
  width: 100%;
  height: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.p`
  height: 100%;
  font-family: "Inter";
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 0;

  ${({ theme }) => theme.max("sm")`
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  `}
`;

export const InputField = styled.div`
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      position: relative;

      input {
        width: 100%;
        font-family: "Inter";
        font-weight: 400;
        min-width: 98px;
        font-size: 12px;
        line-height: 20px;
        color: #1b2a3d;
        border: 1px solid #e3e5e9;
        border-radius: 4px;
        padding: 3px 6px;
        outline: none;
        transition: box-shadow .3s;

        &::placeholder {
          color: #A3A3A3;
        }

        &:focus,
        &:active {
          box-shadow: 0px 0px 0px 2px #D0E3ED;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: url("${calendarIcon}") no-repeat;
        background-size: 20px 20px;
        background-position: 100% 100%;
        width: 20px;
        height: 20px;
        right: 6px;
        top: 4px;
        pointer-events: none;
      }
    }
  }
`;

export const InputItem = styled.input`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  width: 100%;
  border: 1px solid #e3e5e9;
  height: 36px;
  padding: 0 12px;
  outline: none;
  border-radius: 4px;
  background-color: #fff;
  text-overflow: ellipsis;
  letter-spacing: 0;

  &::placeholder {
    color: #8e97a3;
  }

  &[type="date"] {
    &::-webkit-calendar-picker-indicator {
      background: url("${calendarIcon}") no-repeat;
      background-size: 13px 15px;
      background-position: 100% 100%;
      width: 13px;
      height: 15px;
      transform: translateX(2px) translateY(-2px);
    }
  }
`;

export const Checkdiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-select.ant-select-single {
    border: none;
  }
  /* .ant-select .ant-select-dropdown {
    min-width: 130px !important;
    width: 118px !important;
  } */
`;

export const DropDownWrapper = styled.div`
  width: ${({ width }) => (width ? width : "180px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  .ant-select.ant-select-single {
    border: none !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-select-selector {
    background-color: #fff !important;
    text-align: center;
  }

  .ant-space-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      font-size: 0.7rem;
      fill: #8e97a3;
      path {
        stroke: #8e97a3;
        stroke-width: 150px !important;
      }
    }
  }

  ${({ theme }) => theme.max("sm")`
    max-width: 266px;
  `}
`;

export const ModifiedButton = styled(Button)`
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 4px 4px 0;
`;

export const HeadCol = styled.div`
  // border: 1px solid red;

  ${({ $actions }) => $actions && css`
    display: flex;
    align-items: center;
  `}

  ${({ $width }) => $width && css`
    width: ${$width};
  `}

  ${({ $flex }) => $flex && css`
    flex: 1;
  `}

  ${({ $dnd }) => $dnd && css`
    // margin-right: 3.8vw;
    // padding-right: 30px;
    // margin-right: 30px;
  `}

  ${({ $deadline }) => $deadline && css`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-right: 2vw;
  `}

  ${({ $startline }) => $startline && css`
    margin-right: 2vw;
  `}

  ${({ $last }) => $last && css`
    ::before {
      content: "";
    }
  `}
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
`;

/* export const ActionStatusIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 5px;
`;
 */
export const ActionHeading = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  margin: 0;
  height: 100%;
  min-width: 21px;
  margin-right: 7px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 12px;

  ${({ theme }) => theme.max("sm")`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const ActionName = styled.span`
  width: 100%;
  color: var(--stroke-text-body-prussian-blue, #1B2A3D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
`;

export const Icon = styled.img``;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  width: 36px;
  height: 36px;
  background-color: transparent;
  border-radius: 4px;

  ${({ $disabled }) => $disabled && `
    opacity: 0.6;
  `}

  ${({ $disabled }) => $disabled || `
    :hover,
    :active {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;

  ${({ $first }) => $first && `
    width: calc(28% + 80px);
    // padding-right: 30px;
  `}

  ${({ $action }) => $action && `
    gap: 12px;
    // margin-right: 2vw;
  `}

  ${({ $fill }) =>
    $fill &&
    `
    flex: 1;
  `}

  ${({ theme }) => theme.max("sm")`
    flex: 1;
    width: 100%;

    ${({ $action }) =>
      $action &&
      `
      background: #E28F21;
      padding: 3px 7px 0 7px;
    `}
  `}
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  height: 6rem;
`;

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 5px;
`;

export const DragDropContainer = styled.div`
  width: 100%;
  min-height: 16vh;
  height: auto;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;

  ${({ theme }) => theme.min("sm")`
    ${({ $mobile }) =>
      $mobile &&
      `
      display: none;
    `};
  `}

  ${({ theme }) => theme.max("sm")`
    ${({ $desktop }) =>
      $desktop &&
      `
      display: none;
    `};
  `}
`;

export const PrevNextPlan = styled.button`
  font-family: "Poppins";
  outline: none;
  border: none;
  background: none;
  font-size: 14px;
  line-height: 24px;
  color: #000;
  width: 28px;
  height: 28px;
  background: #ecf3f7;
  border-radius: 3px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.max("sm")`
    padding: 0;
  `}

  img {
    width: 12px;
    height: 12px;

    ${({ $prev }) =>
    $prev &&
    `
      transform: rotate(180deg);
      
      
    `};

    ${({ $next }) =>
    $next &&
    `
    `};
  }

  ${({ $hide }) =>
    $hide &&
    `
    display: none;
  `};

  &:hover {
    img {
      ${({ $prev }) =>
    $prev &&
    `
        transform: rotate(180deg) ;
      `};
    }
  }
`;

export const PageCount = styled.span`
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  margin: 0 14px;
  color: #06203a;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .ant-btn-compact-first-item {
    display: none;
  }

  .ant-btn {
    background-color: transparent;
    border: none;
    transform: rotate(90deg);
  }
`;

export const SwitchWrapper = styled.div`
  font-size: 0.75rem;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;

  .switch {
    margin-right: 0.5rem;
  }
`;

export const SwitchBtn = styled(Switch)`
  .react-switch-bg {
    height: 20px !important;
    width: 34px !important;
  }
  .react-switch-handle {
    top: 1px !important;
    transform: translateX(
      ${({ checked }) => (checked ? "15px" : "0px")}
    ) !important;
  }
`;

export const AddButton = styled.button`
  border: none;
  background-color: transparent;
  color: var(--primary-500-main, #1271A6);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 0;
  gap: 6px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProgressLabel = styled.span`
  color: var(--primary-500-main, #1271A6);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;

export const DropdownToggler = styled.button`
  width: 100%;
  display: flex;
  background: transparent;
  border: none;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  color: var(--neutral-500, var(--Input-header, #737373));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: box-shadow .3s;
  outline: none;
  border-radius: 4px;
  padding: 1px 0;

  img {
    transform: rotate(90deg);
  }

  ${({ $disabled }) => !$disabled && `
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 2px #D0E3ED;
    }
  `}

  ${({ $disabled }) => $disabled && `
    cursor: default;
  `}
`;
