import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { InputField, Button } from "elements";
import { Popover } from 'antd';

//@ts-ignore
import { ReactComponent as HourglassIcon } from "assets/images/priorities/hourglass.svg";

//@ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";
//@ts-ignore
import { ReactComponent as QuestionIcon } from "assets/images/priorities/QuestionIcon.svg";

import {
  Wrapper,
  Head,
  BodyTime,
  Title,
  CloseBtn,
  Row,
  Col,
  TextWithPopover,
  Text,
  Footer
} from "./styled";

//@ts-ignore
import useDeviceType from "../../../../Hooks/ResponsiveHook";
import { updateAction } from "Redux/APM/CreateAction/action";
import { updateTask } from "Redux/APM/CreateTask/action";

import WeeklyDurationPopover from "components/PriorityApp/SimpleView/WeeklyDurationPopover";
import DelegationPopover from "../DelegationPopup";

interface TimePopoverProps {
  id: any;
  inline?: boolean;
  isTask?: boolean;
  estimation_time: number;
  delegation_time: number;
  onClose?: () => void;
}

interface IState {
  delegation: {
    h: string;
    m: string;
  };
  estimation: {
    h: string;
    m: string;
  };
}

const TimePopover = forwardRef(
  (
    {
      onClose,
      id,
      inline,
      isTask,
      estimation_time,
      delegation_time,
    }: TimePopoverProps,
    ref
  ) => {
    const screenSize = useDeviceType();
    const dispatch = useDispatch();

    const [values, setValues] = useState<IState>({
      delegation: {
        h: "",
        m: "",
      },
      estimation: {
        h: "",
        m: "",
      },
    });

    const [showError, setShowError] = useState(false);

    useImperativeHandle(ref, () => ({
      submit: () => {
        onSubmit();
      },
      clear: () => {
        onInit();
      }
    }));

    useEffect(() => {
      onInit();
    }, [delegation_time, estimation_time]);

    const onInit = () => {
      setValues({
        delegation: {
          h: isNaN(Math.floor(delegation_time / 60))
            ? ""
            : Math.floor(delegation_time / 60).toString(),
          m: isNaN(delegation_time % 60)
            ? ""
            : (delegation_time % 60).toString(),
        },
        estimation: {
          h: isNaN(Math.floor(estimation_time / 60))
            ? ""
            : Math.floor(estimation_time / 60).toString(),
          m: isNaN(estimation_time % 60)
            ? ""
            : (estimation_time % 60).toString(),
        },
      });

      setShowError(true);
    }

    const handleTimeChange =
      (field: string, unit: string) => (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value.length) {
          setValues({
            ...values,
            [field]: {
              // @ts-ignore
              ...values[field],
              [unit]: "",
            },
          });

          return;
        }

        let value = parseInt(e.target.value);

        if (isNaN(value)) {
          return;
        }

        if (unit === "h") {
          value = Math.min(999, Math.max(0, value));
        } else if (unit === "m") {
          value = Math.min(59, Math.max(0, value));
        }

        setValues({
          ...values,
          [field]: {
            // @ts-ignore
            ...values[field],
            [unit]: value,
          },
        });
      };

    const handleBlur = () => {
      if (!inline)
        return;

      if (!values.delegation.h && !values.delegation.m) {
        // Set border color to red if duration fields are empty
        const newValues = {
          ...values,
          delegation: {
            ...values.delegation,
            h: values.delegation.h || "", // Preserve existing value or set empty string
            m: values.delegation.m || "", // Preserve existing value or set empty string
          },
        };

        setValues(newValues);

        return;
      }

      onSubmit();
    };

    const onSubmit = () => {
      const formData = new FormData();

      if (!isTask)
        formData.append("prioritized_activity_id", id);

      if (values.estimation.h || values.estimation.m) {
        const estimateDuration = {
          h: parseInt(values.estimation.h || "0"),
          m: parseInt(values.estimation.m || "0"),
        };

        formData.append(
          "estimated_duration",
          (estimateDuration.h * 60 + estimateDuration.m).toString()
        );
      } else {
        setShowError(true); // Show error if both fields are empty or 0
        return;
      }

      if (/* (values.estimation.h || values.estimation.m) &&  */ !isTask) {
        const delegateDuration = {
          h: parseInt(values.delegation.h || "0"),
          m: parseInt(values.delegation.m || "0"),
        };

        // Adjust duration if estimation is greater than duration
        if (
          delegateDuration.h * 60 + delegateDuration.m >
          parseInt(values.estimation.h || "0") * 60 +
            parseInt(values.estimation.m || "0")
        ) {
          const duration = {
            h: delegateDuration.h,
            m: delegateDuration.m,
          };

          formData.append(
            "estimated_duration",
            (duration.h * 60 + duration.m).toString()
          );
        }

        formData.append("my_time", (delegateDuration.h * 60 + delegateDuration.m).toString());
      }

      if (isTask)
        dispatch(updateTask(formData, id, handleCallback, { notify: false }));
      else
        dispatch(
          updateAction(formData, handleCallback, { notify: false, loader: false })
        );
    };

    const handleCallback = () => {
      onClose && onClose();
    };

    const isDisabled =
      values.estimation.h === Math.floor(estimation_time / 60).toString() &&
      values.estimation.m === (estimation_time % 60).toString() &&
      values.delegation.h === Math.floor(delegation_time / 60).toString() &&
      values.delegation.m === (delegation_time % 60).toString();

    return (
      <Wrapper $inline={!!inline} $mobile={screenSize !== "desktop"}>
        {inline ? null : (
          <Head>
            <Title>
              <HourglassIcon />
              <span>Time</span>
            </Title>
            <CloseBtn onClick={() => onClose && onClose()}>
              <CloseIcon />
            </CloseBtn>
          </Head>
        )}

        <BodyTime $inline={!!inline}>
          <Col id="react-joyride-step5">
            {inline ? (
              <TextWithPopover>
                <Text>Estimated Weekly Duration*</Text>

                <Popover
                  align={{ offset: [20, 0] }}
                  // @ts-ignore
                  content={WeeklyDurationPopover}
                  title={null}
                  placement="bottomRight"
                  overlayClassName="custom_popover"
                  overlayStyle={{ zIndex: 9999 }}
                >
                  <QuestionIcon />
                </Popover>
              </TextWithPopover>
            ) : (
              <Text>Estimated Duration*</Text>
            )}
            <Row>
              <InputField
                /* @ts-ignore */
                style={{ width: 70, paddingRight: 12 }}
                value={values.estimation.h}
                size="xs"
                placeholder="-"
                suffix={"h"}
                error={
                  showError &&
                  (!values.estimation.h || values.estimation.h === "0") &&
                  (!values.estimation.m || values.estimation.m === "0")
                }
                onChange={handleTimeChange("estimation", "h")}
                onBlur={handleBlur}
              />
              <InputField
                /* @ts-ignore */
                style={{ width: 70 }}
                value={values.estimation.m}
                size="xs"
                placeholder="-"
                suffix={"m"}
                error={
                  showError &&
                  (!values.estimation.h || values.estimation.h === "0") &&
                  (!values.estimation.m || values.estimation.m === "0")
                }
                onChange={handleTimeChange("estimation", "m")}
                onBlur={handleBlur}
              />
            </Row>
          </Col>

          {isTask ? null : (
            <Col>
              <TextWithPopover>
                <Text>Delegation overhead</Text>
                {inline ? (
                  <Popover
                    align={{ offset: [20, 0] }}
                    // @ts-ignore
                    content={DelegationPopover}
                    title={null}
                    placement="bottomRight"
                    overlayClassName="custom_popover"
                    overlayStyle={{ zIndex: 9999 }}
                  >
                    <QuestionIcon />
                  </Popover>
                ) : null}
              </TextWithPopover>

              <Row>
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70, paddingRight: 12 }}
                  value={values.delegation.h}
                  size="xs"
                  placeholder="-"
                  suffix={"h"}
                  /* error={
                    showError &&
                    (!values.delegation.h || values.delegation.h === "0") &&
                    (!values.delegation.m || values.delegation.m === "0")
                  } */
                  onChange={handleTimeChange("delegation", "h")}
                  onBlur={handleBlur}
                />
                <InputField
                  /* @ts-ignore */
                  style={{ width: 70 }}
                  value={values.delegation.m}
                  size="xs"
                  placeholder="-"
                  suffix={"m"}
                  /* error={
                    showError &&
                    (!values.delegation.h || values.delegation.h === "0") &&
                    (!values.delegation.m || values.delegation.m === "0")
                  } */
                  onChange={handleTimeChange("delegation", "m")}
                  onBlur={handleBlur}
                />
              </Row>
            </Col>
          )}

          {inline ? null : <Footer>
            <Button size="small" styleType="link-primary" onClick={onClose}>Cancel</Button>
            <Button size="small" disabled={isDisabled} onClick={onSubmit}>Save</Button>
          </Footer>}
        </BodyTime>
      </Wrapper>
    );
  }
);

export default TimePopover;
