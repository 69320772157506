import React from 'react';
import { Wrapper, Label, Progress } from './styled';

const PasswordIndicator = ({ value, secondary }) => {
  let strength = 0;
  let color = 'transparent';
  let label = '';
  let description = '';

  if (new RegExp(/^(?=.*[a-z])(?=.{8,})/).test(value))
    strength++;
  if (new RegExp(/^(?=.*[A-Z])/).test(value))
    strength++;
  if (new RegExp(/^(?=.*[0-9])/).test(value))
    strength++;
  if (new RegExp(/^(?=.*[!@#\$%\^&\*])/).test(value))
    strength++;

  if (strength === 1)
    color = '#F00000';
  if (strength === 2)
    color = '#FF9900';
  if (strength === 3)
    color = '#448FFF';
  if (strength === 4)
    color = '#35AA22';

  if (strength === 1) {
    label = 'Weak';
    description = 'Your password is easily-guessed. You deserve better.'; // As weak as a newborn kitten.
  }
  if (strength === 2) {
    label = "Fair";
    description = 'An average-strength password. Try adding numbers and special characters.'; // So-so, could use some spice.
  }
  if (strength === 3) {
    label = 'Strong';
    description = 'This password is secure.'; // Locked and loaded, no joke.
  }
  if (strength === 4) {
    label = 'Excellent';
    description = 'A peak performing password. Bravo.'; // Unbreakable like the Hulk's grip.
  }

  return (
    <Wrapper $secondary={secondary}>
      <Label color={color} $secondary={secondary}><span>{label}</span> {description ? ` - ${description}` : ''}</Label>
      <Progress width={`${strength * 25}%`} fill={color} $secondary={secondary} />
    </Wrapper>
  );
}

export default PasswordIndicator;
