import { ReactComponent as BackIcon } from "assets/svg/backArrow.svg";
import { Back } from '../styled';

const BackNavigate = ({ to, alternative }) => {
  return (
    <Back to={to} $alternative={alternative}>
      <BackIcon />
      Back
    </Back>
  );
}

export { BackNavigate };
