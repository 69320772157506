import { useSelector } from "react-redux";

// @ts-ignore
import { getCookie } from "utils/StorageVariables";

// @ts-ignore
import logoImg from "assets/images/header_logo_modern.svg";

import {
  Wrapper,
  Col,
  ColSecondary,
  Logo,
  Divider,
  Title,
  SubTitle,
  User,
} from "./styled";

const PrintHead = () => {
  const domainName = getCookie("domainName");

  // @ts-ignore
  const { userDetail: userData } = useSelector((state) => state.userDetailReducer);

  return (
    <>
      <style type="text/css" media="print">{"\
        @page {\ size: portrait;\ }\
      "}</style>

      <Wrapper>
        <Col>
          <Logo src={logoImg} height={35} />
          <Divider />
          <Title>{domainName} Goal Setting by Arootah</Title>
        </Col>

        <ColSecondary>
          <SubTitle>Mission Statement</SubTitle>
          <User>{userData?.name}</User>
        </ColSecondary>
      </Wrapper>
    </>
  );
}

export default PrintHead;
