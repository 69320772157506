import React, { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  align-items: flex-start;
  width: 95%;
  min-height: 100% !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  ${({ theme }) => theme.max("lg")`
    flex-direction: row !important;
    width:100%;
    height:auto;
`}

  ${({ theme }) => theme.max("sm")`
    flex-direction: column !important;
    width:100%;
    min-height:100%;
    height:auto;
    margin:0;
  `}

  @media (min-width: 744px) and (max-width: 1024px) {
    width: 90%;
  }

`;

const Image = styled.img`
  width: 100%;
  height: 370px;
  object-fit: cover;

  ${({ theme }) => theme.max("xl")`
    height: 280px;
    width: 100%;
  `}

  ${({ theme }) => theme.max("lg")`
    height: 220px;
    width: 35%;
  `}

  ${({ theme }) => theme.max("sm")`
    height: 350px;
    width: 100%;
  `}
`;

const Heading = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  padding-top: 1rem;
  color: #000000;
`;

const Description = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex-wrap: wrap;

  ${({ theme }) => theme.max("md")`
    width: 90%;
  `}

  ${({ theme }) => theme.max("sm")`
    width: 100%;
  `}
`;

const Button = styled.a`
  border-radius: 3px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  text-decoration: none;
  font-family: Inter;
  text-align: center;

`;

const ButtonWrapper = styled.div`
  width: 108px;
  height: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: linear-gradient(161.19deg, #1263de 18.35%, #448fff 74.45%);
  
  ${({ theme }) => theme.max("md")`
    position:absolute;
    bottom:25px;
    right:20px;
    border-radius: 2px;
  `}

  ${({ theme }) => theme.max("sm")`
    position:inherit;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  min-height: 100%;
  height: auto;
`;

const Text = styled.div`
  flex: 1;
`;

interface CardProps {
  src: string;
  description: string;
  heading: string;
  link: string;
}
const Card : FC<CardProps> = ({ src, description, heading, link }) => {
  return (
    <Wrapper>
      <Image src={src} />
      <Container>
        <Text>
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </Text>

        <ButtonWrapper>
          <Button href={link} target="_blank">
            Learn More
          </Button>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default Card;
