import styled from "styled-components";
import { BREAKPOINTS } from "theme/mixins";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  background-color: #05192e;
  z-index: 1200;
  position: sticky;
  width: 100%;
  height: 50px;

  ${({ $apm }) => $apm || `
    height: 67px;
  `};

  @media screen and (max-width: ${({ $apm }) => $apm ? '1439px' : `${BREAKPOINTS.lg}px`}) {
    height: 108px;
    flex-direction: column;
    padding: 15px;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${({ $apm }) => $apm ? '1439px' : `${BREAKPOINTS.lg}px`}) {
    flex-direction: column;
  }
`;

export const FollowText = styled.p`
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

export const Icon = styled.img`
  width: 1.35rem;
  height: 1.35rem;
  margin: 0 0.25rem;

  @media screen and (max-width: ${({ $apm }) => $apm ? '1439px' : `${BREAKPOINTS.lg}px`}) {
    margin: 0 0.5rem;
  }
`;

export const Copyright = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin: 0;
  color: #ffffff;
`;

export const IconWrapper = styled.div`
  padding-left: 1rem;

  @media screen and (max-width: ${({ $apm }) => $apm ? '1439px' : `${BREAKPOINTS.lg}px`}) {
    padding-left: 0;
    padding-bottom: 1rem;
    padding-top: 0.2rem;
  }
`;

export const Anchor = styled.a`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin: 0;
  color: #ffffff;
  text-decoration: none;
`;
