import { getMyClients, getMyClientsAvatars } from "api";
import { ActionTypes } from "./types";

export const GetMyClients = (options = { loading: false }) => async (dispatch) => {
  try {
    if (options.loading)
      dispatch({ type: ActionTypes.LOADING });

    const { data } = await getMyClients();

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.error(error);

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
