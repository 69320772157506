export const ActionTypes = {
  LOADING: "MISSION_STATEMENTS_LOADING",
  ERROR: "MISSION_STATEMENTS_ERROR",
  SUCCESS: "MISSION_STATEMENTS_SUCCESS",
};

export interface IMissionStatement {
  id: string;
  conclusion: string;
  color: string;
  icon: string;
  area: string;
  goal: string;
}
