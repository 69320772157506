// import React from "react";
import { Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";

import TypeBadge from "../TypeBadge";

import { Custombutton, Custom, CustomMenu, MenuItemContent } from "./styled";

//@ts-ignore
import { ReactComponent as PlusBig } from "../../../../assets/images/priorities/PlusBig.svg";

//@ts-ignore
import { ReactComponent as DownWard } from "../../../../assets/images/priorities/DownWard.svg";

type Activity = {
  type: string;
  label: string;
};

const data: Activity[] = [
  { type: 'H', label: 'Habit' },
  { type: 'A', label: 'Action' },
  // { type: 'D', label: 'Decision' },
  { type: 'T', label: 'Task' },
];

const ActionDropdown = () => {
  const navigate = useNavigate();

  const handleClick = (type: string) => () => {
    navigate(`?create=${type}`);
  }

  const menu = (
    <CustomMenu style={{ top: "0px", width: "91px !important", padding: 4 }}>
      {data.map((ele, index) => (
        <Menu.Item key={index.toString()} onClick={handleClick(ele.label)}>
          <MenuItemContent>
            <TypeBadge type={ele?.type} />
            {ele.label}
          </MenuItemContent>
        </Menu.Item>
      ))}
    </CustomMenu>
  );

  return (
    <Custom>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        overlayStyle={{ width: "8px" }}
      >
        <Custombutton id="react-joyride-step1">
          <PlusBig />
          Add Priority
          <DownWard />
        </Custombutton>
      </Dropdown>
    </Custom>
  );
};

export default ActionDropdown;
