import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useDeviceType from "../../Hooks/ResponsiveHook";

import { Button, Tooltip } from "elements";

import { EmptyListDomain, ListDomain } from "Redux/ListDomains/action";
import lockIcon from "assets/images/journeys/lock.svg";
import sidebarCloseIcon from "assets/images/journeys/sidebar-close.svg";
import tickIcon from "assets/images/goals-tick.svg";

import * as Styles from "./styled.js";

import ActionItem from "./ActionItem/index.jsx";
import Header from "components/Journeys/Actions/Header";
import { ToggleSidebarAction } from "Redux/Global/action.js";

import { MISSION_PATH } from "utils/routes";

const Wrapper = ({ children }) => {
  const dispatch = useDispatch();

  const { toggledSidebar } = useSelector((state) => state.globalReducer);

  const handleTogglerClick = () => {
    dispatch(ToggleSidebarAction(toggledSidebar === "" ? "left" : ""));
  };

  return (
    <Styles.Container>
      <Styles.Step>
        <Styles.Heading>
          <Tooltip title={toggledSidebar === "left" ? "Hide Goals" : "Goals"}>
            <Styles.Toggler
              $active={toggledSidebar === "left"}
              type="button"
              onClick={handleTogglerClick}
            >
              <img src={sidebarCloseIcon} alt="" />
            </Styles.Toggler>
          </Tooltip>
        </Styles.Heading>
      </Styles.Step>

      {children}
    </Styles.Container>
  );
};

const CompletedGoals = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const { SelectedClient, selectedGoalAction } = useSelector(
    (state) => state.globalReducer
  );

  useEffect(() => {
    if (SelectedClient) {
      dispatch(ListDomain(SelectedClient?.id));
    } else {
      dispatch(ListDomain());
    }

    return () => {
      dispatch(EmptyListDomain(null));
    };
  }, [SelectedClient]);

  const handleCreateGoal = () => {
    navigate(MISSION_PATH);
  };

  if (selectedGoalAction?.i === -1) {
    return (
      <Wrapper>
        <Styles.Placeholder>
          <Styles.PContainer>
            <Styles.PIcon src={tickIcon} alt="" />
            <Styles.PTitle>Complete your first goal</Styles.PTitle>
            <Styles.PText>
              Here you'll find a record of your completed goals. Check here
              often to celebrate your achievements and boost your willpower.
            </Styles.PText>
            <Button onClick={handleCreateGoal}>+ Create Goal</Button>
          </Styles.PContainer>
        </Styles.Placeholder>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Styles.TableContainer $swidth>
        <Header
          data={selectedGoalAction}
          readOnly
          device={!!isMobile ? "Mobile" : !!isTablet ? "Tablet" : "Desktop"}
        />

        <Styles.Planwrapper>
          <Styles.Goalsitem>
            <Styles.Wrapper>
              <Styles.Content>
                {!isMobile && !isTablet ? (
                  <Styles.GoalsRow>
                    <Styles.WrapHead>
                      <Styles.GoalHeading>Goal</Styles.GoalHeading>
                      <Styles.GoalText>
                        {selectedGoalAction?.goal?.goal_name}
                      </Styles.GoalText>
                    </Styles.WrapHead>

                    <Styles.ActionsWrapper
                      $action
                      style={{ marginRight: "2vw" }}
                    >
                      <Styles.GoalsCol $date>
                        <Styles.InputLabel>Startline</Styles.InputLabel>
                        <Styles.InputValue>
                          {selectedGoalAction?.goal?.startline
                            ? moment(
                                selectedGoalAction?.goal?.startline
                              ).format("YYYY-MM-DD")
                            : null}
                        </Styles.InputValue>
                      </Styles.GoalsCol>

                      <Styles.GoalsCol $date>
                        <Styles.InputLabel>Deadline</Styles.InputLabel>
                        <Styles.InputValue>
                          {selectedGoalAction?.goal?.deadline
                            ? moment(selectedGoalAction?.goal?.deadline).format(
                                "YYYY-MM-DD"
                              )
                            : null}
                        </Styles.InputValue>
                      </Styles.GoalsCol>
                    </Styles.ActionsWrapper>

                    <Styles.GoalsCol $accountability>
                      <Styles.InputLabel>Accountability</Styles.InputLabel>
                      <Styles.InputValue>
                        {selectedGoalAction?.goal?.accountability || " – "}
                      </Styles.InputValue>
                    </Styles.GoalsCol>

                    <Styles.GoalsCol $private>
                      {selectedGoalAction?.goal?.is_private && (
                        <Styles.PrivateLabel>
                          <img src={lockIcon} width={16} height={16} alt="" />
                          Private Goal
                        </Styles.PrivateLabel>
                      )}
                    </Styles.GoalsCol>

                    <Styles.GoalsCol $habit />
                  </Styles.GoalsRow>
                ) : (
                  <Styles.GoalsRow>
                    <Styles.WrapHead>
                    <Styles.GoalsHeader>
                      <Styles.GoalHeading $width={'75%'}>Goal</Styles.GoalHeading>
                      <Styles.GoalsCol $private>
                      {selectedGoalAction?.goal?.is_private && (
                        <Styles.PrivateLabel>
                          <img src={lockIcon} width={16} height={16} alt="" />
                          Private Goal
                        </Styles.PrivateLabel>
                      )}
                    </Styles.GoalsCol>
                    </Styles.GoalsHeader>
                      <Styles.GoalText>
                        {selectedGoalAction?.goal?.goal_name}
                      </Styles.GoalText>
                    </Styles.WrapHead>
                    <Styles.ActionsWrappers>
                      <Styles.ActionsWrapper
                        $goalaction
                        $action
                        style={{ marginRight: "2vw" }}
                      >
                        <Styles.GoalsCol $date>
                          <Styles.InputLabel>Startline</Styles.InputLabel>
                          <Styles.InputValue>
                            {selectedGoalAction?.goal?.startline
                              ? moment(
                                  selectedGoalAction?.goal?.startline
                                ).format("YYYY-MM-DD")
                              : null}
                          </Styles.InputValue>
                        </Styles.GoalsCol>

                        <Styles.GoalsCol $date>
                          <Styles.InputLabel>Deadline</Styles.InputLabel>
                          <Styles.InputValue>
                            {selectedGoalAction?.goal?.deadline
                              ? moment(
                                  selectedGoalAction?.goal?.deadline
                                ).format("YYYY-MM-DD")
                              : null}
                          </Styles.InputValue>
                        </Styles.GoalsCol>
                      </Styles.ActionsWrapper>

                      <Styles.GoalsCol $accountability $accountabilitySmall >
                        <Styles.InputLabel>Accountability</Styles.InputLabel>
                        <Styles.InputValue>
                          {selectedGoalAction?.goal?.accountability || " – "}
                        </Styles.InputValue>
                      </Styles.GoalsCol>
                    </Styles.ActionsWrappers>
                    <Styles.GoalsCol $private>
                      {selectedGoalAction?.goal?.is_private && (
                        <Styles.PrivateLabel>
                          <img src={lockIcon} width={16} height={16} alt="" />
                          Private Goal
                        </Styles.PrivateLabel>
                      )}
                    </Styles.GoalsCol>

                    <Styles.GoalsCol $habit />
                  </Styles.GoalsRow>
                )}
              </Styles.Content>
            </Styles.Wrapper>
          </Styles.Goalsitem>

          {!isMobile && !isTablet ? (
            <>
              <Styles.Step1 isWidth={true} isMargin={true}>
                <Styles.RowWrapper>
                  <Styles.HeadCol $actions $width={"calc(28% + 80px)"}>
                    <Styles.Item style={{ flex: 1 }}>Actions</Styles.Item>
                  </Styles.HeadCol>

                  <Styles.HeadCol $deadline>
                    <Styles.Item style={{ width: "7vw" }}>
                      Startline
                    </Styles.Item>
                    <Styles.Item style={{ width: "7vw" }}>Deadline</Styles.Item>
                  </Styles.HeadCol>

                  <Styles.HeadCol $width="14%" style={{ marginRight: 20 }}>
                    <Styles.Item>Accountability</Styles.Item>
                  </Styles.HeadCol>

                  <Styles.HeadCol $width="140px">
                    <Styles.Item $type>Type</Styles.Item>
                  </Styles.HeadCol>
                  <Styles.HeadCol $width="51px" $last></Styles.HeadCol>
                </Styles.RowWrapper>
              </Styles.Step1>

              <Styles.Wrapper className="characters">
                {selectedGoalAction?.goal?.actions
                  ?.filter((action) => action?.completed)
                  ?.map((action, actionIndex) => (
                    <ActionItem
                      action={action}
                      actionIndex={actionIndex}
                      selectedGoalAction={selectedGoalAction}
                      key={action.id}
                    />
                  ))}
              </Styles.Wrapper>
            </>
          ) : (
            <></>
          )}
        </Styles.Planwrapper>
        {(isMobile || isTablet) && (
          <>
            <Styles.GoalsCol>
              <Styles.InputLabel $nonstyle>Actions</Styles.InputLabel>
            </Styles.GoalsCol>
            <Styles.Wrapper className="characters">
              {selectedGoalAction?.goal?.actions
                ?.filter((action) => action?.completed)
                ?.map((action, actionIndex) => (
                  <Styles.ActionItemWrapper >
                    <Styles.ActionItemHeader>
                      <Styles.ActionHeading>
                        {actionIndex + 1}
                      </Styles.ActionHeading>

                      <Styles.InputField>
                        <Styles.ActionName>{action?.context}</Styles.ActionName>
                      </Styles.InputField>
                    </Styles.ActionItemHeader>
                    <Styles.ActionsWrappers>
                      <Styles.ActionsWrapper
                        $action
                        style={{ marginRight: "2vw" }}
                      >
                        <Styles.GoalsCol $date>
                          <Styles.InputLabel>Startline</Styles.InputLabel>
                          <Styles.InputValue>
                          {action.startline ? moment(action.startline).format('YYYY-MM-DD') : null}
                          </Styles.InputValue>
                        </Styles.GoalsCol>

                        <Styles.GoalsCol $date>
                          <Styles.InputLabel>Deadline</Styles.InputLabel>
                          <Styles.InputValue>
                          {action?.deadline ? moment(action?.deadline).format('YYYY-MM-DD') : null}
                          </Styles.InputValue>
                        </Styles.GoalsCol>
                      </Styles.ActionsWrapper>
                      <Styles.ActionsWrapper
                        $action
                        style={{ marginRight: "2vw" }}
                      >
                      <Styles.GoalsCol $accountability $accountabilitySmall>
                        <Styles.InputLabel>Accountability</Styles.InputLabel>
                        <Styles.InputValue>
                        {action?.accountability || ' – '}
                        </Styles.InputValue>
                      </Styles.GoalsCol>

                      <Styles.GoalsCol $type>
                        <Styles.InputLabel>type</Styles.InputLabel>
                        <Styles.InputValue>
                        {action?.type ? (action.type === 'Action Item' ? 'Action' : action.type) : " – "}
                        </Styles.InputValue>
                      </Styles.GoalsCol>
                      </Styles.ActionsWrapper>
                    </Styles.ActionsWrappers>
                  </Styles.ActionItemWrapper>
                ))}
            </Styles.Wrapper>
          </>
        )}
      </Styles.TableContainer>
    </Wrapper>
  );
};

export default CompletedGoals;
