import { toast } from "react-toastify";
import { getSimplifiedError } from "./error";

function success(message: any): void {
  toast.success(message);
  console.log(`🟢 ${message}`);
}

function error(message: any, parse: boolean): void {
  const errorMessage = parse
    ? getSimplifiedError(message).toString()
    : message;

  toast.error(errorMessage);
  console.log('🔴 [error]', errorMessage);
}

// Notify object that groups the exported functions
const Notify = {
  success,
  error
};

export default Notify;
export { success, error };
