// @ts-ignore
import { prioritizeActionPlan } from "api";

// @ts-ignore
import { ActionTypes } from "./types";

// @ts-ignore
import { MasterPlanPrioritizeActionItems } from "../RetrieveMasterPlan/action";

export const PrioritizeActionPlan = (
    masterplanId: string,
    Data: FormData,
    { propagate = true, callback, showLoader = true }: { propagate?: boolean, callback?: (args?: any) => void, showLoader?: boolean } = {}
  ) => async (dispatch: any) => {
  try {
    let masterplan = "";

    if (masterplanId) {
      masterplan = `?masterplan=${masterplanId}`;
    }

    dispatch({ type: ActionTypes.LOADING, payload: showLoader });
    const { data } = await prioritizeActionPlan(masterplan, Data);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });

    if (propagate)
      dispatch(MasterPlanPrioritizeActionItems(data));

    if (callback)
      callback(data);
  } catch (error) {
    console.error('🔴', error)

    dispatch({
      type: ActionTypes.ERROR,
      payload: error,
    });
  }
};
