import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Button, InputArea } from "elements";

import {
  Wrapper,
  Modal,

  Header,
  HeaderHeading,
  HeaderTitle,
  CloseBtn,

  Body,
  Form,
  Label,

  Footer
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

interface IProps {
  active: boolean;
  data?: any;
  onClose: (goal_name?: string) => void;
}

const GoalForm: FC<IProps> = ({ active, data, onClose }) => {
  const [isRendered, setIsRendered] = useState(active);
  const [isActiveState, setIsActiveState] = useState(false);
  const [value, setValue] = useState("");

  const $input = useRef(null);

  useEffect(() => {
    if (active) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50); // Delay setting $active to true by 50ms
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500); // Delay unmounting by 500ms
    }
  }, [active]);

  useEffect(() => {
    if (isActiveState) {
      setTimeout(() => {
        if ($input && $input.current) {
          // @ts-ignore
          $input.current.focus();
        }
      }, 50);
    }
  }, [isActiveState]);

  const handleClose = () => {
    onClose();
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value ?? '');
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onClose(value.trim());
  }

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <Wrapper $active={isActiveState}>
      <Modal $active={isActiveState}>
        <Header>
          <HeaderHeading>
            <HeaderTitle>{!!data ? "Edit Goal" : "Create Goal"}</HeaderTitle>
          </HeaderHeading>

          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Header>

        <Body>
          <Form onSubmit={handleSubmit}>
            <Label>Your Goal</Label>
            <InputArea
              value={value}
              placeholder="Goal Name..."
              ref={$input}
              max={50}
              counter
              style={{ maxHeight: 180, overflowY: "auto" }}
              onChange={handleChange}
            />
            <Footer>
              <Button disabled={!value.trim().length}>Add</Button>
            </Footer>
          </Form>
        </Body>
      </Modal>
    </Wrapper>,
    document.getElementById("portal") as HTMLElement
  );
};

export default GoalForm;
