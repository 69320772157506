import styled from "styled-components";

interface BackgroundProps {
  $active: boolean;
}

export const Wrapper = styled.div<BackgroundProps>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transition: transform .25s, opacity .25s, visibility .25s;

  ${({ $active }) => $active && `
    opacity: 1;
    visibility: visible;
  `};
}`;

export const Modal = styled.div<BackgroundProps>`
  padding: 0 12px 12px 12px;
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 180px;
  background: #fff;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.25);
  gap: 12px;
}`;

export const Header = styled.div`
  padding: 9px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--fill-controller-gainsboro-pressed, #CED0D4);
`;

export const HeaderHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderTitle = styled.span`
  color: var(--Stroke-Text-Caption-Prussian-blue, #1B2A3D);
  font-variant-numeric: lining-nums tabular-nums;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const CloseBtn = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  transition: transform 0.25s;
  margin-left: 10px;

  svg .st0 {
    fill: #8E97A3;
  }

  &:hover {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: var(--Neutral-500, #737373);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 12px;
  gap: 10px;
`;
