import { useState, FC, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Tooltip as AntdTooltip } from "antd";
import { Button } from 'elements';
import Cookies from "js-cookie";

import {
  Content,
  Header,
  HeaderTitle,
  CloseBtn,
  Img,
  Title,
  Text,
  Footer,
} from "./styled";

// @ts-ignore
import styles from "./styles.module.scss";

// @ts-ignore
import leverageScreen from "assets/images/journeys/leverage/leverage-screen.png";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ToggleInfoSidebarAction } from "Redux/Global/action";

interface IInstructions {
  onClose?: () => void;
}

interface IProps {
  open?: boolean;
  children: ReactNode;
}

const CookieName = "goals_leverage_score_popup_hidden";

export const Instructions: FC<IInstructions> = ({ onClose }) => {
  const dispatch = useDispatch();

  const handleApply = () => {
    if (onClose) {
      onClose();
    }

    dispatch(ToggleInfoSidebarAction());
  };

  return (
    <>
      <Title>Add a leverage score to your goal</Title>
      <Text>Leverage score shows you how much an activity impacts other areas of your mission. Higher leverage means greater returns with fewer spent resources.<br /><br />Set a score for each goal to have Goal Manager automatically prioritize actions that will maximize your progress. Goals with higher scores have their action items prioritized over lower ones with.<br /><br />Update your scores to reflect your current priorities.</Text>

      <Footer>
        <Button onClick={handleApply}>Learn More</Button>
      </Footer>
    </>
  );
}

const IntroTooltip: FC<IProps> = ({ open, children, ...rest }) => {
  const isPopupHidden = Cookies.get(CookieName) === "true";

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (open && !isPopupHidden) {
      setIsActive(true);
    }
  }, [open, isPopupHidden])

  const handleClose = () => {
    setCookie();
    setIsActive(false);
  };  

  const setCookie = () => {
    Cookies.set(CookieName, "true", { expires: 7 });
  }

  if (!isActive) {
    return <>{children}</>;
  }

  const content: () => JSX.Element = () => (
    <Content>
      <Header>
        <HeaderTitle>Leverage Score</HeaderTitle>
        <CloseBtn onClick={handleClose}>
          <CloseIcon />
        </CloseBtn>
      </Header>

      <Img src={leverageScreen} alt="" />
      <Instructions onClose={handleClose} />
    </Content>
  );

  return (
    <AntdTooltip
      open
      title={content()}
      overlayClassName={styles.primary}
      placement={"rightTop"}
      // @ts-ignore
      getPopupContainer={(trigger) => trigger.parentNode.parentNode.parentNode}
      align={{
        offset: [10, -20],
        overflow: {
          adjustY: false
        }
      }}
      {...rest}
    >
      {children}
    </AntdTooltip>
  );
};

export default IntroTooltip;
