import styled from 'styled-components';
import DefaultAvatar from '../../../assets/images/DefaultAvatar.png';

export const ProfileImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50px;
  left: 32px;

  ${({ theme }) => theme.max('sm')`
    width: 100px;
    height: 100px;
    top: 56px;
  `}
  ${({ theme }) => theme.max('md')`
    width: 95px;
    height: 95px;
    top: 56px;
  `}
`;

export const ProfileImage = styled.img`
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  background-image: url(${DefaultAvatar});
  border: 2px solid #fff;

  ${({ theme }) => theme.max('sm')`
    width: 100px;
    height: 100px;
  `}
  ${({ theme }) => theme.max('md')`
    width: 95px;
    height: 95px;
  `}
`;

export const ProfilePlanIcon = styled.img`
  position: absolute;
  right: -8px;
  top: -2px;
`;

export const EditProfileIcon = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 28px;
  width: 28px;
  cursor: pointer;
`;

export const FileUploader = styled.input`
  display: none;
`;
