import { useState } from 'react';
import { Button } from 'elements';
import Popup from './items/Popup';

// @ts-ignore
import { ReactComponent as PieIcon } from "assets/images/priorities/pie.svg";

// @ts-ignore
import { ReactComponent as ChevronLeftIcon } from "assets/images/priorities/chevron-left-primary.svg";

const TimeAllocation = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(true);
  }

  const handleClose = () => {
    setIsActive(false);
  }

  return (
    <>
      <Popup active={isActive} onClose={handleClose} />

      <Button styleType="secondary" size="small" onClick={handleClick}>
        <ChevronLeftIcon />
        Time Allocation
        <PieIcon />
      </Button>
    </>
  )
}

export default TimeAllocation;
