import React from "react";
import { Wrapper, Head, SubTitle, Title } from "./styled";

const WeeklyDuration: React.FC = () => {
  return (
    <Wrapper>
      <Head>
        <Title>Estimated Weekly Duration</Title>
      </Head>
      <SubTitle data-testid="subtitle">
        How much time will it take you to complete this task weekly?
      </SubTitle>
    </Wrapper>
  );
};

export default WeeklyDuration;
