export const items = [
  {
    title: "Roles",
    label: "Roles",
    step: 1,
    key: "roles",
    color: "#627CFC",
    placeholder: "Select or enter your own",
    error: "Select up to 3 Roles",
    disc: "What roles do you see yourself playing in this area?",
    tooltipDesc: "The role that you play in carrying out the responsibilities that come with that area of your life.",
    suggestion: "Select suggested Roles from the menu below.",
    flag: false,
    nested: false
  },
  {
    title: "Values",
    step: 2,
    label: "Values",
    key: "values",
    placeholder: "Select or enter your own",
    error: "Select up to 3 Values",
    color: "#6D71F5",
    disc: "What values are non-negotiable for you?",
    suggestion: "Select the Values from the menu below.",
    flag: false,
    nested: false,
    tooltipDesc: "Your values represent what’s important to you and helps you understand what drives you, what you enjoy, what inspires you and what you’d like more of.",
  },
  {

    label: "Principles",
    title: "Principles",
    placeholder: "I believe that as {role}",
    step: 3,
    key: "principle",
    disc: "What principles guide your actions?",
    suggestion: "Enter your own Principles below.",
    color: "#815DE9",
    flag: false,
    nested: true,
    tooltipDesc: "Principles are mindsets or beliefs that guide us to live consistently by our values.",
  },
  {
    label: "Code of Conduct",
    placeholder: "I will",
    title: "Code of Conduct",
    key: "code_of_conduct",
    disc: "What behavior is acceptable and unacceptable for you?",
    suggestion: "Enter your own Code of Conduct below.",
    step: 4,
    color: "#904DE0",
    flag: false,
    nested: true,
    tooltipDesc: "These are rules and standards for how to act and behave that you always follow.",
  },
  {
    label: "Purpose (Statement)",
    placeholder: "I do this because",
    title: "Purpose",
    disc: "What impact do you aim to have?",
    suggestion: "Write your own Purpose into text area below.",
    step: 5,
    key: "purpose",
    color: "#A03CD6",
    flag: false,
    nested: true,
    tooltipDesc: "Define how each role will help you complete your life’s mission. Why is this role important? Who will you impact?",
  },
  {
    label: "Vision (Phrase)",
    placeholder: "I envision a future where",
    title: "Vision",
    step: 6,
    key: "vision",
    color: "#B02CCC",
    disc: "How will your dream life look?",
    suggestion: "Select a suggested Vision or enter your own below.",
    flag: true,
    nested: true,
    tooltipDesc: "How you see yourself when fully engaging this area of your life. This is your dream state.",
  },
  {
    label: "Mission Statement",
    title: "Mission Statement",
    step: 7,
    color: "#61E1AE",
    disc: "How will your dream life look?",
    suggestion: "Select a suggested Vision or enter your own below.",
    flag: false,
    nested: true,
    tooltipDesc: "The role that you play in carrying out the responsibilities that come with that area of your life.",
  },
];

export const steps = [
  {
    label: "Roles",
    value: 1,
  },
  {
    label: "Values",
    value: 2,
  },
  {
    label: "Principles",
    value: 3,
  },
  {
    label: "Code of Conduct",
    value: 4,
  },
  {
    label: "Purpose",
    value: 5,
  },
  {
    label: "Vision",
    value: 6,
  },
  {
    label: "Mission Statement",
    value: 7,
  },
];
