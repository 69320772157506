import styled from "styled-components";
import { Menu } from "antd";

export const Custombutton = styled.button`
  gap: 6px;
  height: 32px;
  width: 136px;
  padding: 6px;
  border-radius: 2px 0px 0px 0px;
  opacity: 0px;
  color: white;
  background: #ff9900;
  font-family: inter;
  font-bold: 500;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
`;

export const Custom = styled.div`
  .ant-dropdown .ant-dropdown-menu {
    width: 100px; /* Ensure the menu stretches to fill the dropdown */
  }

  .ant-dropdown-menu {
    width: 120px !important;
  }
`;

export const CustomMenu = styled(Menu)`
  width: 137px !important;
`;

export const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
