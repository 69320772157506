import styled from 'styled-components';

export const Toggle = styled.div<{ $free?: boolean; $secondary?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  transition: transform .25s;

  ${({ $free }) => $free && `
    width: initial;
  `}

  ${({ $secondary }) => $secondary && `
    width: initial;
    height: initial;
  `}

  ${({ $secondary }) => $secondary && `
    width: initial;
    height: initial;
  `}

  &:active {
    transform: scale(0.95);
  }
`;

export const Item = styled.div<{ $active?: boolean; $disabled?: boolean; $free?: boolean; $secondary?: boolean }>`
  display: flex;
  align-items: center;
  padding: 18px;
  color: var(--neutral-600, #525252);
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  max-width: 192px;

  ${({ $secondary }) => $secondary && `
    max-width: 168px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 6px 10px;
    width: 100%;
  `}

  ${({ $disabled }) => $disabled && `
    opacity: .5;
    pointer-events: none;
  `}

  ${({ $active }) => $active && `
    background: var(--primary-50, #ECF3F6);
    color: #1271A6;
    font-weight: 600;
  `}

  ${({ $free }) => $free && `
    width: initial;
  `}

  svg {
    margin-right: 12px;
  }
`;
