import { Modal } from "antd";
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  padding: 60px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Icon = styled.img`
  width: 40px;
  margin-bottom: 12px;
`;

export const Title = styled.span`
  color: var(--Oxford-blue, #06203A);
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const Text = styled.span`
  color: var(--Oxford-blue, #06203A);
  font-family: Inter;
  font-size: 14px;
  line-height: 24px;
  max-width: 486px;
`;

export const Footer = styled.div`
  margin-top: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
`;
