import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";

// @ts-ignore
import { getCookie } from "utils/StorageVariables";

// @ts-ignore
import SubHeader from "components/Scheduler/SubHeader";
import PremiseScreen from "components/Journeys/Premise";

import { JOURNEY_PATH } from 'utils/routes';

const Premise = () => {
  const domainName = getCookie("domainName");

  const navigate = useNavigate();

  useEffect(() => {
    if (!domainName) {
      navigate(JOURNEY_PATH, { replace: true });
    }
  }, [domainName]);

  return (
    <MainContainer>
      <InnerContainer>
        <SubHeader
          title={`${domainName} Journey`}
        />

        <Dashboard>
          <PremiseScreen />
        </Dashboard>
      </InnerContainer>
    </MainContainer>
  );
};

export default Premise;

const MainContainer = styled.div`
  display: flex;
  height: calc(100vh - 26px); // 60
  background: var(--primary-50, #ECF3F6);
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--primary-50, #ECF3F6);
`;

const Dashboard = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  gap: 18px;
`;
