import styled from "styled-components";

export const Content = styled.div<{ $color?: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 325px;
  border-radius: 4px;
  border-top: 3px solid;
  border-top-color: ${({ $color }) => $color ? $color : '#737373'};
  background: var(--Dark-mode-bg, #FFF);
  padding: 16px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
`;

export const Title = styled.span`
  color: var(--Neutral-900, var(--Color-2, #171717));
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
`;

export const Text = styled.span`
  color: var(--Neutral-700, var(--Input-heading, #404040));
  font-family: Inter;
  font-size: 12px;
  line-height: 20px;
}`

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}`
