import styled from "styled-components";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: default;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover ${Button} {
    visibility: visible;
    opacity: 1;
  }
`;
