import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useDeviceType from "../../../Hooks/ResponsiveHook";
import { getCookie, setCookie } from "utils/StorageVariables";
import { Drawer } from "antd";
import GoalsGroup from "./GoalsGroup";
import Sidebar from "../../Journeys/Sidebar";
import { Wrapper, Content } from "./styled";
import { ToggleSidebarAction } from "Redux/Global/action";
import { RetrieveMasterPlanDetails } from "Redux/RetrieveMasterPlan/action";
import { SetSelectedGoalAction } from "Redux/Global/action";
import { UpdateLastStep } from "Redux/UpdateLastStep/action";

const SortArr = (data) => {
  if (!data) return [];
  return data?.sort(function (a, b) {
    return a?.goal?.priority - b?.goal?.priority;
  });
};

const List = () => {
  const dispatch = useDispatch();
  const screenSizes = useDeviceType();
  const isMobile = screenSizes === "mobile";
  const isTablet = screenSizes === "iPad";

  const { domainList } = useSelector((state) => state.domainListReducer);
  const { masterPlanDetails } = useSelector(
    (state) => state.RetrieveMasterPlanDetailsReducer
  );
  const { selectedGoalAction } = useSelector((state) => state.globalReducer);

  const sortedDomainList = (domainList ?? []).filter(
    (item) => item.current_state.status !== "Not started"
  );
  const sortedData = SortArr(masterPlanDetails?.areas ?? []);

  useEffect(() => {
    let MasterPlanId = getCookie("MasterPlanId");
    dispatch(RetrieveMasterPlanDetails(MasterPlanId));
  }, []);

  useEffect(() => {
    if (!masterPlanDetails) return;

    if (!sortedData.length) return;

    const index = sortedData.findIndex((element) => element?.goal?.completed);

    if (selectedGoalAction?.parent_masterplan !== masterPlanDetails.id) {
      dispatch(
        SetSelectedGoalAction({
          ...sortedData[index],
          i: index,
        })
      );
    }
    else if (!selectedGoalAction?.goal?.completed) {
      dispatch(SetSelectedGoalAction({
        i: -1
      }));
    }
  }, [sortedData, masterPlanDetails]);

  const onToggleHandle = (key) => {
    const domain = sortedDomainList.find((item) => item.id === key);

    const id = domain?.id,
      masterId = domain?.current_state?.id,
      domainName = domain?.name;

    setCookie("MasterPlanId", masterId);
    setCookie("domainName", domainName);
    setCookie("JourneyId", id);
    setCookie("domainId", id);

    const stepFormData = new FormData();
    stepFormData.append("step", 0);

    dispatch(UpdateLastStep(stepFormData));
    dispatch(RetrieveMasterPlanDetails(masterId));
  };

  const onGoalToggleHandle = (key) => {
    const index = masterPlanDetails?.areas.findIndex((item) => item.id === key);

    if (index === -1) return;

    dispatch(
      SetSelectedGoalAction({
        ...masterPlanDetails?.areas[index],
        i: index,
      })
    );

    (isMobile || isTablet) && onTogglerClickHandle();
  };

  const { toggledSidebar } = useSelector((state) => state.globalReducer);

  const onTogglerClickHandle = () => {
    dispatch(ToggleSidebarAction(toggledSidebar === "left" ? "" : "left"));
  };

  return (
    <>
      {!isMobile && !isTablet ? (
        <Sidebar title="Goals" withoutToggle>
          <Wrapper>
            <Accordion
              as={Content}
              activeKey={selectedGoalAction?.parent_masterplan}
            >
              {sortedDomainList.map((item) => (
                <GoalsGroup
                  activeKey={masterPlanDetails?.id}
                  data={item}
                  goalsData={
                    selectedGoalAction?.parent_masterplan ===
                    item?.current_state.id
                      ? masterPlanDetails?.areas ?? []
                      : []
                  }
                  selectedGoal={selectedGoalAction?.id}
                  onGoalToggle={onGoalToggleHandle}
                  onToggle={onToggleHandle}
                  key={item.id}
                />
              ))}
            </Accordion>
          </Wrapper>
        </Sidebar>
      ) : (
        <>
          <Drawer
            width={isTablet ? "40%" : "60%"}
            placement="left"
            onClose={onTogglerClickHandle}
            open={toggledSidebar}
            headerStyle={{ padding: 0 }}
            closable={false}
            getContainer={false}
            style={{ position: "absolute", top: "0px" }}
            bodyStyle={{ padding: 0 }}
          >
            <Sidebar CustomWidth={100} title="Goals" active={0}>
              <Wrapper>
                <Accordion
                  as={Content}
                  activeKey={selectedGoalAction?.parent_masterplan}
                >
                  {sortedDomainList.map((item) => (
                    <GoalsGroup
                      activeKey={masterPlanDetails?.id}
                      data={item}
                      goalsData={
                        selectedGoalAction?.parent_masterplan ===
                        item?.current_state.id
                          ? masterPlanDetails?.areas ?? []
                          : []
                      }
                      selectedGoal={selectedGoalAction?.id}
                      onGoalToggle={onGoalToggleHandle}
                      onToggle={onToggleHandle}
                      key={item.id}
                    />
                  ))}
                </Accordion>
              </Wrapper>
            </Sidebar>
          </Drawer>
        </>
      )}
    </>
  );
};

export default List;
