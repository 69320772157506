function NewIcon({ color = '#fff', darkColor = '#fff', ...props }) {
  return (
    <svg
      width={40}
      height={27}
      viewBox="0 0 40 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 27V22H0L6 27Z" fill={darkColor} />
      <g filter="url(#filter0_d_18665_183049)">
        <path d="M0 2C0 0.89543 0.895431 0 2 0H36C37.1046 0 38 0.895431 38 2V20C38 21.1046 37.1046 22 36 22H0V2Z" fill={color} />
      </g>
      <path d="M14.878 6.72727L14.878 14L13.8695 14L10.1728 8.66619H10.1053L10.1053 14L9.00799 14L9.00799 6.72727L10.0236 6.72727L13.7239 12.0682H13.7914L13.7914 6.72727L14.878 6.72727ZM18.7443 14.1101C18.2069 14.1101 17.7441 13.9953 17.3558 13.7656C16.9699 13.5336 16.6716 13.2081 16.4609 12.7891C16.2526 12.3677 16.1484 11.8741 16.1484 11.3082C16.1484 10.7495 16.2526 10.2571 16.4609 9.83097C16.6716 9.40483 16.9652 9.07221 17.3416 8.8331C17.7204 8.59399 18.1631 8.47443 18.6697 8.47443C18.9775 8.47443 19.2758 8.52533 19.5646 8.62713C19.8535 8.72893 20.1127 8.88873 20.3423 9.10653C20.572 9.32434 20.7531 9.60724 20.8857 9.95526C21.0182 10.3009 21.0845 10.7211 21.0845 11.2159V11.5923L16.7486 11.5923V10.7969L20.044 10.7969C20.044 10.5175 19.9872 10.2701 19.8736 10.0547C19.7599 9.83688 19.6001 9.66525 19.3942 9.53977C19.1906 9.4143 18.9515 9.35156 18.6768 9.35156C18.3786 9.35156 18.1181 9.42495 17.8956 9.57173C17.6754 9.71615 17.505 9.90554 17.3842 10.1399C17.2659 10.3719 17.2067 10.6241 17.2067 10.8963V11.5178C17.2067 11.8823 17.2706 12.1925 17.3984 12.4482C17.5286 12.7038 17.7098 12.8991 17.9418 13.0341C18.1738 13.1667 18.4448 13.233 18.755 13.233C18.9562 13.233 19.1397 13.2045 19.3054 13.1477C19.4711 13.0885 19.6143 13.0009 19.7351 12.8849C19.8558 12.7689 19.9482 12.6257 20.0121 12.4553L21.017 12.6364C20.9366 12.9323 20.7921 13.1915 20.5838 13.4141C20.3778 13.6342 20.1186 13.8059 19.8061 13.929C19.496 14.0497 19.142 14.1101 18.7443 14.1101ZM23.4354 14L21.8303 8.54545L22.9276 8.54545L23.9964 12.5511H24.0497L25.1222 8.54545L26.2195 8.54545L27.2848 12.5334H27.3381L28.3999 8.54545H29.4972L27.8956 14L26.8125 14L25.7045 10.0618H25.6229L24.5149 14L23.4354 14Z" fill="white" />
      <path d="M38 12.9625C19.561 13.6539 6.98374 4.6089 3 0H38V12.9625Z" fill="white" fillOpacity="0.2" />
      <defs>
        <filter id="filter0_d_18665_183049" x="0" y="0" width="40" height="24" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18665_183049" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18665_183049" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default NewIcon;
