import styled from "styled-components";

export const DropdownToggler = styled.button<{ $disabled?: boolean; $empty?: boolean }>`
  width: 100%;
  display: flex;
  background: transparent;
  // border: none;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
  color: #1B2A3D;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  transition: box-shadow .3s;
  outline: none;
  // border-radius: 4px;
  // padding: 1px 0;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid var(--Neutral-200, #E5E5E5);

  img {
    transform: rotate(90deg);
  }

  ${({ $disabled }) => !$disabled && `
    &:focus,
    &:active {
      box-shadow: 0px 0px 0px 2px #D0E3ED;
    }
  `}

  ${({ $disabled }) => $disabled && `
    cursor: default;
  `}

  ${({ $empty }) => $empty && `
    color: #A3A3A3;
  `}
`;
