import Notify from "utils/notify";
import { addPrioritizedActivityToFocusedActivities } from "../apm-apis";
import { ActionTypes } from "./type";

import { listFocusedPrioritise } from "../ListFocusedPriorities/action";
import { openPopup, closePopup } from "../ListFocusedExtremes/action";
import { fetchTasks } from "../../APM/ListTasks/action";


export const addPrioritizedActivityToFocused = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_PRIORITIZED_TO_FOCUSED });

    const { data, status } = await addPrioritizedActivityToFocusedActivities(payload);

    dispatch(listFocusedPrioritise());
    dispatch({
      type: ActionTypes.UPDATE_PRIORITIZED_TO_FOCUSED_SUCCESS,
      payload: data,
    });

    if (status === 201) {
      dispatch(fetchTasks());
      dispatch(closePopup());
      Notify.success("Successfully added to focused activities");
    }
  } catch (error) {
    const { popup } = getState().listFocusedExtremes;

    if (error.response?.status === 428 && !popup) {
      dispatch(openPopup(payload));
    } else {
      Notify.error(error, true);
    }

    dispatch({
      type: ActionTypes.UPDATE_PRIORITIZED_TO_FOCUSED_FAILURE,
      payload: error.response?.status,
    });
  }
};
