import React from 'react';

import LegalTerms from '../../../components/Auth/LegalTerms';
import Layout from '../../../components/Auth/Layout';

const TermsOfUse = () => {
  return (
    <Layout bg="terms">
      <LegalTerms active readOnly />
    </Layout>
  );
}

export default TermsOfUse;
