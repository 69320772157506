import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Styled from "./styled";

import Logout from "components/Auth/Components/Logout";
import useDeviceType from "../../../Hooks/ResponsiveHook";

import { ReactComponent as DefaultLogo } from "assets/images/header_logo_modern.svg";
import { ReactComponent as ShortLogo } from "assets/images/short_logo.svg";
import { ReactComponent as Menu } from "assets/images/menuBar.svg";

import { INDEX_PATH, WELCOME_PATH } from "utils/routes";

const Layout = ({ bg, children }) => {
  const screensSizes = useDeviceType();
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setIsMobile(screensSizes === "mobile" || screensSizes === "iPad");
  }, [screensSizes]);

  useEffect(() => {
    setAnimate(false);

    setTimeout(() => {
      setAnimate(true);
    }, 100);
  }, [bg]);

  return (
    <Styled.Wrapper>
      <Styled.Background $bg={bg} $animate={animate} $animateDuration={500} />
      <Styled.Header>
        <Styled.HeaderLink
          to={
            location.pathname.includes(WELCOME_PATH) ? WELCOME_PATH : INDEX_PATH
          }
        >
          {isMobile ? <ShortLogo height={30} /> : <DefaultLogo height={56} />}
        </Styled.HeaderLink>

        <Logout />
        {/* {isMobile && (
          <Styled.HeaderRight>
            <Styled.HeaderLink
              to={
                WELCOME_PATH
              }
            >
              <Menu height={26} />
            </Styled.HeaderLink>
          </Styled.HeaderRight>
        )} */}
      </Styled.Header>

      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export default Layout;
