import { TypeChar } from './styled';

interface TypeBadgeProps {
  type?: string;
  width?: number | undefined;
  height?: number | undefined;
}

const colors: { [key: string]: string } = {
  a: '#FF9800',
  h: '#4CAF50',
  t: '#FBC02D',
  d: '#2196F3'
}

const TypeBadge: React.FC<TypeBadgeProps> = ({ type, width, height }) => {
  const char = type && type[0] ? type[0].toLowerCase() : null;
  const color = char ? colors[char] : null;

  return char ? (
    <TypeChar $color={color} $width={width ? width : 0} $height={height ? height : 0}>
      {char.toUpperCase()}
    </TypeChar>
  ) : null;
}

export default TypeBadge;
