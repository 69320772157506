import { FC, useEffect, useState, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Accordion } from "react-bootstrap";
import { Spinner } from "elements";

import Item from "./Item";
import Print from "./Print";
import JourneyStatement from "./JourneyStatement";

import {
  Wrapper,
  Background,

  Header,
  HeaderHeading,
  HeaderTitle,
  CloseBtn,

  PrintRow,
  PrintRowContent,

  Body,
  List,
  ListTitle,

  SpinnerWrapper,
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as MissionIcon } from "assets/svg/statmentIcon.svg";

import { ListMissionStatements } from "Redux/ListMissionStatements/action";
import { IMissionStatement } from "Redux/ListMissionStatements/types";

interface IProps {
  isActive: boolean;
  onClose: () => void;
}

interface IStateSlice {
  listMissionStatements: {
    data: IMissionStatement[];
    loading: boolean;
  }
}

const MissionStatements: FC<IProps> = ({ isActive, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRendered, setIsRendered] = useState(isActive);
  const [isActiveState, setIsActiveState] = useState(false);
  const [activeItem, setActiveItem] = useState("");

  // @ts-ignore
  const { masterPlanDetails } = useSelector((state) => state.RetrieveMasterPlanDetailsReducer);
  const { data, loading } = useSelector((state: IStateSlice) => state.listMissionStatements);

  useLayoutEffect(() => {
    if (data.length)
      setActiveItem(data[0].id);
  }, [data]);

  useEffect(() => {
    if (isActive) {
      setIsRendered(true);

      dispatch(ListMissionStatements());
  
      setTimeout(() => {
        setIsActiveState(true);
      }, 50);
    } else {
      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 250);
    }
  }, [isActive]);

  const handleToggle = (key: string) => {
    setActiveItem((prev) => (prev === key ? "" : key));
  };

  const handleEdit = (id: string) => {
    navigate('?mission-statement=' + id);
    onClose();
  };

  const handleClose = () => {
    onClose();
  }

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <>
      <Wrapper $active={isActiveState}>
        <Header>
          <HeaderHeading>
            <MissionIcon />
            <HeaderTitle>Mission Statements</HeaderTitle>
          </HeaderHeading>

          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>
        </Header>

        <PrintRow>
          <PrintRowContent>
            <Print data={data} journeyData={masterPlanDetails?.mission_statement} />
          </PrintRowContent>
        </PrintRow>

        {loading ? (
          <SpinnerWrapper>
            <Spinner variant="secondary" />
          </SpinnerWrapper>
        ) : (
          <Body>
            <JourneyStatement data={masterPlanDetails?.mission_statement} />

            <ListTitle>Mission Statements</ListTitle>
            <Accordion as={List} activeKey={activeItem}>
              {data.map((area) => (
                <Item
                  data={area}
                  key={area.id}
                  onToggle={handleToggle}
                  onEdit={handleEdit}
                />
              ))}
            </Accordion>
          </Body>
        )}
      </Wrapper>

      <Background
        $active={isActiveState}
        onClick={isActiveState ? handleClose : undefined}
      />
    </>,
    document.getElementById("portal") as HTMLElement
  );
}

export default MissionStatements;
