import PropTypes from 'prop-types';
import { Wrap, Text, StyledLink } from "./styled";

const EmptyPlaceholder = ({ title, linkPath, linkLabel, linkStyle = 'primary', linkIcon: LinkIcon }) => {
  return (
    <Wrap>
      <Text>{title}</Text>
      <StyledLink $style={linkStyle} to={linkPath}>{linkLabel} {LinkIcon && <LinkIcon />}</StyledLink>
    </Wrap>
  );
}

EmptyPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  linkStyle: PropTypes.oneOf([/* 'primary',  */'secondary', 'ternary']),
  linkIcon: PropTypes.elementType
}

export default EmptyPlaceholder;
